import React from 'react';
import type { ComponentProps, ReactNode } from 'react';
import classnames from 'classnames';

import { formatActivityDate, showQuarter } from 'services/date';
import {
  LABEL_ACTABILITY_DATE,
  LABEL_DELIVERY_DATE,
  LABEL_END_DATE_TMA,
  LABEL_MARKETING_DATE,
} from 'settings/labels';
import { PROGRAM_PHASE_MARKETING, PROGRAM_PHASE_PREVIEW } from 'settings/programs';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import ProgramBoosterTop from './ProgramBoosterTop';
import ProgramActions from './ProgramActions';

import styles from './ProgramBooster.module.scss';

type Dates = {
  key: keyof ProgramTypeV2['dates'];
  label: string;
  formatter?: (dateStr: string) => ReactNode;
}[];
const DATES_DEFAULT: Dates = [
  {
    key: 'actabilite',
    label: LABEL_ACTABILITY_DATE,
  },
  {
    key: 'livraison',
    label: LABEL_DELIVERY_DATE,
  },
  {
    key: 'fermetureTMATech',
    label: LABEL_END_DATE_TMA,
  },
];
const DATES_PREVIEW: Dates = [
  {
    key: 'lancementCommercial',
    label: LABEL_MARKETING_DATE,
    formatter: dateStr => formatActivityDate(dateStr, 'DD/MM/YYYY'),
  },
  {
    key: 'livraison',
    label: LABEL_DELIVERY_DATE,
  },
];

interface ProgramBoosterProps {
  allLots: ComponentProps<typeof ProgramActions>['allLots'];
  allPrograms: ComponentProps<typeof ProgramActions>['allPrograms'];
  className?: string;
  generateProgramPdf: () => void;
  program: ProgramTypeV2;
  programPdf: ComponentProps<typeof ProgramActions>['programPdf'];
  taxById: TaxesById;
  waitForPdfDatas?: boolean;
}

export default function ProgramBooster({
  allLots,
  allPrograms,
  className,
  generateProgramPdf,
  program,
  programPdf,
  taxById,
  waitForPdfDatas = false,
}: ProgramBoosterProps) {
  const { dates, phaseCommerciale: commercialPhase } = program;
  const dateLabels = commercialPhase === PROGRAM_PHASE_PREVIEW ? DATES_PREVIEW : DATES_DEFAULT;

  return (
    <div className={classnames(className, styles.root)}>
      <ProgramBoosterTop className={styles.top} program={program} />

      {dateLabels.some(({ key }) => dates[key]) && (
        <div className={styles.infos}>
          {program.phaseCommerciale === PROGRAM_PHASE_MARKETING && (
            <div className={styles.separator}>
              <div className={styles.separatorContent} />
            </div>
          )}

          <dl className={styles.dates}>
            {dateLabels.map(({ key, label, formatter = showQuarter }) =>
              dates[key] ? (
                <div key={key} className={styles.date}>
                  <dt className={styles.dateTitle}>{label}</dt>
                  <dd className={styles.dateValue}>{formatter(dates[key])}</dd>
                </div>
              ) : null
            )}
          </dl>
        </div>
      )}

      <div className={styles.actionsWrapper}>
        <div className={styles.actions}>
          <ProgramActions
            allLots={allLots}
            allPrograms={allPrograms}
            generateProgramPdf={generateProgramPdf}
            program={program}
            programPdf={programPdf}
            taxById={taxById}
            waitForPdfDatas={waitForPdfDatas}
          />
        </div>
      </div>
    </div>
  );
}
