import React, { useContext, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';

import { LABEL_SEARCH_NO_RESULTS, LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS } from 'settings/labels';
import { LOTS_PER_PAGE_TABLET } from 'settings/lots';
import { TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS } from 'settings/taxes';

import type {
  HeadingType,
  LotJson,
  LotSortBy,
  LotSortOrder,
} from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { SelectedLotType } from 'types/lots';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getLotHeadings } from 'services/lotsV2';

import { useInfinitePagerWithTyped } from 'hooks/useInfinitePager';
import { useSortedLots } from 'hooks/useSortedLots';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';

import LotTableTablette from './LotTableTablette';

import styles from './LotsList.module.scss';

interface LotsListTabletteProps {
  allLots: ComponentProps<typeof LotTableTablette>['allLots'];
  allPrograms: ComponentProps<typeof LotTableTablette>['allPrograms'];
  displayAllHeader: boolean;
  isLoading?: boolean;
  lots: LotJson[];
  lotsSortBy: LotSortBy;
  lotsSortOrder: LotSortOrder;
  programs?: ProgramListType[];
  pageTemplate?: string;
  pageTemplateRef?: string;
  selectedLots: SelectedLotType[];
  setSelectedLots: (selectedLots: SelectedLotType[]) => void;
}

export default function LotsListTablette({
  allLots,
  allPrograms,
  displayAllHeader,
  isLoading = false,
  lots,
  lotsSortBy,
  lotsSortOrder,
  programs = [],
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  selectedLots,
  setSelectedLots,
}: LotsListTabletteProps) {
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  const [lotsPage, setLotsPage] = useState(0);

  let programsRef: { programRef: string }[] = [];
  if (programs?.length > 0) {
    programsRef = programs.map(program => ({ programRef: program.ref }));
  } else if (lots?.length > 0) {
    programsRef = lots?.map(lot => ({ programRef: lot.ref }));
  }
  const { lots: sortedLots, isLoading: isSortLoading } = useSortedLots(
    lots,
    lotsSortBy,
    lotsSortOrder,
    programsRef
  );
  const { locations, programRef, taxes = [], nearProgram } = useSearch();

  const { paginatedItems: paginatedLots, hasMoreItems: hasMoreLots } = useInfinitePagerWithTyped<
    LotJson
  >(lotsPage, LOTS_PER_PAGE_TABLET, sortedLots);
  const activeLots = useMemo(() => {
    if (!programRef) {
      return [];
    }
    const activeLots = paginatedLots.filter(lot => lot.ref === programRef);
    return activeLots.length ? activeLots : sortedLots.filter(lot => lot.ref === programRef);
  }, [programRef, paginatedLots, sortedLots]);
  const taxeFormatted = taxes && taxes.length === 1 ? taxesById?.[taxes[0]] : undefined;
  const headings = useMemo(() => {
    const isPinel =
      taxesById &&
      taxes.every(taxId =>
        [TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS].includes(taxesById[taxId])
      );
    return getLotHeadings(
      !displayAllHeader,
      isPinel && taxes.length > 1 ? TAX_TYPE_PINEL : taxeFormatted,
      true,
      false,
      'tablet'
    ) as HeadingType[];
  }, [taxes, taxesById, displayAllHeader]);

  if (isLoading || isSortLoading || isTaxesLoading) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  const displayAnnex = !!nearProgram && locations.length === 1;

  return (
    <div className={styles.lotsList}>
      {(!paginatedLots?.length || displayAnnex) && (
        <div className={styles.noResults}>
          <span>{LABEL_SEARCH_NO_RESULTS}</span>
          {displayAnnex && !!paginatedLots?.length && (
            <span>{LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS}</span>
          )}
        </div>
      )}
      <LotTableTablette
        activeLots={activeLots}
        activeProgramRef={programRef}
        allLots={allLots}
        allPrograms={allPrograms}
        currentPage={lotsPage}
        headings={headings}
        lots={paginatedLots}
        taxById={taxesById}
        handleOnChangeCheckbox={(programRef, lotNumber) => {
          if (
            selectedLots.find(
              selectedLot =>
                selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber
            )
          ) {
            setSelectedLots(
              selectedLots.filter(
                selectedLot =>
                  !(selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber)
              )
            );
            return;
          }
          setSelectedLots([...selectedLots, { programRef, lotNumber }]);
        }}
        loadMore={() => setLotsPage(prev => prev + 1)}
        hasMore={hasMoreLots}
        selectedLots={selectedLots}
        pageTemplate={pageTemplate}
        pageTemplateRef={pageTemplateRef}
      />
    </div>
  );
}
