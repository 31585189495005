import React, { useContext, useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import useSWR from 'swr';
import memoize from 'memoizee';

import { LOT_JSON_ATTRIBUTION, LOT_JSON_NUMBER, LOT_JSON_PROGRAM_REF } from 'settings/lots';

import type { LotExport } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramExport } from 'api/viOffresAPI/apiTypes/Program';

import programLotContext from 'modules/App/Contexts/programLotContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import { userContext } from 'modules/App/Contexts/userContext';

import { formatLot } from 'services/lotsV2';
import { formatProgramsSwitchedOff, formatProgramsSwitchedOn } from 'services/programs';

export default function ProgramLotProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { settings } = useContext(settingsContext);
  const isAttributionSwitchedOn = !!settings?.cdo?.liste_attribution;
  const { exclusionList, userCrm } = useContext(userContext);
  const { taxesById } = useContext(TaxonomiesContext);

  const { data: lots, isValidating: isLotsValidating } = useSWR<LotExport[]>(
    window.vinci.REACT_APP_STORAGE_LOTS_PATH,
    () =>
      fetch(`${window.vinci.REACT_APP_STORAGE_LOTS_PATH}?${new Date().getTime()}`).then(stream =>
        stream.json()
      ),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  const processedLots = useMemo(() => {
    if (!lots?.length) {
      return [];
    }
    if (isAttributionSwitchedOn) {
      if (!exclusionList) {
        return [];
      }
      return lots.filter(
        lot =>
          !exclusionList.some(
            ([listRef, listLotNumber]) =>
              listRef.toUpperCase() === lot[LOT_JSON_PROGRAM_REF].toUpperCase() &&
              listLotNumber.toUpperCase() === lot[LOT_JSON_NUMBER].toUpperCase()
          )
      );
    }
    return lots.filter(lot =>
      userCrm?.extension_VI3P_ListeAttribution !== undefined
        ? lot[LOT_JSON_ATTRIBUTION].includes(userCrm?.extension_VI3P_ListeAttribution)
        : false
    );
  }, [isAttributionSwitchedOn, userCrm?.extension_VI3P_ListeAttribution, lots, exclusionList]);

  const { data: programs, isValidating: isProgramsValidating } = useSWR<ProgramExport[]>(
    window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH,
    () =>
      fetch(
        `${window.vinci.REACT_APP_STORAGE_PROGRAMS_PATH}?${new Date().getTime()}`
      ).then(stream => stream.json()),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  const processedPrograms = useMemo(() => {
    if (!programs?.length) {
      return [];
    }
    if (isAttributionSwitchedOn) {
      return formatProgramsSwitchedOn(programs, processedLots, taxesById);
    }
    return formatProgramsSwitchedOff(programs, userCrm?.extension_VI3P_ListeAttribution);
  }, [programs, isAttributionSwitchedOn, taxesById, processedLots]);

  const getProgram = useMemo(
    () =>
      memoize((programRef: string) =>
        processedPrograms.find(program => program.ref === programRef)
      ),
    [processedPrograms]
  );
  const getLot = useMemo(
    () =>
      memoize((programRef: string, lotNumber: string) =>
        formatLot(
          processedLots.find(
            lot => lot[LOT_JSON_PROGRAM_REF] === programRef && lot[LOT_JSON_NUMBER] === lotNumber
          ),
          getProgram(programRef)
        )
      ),
    [processedLots, getProgram]
  );

  return (
    <programLotContext.Provider
      value={{
        lots: processedLots,
        programs: processedPrograms,
        getProgram,
        getLot,
        isLotsValidating,
        isProgramsValidating,
      }}
    >
      {children}
    </programLotContext.Provider>
  );
}
