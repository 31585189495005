import React, { useContext, useEffect, useMemo, useState } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import classnames from 'classnames';
import qs from 'query-string';
import { useSnackbar } from 'notistack';

import {
  DELETE_FOLDER_MODAL_ID,
  EDIT_FOLDER_MODAL_ID,
  LABEL_DELETE_FOLDER,
  LABEL_FOLDERS_CONFIRM_DELETE,
  LABEL_FOLDER_TAB_COMPARISONS,
  LABEL_FOLDER_TAB_LOTS,
  LABEL_FOLDER_TAB_PROGRAMS,
  LABEL_RENAME_FOLDER,
  LABEL_RETURN,
  LABEL_SEND_ALL_DATAS,
  LABEL_SEND_FOLDER_DOCUMENTS,
  MODAL_ID_SEND_DOCUMENTS,
  TOKEN_FOLDER_NAME,
  TOKEN_NB,
} from 'settings';

import { FolderType } from 'api/vi3pAPI/apiTypes/FolderType';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import { replaceTokens } from 'services/formatter';
import { error } from 'services/log';

import { userContext } from 'modules/App/Contexts/userContext';

import { useModalMultiples } from 'hooks/useModal';

import Button from 'commonUi/Button/Button';
import Tabs from 'commonUi/Tabs/Tabs';
import TabPanel from 'commonUi/Tabs/TabPanel';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { ModalSendDocuments } from 'modules/ActionsProgramsAndLots/ModalSendDocuments/ModalSendDocuments';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import { TabComparisons } from '../Tabs/TabComparisons';
import { TabLots } from '../Tabs/TabLots';
import { TabPrograms } from '../Tabs/TabPrograms';
import { ModalDeleteFolder } from '../Modals/ModalDeleteFolder';
import { ModalEditFolder } from '../Modals/ModalEditFolder';
import { useDocuments } from '../../hooks/useDocuments';

import styles from './MyFolderPage.module.scss';

export function MyFolderPage({ history, match }: RouteComponentProps<{ id: string }>) {
  const { id } = match.params;
  const { userCrm } = useContext(userContext);
  const [editFolder, setEditFolder] = useState<FolderType>();
  const [isFolderNameAlreadyUsed, setIsFolderNameAlreadyUsed] = useState<boolean>(false);
  const [, setFolderOldName] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();

  const { open, openModal, closeModal } = useModalMultiples();

  const { data: folders, mutate } = useSWRVi3p<FolderType[]>(
    { url: `dossiers/${userCrm?.extension_VI3P_ContactId}` },
    { revalidateIfStale: false }
  );

  const headers = useMemo(() => {
    if (!editFolder) {
      return [];
    }
    return [
      {
        id: 'lots',
        label: replaceTokens(LABEL_FOLDER_TAB_LOTS, { [TOKEN_NB]: editFolder.lots.length }),
      },
      {
        id: 'programs',
        label: replaceTokens(LABEL_FOLDER_TAB_PROGRAMS, {
          [TOKEN_NB]: editFolder.programmes.length,
        }),
      },
      {
        id: 'comparisons',
        label: replaceTokens(LABEL_FOLDER_TAB_COMPARISONS, {
          [TOKEN_NB]: editFolder.comparaisons.length,
        }),
      },
    ];
  }, [editFolder?.id]);
  const queryParams =
    editFolder &&
    qs.stringify(
      {
        lots: editFolder.lots.map(l => [l.programRef, l.lotNumber]),
        programs: editFolder.programmes.map(s => s.programRef),
      },
      {
        encode: false,
        arrayFormat: 'bracket',
      }
    );
  const { documents } = useDocuments(queryParams);

  useEffect(() => {
    const myFolder = folders && folders.filter(folder => folder.id === Number(id))[0];
    if (myFolder && !editFolder) {
      setEditFolder(myFolder);
    }
  }, [editFolder, folders]);

  if (!editFolder) {
    return null;
  }

  return (
    <div>
      <div className={styles.header}>
        <button className={styles.returnBtn} onClick={() => history.goBack()} type="button">
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_RETURN}</span>
        </button>
        <div className={styles.title}>
          <Icon className={styles.titleIcon} icon="my-folders" />
          {editFolder.title}
        </div>
        <div className={styles.buttons}>
          <div className={styles.buttonsItem}>
            <Button
              className={styles.button}
              color="primary"
              icon="edit"
              iconClass={styles.buttonIcon}
              onClick={() => openModal(EDIT_FOLDER_MODAL_ID)}
              variant="outlined"
              tooltip={LABEL_RENAME_FOLDER}
              tooltipClassName={styles.tooltip}
            />
          </div>
          <div className={styles.buttonsItem}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-send"
              iconClass={styles.buttonSvgIcon}
              onClick={() => openModal(MODAL_ID_SEND_DOCUMENTS)}
              variant="outlined"
              tooltip={LABEL_SEND_ALL_DATAS}
              tooltipClassName={styles.tooltip}
            />
          </div>
          <div className={styles.buttonsItem}>
            <Button
              className={classnames(styles.button, styles.deleteBtn)}
              color="primary"
              icon="trash"
              iconClass={styles.buttonIcon}
              onClick={() => openModal(DELETE_FOLDER_MODAL_ID)}
              variant="outlined"
              tooltip={LABEL_DELETE_FOLDER}
              tooltipClassName={styles.tooltip}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <Tabs defaultTab="lots" headers={headers}>
          {activeTab => (
            <>
              <TabPanel index="lots" value={activeTab}>
                <TabLots
                  handleDelete={async lots => {
                    if (!userCrm) {
                      return;
                    }
                    const newLots = editFolder.lots.filter(
                      l =>
                        !lots.some(
                          s => s.programRef === l.programRef && s.lotNumber === l.lotNumber
                        )
                    );
                    const newFolder = {
                      ...editFolder,
                      lots: newLots,
                    };
                    setEditFolder(newFolder);
                    await axiosVI3PInstance.post('dossiers/update', {
                      [userCrm.extension_VI3P_ContactId]: {
                        [editFolder.title]: newFolder,
                      },
                    });
                  }}
                  folder={editFolder}
                  lots={editFolder.lots}
                />
              </TabPanel>
              <TabPanel index="programs" value={activeTab}>
                <TabPrograms
                  handleDelete={async programs => {
                    if (!userCrm) {
                      return;
                    }
                    const newPrograms = editFolder.programmes.filter(
                      p => !programs.some(s => s.programRef === p.programRef)
                    );
                    const newFolder = {
                      ...editFolder,
                      programmes: newPrograms,
                    };
                    setEditFolder(newFolder);
                    await axiosVI3PInstance.post('dossiers/update', {
                      [userCrm.extension_VI3P_ContactId]: {
                        [editFolder.title]: newFolder,
                      },
                    });
                  }}
                  folder={editFolder}
                  programs={editFolder.programmes}
                />
              </TabPanel>
              <TabPanel index="comparisons" value={activeTab}>
                <TabComparisons
                  handleDelete={async comparison => {
                    if (!userCrm) {
                      return;
                    }
                    const newFolder = {
                      ...editFolder,
                      comparaisons: editFolder.comparaisons.filter(
                        c => JSON.stringify(c) !== JSON.stringify(comparison)
                      ),
                    };
                    setEditFolder(newFolder);
                    await axiosVI3PInstance.post('dossiers/update', {
                      [userCrm.extension_VI3P_ContactId]: {
                        [editFolder.title]: newFolder,
                      },
                    });
                  }}
                  comparisons={editFolder.comparaisons}
                />
              </TabPanel>
            </>
          )}
        </Tabs>
      </div>

      <ModalEditFolder
        closeCallBack={() => {
          closeModal();
          setIsFolderNameAlreadyUsed(false);
        }}
        open={open === EDIT_FOLDER_MODAL_ID}
        handleInputOnChange={() => setIsFolderNameAlreadyUsed(false)}
        updateFolder={async newName => {
          if (!userCrm) {
            return;
          }
          try {
            const newFolder = {
              ...editFolder,
              title: newName,
            };
            await axiosVI3PInstance.post('dossiers/update', {
              [userCrm?.extension_VI3P_ContactId]: {
                [newName]: newFolder,
              },
            });
            closeModal();
            setEditFolder(newFolder);
          } catch (newError) {
            if (newError?.response?.status) {
              setIsFolderNameAlreadyUsed(true);
              setFolderOldName(newName);
            }
          }
        }}
        folderName={editFolder.title}
        isFolderNameAlreadyUsed={isFolderNameAlreadyUsed}
        folderNames={folders ? folders.map(folder => folder.title) : []}
        setIsFolderNameAlreadyUsed={setIsFolderNameAlreadyUsed}
      />

      <ModalDeleteFolder
        closeCallBack={closeModal}
        deleteCallBack={async () => {
          if (!folders) {
            return;
          }
          closeModal();
          const queryToString = qs.stringify(
            { panelFolders: 'open' },
            { encode: false, arrayFormat: 'bracket' }
          );
          mutate(
            folders.filter(f => f.id !== editFolder?.id),
            false
          );
          axiosVI3PInstance
            .delete(`dossiers/${userCrm?.extension_VI3P_ContactId}/${editFolder.id}`)
            .then(() => {
              mutate(folders.filter(f => f.id !== editFolder.id));
              history.replace(`/recherche?${encodeURI(queryToString)}`);
              enqueueSnackbar(LABEL_FOLDERS_CONFIRM_DELETE);
            })
            .catch(e => {
              error(`DELETE folder "${editFolder.title}"`, e);
            });
        }}
        open={open === DELETE_FOLDER_MODAL_ID}
      />

      <ModalSendDocuments
        closeCallBack={closeModal}
        documents={documents}
        open={open === MODAL_ID_SEND_DOCUMENTS}
        title={replaceTokens(LABEL_SEND_FOLDER_DOCUMENTS, {
          [TOKEN_FOLDER_NAME]: editFolder.title,
        })}
      />
      <TagCommander
        navigation_template="espace_perso"
        navigation_pagename="espace_perso.dossiers"
      />
    </div>
  );
}
