import React, { useContext, useMemo, useRef, useState } from 'react';
import type { MouseEvent } from 'react';
import classnames from 'classnames';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { LABEL_DEFAULT_SORT_SAVE_FAIL, LABEL_DEFAULT_SORT_SAVE_SUCCESS } from 'settings/labels';

import type { Ville } from 'api/viOffresAPI/apiTypes/GeoSearch';

import { userContext } from 'modules/App/Contexts';

import { getPreferredLocationString, patchContactPrescriptor } from 'services/user';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import AutocompleteCity from 'commonUi/AutocompleteCity/AutocompleteCity';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';

import accountDetailFieldStyles from './AccountDetailField.module.scss';
import styles from './AccountDetailPreferredField.module.scss';

interface AccountDetailPreferredFieldProps {
  className?: string;
  label: string;
}

export default function AccountDetailPreferredField({
  className,
  label,
}: AccountDetailPreferredFieldProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { contactPrescriptor, userCrm, mutateContactPrescriptor } = useContext(userContext);
  const [state, setState] = useState<'idle' | 'edition' | 'loading' | 'saved'>('idle');
  const [location, setLocation] = useState<Ville>();
  const [error, setError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const defaultValue = useMemo(() => {
    if (contactPrescriptor?.localisationPreferee) {
      const [postalCode, city] = contactPrescriptor.localisationPreferee.split(':');
      return {
        libelle_postal_code: postalCode,
        libelle_ville: decodeURIComponent(city),
      } as Ville;
    }
    if (contactPrescriptor?.ville && contactPrescriptor?.codePostal) {
      return {
        libelle_postal_code: contactPrescriptor.codePostal,
        libelle_ville: decodeURIComponent(contactPrescriptor.ville),
      } as Ville;
    }
    return undefined;
  }, [contactPrescriptor]);

  function handleButtonClick(ev: MouseEvent<HTMLButtonElement>) {
    switch (state) {
      case 'loading':
        ev.preventDefault();
        return undefined;

      case 'idle':
      case 'saved':
        setState('edition');
        setTimeout(() => {
          if (typeof inputRef.current?.select === 'function') {
            inputRef.current.select();
          }
        }, 0);
        return undefined;

      case 'edition': {
        if (!location) {
          return setState('idle');
        }
        setState('loading');
        let oldPreferredLocation: string | undefined;
        const newPreferredLocation = getPreferredLocationString(
          location.postal_code,
          location.libelle_ville,
          location.latitude,
          location.longitude
        );
        mutateContactPrescriptor(contactPrescriptor => {
          oldPreferredLocation = contactPrescriptor?.localisationPreferee;
          if (contactPrescriptor) {
            return {
              ...contactPrescriptor,
              localisationPreferee: newPreferredLocation,
            };
          }
          return contactPrescriptor;
        }, false);
        return patchContactPrescriptor(userCrm?.extension_VI3P_ContactId, {
          localisationPreferee: newPreferredLocation,
        })
          .then(contactPrescriptor => {
            mutateContactPrescriptor(contactPrescriptor, false);
            setState('saved');
            enqueueSnackbar(LABEL_DEFAULT_SORT_SAVE_SUCCESS);
          })
          .catch(() => {
            if (contactPrescriptor) {
              mutateContactPrescriptor(
                {
                  ...contactPrescriptor,
                  localisationPreferee: oldPreferredLocation,
                },
                false
              );
            }
            setError(true);
            setState('edition');
            enqueueSnackbar(LABEL_DEFAULT_SORT_SAVE_FAIL, {
              autoHideDuration: null,
              content: (key, message) => (
                <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
              ),
            });
          });
      }

      default: {
        const exhaustivenessCheck: never = state;
        return exhaustivenessCheck;
      }
    }
  }

  if (!defaultValue) {
    return (
      <div
        className={classnames(className, styles.root, accountDetailFieldStyles.root, {
          [styles.edit]: state === 'edition',
          [styles.error]: error,
        })}
      >
        <div>
          <div className={accountDetailFieldStyles.label}>{label}</div>
          <div className={accountDetailFieldStyles.value}>&nbsp;</div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={classnames(className, styles.root, accountDetailFieldStyles.root, {
        [styles.edit]: state === 'edition',
        [styles.error]: error,
      })}
    >
      <div>
        <div className={accountDetailFieldStyles.label}>{label}</div>
        <AutocompleteCity
          ref={inputRef}
          id="profile-preferred-location"
          classes={{
            root: styles.autocomplete,
            input: styles.input,
            paper: styles.paper,
            popper: styles.popper,
          }}
          disabled={state !== 'edition'}
          onChange={(_, location) => setLocation(location)}
          value={location}
          defaultValue={defaultValue}
        />
      </div>
      <button
        type="button"
        className={classnames(styles.button, accountDetailFieldStyles.icon, {
          [styles.saved]: state === 'saved',
        })}
        onClick={handleButtonClick}
      >
        {state === 'loading' && <CircularProgress variant="indeterminate" size={24} />}
        {state === 'idle' && <Icon className={styles.icon} icon="edit" />}
        {state === 'edition' && <Icon className={styles.icon} icon={location ? 'save' : 'cross'} />}
        {state === 'saved' && <Icon className={styles.icon} icon="check-circle-bg" />}
      </button>
    </div>
  );
}
