import React, { useContext, useEffect, useState } from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { GoogleMap, TransitLayer, useLoadScript } from '@react-google-maps/api';
import { Switch as MuiSwitch } from '@material-ui/core';

import {
  LABEL_SEARCH_AREA,
  LABEL_TRANSIT_BOX_INFOS,
  LABEL_TRANSIT_BOX_TITLE,
} from 'settings/labels';
import { TMS_CATEGORY_PERSONALIZATION, TMS_VENDOR_GOOGLE_MAPS } from 'settings/tms';

import { modifyQuery } from 'services/url';

import useTms from 'hooks/context/useTms';

import { ResponsiveContext } from 'modules/App/Contexts';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import { Placeholder } from 'commonUi/Placeholder/Placeholder';

import mapStyles from '../mapStyle.json';

import styles from './GMap.module.scss';

interface GMapProps {
  center: { lat: number; lng: number };
  className?: string;
  onClick?: ComponentProps<typeof GoogleMap>['onClick'];
  onLoad?: (ref: google.maps.Map) => void;
  onMapChange?: ComponentProps<typeof GoogleMap>['onZoomChanged'] &
    ComponentProps<typeof GoogleMap>['onDragEnd'];
  zoom: number;
  withTransitLayer?: boolean;
}

export default function GMap({
  center,
  children,
  className,
  onClick,
  onLoad,
  onMapChange,
  withTransitLayer = false,
  zoom,
}: PropsWithChildren<GMapProps>) {
  const history = useHistory();
  const canShow = useTms({
    category: TMS_CATEGORY_PERSONALIZATION,
    vendor: TMS_VENDOR_GOOGLE_MAPS,
  });
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const { locations, programRef } = useSearch();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: window.vinci.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const [map, setMap] = useState<google.maps.Map>();
  const [showTransitLayer, setShowTransitLayer] = useState(false);

  function toggleTransitLayer() {
    setShowTransitLayer(prevState => !prevState);
  }

  useEffect(() => {
    if (window.google) {
      const POSITION_TOP_CENTER = window.google.maps.ControlPosition.TOP_CENTER;
      const POSITION_LEFT_BOTTOM = window.google.maps.ControlPosition.LEFT_BOTTOM;
      const POSITION_BOTTOM_LEFT = window.google.maps.ControlPosition.BOTTOM_LEFT;
      const POSITION_BOTTOM_CENTER = window.google.maps.ControlPosition.BOTTOM_CENTER;
      const gmapBtnPosition = isResponsive ? POSITION_LEFT_BOTTOM : POSITION_TOP_CENTER;

      [
        POSITION_TOP_CENTER,
        POSITION_LEFT_BOTTOM,
        POSITION_BOTTOM_LEFT,
        POSITION_BOTTOM_CENTER,
      ].forEach(position => {
        if (map && map.controls[position].getLength() > 0) {
          map.controls[position].pop();
        }
      });

      if (withTransitLayer && map) {
        const controlTransitDiv = document.createElement('div');
        ReactDOM.render(
          <div className={styles.transitBox}>
            <div className={styles.transitBoxLeft}>
              <div className={styles.transitBoxTitle}>{LABEL_TRANSIT_BOX_TITLE}</div>
              <div className={styles.transitBoxInfos}>{LABEL_TRANSIT_BOX_INFOS}</div>
            </div>
            <div className={styles.transitBoxRight}>
              <MuiSwitch color="primary" onChange={toggleTransitLayer} size="small" />
            </div>
          </div>,
          controlTransitDiv
        );
        map.controls[POSITION_BOTTOM_CENTER].push(controlTransitDiv);
      }

      if (map && locations?.length > 1) {
        const controlButtonDiv = document.createElement('div');
        controlButtonDiv.id = 'btn-clear';
        ReactDOM.render(
          <button
            className={styles.clear}
            onClick={() => history.replace(modifyQuery({}, ['locations']))}
            type="button"
          >
            {LABEL_SEARCH_AREA}
          </button>,
          controlButtonDiv
        );
        map.controls[gmapBtnPosition].push(controlButtonDiv);
      }
    }
  }, [map, isResponsive]);

  if (!canShow)
    return (
      <Placeholder
        absolute
        featureName="la carte"
        featureCategory="Personnalisation"
        featureVendor="Google Maps"
      />
    );
  if (!isLoaded) return null;

  const zoomControlPosition = isTablette
    ? window.google.maps.ControlPosition.RIGHT_BOTTOM
    : window.google.maps.ControlPosition.LEFT_BOTTOM;

  return (
    <GoogleMap
      center={center}
      options={{
        styles: mapStyles as google.maps.MapTypeStyle[],
        mapTypeControl: false,
        streetViewControl: false,
        minZoom: 5,
        maxZoom: 20,
        fullscreenControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: isResponsive
            ? window.google.maps.ControlPosition.RIGHT_CENTER
            : zoomControlPosition,
        },
      }}
      id="map"
      mapContainerClassName={classnames(className, styles.root, {
        [styles.programActive]: programRef,
      })}
      onZoomChanged={onMapChange}
      onDragEnd={onMapChange}
      onClick={onClick}
      onLoad={ref => {
        setMap(ref);
        if (typeof onLoad === 'function') {
          onLoad(ref);
        }
      }}
      zoom={zoom}
    >
      {showTransitLayer ? <TransitLayer>{children}</TransitLayer> : children}
    </GoogleMap>
  );
}
