import React from 'react';
import type { ComponentProps } from 'react';

import { DOC_NATURE_PRODUCT } from 'settings/documents';
import {
  LABEL_BOOKLET,
  LABEL_DOWNLOAD_BOOKLET,
  LABEL_DOWNLOAD_PRODUCT_BOOKLET,
  LABEL_PRODUCT_BOOKLET,
  LABEL_SEND_BOOKLET,
  LABEL_SEND_PRODUCT_BOOKLET,
} from 'settings/labels';
import { MODAL_ID_SEND_BOOKLET, MODAL_ID_SEND_PRODUCT_BOOKLET } from 'settings/modal';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';

import BookletDropdown from 'commonUi/Dropdowns/BookletDropdown';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';

import PdfProgramDropdown from 'commonUi/Dropdowns/PdfProgramDropdown';

import styles from './ProgramActions.module.scss';

interface ProgramActionsProps {
  allLots: ComponentProps<typeof PdfProgramDropdown>['allLots'];
  allPrograms: ComponentProps<typeof PdfProgramDropdown>['allPrograms'];
  generateProgramPdf: () => void;
  program: ProgramTypeV2;
  programPdf?: ComponentProps<typeof PdfProgramDropdown>['programPdf'];
  taxById: TaxesById;
  waitForPdfDatas?: boolean;
}

export default function ProgramActions({
  allLots,
  allPrograms,
  generateProgramPdf,
  program,
  programPdf,
  taxById,
  waitForPdfDatas = false,
}: ProgramActionsProps) {
  const { open, openModal, closeModal } = useModalMultiples();

  const { booklet } = useBooklet(program?.referenceProgramme, true);
  const productBooklet = program.documents.find(
    doc => doc.nature.toLowerCase() === DOC_NATURE_PRODUCT.toLowerCase()
  );

  return (
    <div className={styles.root}>
      <div className={styles.buttonWrapper}>
        <PdfProgramDropdown
          allLots={allLots}
          allPrograms={allPrograms}
          generateProgramPdf={generateProgramPdf}
          program={program}
          programPdf={programPdf}
          taxById={taxById}
          tms={{
            navigation_template: `programme.${program.referenceProgramme}`,
            navigation_pagename: 'programme.ficheprogramme',
          }}
          waitForPdfDatas={waitForPdfDatas}
        />
      </div>
      {productBooklet && (
        <div className={styles.buttonWrapper}>
          <BookletDropdown
            bookletUrl={productBooklet.url}
            buttonIcon="booklet"
            buttonId="product"
            modalId={MODAL_ID_SEND_PRODUCT_BOOKLET}
            openModal={openModal}
          >
            {LABEL_PRODUCT_BOOKLET}
          </BookletDropdown>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <BookletDropdown
          bookletUrl={booklet?.url}
          buttonIcon="booklet"
          buttonId="booklet"
          modalId={MODAL_ID_SEND_BOOKLET}
          openModal={openModal}
        >
          {LABEL_BOOKLET}
        </BookletDropdown>
      </div>

      <ModalSendBooklet
        booklet={booklet}
        closeCallBack={closeModal}
        open={!!open && [MODAL_ID_SEND_BOOKLET, MODAL_ID_SEND_PRODUCT_BOOKLET].includes(open)}
        program={program}
        title={open === MODAL_ID_SEND_BOOKLET ? LABEL_SEND_BOOKLET : LABEL_SEND_PRODUCT_BOOKLET}
        downloadLabel={
          open === MODAL_ID_SEND_BOOKLET ? LABEL_DOWNLOAD_BOOKLET : LABEL_DOWNLOAD_PRODUCT_BOOKLET
        }
      />
    </div>
  );
}
