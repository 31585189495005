import { createContext } from 'react';
import type { KeyedMutator } from 'swr';

import type { SettingsType } from 'api/vi3pAPI/apiTypes/SettingsType';
import type { SWRVi3pDataType } from 'api/vi3pAPI/useSWRVi3p';

export const settingsContextDefault: SettingsType = {
  alertes_mail: {
    nb_resultats_recherche_lot: '0',
    nb_resultats_recherche_programme: '0',
  },
  email: {
    import_mail_signature: undefined,
  },
  pages: {
    page_adb: null,
    cuisine_equipee: null,
    page_seo: null,
  },
  bloc_alerte: undefined,
  home: undefined,
  footer: undefined,
  blocks: {
    adb: null,
    adb_lame_service: null,
    equipped_kitchen: null,
  },
  promotions: {
    promotions_background_color: '',
    promotions_text_color: '',
  },
  cdo: {
    liste_attribution: 0 as const,
    activite_commerciale_api: 0 as const,
  },
  equipped_kitchen_label: undefined,
  equipped_kitchen_url: undefined,
};

export default createContext<{
  settings: SettingsType;
  isLagging: boolean;
  isValidating: boolean;
  mutate: () => void;
}>({
  settings: settingsContextDefault,
  isLagging: false,
  isValidating: false,
  mutate: (() => {}) as KeyedMutator<SWRVi3pDataType<SettingsType>>,
});
