import React, { useMemo } from 'react';
import type { PropsWithChildren } from 'react';
import useSWR from 'swr';

import type {
  AnnexTaxonomyTerm,
  KindTaxonomyTerm,
  TaxTaxonomyTerm,
} from 'api/viOffresAPI/apiTypes/Taxonomies';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { taxMapping } from 'services/taxes';

import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';

const DEFAULT_YEARS_ITEM = {
  label: 'Immédiate',
  value: 'now',
};

export default function TaxonomiesProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const {
    data: annexes,
    isValidating: isAnnexesValidating,
  } = useSWR('taxonomies/normalisation_annexes?site=vi3p', url =>
    axiosViOffreInstance.get<AnnexTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: kinds,
    isValidating: isKindsValidating,
  } = useSWR('taxonomies/normalisation_type_de_lots?site=vi3p', url =>
    axiosViOffreInstance.get<KindTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: taxTypes,
    isValidating: isTaxesValidating,
  } = useSWR('taxonomies/fiscalite?site=vi3p', url =>
    axiosViOffreInstance.get<TaxTaxonomyTerm[]>(url).then(response => response.data || [])
  );
  const {
    data: years,
    isValidating: isYearsValidating,
  } = useSWR('programs/criteria/year?site=vi3p', url =>
    axiosViOffreInstance
      .get<string[]>(url)
      .then(response => [DEFAULT_YEARS_ITEM, ...response.data.map(v => ({ label: v, value: v }))])
  );

  return (
    <TaxonomiesContext.Provider
      value={{
        annexes: annexes ?? [],
        isAnnexesLoading: !annexes && isAnnexesValidating,
        isKindsLoading: !kinds && isKindsValidating,
        isTaxesLoading: !taxTypes && isTaxesValidating,
        isYearsLoading: !years && isYearsValidating,
        kinds: kinds ?? [],
        taxes: taxTypes ?? [],
        taxesById: useMemo(
          () =>
            taxTypes
              ? Object.fromEntries(
                  taxTypes.map(term => [
                    term.id,
                    taxMapping.get(term.nom) as NonNullable<ReturnType<typeof taxMapping.get>>,
                  ])
                )
              : {},
          [taxTypes]
        ),
        years: years ?? [DEFAULT_YEARS_ITEM],
      }}
    >
      {children}
    </TaxonomiesContext.Provider>
  );
}
