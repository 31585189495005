import React from 'react';
import { Route } from 'react-router-dom';

import GuestLayout from 'layout/GuestLayout';
import ErrorBoundary from './Tools/ErrorBoundary';

function GuestRoute(props) {
  // eslint-disable-next-line react/prop-types
  const { component: Component, location, path, ...rest } = props;

  return (
    <Route
      {...rest}
      render={props => (
        <GuestLayout>
          <ErrorBoundary location={location}>
            <Component path={path} {...props} />
          </ErrorBoundary>
        </GuestLayout>
      )}
    />
  );
}

export default GuestRoute;
