import { createContext } from 'react';
import type { Dispatch, SetStateAction } from 'react';
import type { KeyedMutator } from 'swr';
import type { OptionsObject } from 'notistack';

import type { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';
import type { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';
import type { Prescriptor } from 'api/vi3pAPI/apiTypes/Prescriptor';
import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { AlertMarketing, AlertType } from 'api/vi3pAPI/apiTypes/AccountTypes';
import type { SavedSearchType } from 'api/vi3pAPI/apiTypes/SavedSearch';
import type { Newsletter } from 'api/vi3pAPI/apiTypes/Newsletter';

interface UserContext {
  contactPrescriptor: ContactPrescriptorType | undefined;
  // eslint-disable-next-line prettier/prettier
  exclusionList: [programRef: string, lotNumber: string][];
  userToken: string | undefined;
  userCrm: UserTokenType | undefined;
  userPrescripteurs: Prescriptor | undefined;
  setUserToken: Dispatch<SetStateAction<string | undefined>>;
  options: Activity[] | undefined;
  preBookings: Activity[] | undefined;
  activityUrls: {
    options: string | undefined;
    preBookings: string | undefined;
    savedSearch: string;
  };
  alerts: AlertType[] | undefined;
  createAlert: (lotNumber: string, programRef: string, noToast?: boolean) => any;
  deleteAlert: (lotNumber: string, programRef: string, noToast?: boolean) => any;
  toggleAlert: (lotNumber: string, programRef: string, noToast?: boolean) => any;
  alertsMarketing: Record<string, AlertMarketing>;
  createMarketingAlert: (
    programRef: string,
    email: string,
    toastOptions?: boolean | OptionsObject | ((toastType: 'success' | 'failure') => OptionsObject | undefined)
  ) => Promise<void>;
  deleteMarketingAlerts: (alertIds: string | string[], noToast?: boolean) => Promise<void>;
  savedSearch: SavedSearchType[] | undefined;
  mutateContactPrescriptor: KeyedMutator<ContactPrescriptorType>;
  isContactPrescriptorLoading: boolean;
  newsletters: Newsletter[];
  unreadNewsletters: number;
  updateNewsletterStatus: (id: Newsletter['id'], status: Newsletter['statut']) => void;
  refreshNewsletters: () => void;
}

export const userContext = createContext<UserContext>({
  contactPrescriptor: undefined,
  exclusionList: [],
  userCrm: undefined,
  userPrescripteurs: undefined,
  userToken: undefined,
  setUserToken: () => {},
  options: [],
  preBookings: [],
  activityUrls: {
    options: undefined,
    preBookings: undefined,
    savedSearch: ''
  },
  alerts: [],
  createAlert(_, __) {},
  deleteAlert(_, __) {},
  toggleAlert(_, __) {},
  alertsMarketing: {},
  createMarketingAlert: (_, __) => Promise.resolve(),
  deleteMarketingAlerts: (_, __) => Promise.resolve(),
  savedSearch: [],
  mutateContactPrescriptor: () => Promise.resolve(undefined),
  isContactPrescriptorLoading: false,
  newsletters: [],
  unreadNewsletters: 0,
  updateNewsletterStatus(_, __) {},
  refreshNewsletters() {},
});
