import { createContext } from 'react';

export const ResponsiveContext = createContext<{
  isAppleDevice: boolean;
  isFooterVisibleMobile: boolean;
  isMobile: boolean;
  isResponsive: boolean;
  isTablette: boolean;
  setIsFooterVisibleMobile: (flag: boolean) => any;
}>({
  isAppleDevice: false,
  isFooterVisibleMobile: false,
  isMobile: false,
  isResponsive: false,
  isTablette: false,
  setIsFooterVisibleMobile() {},
});
