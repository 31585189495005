import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Drawer } from '@material-ui/core';

import styles from './MobileDrawer.module.scss';

export default function MobileDrawer({
  children,
  displayNone,
  isDrawerOpen = false,
  openFrom = 'left',
}: MobileDrawerProps) {
  if (!isDrawerOpen) {
    return null;
  }

  return (
    <Drawer
      anchor={openFrom}
      BackdropProps={{
        invisible: true,
      }}
      classes={{
        root: classNames(styles.drawerRoot, { [styles.displayNone]: displayNone }),
        paper: styles.drawerPaper,
      }}
      elevation={0}
      open={isDrawerOpen}
      PaperProps={{
        classes: {
          root: styles.paperRoot,
        },
      }}
      variant="temporary"
    >
      <div className={styles.drawerContainer}>{children}</div>
    </Drawer>
  );
}

export type MobileDrawerProps = {
  children: ReactNode;
  displayNone?: boolean;
  isDrawerOpen: boolean;
  openFrom?: 'bottom' | 'left' | 'right' | 'top';
};
