import React, { useContext, useState } from 'react';
import type { ComponentProps } from 'react';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { TmsContext } from 'modules/App/Contexts/TmsContext';
import { userContext } from 'modules/App/Contexts/userContext';

import type { useModal } from 'hooks/useModal';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import ModalFormSaveSearch from 'modules/SaveSearch/Components/Modals/ModalFormSaveSearch';
import SaveSearchButton from 'modules/SaveSearch/Components/SaveSearchButton/SaveSearchButton';

interface Props
  extends Omit<
    ComponentProps<typeof SaveSearchButton>,
    | 'nbResultsLots'
    | 'nbResultsPrograms'
    | 'openSaveSearchModal'
    | 'searchSaved'
    | 'setSearchSaved'
    | 'setShowPopin'
    | 'showPopin'
  > {
  resultsLots: LotJson[];
  resultsPrograms: ProgramListType[];
  modal: ReturnType<typeof useModal>;
}

export default function SaveSearchButtonWithModal({
  classes,
  resultsLots,
  resultsPrograms,
  modal,
}: Props) {
  const { savedSearch } = useContext(userContext);
  const { setEventPageVars } = useContext(TmsContext);
  const { searchId } = useSearch();

  const [searchSaved, setSearchSaved] = useState(false);
  const [showPopin, setShowPopin] = useState(false);
  const currentSearchSaved =
    searchId && savedSearch ? savedSearch.find(s => s.id === searchId) : undefined;

  return (
    <>
      <SaveSearchButton
        classes={classes}
        nbResultsLots={resultsLots.length}
        nbResultsPrograms={resultsPrograms.length}
        openSaveSearchModal={modal.openModal}
        searchSaved={searchSaved}
        setSearchSaved={setSearchSaved}
        setShowPopin={setShowPopin}
        showPopin={showPopin}
      />
      {modal.open && (
        <ModalFormSaveSearch
          closeCallBack={() => {
            setEventPageVars({});
            modal.closeModal();
          }}
          lots={resultsLots}
          open
          programs={resultsPrograms}
          setSearchSaved={setSearchSaved}
          savedSearch={currentSearchSaved}
          setShowPopin={setShowPopin}
        />
      )}
    </>
  );
}
