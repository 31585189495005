import React from 'react';
import Slider from 'react-slick';

import { LABEL_CONTACT_REGIONAL_CONTACTS } from 'settings/labels';

import LazyImage from 'commonUi/LazyImage/LazyImage';
import SliderArrow from './SliderArrow';

export interface ContactsSliderProps {
  content?: {
    prenom: string;
    nom: string;
    photo: string;
    region: string;
    email: string;
    telephone: string;
  }[];
}

export default function ContactsSlider({ content = [] }: ContactsSliderProps) {
  if (content.length === 0) {
    return null;
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    initialSlide: 0,
    nextArrow: <SliderArrow label="Suivant" />,
    prevArrow: <SliderArrow label="Précédent" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          dots: true,
        },
      },
    ],
  };

  return (
    <section className="section-conteneur section-interlocuteurs" id="interlocuteurs">
      <h2 className="block-content-title">{LABEL_CONTACT_REGIONAL_CONTACTS}</h2>

      <Slider {...sliderSettings}>
        {content.map(({ prenom, nom, photo, region, email, telephone }) => (
          <div key={email} className="contact">
            <LazyImage
              className="contact__img"
              src={photo || '/images/placeholders/200x200.png'}
              alt={`${prenom} ${nom}`}
            />

            <div className="contact__name">
              <div>{prenom}</div>
              <div>{nom}</div>
            </div>
            <div className="contact__location">{region}</div>

            <div className="contact__contacts">
              {email && (
                <a href={`mailto:${email}`} title={email}>
                  <img src="/images/ico_mail_blanc.png" alt={email} />
                </a>
              )}

              {telephone && (
                <a href={`tel:${telephone}`} title={telephone}>
                  <img src="/images/ico_phone_blanc.png" alt={telephone} />
                </a>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </section>
  );
}
