import React, { useContext, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';
import dayjs from 'dayjs';

import { LABEL_LAUNCH_ALERT_DISABLE, LABEL_LAUNCH_ALERT_ENABLE } from 'settings/labels';

import { userContext } from 'modules/App/Contexts';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import ModalMarketingAlert from 'commonUi/ModalMarketingAlert/ModalMarketingAlert';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './LaunchAlertCta.module.scss';

interface LaunchAlertCtaProps {
  big?: boolean;
  classes?: Partial<Record<'active' | 'circle' | 'countdown' | 'icon' | 'label' | 'root', string>>;
  launch?: string | Date | null;
  modalTmsValues?: ComponentProps<typeof ModalMarketingAlert>['tmsValues'];
  programData: { programRef: string } & ComponentProps<typeof ModalMarketingAlert>['programData'];
}

export default function LaunchAlertCta({
  big = false,
  classes = {},
  launch,
  programData,
  modalTmsValues,
}: LaunchAlertCtaProps) {
  const { alertsMarketing, deleteMarketingAlerts } = useContext(userContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const alertId = useMemo(
    () =>
      Object.entries(alertsMarketing).find(
        ([, alert]) => alert.programme_ref === programData.programRef
      )?.[0] ?? null,
    [alertsMarketing, programData.programRef]
  );
  const differenceInDays = useMemo(() => {
    if (!launch || (typeof launch === 'string' && Number.isNaN(Date.parse(launch)))) {
      return null;
    }

    const diff = dayjs(launch).diff(Date.now(), 'days');
    if (diff <= 0) {
      return null;
    }
    return diff;
  }, [launch]);

  return (
    <>
      <button
        type="button"
        className={classnames(
          classes.root,
          styles.root,
          alertId !== null ? classes.active : undefined,
          {
            [styles.active]: alertId !== null,
            [styles.big]: big,
          }
        )}
        onClick={ev => {
          ev.preventDefault();
          return alertId !== null ? deleteMarketingAlerts(alertId) : setModalIsOpen(true);
        }}
      >
        <svg
          className={classnames(classes.circle, styles.circle)}
          viewBox="0 0 130 130"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M64.9968 130C56.3317 130.01 47.7549 128.259 39.787 124.854C24.2106 118.186 11.803 105.772 5.14373 90.1919C1.7395 82.2188 -0.0104095 73.6375 4.65827e-05 64.9681C0.00158182 54.8329 2.36961 44.8381 6.91554 35.7796C7.19924 35.214 7.69598 34.7843 8.2965 34.585C8.89702 34.3857 9.55211 34.4331 10.1177 34.7168C10.6832 35.0005 11.1129 35.4972 11.3123 36.0978C11.5116 36.6983 11.4642 37.3534 11.1805 37.9189C6.96764 46.3136 4.7731 55.5759 4.77162 64.9684C4.7612 72.9952 6.38062 80.9406 9.53175 88.3231C15.7089 102.77 27.2148 114.281 41.6587 120.465C49.0385 123.608 56.9773 125.229 64.9987 125.229C73.0201 125.229 80.9588 123.608 88.3386 120.465C102.783 114.281 114.289 102.77 120.466 88.3231C123.561 81.0475 125.18 73.2296 125.228 65.3233C125.276 57.417 123.752 49.58 120.746 42.2675C114.924 28.1644 104.03 16.7543 90.2112 10.2876C83.1595 6.99232 75.5283 5.11686 67.7524 4.76808C67.1205 4.73908 66.5259 4.46022 66.0996 3.99286C65.6732 3.52549 65.45 2.9079 65.479 2.27595C65.508 1.644 65.7868 1.04945 66.2542 0.623094C66.7216 0.196741 67.3392 -0.0264895 67.9711 0.00251001C76.3688 0.379481 84.6102 2.40511 92.2257 5.96398C107.131 12.9365 118.881 25.2422 125.158 40.453C128.405 48.3502 130.05 56.814 129.999 65.3526C129.947 73.8913 128.199 82.3345 124.857 90.1919C118.197 105.773 105.787 118.187 90.2086 124.854C82.2401 128.259 73.6626 130.01 64.9968 130Z" />
        </svg>
        {alertId !== null ? (
          <SvgIcon
            className={classnames(classes.icon, styles.icon)}
            iconId="icon-inactive-alert-onhover"
          />
        ) : (
          <Icon className={classnames(classes.icon, styles.icon)} icon="notifications" />
        )}
        <span className={classnames(classes.label, styles.label)}>
          {alertId !== null ? LABEL_LAUNCH_ALERT_DISABLE : LABEL_LAUNCH_ALERT_ENABLE}
        </span>
        <span className={classnames(classes.countdown, styles.countdown)}>
          {differenceInDays !== null ? <>J-{differenceInDays}</> : <>&nbsp;</>}
        </span>
      </button>

      <ModalMarketingAlert
        programData={programData}
        onClose={() => setModalIsOpen(false)}
        open={modalIsOpen}
        tmsValues={modalTmsValues}
      />
    </>
  );
}
