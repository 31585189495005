import React from 'react';

import { LABEL_PROGRAM_404 } from 'settings/labels';
import { TOKEN_REF } from 'settings/token';

import { replaceTokens } from 'services/formatter';

import useAttributionProgram from 'hooks/useAttributionProgram';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import ErrorPage from 'layout/components/ErrorPage/ErrorPage';

import ProgramContent from '../ProgramContent/ProgramContent';

import styles from './Program.module.scss';

interface ProgramPageProps {
  match: { params: { activeTab: string; programRef: string } };
}

export default function Program(props: ProgramPageProps) {
  const { match } = props;
  const { programRef } = match.params;
  const { program, error } = useAttributionProgram(match.params.programRef);

  if (error) {
    return (
      <ErrorPage
        errorMessage={replaceTokens(LABEL_PROGRAM_404, { [TOKEN_REF]: programRef })}
        {...props}
      />
    );
  }

  if (!program) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <>
      <ProgramContent program={program} />
      <TagCommander
        navigation_pagename="programme"
        navigation_template={`programme.${programRef}`}
      />
    </>
  );
}
