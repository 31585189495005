import React from 'react';

import {
  LABEL_CITIES,
  LABEL_DELIVERY,
  LABEL_FISCALITY,
  LABEL_FLOOR,
  LABEL_LOT,
  LABEL_PARKING,
  LABEL_PRICE_COMMERCIAL_TTC,
  LABEL_PRICE_IMMO_HT,
  LABEL_PRICE_INCLUDING_TAX_NORMALE,
  LABEL_PRICE_INCLUDING_TAX_TTC,
  LABEL_PRICE_MOB_HT,
  LABEL_PRICE_REDUCED_VAT,
  LABEL_PROFITABILITY,
  LABEL_RENT,
  LABEL_RENT_LMNP,
  LABEL_RENT_MARKET,
  LABEL_RENT_PINEL,
  LABEL_SORT_BY,
  LABEL_STATUS,
  LABEL_SURFACE,
  LABEL_SURFACE_ANNEX,
  LABEL_TYPE,
} from 'settings/labels';
import {
  SORT_LOT_BY_DELIVERY,
  SORT_LOT_BY_FLOOR,
  SORT_LOT_BY_KIND,
  SORT_LOT_BY_NUMBER,
  SORT_LOT_BY_PARKING,
  SORT_LOT_BY_PRICE_IMMO_HT,
  SORT_LOT_BY_PRICE_INCLUDING_TAX,
  SORT_LOT_BY_PRICE_MOB_HT,
  SORT_LOT_BY_PRICE_REDUCED_VAT,
  SORT_LOT_BY_PROFITABILITY,
  SORT_LOT_BY_PROGRAM,
  SORT_LOT_BY_RENT,
  SORT_LOT_BY_RENT_CUSTOM,
  SORT_LOT_BY_STATUS,
  SORT_LOT_BY_SURFACE,
  SORT_LOT_BY_SURFACE_ANNEX,
  SORT_LOT_BY_TAX,
  SORT_LOT_BY_TYPE,
} from 'settings/search';

import type { LotSortBy, LotSortOrder } from 'api/viOffresAPI/apiTypes/LotType';

import DropdownList from 'commonUi/DropdownList/DropdownList';
import SortItem from 'commonUi/SortItem/SortItem';

interface LotDropdownSortProps {
  buttonClassName?: string;
  className?: string;
  taxes: string[];
  reorderLots: (sortBy: LotSortBy) => void;
  lotsSortBy: LotSortBy;
  lotsSortOrder: LotSortOrder;
}

export default function LotDropdownSort({
  buttonClassName = undefined,
  className = undefined,
  taxes,
  reorderLots,
  lotsSortBy,
  lotsSortOrder,
}: LotDropdownSortProps) {
  let selectedTax = 'multi';
  if (taxes.length >= 1) {
    const [firstTax] = taxes;
    selectedTax = firstTax;
  }
  const isMulti = selectedTax === 'multi';
  const isPinel = selectedTax === 'Pinel';
  const isLMNP = selectedTax === 'LMNP';
  const isHorsDispositif =
    selectedTax === 'Hors dispositif' || selectedTax === 'ResidenceHorsPinel';
  const isDemembrement = selectedTax === 'Démembrement';

  function getSortItem(label: string, type: string) {
    return (
      <SortItem
        onClick={reorderLots}
        sortBy={lotsSortBy}
        sortOrder={lotsSortOrder}
        title={label}
        type={type}
      />
    );
  }

  return (
    <DropdownList
      id="sort"
      buttonClassName={buttonClassName}
      className={className}
      label={LABEL_SORT_BY}
    >
      {isMulti && getSortItem(LABEL_FISCALITY, SORT_LOT_BY_TAX)}
      {getSortItem(LABEL_CITIES, SORT_LOT_BY_PROGRAM)}
      {isLMNP ? getSortItem('Nature', SORT_LOT_BY_TYPE) : null}
      {getSortItem(LABEL_LOT, SORT_LOT_BY_NUMBER)}
      {getSortItem(LABEL_TYPE, SORT_LOT_BY_KIND)}
      {getSortItem(LABEL_FLOOR, SORT_LOT_BY_FLOOR)}
      {getSortItem(LABEL_SURFACE, SORT_LOT_BY_SURFACE)}
      {getSortItem(LABEL_SURFACE_ANNEX, SORT_LOT_BY_SURFACE_ANNEX)}
      {getSortItem(LABEL_PARKING, SORT_LOT_BY_PARKING)}

      {/* Price behavior */}
      {(isMulti || isPinel || isHorsDispositif) &&
        getSortItem(LABEL_PRICE_REDUCED_VAT, SORT_LOT_BY_PRICE_REDUCED_VAT)}
      {isLMNP && getSortItem(LABEL_PRICE_IMMO_HT, SORT_LOT_BY_PRICE_IMMO_HT)}
      {isMulti && getSortItem(LABEL_PRICE_INCLUDING_TAX_TTC, SORT_LOT_BY_PRICE_INCLUDING_TAX)}
      {(isPinel || isHorsDispositif) &&
        getSortItem(LABEL_PRICE_INCLUDING_TAX_NORMALE, SORT_LOT_BY_PRICE_INCLUDING_TAX)}
      {isLMNP && getSortItem(LABEL_PRICE_MOB_HT, SORT_LOT_BY_PRICE_MOB_HT)}
      {isDemembrement && getSortItem(LABEL_PRICE_COMMERCIAL_TTC, SORT_LOT_BY_PRICE_INCLUDING_TAX)}

      {/* Rent behavior */}
      {isMulti && getSortItem(LABEL_RENT, SORT_LOT_BY_RENT)}
      {isPinel && getSortItem(LABEL_RENT_PINEL, SORT_LOT_BY_RENT_CUSTOM)}
      {isLMNP && getSortItem(LABEL_RENT_LMNP, SORT_LOT_BY_RENT_CUSTOM)}
      {isHorsDispositif && getSortItem(LABEL_RENT_MARKET, SORT_LOT_BY_RENT_CUSTOM)}

      {/* Profitability behavior */}
      {(isMulti || isPinel || isLMNP) &&
        getSortItem(LABEL_PROFITABILITY, SORT_LOT_BY_PROFITABILITY)}

      {/* Delivery behavior */}
      {(isMulti || isPinel || isLMNP) && getSortItem(LABEL_DELIVERY, SORT_LOT_BY_DELIVERY)}

      {getSortItem(LABEL_STATUS, SORT_LOT_BY_STATUS)}
    </DropdownList>
  );
}
