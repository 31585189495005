import React from 'react';
import type { RouteComponentProps } from 'react-router-dom';

import {
  ACTIVITIES_TABS_TMS,
  ACTIVITY_TAB_ID_BOOKINGS,
  ACTIVITY_TAB_ID_OPTIONS,
  ACTIVITY_TAB_ID_PREBOOKINGS,
  ACTIVITY_TAB_ID_SALES,
} from 'settings/activity';

import type { ReportLight } from 'api/viOffresAPI/apiTypes/Reports';

import { useSWROffres } from 'api/offresAPI/useSWROffres';

import useAttributionProgram from 'hooks/useAttributionProgram';

import TagCommander from 'modules/App/TagCommander/TagCommander';

import ReportsListContent from './ReportsContent/ReportsListContent';

function calculateTMS(from: string | undefined, programRef: string) {
  switch (from) {
    case ACTIVITY_TAB_ID_BOOKINGS:
    case ACTIVITY_TAB_ID_OPTIONS:
    case ACTIVITY_TAB_ID_PREBOOKINGS:
    case ACTIVITY_TAB_ID_SALES:
      return {
        navigation_pagename: `${ACTIVITIES_TABS_TMS[from].navigation_pagename}.chantier.${programRef}`,
        navigation_template: ACTIVITIES_TABS_TMS[from].navigation_template,
      };

    default:
      return {
        navigation_pagename: 'programme.chantier',
        navigation_template: `programme.chantier.${programRef}`,
      };
  }
}

export default function ReportsList({
  match,
  location,
}: RouteComponentProps<{ programRef: string }>) {
  const { programRef } = match.params;
  const from = new URLSearchParams(location.search).get('from') ?? undefined;

  const { program } = useAttributionProgram(programRef);
  const { data: reports } = useSWROffres<ReportLight[]>(
    { url: `/programmes/${programRef}/bulletinsChantier` },
    { revalidateOnFocus: false, revalidateIfStale: false }
  );

  return (
    <>
      <ReportsListContent from={from} program={program} reports={reports} />
      <TagCommander {...calculateTMS(from, programRef)} />
    </>
  );
}
