import React, { useContext } from 'react';

import { ResponsiveContext, userContext } from 'modules/App/Contexts/';
import { DEFAULT_CONTACT, LABEL_DEFAULT_JOB } from 'settings';

import AssistanceBlockDesktop from './AssistanceBlockDesktop';
import AssistanceBlockMobile from './AssistanceBlockMobile';

import styles from './AssistanceBlock.module.scss';

export default function AssistanceBlock() {
  const { userPrescripteurs } = useContext(userContext);
  const { isResponsive } = useContext(ResponsiveContext);

  if (!userPrescripteurs || !userPrescripteurs.interlocuteurs) {
    return null;
  }

  if (isResponsive) {
    return (
      <AssistanceBlockMobile
        defaultJob={LABEL_DEFAULT_JOB}
        interlocutors={[...userPrescripteurs.interlocuteurs, DEFAULT_CONTACT]}
      />
    );
  }

  return (
    <div className={styles.root}>
      <AssistanceBlockDesktop
        defaultJob={LABEL_DEFAULT_JOB}
        interlocutors={[...userPrescripteurs.interlocuteurs, DEFAULT_CONTACT]}
      />
    </div>
  );
}
