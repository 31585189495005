import React, { useContext, useEffect, useRef, useState } from 'react';
import type { MouseEvent, ReactNode } from 'react';
import classnames from 'classnames';

import type { Activity, ActivityHeading } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { HeadingsWidthType, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType, ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { ResponsiveContext } from 'modules/App/Contexts';
import programLotContext from 'modules/App/Contexts/programLotContext';

import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import styles from './ActivityRow.module.scss';

interface ActivityRowProps {
  cellsWidth?: HeadingsWidthType;
  className?: string;
  isCurrentRowOpen: boolean;
  style?: any;
  activity: Activity;
  headings?: ActivityHeading[];
  index: number;
  renderShowMore(
    index: number,
    lot: LotTypeV2 | undefined,
    program: ProgramTypeV2 | undefined,
    isOpen: boolean,
    isLoading: boolean
  ): ReactNode;
  renderCell(
    col: ActivityHeading,
    activity: Activity,
    program: ProgramListType | undefined,
    isRowHover: boolean
  ): ReactNode;
  onClick?(): void;
  onLoadError?(): void;
  onLoadSuccess?(): void;
  onMouseEnter?(ev?: MouseEvent): void;
  onMouseOut?(ev?: MouseEvent): void;
}

export default function ActivityRow({
  activity,
  cellsWidth,
  className,
  headings = [],
  index,
  isCurrentRowOpen,
  onClick,
  onLoadError = () => {},
  onLoadSuccess = () => {},
  onMouseEnter,
  onMouseOut,
  renderCell,
  renderShowMore,
  style = {},
}: ActivityRowProps) {
  const shouldBeLoaded = useRef(false);
  const { isTablette } = useContext(ResponsiveContext);
  const [isRowHover, setIsRowHover] = useState(false);
  const { getProgram } = useContext(programLotContext);
  const programFromExport = getProgram(activity.field_referenceprogramme_ac);

  useEffect(() => {
    if (isCurrentRowOpen) {
      shouldBeLoaded.current = true;
    }
  }, [isCurrentRowOpen]);

  const { lot, program, isLoading: isDetailsLoading, isError } = useLoadProgramAndLot(
    shouldBeLoaded.current ? activity.field_referenceprogramme_ac : undefined,
    shouldBeLoaded.current ? activity.field_numerolot_ac : undefined
  );

  useEffect(() => {
    if (!isDetailsLoading) {
      if (isError) {
        onLoadError();
      } else {
        onLoadSuccess();
      }
    }
  }, [isDetailsLoading, isError]);

  return (
    <div data-test-id="activity-row" style={style}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        id={`activity-${activity.field_idcrm_ac}`}
        className={classnames(
          className,
          styles.resultItem,
          index % 2 === 0 ? styles.even : styles.odd,
          {
            [styles.resultItemTablet]: isTablette,
            [styles.isLotOpen]: isCurrentRowOpen,
          }
        )}
        role="button"
        tabIndex={0}
        onClick={ev => {
          const targetClassName = (ev?.target as Element | undefined)?.className;
          if (targetClassName?.includes('PrivateSwitchBase')) {
            return;
          }
          if (onClick) onClick();
        }}
        onMouseEnter={event => {
          setIsRowHover(true);
          onMouseEnter?.(event);
        }}
        onMouseLeave={event => {
          setIsRowHover(false);
          onMouseOut?.(event);
        }}
        onBlur={() => {}}
      >
        {headings?.map(col => (
          <div
            key={col.id}
            className={classnames(styles.cell, {
              [styles.alignCenter]: col.align === 'center',
              [styles.alignLeft]: col.align === 'left',
              [styles.alignRight]: col.align === 'right',
            })}
            style={cellsWidth && cellsWidth[col.id] ? { width: `${cellsWidth[col.id]}%` } : {}}
          >
            {renderCell(col, activity, programFromExport, isRowHover)}
          </div>
        ))}
      </div>
      {renderShowMore(index, lot, program, isCurrentRowOpen, isDetailsLoading)}
    </div>
  );
}
