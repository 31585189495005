import React, { useContext, useRef, useState } from 'react';
import { Grow as MuiGrow, Paper as MuiPaper, Popper as MuiPopper } from '@material-ui/core';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';

import { openBlob } from 'services/files';
import { ACTIVITIES_DOWNLOAD_TMS } from 'settings/activity';
import { LABEL_CLOSE, LABEL_DOWNLOAD_CONTRACT, LABEL_FAIL_DOWNLOAD_FILE } from 'settings/labels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import useDownloadActivityFile from 'hooks/useDownloadActivityFile';

import Tooltip from 'commonUi/Tooltip/Tooltip';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import CircularProgressWithLabel from 'commonUi/CircularProgressWithLabel/CircularProgressWithLabel';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import ActivityDownloadDocuments from '../ActivityDownloadDocuments/ActivityDownloadDocuments';

import styles from './ActivityDownloadContract.module.scss';

interface ActivityDownloadContractprops {
  activity: Activity;
  classes?: {
    button?: string;
    buttonIcon?: string;
  };
}

export default function ActivityDownloadContract({
  activity,
  classes = {},
}: ActivityDownloadContractprops) {
  const { isResponsive } = useContext(ResponsiveContext);
  const downloadBtnRef = useRef(null);
  const [isDownloadPopinOpen, setIsDownloadPopinOpen] = useState(false);
  const { isDownloading, downloadingProgress, handleFileDownload } = useDownloadActivityFile();
  const { enqueueSnackbar } = useSnackbar();

  function handleOpenDownloadPopin() {
    setIsDownloadPopinOpen(true);
  }

  function handleCloseDownloadPopin(ev: React.MouseEvent) {
    ev.stopPropagation();
    setIsDownloadPopinOpen(false);
  }

  function handleDownloadClick(ev: React.MouseEvent) {
    ev.stopPropagation();
    if (activity.field_documents_ac?.length === 1) {
      downloadOneFile(activity.field_documents_ac[0].nom);
    } else {
      handleOpenDownloadPopin();
    }
  }

  function downloadOneFile(filename: string) {
    if (isDownloading || !activity.field_documents_ac) return;
    const documentId = activity.field_documents_ac[0].idCrm;
    openBlob(
      () =>
        handleFileDownload(`activiteCommerciale/${activity.field_idcrm_ac}/documents/${documentId}`)
          .then(response => new Blob([response.data], { type: response.headers['content-type'] }))
          .catch(() => {
            enqueueSnackbar(LABEL_FAIL_DOWNLOAD_FILE, {
              autoHideDuration: null,
              content: (key, message) => (
                <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
              ),
            });
            throw new Error();
          }),
      filename,
      isResponsive
    );
  }

  return (
    <>
      <Tooltip arrow title={LABEL_DOWNLOAD_CONTRACT} placement="top">
        <button
          type="button"
          className={classes?.button}
          onClick={handleDownloadClick}
          ref={downloadBtnRef}
        >
          {isDownloading ? (
            <CircularProgressWithLabel value={downloadingProgress} />
          ) : (
            <SvgIcon className={classes?.buttonIcon} iconId="icon-download-contract" />
          )}
        </button>
      </Tooltip>

      {activity.field_documents_ac && activity.field_documents_ac.length > 1 && (
        <>
          {!isResponsive && (
            <>
              {isDownloadPopinOpen && (
                <button className={styles.bgLayer} onClick={handleCloseDownloadPopin} type="button">
                  {LABEL_CLOSE}
                </button>
              )}

              <MuiPopper
                transition
                anchorEl={downloadBtnRef.current}
                placement="bottom-end"
                open={isDownloadPopinOpen}
                style={{ zIndex: 1500 }}
              >
                {({ TransitionProps }) => (
                  <MuiGrow {...TransitionProps}>
                    <MuiPaper
                      classes={{
                        root: classnames(styles.paper),
                      }}
                      onClick={ev => ev.stopPropagation()}
                    >
                      <ActivityDownloadDocuments
                        activity={activity}
                        closePopin={handleCloseDownloadPopin}
                      />
                    </MuiPaper>
                  </MuiGrow>
                )}
              </MuiPopper>
            </>
          )}
          {isResponsive && isDownloadPopinOpen && (
            <ActivityDownloadDocuments activity={activity} closePopin={handleCloseDownloadPopin} />
          )}
        </>
      )}
      <TagCommanderEvent
        isActive={isDownloading}
        navigation_pagename={ACTIVITIES_DOWNLOAD_TMS[activity.field_type_ac]}
        navigation_template="espace_perso"
        useEffectDeps={['navigation_pagename']}
      />
    </>
  );
}
