import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import {
  getActivitiesHeadings,
  getActivityPageHeadingsWidth,
  sortActivities,
} from 'services/activities';
import { getNormalizedRole } from 'services/authentication';
import {
  ACTIVITY_HEADING_STATUS,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
} from 'settings/activity';
import {
  LABEL_ACTIVITY_NO_RESULT,
  LABEL_LOTS_ENABLE_ERROR_SUBTITLE,
  LABEL_LOTS_ENABLE_ERROR_TITLE,
} from 'settings/labels';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';
import { HEADER_DOM_ID } from 'settings/ui';

import type {
  Activity,
  ActivityHeading,
  ActivitySortByType,
  ActivitySortOrderType,
} from 'api/vi3pAPI/apiTypes/ActivityType';

import { ResponsiveContext } from 'modules/App/Contexts';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import MobileDrawer from 'layout/components/HeaderAuthenticated/Drawer/MobileDrawer';
import { ActivityTabletteDetailsCpn } from 'modules/DetailsLotsAndActivity/ActivityTabletteDetailsCpn';
import { ActivityDesktopDetailsCpn } from 'modules/DetailsLotsAndActivity/ActivityDesktopDetailsCpn';

import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import TableV2 from 'commonUi/Table/TableV2';
import TableHeader from 'commonUi/Table/TableHeader/TableHeader';
import ActivityRow from 'commonUi/ListLotV2/ActivityRow/ActivityRow';
import LotScrollingRow from 'commonUi/ListLotV2/LotRow/LotScrollingRow';

import ActivityCell from './ActivityCell';

import styles from './ActivityTableDesktopAndTablet.module.scss';

export interface ActivityTableDesktopAndTabletProps {
  activityType:
    | typeof ACTIVITY_TYPE_BOOKING
    | typeof ACTIVITY_TYPE_OPTION
    | typeof ACTIVITY_TYPE_PRE_BOOKING
    | typeof ACTIVITY_TYPE_SALE;
  apiUrls: ComponentProps<typeof ActivityCell>['apiUrls'];
  changeTab: (newTab: string) => void;
  data: Activity[] | undefined;
}

export default function ActivityTableDesktopAndTablet({
  activityType,
  apiUrls,
  changeTab,
  data = [],
}: ActivityTableDesktopAndTabletProps) {
  const { activityId } = useSearch();
  const { taxes: fiscalities } = useContext(TaxonomiesContext);
  const { isTablette } = useContext(ResponsiveContext);
  const [, setRenderSignal] = useState(0);

  const [currentOpenRow, setCurrentOpenRow] = useState(activityId);
  const [sortBy, setSortBy] = useState<ActivitySortByType>(ACTIVITY_HEADING_STATUS);
  const [sortOrder, setSortOrder] = useState<ActivitySortOrderType>(SORT_ORDER_ASC);
  const [loadActivityError, setLoadActivityError] = useState<boolean>(
    typeof activityId !== 'undefined' &&
      data?.length > 0 &&
      !data.some(activity => activity?.field_idcrm_ac.toLowerCase() === activityId.toLowerCase())
  );
  const [openError, setOpenError] = useState<boolean>(false);

  const userType = getNormalizedRole();
  const headings = getActivitiesHeadings(activityType, userType);
  const headingsWidth = getActivityPageHeadingsWidth(headings, activityType);
  const sortedData = useMemo(() => {
    const activities = sortActivities(data, sortBy, sortOrder);
    const activityIndex = activities.findIndex(activity => activity.field_idcrm_ac === activityId);

    if (activityIndex !== -1) {
      return [
        activities[activityIndex],
        ...activities.slice(0, activityIndex),
        ...activities.slice(activityIndex + 1),
      ];
    }
    return activities;
  }, [data, sortBy, sortOrder]);
  const rowsListRef = useRef(null);

  function reorder(heading: ActivityHeading) {
    if (!heading.sort) {
      return;
    }
    if (heading.sort === sortBy) {
      if (sortOrder === SORT_ORDER_ASC) {
        setSortOrder(SORT_ORDER_DESC);
      } else {
        setSortOrder(SORT_ORDER_ASC);
      }
    } else {
      setSortBy(heading.sort);
      setSortOrder(SORT_ORDER_ASC);
    }
  }

  // scroll to table if lotnumber exist
  useEffect(() => {
    const element = document.getElementById('activities-lots');
    if (!isTablette && element && activityId) {
      const header = document.getElementById(HEADER_DOM_ID);
      const tabs = document.getElementById('activity-tabs');
      const elementPos = element.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({
        top: elementPos - (header?.clientHeight || 0) - (tabs?.clientHeight || 0),
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [activityId]);

  if (sortedData.length === 0) {
    return (
      <div className={classnames(styles.container)}>
        <div className={styles.noResult}>{LABEL_ACTIVITY_NO_RESULT[activityType]}</div>
      </div>
    );
  }

  return (
    <TableV2
      className="header-sticky--large"
      disableHeight
      error={
        <LotTableErrorModal
          open={loadActivityError || openError}
          onClose={() => {
            setOpenError(false);
            setLoadActivityError(false);
          }}
          title={LABEL_LOTS_ENABLE_ERROR_TITLE}
          subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
        />
      }
      headings={headings}
      id="activities-lots"
      rowCount={sortedData.length}
      rowsListRef={rowsListRef}
      renderHeader={col => (
        <TableHeader
          key={col.id}
          col={col}
          reorder={reorder}
          sortBy={sortBy}
          sortOrder={sortOrder}
          style={{ width: `${headingsWidth[col.id]}%` }}
        />
      )}
      renderRow={({ index, isScrolling, isVisible, key, style }) => {
        if (!isVisible && isScrolling) {
          return (
            <LotScrollingRow
              key={key}
              cellsWidth={headingsWidth}
              headings={headings}
              index={index}
              style={style}
            />
          );
        }

        return (
          <ActivityRow
            key={key}
            activity={sortedData[index]}
            cellsWidth={headingsWidth}
            headings={headings}
            index={index}
            isCurrentRowOpen={
              currentOpenRow?.toLowerCase() === sortedData[index].field_idcrm_ac.toLowerCase()
            }
            onClick={() =>
              setCurrentOpenRow(prevOpenRow =>
                prevOpenRow !== sortedData[index].field_idcrm_ac
                  ? sortedData[index].field_idcrm_ac
                  : undefined
              )
            }
            // TableV2 apparently needs to re-render when a row's data has been loaded to display it properly
            onLoadSuccess={() => setRenderSignal(prev => prev + 1)}
            onLoadError={() => setOpenError(true)}
            renderCell={(col, activity, program, isRowHover) => (
              <ActivityCell
                activity={activity}
                changeTab={changeTab}
                hover={isRowHover}
                program={program}
                col={col}
                apiUrls={apiUrls}
              />
            )}
            renderShowMore={(index, lot, program, isOpen, isLoading) => {
              if (isTablette)
                return (
                  <MobileDrawer isDrawerOpen={isOpen}>
                    <ActivityTabletteDetailsCpn
                      closeDrawer={() => setCurrentOpenRow(undefined)}
                      activity={sortedData[index]}
                      lot={lot}
                      fiscalities={fiscalities}
                    />
                  </MobileDrawer>
                );

              return (
                <ActivityDesktopDetailsCpn
                  activity={sortedData[index]}
                  fiscalities={fiscalities}
                  index={index}
                  lot={lot}
                  program={program}
                  isOpen={isOpen}
                  isLoading={isLoading}
                />
              );
            }}
            style={style}
          />
        );
      }}
    />
  );
}
