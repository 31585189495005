import React from 'react';
import classnames from 'classnames';
import {
  Button as ButtonMaterial,
  ButtonProps as ButtonPropsMd,
  CircularProgress,
  CircularProgressProps,
  TooltipProps,
} from '@material-ui/core';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Tooltip from 'commonUi/Tooltip/Tooltip';
import Icon, { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';
import styles from './Button.module.scss';

function Button(
  {
    action = false,
    activeClassName,
    className = '',
    color = 'inherit',
    disabled = false,
    withDropdown = false,
    withSaveAction = false,
    icon = undefined,
    iconClass = undefined,
    iconDropdownClass = undefined,
    iconId = undefined,
    isActive = false,
    labelClassName = undefined,
    labelTextClassName = undefined,
    labelWhileLoading = false,
    loading = false,
    loadingClassName = undefined,
    loadingReplaceIcon = false,
    onClick = undefined,
    tooltip,
    tooltipClassName = undefined,
    tooltipPlacement = 'top',
    variant,
    children,
    ...props
  }: ButtonProps,
  ref
) {
  const btnClassName = classnames(className, styles.root, {
    'content-regular-16': variant === 'contained',
    'content-regular-15': variant === 'outlined' && !action,
    'content-regular-14': action || withDropdown,
    [styles.loading]: loading,
    [classnames(styles.isActive, activeClassName)]: withDropdown && isActive,
  });
  const button = (
    <ButtonMaterial
      ref={ref}
      classes={{
        containedPrimary: styles.containedPrimary,
        containedSecondary: styles.containedSecondary,
        outlined: icon && tooltip ? styles.outlined : styles.outlinedWithText,
        outlinedPrimary: !withDropdown
          ? styles.outlinedColorPrimary
          : styles.outlinedColorPrimaryDropdown,
        outlinedSecondary: styles.outlinedColorSecondary,
        label: classnames(labelClassName, action ? styles.labelAction : styles.label),
        root: classnames(styles.root, styles.selectorWeight),
        colorInherit: styles.colorInherit,
        text: styles.text,
      }}
      className={btnClassName}
      color={color}
      disableRipple
      onClick={onClick}
      variant={variant}
      disabled={disabled || loading}
      {...props}
    >
      {loading && (
        <CircularProgress
          classes={{
            colorPrimary: styles.colorPrimary,
            colorSecondary: styles.colorSecondary,
            root: classnames(styles.loader, loadingClassName, {
              [styles.withLabel]: labelWhileLoading,
            }),
          }}
          variant="indeterminate"
          color={color}
          size={24}
        />
      )}
      {icon && !loadingReplaceIcon && !loading && (
        <Icon
          className={classnames(iconClass, styles.icon, {
            [styles.isActiveIconDropdown]: withDropdown && isActive,
            [styles.disabledIconSaveAction]: withSaveAction && disabled,
          })}
          icon={icon}
        />
      )}

      {iconId && !loadingReplaceIcon && !loading && (
        <SvgIcon className={classnames(styles.iconSprite, iconClass)} iconId={iconId} />
      )}
      {(!loading || labelWhileLoading) && (
        <span className={classnames(styles.labelText, labelTextClassName)}>{children}</span>
      )}
      {withDropdown && (
        <Icon
          className={classnames(iconDropdownClass, styles.iconDropdown, {
            [styles.dropdownReturn]: isActive,
          })}
          icon="chevron-down"
        />
      )}
    </ButtonMaterial>
  );
  return (
    <>
      {(icon || iconId) && tooltip ? (
        <Tooltip className={tooltipClassName} arrow placement={tooltipPlacement} title={tooltip}>
          {button}
        </Tooltip>
      ) : (
        button
      )}
    </>
  );
}

type ButtonProps = {
  action?: boolean;
  className?: string;
  disabled?: boolean;
  withDropdown?: boolean;
  withSaveAction?: boolean;
  loading?: boolean;
  onClick?: (ev?: React.MouseEvent) => void;
  tooltip?: string;
  tooltipClassName?: TooltipProps['className'];
  tooltipPlacement?: TooltipProps['placement'];
  color?: CircularProgressProps['color'];
  variant?: ButtonPropsMd['variant'];
  icon?: Icons;
  iconClass?: string;
  iconDropdownClass?: string;
  iconId?: string;
  isActive?: boolean;
  children?: React.ReactNode;
  labelClassName?: string;
  labelTextClassName?: string;
  labelWhileLoading?: boolean;
  loadingClassName?: string;
  loadingReplaceIcon?: boolean;
  activeClassName?: string;
  [x: string]: any; // To allow passing any unspecified property on to ButtonMaterial
};

// export default Button;
export default React.forwardRef(Button);
