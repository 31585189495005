import React from 'react';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './AccountDetailField.module.scss';

export default function AccountDetailField({ label, value }: AccountDetailFieldProps) {
  return (
    <div className={styles.root}>
      <div>
        <div className={styles.label}>{label}</div>
        <div className={styles.value}>{value}</div>
      </div>
      <Icon className={styles.icon} icon="lock" />
    </div>
  );
}

type AccountDetailFieldProps = {
  label: string;
  value: string;
};
