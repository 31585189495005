import React, { useContext } from 'react';
import classnames from 'classnames';
import qs from 'query-string';
import { useSnackbar } from 'notistack';

import {
  LABEL_ACCESS_COMPARISON_REFUSED,
  LABEL_COMPARISON,
  LABEL_DELETE_COMPARISON,
  LABEL_SEE_COMPARISON,
} from 'settings/labels';
import { TMS_ORIGIN_FOLDER } from 'settings/tms';

import { FolderComparison } from 'api/vi3pAPI/apiTypes/FolderType';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import Button from 'commonUi/Button/Button';
import LotCardLoadProgramAndLot from 'commonUi/LotCard/LotCardLoadProgramAndLot';

import styles from './ComparisonItem.module.scss';

interface ComparisonItemProps {
  comparisonDatas: FolderComparison;
  handleDelete: (comparison: FolderComparison) => void;
  number: number;
}

export function ComparisonItem({ comparisonDatas, handleDelete, number }: ComparisonItemProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { isResponsive } = useContext(ResponsiveContext);
  const comparisonQueryParams = qs.stringify(
    { lots: comparisonDatas.map(c => [c.programRef, c.lotNumber]) },
    {
      encode: false,
      arrayFormat: 'bracket',
    }
  );

  function seeComparisonMobile() {
    enqueueSnackbar(LABEL_ACCESS_COMPARISON_REFUSED);
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <Icon className={styles.titleIcon} icon="compare" />
        {LABEL_COMPARISON} # {number < 10 ? `0${number}` : number}
      </div>
      <div className={styles.lots}>
        {comparisonDatas.map(item => (
          <div className={styles.lot} key={`${item.programRef}-${item.lotNumber}`}>
            <LotCardLoadProgramAndLot lotNumber={item.lotNumber} programRef={item.programRef} />
          </div>
        ))}
      </div>
      <div className={styles.buttons}>
        <div className={styles.buttonsItem}>
          <Button
            className={classnames(styles.button, styles.deleteBtn)}
            color="primary"
            icon="trash"
            iconClass={styles.buttonIcon}
            onClick={() => handleDelete(comparisonDatas)}
            variant="outlined"
            tooltip={LABEL_DELETE_COMPARISON}
            tooltipClassName={styles.tooltip}
          />
        </div>
        <div className={styles.buttonsItem}>
          <Button
            className={styles.button}
            color="primary"
            href={
              isResponsive
                ? ''
                : `/comparaison?${comparisonQueryParams}&origin=${TMS_ORIGIN_FOLDER}`
            }
            icon="compare"
            iconClass={styles.buttonIcon}
            onClick={isResponsive ? seeComparisonMobile : undefined}
            variant="outlined"
            tooltip={LABEL_SEE_COMPARISON}
            tooltipClassName={styles.tooltip}
          />
        </div>
      </div>
    </div>
  );
}
