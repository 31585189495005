import React, { useState } from 'react';
import type { ComponentProps } from 'react';
import ReactPDF from '@react-pdf/renderer';

import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import { generatePlanImageFromPdf } from 'services/pdf';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import LotPdf from 'commonUi/PDF/LotPdf/LotPdf';
import PdfButtonUI from './PdfButtonUI';

interface PdfLotButtonWithLoadingSystemProps {
  button: ComponentProps<typeof PdfButtonUI>['button'];
  isButtonActive: boolean;
  loadPdf: (instance: ReactPDF.UsePDFInstance) => void;
  lot: LotTypeV2;
  lotPdf: ReactPDF.UsePDFInstance | undefined;
  openDropdownTop?: boolean;
  openModal: (modalId: string) => void;
  program: ProgramTypeV2;
  setActiveButton: (activeButton: any) => void;
  statuses: ComponentProps<typeof LotPdf>['statuses'];
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
}

export default function PdfLotButtonWithLoadingSystem({
  button,
  isButtonActive,
  loadPdf,
  lot,
  lotPdf,
  openDropdownTop = false,
  openModal,
  program,
  setActiveButton,
  statuses,
  tms = undefined,
}: PdfLotButtonWithLoadingSystemProps) {
  const [loading, setLoading] = useState<boolean>(false);

  function prepareLotPlanForPdf() {
    const { documents } = lot;
    const lotPlans = documents.filter(d => d.nature === 'Plan');
    if (lotPlans && lotPlans.length > 0) {
      generatePlanImageFromPdf(lotPlans[0].url, (plan?: string) => generateLotPdf(plan));
    } else {
      generateLotPdf();
    }
  }
  async function generateLotPdf(plan?: string) {
    const blob = await ReactPDF.pdf(
      <LotPdf lot={lot} program={program} statuses={statuses} plan={plan} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    loadPdf({ blob, url, loading: false, error: null });
    setLoading(false);
  }

  function onClick() {
    setLoading(true);
    prepareLotPlanForPdf();
  }

  return (
    <PdfButtonUI
      onClick={() => onClick()}
      loading={loading}
      button={button}
      isButtonActive={isButtonActive}
      url={lotPdf?.url}
      setActiveButton={setActiveButton}
      openDropdownTop={openDropdownTop}
      openModal={openModal}
      tms={tms}
    />
  );
}
