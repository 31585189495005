import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { useSnackbar } from 'notistack';
import { mutate } from 'swr';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { formatDate } from 'services/date';
import {
  LABEL_ALERT_RECIPIENT,
  LABEL_CONFIRM_SAVE_SEARCH_DELETE_ERROR,
  LABEL_CONFIRM_SAVE_SEARCH_ERROR,
  LABEL_DELETE,
  LABEL_DISABLE_MAIL_ALERT,
  LABEL_ENABLE_MAIL_ALERT,
  LABEL_MODIFY,
  LABEL_MY_SEARCH_DELETE_CONFIRM,
  LABEL_SAVE_SEARCH_ALERT_FIELD_LABEL,
  LABEL_SEE_SEARCH_RESULTS,
} from 'settings/labels';
import { SEARCH_PANEL_TMS_INFOS } from 'settings/search';
import { TMS_DEFAULT_VALUES, TMS_TIMEOUT } from 'settings/tms';

import { useModal } from 'hooks/useModal';

import { TmsContext } from 'modules/App/Contexts/TmsContext';
import { userContext } from 'modules/App/Contexts/userContext';

import { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';
import { SavedSearchType } from 'api/vi3pAPI/apiTypes/SavedSearch';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import Button from 'commonUi/Button/Button';
import NotificationDot from 'commonUi/NotificationDot/NotificationDot';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import Tooltip from 'commonUi/Tooltip/Tooltip';

import ModalDeleteSaveSearch from '../Modals/ModalDeleteSaveSearch';

import styles from './SavedSearchItem.module.scss';

interface SavedSearchItemProps {
  className?: string;
  handleClose: () => void;
  notifications: NotificationType[];
  search: SavedSearchType;
  setTms: (tms: Partial<typeof TMS_DEFAULT_VALUES>) => void;
  tms: Partial<typeof TMS_DEFAULT_VALUES>;
  updateNotifications: (searchId: string) => void;
}

export default function SavedSearchItem({
  className,
  handleClose,
  notifications,
  search,
  setTms,
  tms,
  updateNotifications,
}: SavedSearchItemProps) {
  const { open, openModal, closeModal } = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const { activityUrls, userCrm } = useContext(userContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const history = useHistory();
  const { setPageVars, setComponentPageVars, setEventPageVars } = useContext(TmsContext);

  async function handleAlertClick() {
    setLoading(true);
    setTms({
      ...tms,
      ...{
        navigation_pagename: search.alerte
          ? 'mesrecherches_alerte.desactiver'
          : 'mesrecherches_alerte.activer',
      },
    });
    try {
      const response = await axiosVI3PInstance.patch(
        `profils/${userCrm?.extension_VI3P_ContactId}/recherches`,
        { alerte: !search.alerte, id: search.id }
      );
      mutate(
        activityUrls.savedSearch,
        data => {
          return response.data;
        },
        false
      );
    } catch (newError) {
      if (newError) {
        enqueueSnackbar(LABEL_CONFIRM_SAVE_SEARCH_ERROR, {
          content: (key, message) => (
            <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
          ),
        });
      }
    }
    setTms(SEARCH_PANEL_TMS_INFOS);
    setLoading(false);
  }

  async function handleDelete() {
    setDeleteLoading(true);
    setTms({
      ...tms,
      ...{
        navigation_pagename: 'mesrecherches_supprimer.confirmation',
      },
    });
    try {
      const response = await axiosVI3PInstance.delete(
        `profils/${userCrm?.extension_VI3P_ContactId}/recherches?ids=${search.id}`
      );
      mutate(
        activityUrls.savedSearch,
        data => {
          return response.data;
        },
        false
      );
      enqueueSnackbar(LABEL_MY_SEARCH_DELETE_CONFIRM);
      setTms(SEARCH_PANEL_TMS_INFOS);
      closeModal();
    } catch (newError) {
      if (newError) {
        enqueueSnackbar(LABEL_CONFIRM_SAVE_SEARCH_DELETE_ERROR, {
          content: (key, message) => (
            <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
          ),
        });
      }
    }
    setTms(SEARCH_PANEL_TMS_INFOS);
    setDeleteLoading(false);
  }

  function handleDeleteClick() {
    openModal();
    setTms({
      ...tms,
      ...{
        navigation_pagename: 'mesrecherches_supprimer',
      },
    });
  }

  return (
    <>
      <div className={classnames(className, styles.root)}>
        <div className={styles.inner}>
          <div className={classnames(styles.innerCol, styles.infos)}>
            <div className={styles.title}>{search.titre}</div>
            <div className={styles.date}>{formatDate(search.date)}</div>
            <div className={styles.filters}>{search.filtres}</div>
            <div className={styles.linkWrapper}>
              <Button
                className={styles.link}
                labelClassName={styles.linkLabel}
                onClick={() => {
                  updateNotifications(search.id);
                  setTms({ ...tms, ...{ navigation_pagename: 'mesrecherches_voirmarecherche' } });
                  setTimeout(() => {
                    handleClose();
                    history.replace(`/${search.url}`);
                  }, TMS_TIMEOUT);
                }}
                variant="text"
              >
                {LABEL_SEE_SEARCH_RESULTS}
              </Button>
              <NotificationDot
                maxIsInclusive
                amount={notifications?.filter(n => n.target_id === search.id).length}
                max={1}
                TagName="sup"
              />
            </div>
          </div>
          <div className={classnames(styles.innerCol, styles.buttons)}>
            <div className={styles.buttonsItem}>
              <Button
                className={styles.button}
                color="primary"
                onClick={() => {
                  setEventPageVars({});
                  setComponentPageVars({});
                  setPageVars({});
                  handleClose();
                  history.replace(`/${search.url}&searchId=${search.id}`);
                }}
                icon="edit"
                iconClass={styles.buttonIcon}
                variant="outlined"
                tooltip={LABEL_MODIFY}
              />
            </div>
            <div className={styles.buttonsItem}>
              <Button
                className={classnames(styles.button, styles.deleteBtn)}
                color="primary"
                icon="trash"
                iconClass={styles.buttonIcon}
                variant="outlined"
                tooltip={LABEL_DELETE}
                onClick={handleDeleteClick}
              />
            </div>
          </div>
        </div>
        <div className={classnames(styles.inner, styles.alertWrapper)}>
          <div className={classnames(styles.innerCol)}>
            <div className={styles.alertText}>{LABEL_SAVE_SEARCH_ALERT_FIELD_LABEL}</div>
            {search.alerte && search.email && (
              <div className={classnames(styles.recipient)}>
                {LABEL_ALERT_RECIPIENT} <strong>{search.email}</strong>
              </div>
            )}
          </div>
          <div className={classnames(styles.innerCol, styles.buttons)}>
            <div className={styles.buttonsItem}>
              <Tooltip
                arrow
                title={search.alerte ? LABEL_DISABLE_MAIL_ALERT : LABEL_ENABLE_MAIL_ALERT}
                placement="top"
              >
                <button
                  className={classnames(styles.button, styles.alertBtn, {
                    [styles.alertBtnOff]: !search.alerte,
                  })}
                  type="button"
                  onClick={handleAlertClick}
                >
                  {loading ? (
                    <CircularProgress
                      classes={{ root: styles.buttonLoader }}
                      variant="indeterminate"
                      size={24}
                    />
                  ) : (
                    <>
                      <Icon
                        className={classnames(styles.buttonIcon, styles.buttonIconDefault)}
                        icon="notifications"
                      />
                      <SvgIcon
                        className={classnames(styles.buttonSvgIcon, styles.buttonIconHover)}
                        iconId="icon-active-alert-mono"
                      />
                    </>
                  )}
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>

      {open && (
        <ModalDeleteSaveSearch
          closeCallBack={() => {
            setTms(SEARCH_PANEL_TMS_INFOS);
            closeModal();
          }}
          deleteCallBack={handleDelete}
          open
          loading={deleteLoading}
        />
      )}
    </>
  );
}
