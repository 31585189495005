import React, { useContext, useEffect } from 'react';

import { extractVideoIdFromUrl } from 'services/videos';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { ContactType } from 'api/vi3pAPI/apiTypes/ContactType';
import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

import ActualityPage from 'modules/Actuality/components/ActualityPage/ActualityPage';

import UnderFirstSlider from 'commonUi/HomeSliders/UnderFirstSlider';
import ContactsSlider from 'commonUi/HomeSliders/ContactsSlider';
import BannerBottom from 'commonUi/BannerBottom/BannerBottom';
import ImageOverlay from 'commonUi/Image/ImageOverlay';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

const LOGIN_URL = window.vinci.REACT_APP_LOGIN_URL;
const SIGNUP_URL = window.vinci.REACT_APP_SIGNUP_URL;

export default React.forwardRef<HTMLElement>(function DisconnectedHomePage(_, ref) {
  const { settings } = useContext(SettingsContext);

  const { data: contacts } = useSWRVi3p<ContactType[]>({
    url: `/contact`,
  });

  useEffect(() => {
    document.body.classList.add('homepage-dis');

    return () => {
      document.body.classList.remove('homepage-dis');
    };
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  function scrollToInterlocuteurs() {
    document.getElementById('interlocuteurs')?.scrollIntoView({
      block: 'start',
      behavior: 'auto',
      inline: 'end',
    });
  }

  if (!settings) {
    return null;
  }

  const medias = (settings?.home?.block_media?.soustitre ||
    settings?.home?.block_media?.titre ||
    settings?.home?.block_media?.media) && [
    {
      text: settings?.home?.block_media?.soustitre && (
        <FieldRte html={settings.home.block_media.soustitre} className="vinci-text" />
      ),
      title: settings?.home?.block_media?.titre && (
        <FieldRte html={settings.home.block_media.titre} className="vinci-title" />
      ),
      image:
        settings?.home?.block_media?.media?.type === 'image'
          ? settings.home.block_media.media.url
          : undefined,
      videoId:
        settings.home.block_media.media.type === 'video' && settings.home.block_media.media.url
          ? extractVideoIdFromUrl(settings.home.block_media.media.url)
          : undefined,
      opts: {
        height: '100%',
        width: '100%',
        playerVars: {
          controls: 0,
          enablejsapi: 1,
          rel: 0,
        } as const,
      },
    },
  ];

  return (
    <>
      {/* Login section */}
      <section className="section-conteneur section-login" ref={ref}>
        <div className="section-conteneur-wrap">
          <div className="content-login">
            <div className="content-image-wrapper">
              <ImageOverlay
                src={settings?.home?.image}
                width="200"
                height="auto"
                alt="image-slogan"
                opacity={settings?.home?.image_opacite}
              />

              <div className="mobile-header">
                <img className="vip" src="/images/logo_vip_color.png" alt="Logo VIP" />
                <img
                  className="vinci"
                  src="/images/new_images/logo_vinci_white.svg"
                  alt="Logo Vinci"
                />
              </div>

              <div className="content">
                <div className="content-left">
                  {settings?.home?.titre && (
                    <FieldRte html={settings.home.titre} className="content-title" />
                  )}
                </div>
                <div className="content-right">
                  <div className="login-system">
                    <div className="login-title">Espace partenaire</div>
                    <div className="login-button">
                      <a
                        data-test-id="homepage-register-button"
                        href={SIGNUP_URL}
                        className="btn-primary btn-reverse btn-optionner"
                      >
                        DEVENIR PARTENAIRE
                      </a>
                      <a
                        data-test-id="homepage-login-button"
                        href={LOGIN_URL}
                        className="btn-primary"
                      >
                        SE CONNECTER
                      </a>
                      {document.getElementById('interlocuteurs') !== null && (
                        <button
                          className="btn-contact"
                          onClick={scrollToInterlocuteurs}
                          type="button"
                        >
                          Contactez vos interlocuteurs régionaux
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End of Login section */}

      {/* Under First section */}
      {medias && <UnderFirstSlider content={medias} />}
      {/* End of Under First section */}

      <ActualityPage />

      {/* Contact section */}
      <ContactsSlider content={contacts} />
      {/* End of contact section */}

      {/* VIP section */}
      <section className="section-conteneur section-vip">
        <BannerBottom />
      </section>
      {/* End of VIP section */}
    </>
  );
});
