/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import { error } from 'services';
import ErrorPage from '../../../layout/components/ErrorPage/ErrorPage';

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorMessage: '',
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message ? error.message : error };
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;
    if (prevProps.location.pathname !== location.pathname) {
      this.setState({ hasError: false, errorMessage: '' });
    }
  }

  componentDidCatch(e, errorInfo) {
    error(e, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { errorMessage, hasError } = this.state;

    if (hasError) {
      return <ErrorPage errorMessage={errorMessage} />;
    }

    return children;
  }
}

export default ErrorBoundary;
