import React, { useContext, useState } from 'react';

import { LABEL_SEE_MORE_NEWS } from 'settings';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import useDisableSlider from 'hooks/useDisableSlider';

import Button from 'commonUi/Button/Button';
import SliderArrow from 'commonUi/HomeSliders/SliderArrow';
import { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';
import SliderContainer from 'layout/components/SliderContainer/SliderContainer';

import NewsSliderItem from './NewsSliderItem';

import styles from './NewsSlider.module.scss';

const SLIDER_ITEM_WIDTH = 275;
const SLIDER_ITEM_GUTTER = 40;

interface NewsSliderProps {
  content?: ActualityType[];
  id?: string;
  title?: string;
}

export default function NewsSlider({ content = [], id, title }: NewsSliderProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { isDisabled, sliderRef } = useDisableSlider(
    content.length,
    SLIDER_ITEM_WIDTH,
    SLIDER_ITEM_GUTTER
  );
  const [nbItemsInMobile, setNbItemsInMobile] = useState(3);

  if (!content.length) {
    return null;
  }

  return (
    <div className={styles.root} id={id}>
      {isResponsive ? (
        <>
          <div className={styles.title}>{title}</div>
          {content.slice(0, nbItemsInMobile).map(item => (
            <NewsSliderItem
              key={item.nid}
              description={item.introduction}
              image={item.photo?.home}
              title={item.titre}
              url={`/actualites/${item.nid}`}
            />
          ))}
        </>
      ) : (
        <SliderContainer
          ref={sliderRef}
          sliderSettings={{
            dots: false,
            infinite: !isDisabled,
            speed: 1000,
            arrows: !isDisabled,
            slidesToScroll: 1,
            touchMove: !isDisabled,
            variableWidth: true,
            nextArrow: <SliderArrow label="Suivant" />,
            prevArrow: <SliderArrow label="Précédent" />,
          }}
          title={title}
        >
          {content.map(item => (
            <NewsSliderItem
              key={item.nid}
              className={styles.card}
              description={item.introduction}
              image={item.photo?.home}
              title={item.titre}
              url={`/actualites/${item.nid}`}
              style={{ width: `${SLIDER_ITEM_WIDTH}px`, margin: `0 ${SLIDER_ITEM_GUTTER / 2}px` }}
            />
          ))}
        </SliderContainer>
      )}

      {isResponsive && nbItemsInMobile < content.length && (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.link}
            variant="contained"
            color="primary"
            onClick={() =>
              setNbItemsInMobile(nbItemsInMobile =>
                nbItemsInMobile + 3 > content.length
                  ? nbItemsInMobile + (content.length - nbItemsInMobile)
                  : nbItemsInMobile + 3
              )
            }
          >
            {LABEL_SEE_MORE_NEWS}
          </Button>
        </div>
      )}
    </div>
  );
}
