import React, { useContext, useState } from 'react';
import type { ComponentProps } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import ReactPDF from '@react-pdf/renderer';
import { Button as ButtonMui } from '@material-ui/core';
import classnames from 'classnames';

import { getLotActionButtons } from 'services/lotsV2';
import { generatePlanImageFromPdf } from 'services/pdf';
import { getPromotionStylesFromSettings } from 'services/promotions';
import {
  LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET,
  LABEL_PROMO,
  LABEL_SEND_LOT_CARD,
  LABEL_SEND_PROGRAM_CARD,
  LABEL_SHOW_LOT_DETAIL,
} from 'settings/labels';
import {
  LOT_CELL_ID_NUMBER,
  LOT_CELL_ID_PLAN,
  LOT_CELL_ID_PROGRAM,
  LOT_CELL_ID_STATUS,
  LOT_CELL_TYPE_ACTION,
  LOT_CELL_TYPE_CHECKBOX,
  LOT_CELL_TYPE_PLAN,
  LOT_MOBILE_HEADINGS,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
} from 'settings/lots';
import {
  MODAL_ID_SEND_BOOKLET,
  MODAL_ID_SEND_CARD_LOT,
  MODAL_ID_SEND_CARD_PROGRAM,
} from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { HeadingType, LotJson, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { modifyQuery } from 'services/url';

import { useSWROffres } from 'api/offresAPI/useSWROffres';

import { useBooklet } from 'hooks/useBooklet';
import { usePanels } from 'hooks/usePanels';
import { useModalMultiples } from 'hooks/useModal';

import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';
import programLotContext from 'modules/App/Contexts/programLotContext';
import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';
import Button from 'commonUi/Button/Button';
import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import LotPdf from 'commonUi/PDF/LotPdf/LotPdf';
import { ModalSendPdf } from 'modules/ActionsProgramsAndLots/ModalSendPdf/ModalSendPdf';
import LotCell from 'commonUi/ListLotV2/LotCell/LotCell';
import TagCommanderComponent from 'modules/App/TagCommander/TagCommanderComponent';
import FolderLinks from 'modules/Folders/FolderLinks';
import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './LotRowMobile.module.scss';

interface LotRowMobile {
  alerts: ComponentProps<typeof LotCell>['alerts'];
  generateProgramPdf: () => void;
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  isChecked?: boolean;
  isDrawerOpen: boolean;
  lot: LotJson;
  myOptions?: ComponentProps<typeof LotCell>['myOptions'];
  myBookings?: ComponentProps<typeof LotCell>['myBookings'];
  onStatusAlertClick: ComponentProps<typeof LotCell>['onStatusAlertClick'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  programFromApi: ProgramTypeV2;
  programPdf?: ComponentProps<typeof ButtonModalPdf>['pdf'];
  reduceMobilePanel?: () => void;
  renderShowMore: () => JSX.Element | null;
  setCurrentRowOpen: (nid: string) => void;
  statuses: Record<string, Status>;
  taxById: TaxesById;
  updateStatus: (lotNid: string, newStatus: string) => void;
  withCheckbox?: boolean;
}

export default function LotRowMobile({
  renderShowMore,
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lot,
  reduceMobilePanel = undefined,
  statuses,
  taxById = {},
  updateStatus,
  withCheckbox = false,
  myOptions,
  myBookings,
  onStatusAlertClick,
  alerts,
  isChecked = false,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  programFromApi,
  programPdf,
  generateProgramPdf,
  isDrawerOpen,
  setCurrentRowOpen,
}: LotRowMobile) {
  const history = useHistory();
  const { isResponsive } = useContext(ResponsiveContext);
  const { settings } = useContext(SettingsContext);

  const { openCancelOption, openCancelPreBooking, openCreateOption } = useContext(
    ActivityModalsContext
  );
  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(lot.ref, open === MODAL_ID_SEND_BOOKLET);
  const { programs } = useContext(programLotContext);

  const option = myOptions?.find(
    option =>
      option.field_numerolot_ac === lot.number && option.field_referenceprogramme_ac === lot.ref
  );
  const preBooking = myBookings?.find(
    booking =>
      booking.field_numerolot_ac === lot.number && booking.field_referenceprogramme_ac === lot.ref
  );

  const checkboxHead = headings.find(({ id }) => id === LOT_CELL_TYPE_CHECKBOX);
  const bodyHeadings = headings.filter(({ id }) => !LOT_MOBILE_HEADINGS.includes(id));
  const statusHead = headings.find(({ id }) => id === LOT_CELL_ID_STATUS);
  const numberHead = headings.find(({ id }) => id === LOT_CELL_ID_NUMBER);
  const planHead = headings.find(({ id }) => id === LOT_CELL_ID_PLAN);
  const programHead = headings.find(({ id }) => id === LOT_CELL_ID_PROGRAM);

  const sortBy = [LOT_CELL_ID_PLAN];
  const bodyHeadingsSorted = sortBy
    .map(id => bodyHeadings.find(heading => heading.id === id))
    .concat(bodyHeadings.filter(heading => !sortBy.includes(heading.id)));

  const program = programs?.find(program => program.ref === lot.ref);
  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType } = useContext(
    foldersContext
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();
  const promotionLabel =
    !!lot.promotions && lot.promotions.length > 0
      ? Object.values(lot.promotions[0])[0] || `<p>${LABEL_PROMO}</p>`
      : undefined;

  const {
    showCancelOption,
    showCancelPreBook,
    showMakeOption,
    showMakePrebook,
  } = getLotActionButtons(
    statuses[lot.nid]?.label ?? lot.status,
    Boolean(option) || Boolean(preBooking)
  );

  const [clickSendProgramPDF, setClickSendProgramPDF] = useState<boolean>(false);
  const [clickSendLotPDF, setClickSendLotPDF] = useState<boolean>(false);
  const [lotPdf, setLotPdf] = useState<ReactPDF.UsePDFInstance>();

  const { data: lotFromApi } = useSWROffres<LotTypeV2>({
    url:
      clickSendLotPDF && lot?.program?.ref
        ? `/programmes/${lot.ref}/lots/${lot.number}`
        : undefined,
  });

  function prepareLotPlanForPdf() {
    const { documents } = lotFromApi;
    const lotPlans = documents.filter(d => d.nature === 'Plan');
    if (lotPlans && lotPlans.length > 0) {
      generatePlanImageFromPdf(lotPlans[0].url, plan => generateLotPdf(plan));
    } else {
      generateLotPdf();
    }
  }
  async function generateLotPdf(plan?: string) {
    const blob = await ReactPDF.pdf(
      <LotPdf lot={lotFromApi} program={programFromApi} statuses={statuses} plan={plan} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    setLotPdf({ blob, url, loading: false, error: null });
  }

  if (isDrawerOpen) {
    return (
      <>
        {renderShowMore()}
        {pageTemplateRef && pageTemplate && (
          <TagCommanderComponent
            navigation_pagename={`${pageTemplateRef}.${lot.number}`}
            navigation_template={pageTemplate}
            useEffectDeps={['navigation_template', 'navigation_pagename']}
          />
        )}
      </>
    );
  }

  return (
    <div className={styles.resultItemWrapper}>
      <div className={styles.resultItemHeader}>
        {withCheckbox && (
          <LotCell
            onStatusAlertClick={onStatusAlertClick}
            myBookings={myBookings}
            myOptions={myOptions}
            alerts={alerts}
            col={checkboxHead}
            lot={lot}
            statuses={statuses}
            taxById={taxById}
            handleOnChangeCheckbox={handleOnChangeCheckbox}
            isChecked={isChecked}
          />
        )}
        <div className={styles.pills}>
          <LotCell
            onStatusAlertClick={onStatusAlertClick}
            myBookings={myBookings}
            myOptions={myOptions}
            alerts={alerts}
            col={statusHead}
            lot={lot}
            statuses={statuses}
            taxById={taxById}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
          />
        </div>
        {numberHead && (
          <div className={styles.lotNumber}>
            {numberHead.title}:{' '}
            <button className={styles.lot} onClick={() => setCurrentRowOpen(lot.nid)} type="button">
              {lot.number}
            </button>
          </div>
        )}
        <LotCell
          className={styles.plan}
          col={planHead}
          lot={lot}
          taxById={taxById}
          myBookings={myBookings}
          myOptions={myOptions}
          alerts={alerts}
          onStatusAlertClick={onStatusAlertClick}
          statuses={statuses}
        />
      </div>
      {(programHead || !hideMapButton) && (
        <div className={styles.resultItemHeader2}>
          {programHead && (
            <LotCell
              onStatusAlertClick={onStatusAlertClick}
              myBookings={myBookings}
              myOptions={myOptions}
              alerts={alerts}
              col={programHead}
              lot={lot}
              statuses={statuses}
              taxById={taxById}
            />
          )}
          {!hideMapButton && (
            <button
              className={styles.showMapBtn}
              type="button"
              onClick={() => {
                if (typeof reduceMobilePanel === 'function') {
                  reduceMobilePanel();
                }
                history.replace(
                  modifyQuery({
                    programRef: lot.program.ref,
                    origin: 'list',
                    lat: lot.program.lat,
                    lng: lot.program.lng,
                    zoom: 14,
                  })
                );
              }}
            >
              <Icon className={styles.showMapBtnIcon} icon="map-plan" />
            </button>
          )}
        </div>
      )}

      <ButtonMui
        onClick={() => setCurrentRowOpen(lot.nid)}
        classes={{
          root: classnames(styles.btnDetails, { [styles.btnDetailsPromo]: promotionLabel }),
          label: classnames(styles.btnDetailsLabel, {
            [styles.btnDetailsLabelPromo]: promotionLabel,
          }),
        }}
        fullWidth
        style={promotionLabel ? getPromotionStylesFromSettings(settings) : undefined}
      >
        {!!promotionLabel && (
          <div className={styles.labelPromo}>
            <FieldRte className={styles.promoText} html={promotionLabel} />
          </div>
        )}
        {LABEL_SHOW_LOT_DETAIL}
      </ButtonMui>

      <dl className={styles.resultItemInfo}>
        {bodyHeadingsSorted.map((col: HeadingType) =>
          col.type === LOT_CELL_TYPE_ACTION ||
          (isResponsive && col.type === LOT_CELL_TYPE_PLAN) ? null : (
            <div key={col.id} className={styles.line}>
              <dt>
                {col.title}
                {col.sub && <br />}
                {col.sub && <span className={styles.additionnalInfo}> {col.sub}</span>}
              </dt>
              <dd>
                <LotCell
                  onStatusAlertClick={onStatusAlertClick}
                  myBookings={myBookings}
                  myOptions={myOptions}
                  alerts={alerts}
                  col={col}
                  lot={lot}
                  statuses={statuses}
                  taxById={taxById}
                />
              </dd>
            </div>
          )
        )}
      </dl>

      <div className={styles.actionsBtn}>
        {showMakeOption && (
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              color="primary"
              iconId="icon-option"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_OPTION}
              onClick={() =>
                openCreateOption(
                  {
                    lotNumber: lot.number,
                    programRef: lot.ref,
                    programName: lot.program.name,
                  },
                  () => {
                    if (typeof updateStatus === 'function') {
                      updateStatus(lot.nid, LOT_STATUS_OPTIONED);
                    }
                  }
                )
              }
            />
          </span>
        )}
        {showCancelOption && (
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              color="primary"
              iconId="icon-cancel"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION}
              onClick={() => {
                if (option?.field_idcrm_ac && option.field_dateexpiration_ac) {
                  openCancelOption(
                    {
                      activityId: option.field_idcrm_ac,
                      expirationDate: new Date(option.field_dateexpiration_ac),
                      lotNumber: option.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            />
          </span>
        )}
        {showMakePrebook && (
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_PREBOOK}
              component={RouterLink}
              to={`/pre-reservation/${lot.ref}/${lot.number}?backUrl=${encodeURIComponent(
                window.location.href
              )}`}
            />
          </span>
        )}
        {showCancelPreBook && (
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK}
              onClick={() => {
                if (preBooking?.field_idcrm_ac && preBooking.field_dateexpiration_ac) {
                  openCancelPreBooking(
                    {
                      activityId: preBooking.field_idcrm_ac,
                      expirationDate: new Date(preBooking.field_dateexpiration_ac),
                      lotNumber: preBooking.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            />
          </span>
        )}
        <span className={styles.actionBtnContainer}>
          <Button
            className={styles.actionBtn}
            variant="outlined"
            iconClass={styles.svgIcon}
            iconId="icon-add-to-folder"
            tooltip={LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER}
            color="primary"
            fullWidth
            onClick={() => {
              if (folderSaveType !== 'lot') {
                emptyDatas();
              }
              openPanel(PANEL_ID_FOLDERS);
              setFolderSaveType('lot');
              setFolderLots([{ lotNumber: lot.number, programRef: lot.ref }]);
            }}
          />
        </span>
        <span className={styles.actionBtnContainer}>
          {(programFromApi && clickSendProgramPDF) || programPdf ? (
            <ButtonModalPdf
              clickOpenSendPDF={clickSendProgramPDF}
              generatePdf={generateProgramPdf}
              pdf={programPdf}
              handleClick={() => openModal(MODAL_ID_SEND_CARD_PROGRAM)}
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-program-card"
              tooltip={LABEL_SEND_PROGRAM_CARD}
              color="primary"
              fullWidth
            />
          ) : (
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-program-card"
              tooltip={LABEL_SEND_PROGRAM_CARD}
              color="primary"
              fullWidth
              loading={clickSendProgramPDF}
              onClick={() => setClickSendProgramPDF(true)}
            />
          )}
        </span>
        <span className={styles.actionBtnContainer}>
          {programFromApi && lotFromApi && clickSendLotPDF ? (
            <ButtonModalPdf
              clickOpenSendPDF={clickSendLotPDF}
              generatePdf={prepareLotPlanForPdf}
              pdf={lotPdf}
              handleClick={() => openModal(MODAL_ID_SEND_CARD_LOT)}
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-lot-card"
              tooltip={LABEL_SEND_LOT_CARD}
              color="primary"
              fullWidth
            />
          ) : (
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-lot-card"
              tooltip={LABEL_SEND_LOT_CARD}
              color="primary"
              fullWidth
              loading={clickSendLotPDF}
              onClick={() => setClickSendLotPDF(true)}
            />
          )}
        </span>
        <span className={styles.actionBtnContainer}>
          <Button
            className={styles.actionBtn}
            variant="outlined"
            iconClass={styles.svgIcon}
            iconId="icon-booklet"
            tooltip={LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET}
            color="primary"
            fullWidth
            onClick={() => openModal(MODAL_ID_SEND_BOOKLET)}
          />
        </span>
      </div>

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      {open === MODAL_ID_SEND_CARD_LOT && (
        <ModalSendPdf
          closeCallBack={() => {
            setClickSendLotPDF(false);
            closeModal();
          }}
          blobPdf={lotPdf?.blob}
          lot={lotFromApi}
          open
          pdfUrl={lotPdf?.url}
          program={programFromApi}
        />
      )}
      {open === MODAL_ID_SEND_CARD_PROGRAM && (
        <ModalSendPdf
          closeCallBack={() => {
            setClickSendProgramPDF(false);
            closeModal();
          }}
          blobPdf={programPdf?.blob}
          open
          pdfUrl={programPdf?.url}
          program={programFromApi}
        />
      )}
      {open === MODAL_ID_SEND_BOOKLET && (
        <ModalSendBooklet booklet={booklet} closeCallBack={closeModal} open program={program} />
      )}
    </div>
  );
}
