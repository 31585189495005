import React from 'react';
import classnames from 'classnames';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { AssistanceType } from '../AssistanceType';

import styles from './AssistanceBlock.module.scss';

export default function AssistanceBlockMobile(props: AssistanceType) {
  const { defaultJob, interlocutors } = props;

  return (
    <div className={styles.root}>
      <div className={styles.title}>Mes interlocuteurs</div>
      <div className={styles.contactsList}>
        {interlocutors.map((interlocutor, idx) => {
          const {
            field_email: email,
            field_nom: lastname,
            field_prenom: firstname,
            field_tel: phone,
            // job, not return by api
          } = interlocutor;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={styles.contact} key={idx}>
              <div className={styles.contactName}>
                {lastname} {firstname}
              </div>
              <div className={styles.contactJob}>{defaultJob}</div>
              <a className={styles.linkMail} href={`mailto:${email}`}>
                {email}
              </a>
              <div className={classnames(styles.contactInfos, styles.contactInfosVisible)}>
                <div>
                  <a
                    className={classnames(styles.button, styles.buttonLight)}
                    href={`mailto:${email}`}
                  >
                    <Icon className={styles.buttonIcon} icon="mail" />
                    Contacter
                  </a>
                  <a className={styles.button} href={`tel:${phone}`}>
                    <Icon className={styles.buttonIcon} icon="call" />
                    {phone}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
