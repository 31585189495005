import React, { useContext } from 'react';
import classnames from 'classnames';

import Housing from 'api/viOffresAPI/apiTypes/Housing';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import SliderArrow from 'commonUi/HomeSliders/SliderArrow';
import SliderContainer from 'layout/components/SliderContainer/SliderContainer';
import HighlightSliderItem, { DISPLAY_COL } from './HighlightSliderItem';

import styles from './HighlightSlider.module.scss';

/**
 * Regroup the items of the given array into arrays of the given size
 */
function chunkifyArray<T>(array: T[], chunkSize: number): T[][] {
  const res: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    res.push(array.slice(i, i + chunkSize));
  }
  return res;
}

interface HighlightSliderProps {
  content?: Housing[];
  dark?: boolean;
  title?: string;
}

export default function HighlightSlider({
  content = [],
  dark = false,
  title,
}: HighlightSliderProps) {
  const { isResponsive } = useContext(ResponsiveContext);

  if (content.length === 0) {
    return null;
  }

  return (
    <SliderContainer
      dark={dark}
      sliderSettings={{
        dots: false,
        infinite: true,
        speed: 1000,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SliderArrow label="Suivant" />,
        prevArrow: <SliderArrow label="Précédent" />,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              arrows: false,
              dots: false,
              variableWidth: true,
            },
          },
        ],
      }}
      title={title}
    >
      {isResponsive
        ? content
            .slice(0, 6)
            .map(housing => <HighlightSliderItem key={housing.nid} data={housing} />)
        : chunkifyArray(content, 3).map(chunk => (
            <div
              key={chunk.reduce((key, { nid }) => `${key}${nid}`, '')}
              className={styles.slideGrid}
            >
              <div className={classnames(styles.slideGridCol, styles.slideColLarge)}>
                <HighlightSliderItem className={styles.slideGridItem} data={chunk[0]} />
              </div>

              <div className={classnames(styles.slideGridCol, styles.slideColLittle)}>
                {chunk.slice(1).map(housing => (
                  <HighlightSliderItem
                    key={housing.nid}
                    className={styles.slideGridItem}
                    data={housing}
                    display={DISPLAY_COL}
                  />
                ))}
              </div>
            </div>
          ))}
    </SliderContainer>
  );
}
