import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classnames from 'classnames';
import { CircularProgress, IconButton } from '@material-ui/core';

import { formatActivityDate } from 'services/date';
import { ACTIVITY_STATUS_ACTIVE } from 'settings/activity';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_LAST_SEND,
  LABEL_NO_PROOF_SENT,
  LABEL_PURCHASER_IDS,
  LABEL_SEND_LOADING,
  LABEL_SEND_SIGNATURE_REQUEST,
} from 'settings/labels';
import { USER_SHORT_GENDER_MAP } from 'settings/user';

import Attachment from 'commonUi/Attachment/Attachment';
import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import styles from '../PageAction.module.scss';

const inputSettings = {
  fileTypes: ['pdf', 'jpg', 'jpeg', 'png', 'heic', 'heif'] as const,
  imgFileTypes: ['jpg', 'jpeg', 'png', 'heic', 'heif'] as const,
  imgSizeLimit: 3e6, // 3MB
  sizeLimit: 5e6, // 5MB
};

function getProspectFullName(civility: string, firstName: string, lastName: string) {
  return [
    USER_SHORT_GENDER_MAP.has(civility) ? `${USER_SHORT_GENDER_MAP.get(civility)}.` : null,
    firstName,
    lastName,
  ]
    .filter(item => item)
    .join(' ');
}

interface Error {
  title: string;
  content: string;
  details?: string;
}
interface FormAttachmentType {
  dateLastSend?: string;
  onClose?: any;
  loadingSubmit: boolean;
  prebook: Activity;
  readonly?: boolean;
  setError: (error: Error | undefined) => void;
  error?: Error;
}

export function FormAttachments({
  dateLastSend: dateLastSendProps = undefined,
  error,
  loadingSubmit,
  prebook,
  readonly = false,
  setError,
  onClose,
}: FormAttachmentType) {
  const { control, watch } = useFormContext();
  const acquereurFile = watch('field-acquereur-id');
  const coAcquereurFile = watch('field-co-acquereur-id');

  if (!prebook) {
    return (
      <div className={styles.loading}>
        <CircularProgress variant="indeterminate" size={24} />
      </div>
    );
  }
  const dateLastSend = dateLastSendProps ?? prebook.field_datedernierenvoi_ac;

  const isAcquereur = prebook.field_prospectprenom_ac && prebook.field_prospectnom_ac;
  const isCoAcquereur = prebook.field_coprospectprenom_ac && prebook.field_coprospectnom_ac;

  return (
    <>
      <div className={styles.form}>
        <div className={styles.title}>{LABEL_PURCHASER_IDS}</div>
        <div className={styles.grid}>
          {isAcquereur && (
            <div className={styles.input}>
              <Controller
                name="field-acquereur-id"
                control={control}
                defaultValue={undefined}
                render={props => (
                  <Attachment
                    cniPdfImageFilename={`CNI_${prebook.field_referenceprogramme_ac}_${prebook.field_numerolot_ac}_${prebook.field_prospectnom_ac}-${prebook.field_prospectprenom_ac}`}
                    disabled={prebook.field_statutnom_ac !== ACTIVITY_STATUS_ACTIVE}
                    inputId="acquereur-id"
                    onInputValueChange={value => props?.field.onChange(value)}
                    onInputValueError={setError}
                    readonly={readonly}
                    selectedFile={props?.field.value}
                    settings={inputSettings}
                    title={
                      <div className={styles.titleContent}>
                        <span className={styles.name}>
                          {getProspectFullName(
                            prebook.field_prospectcivilite_ac,
                            prebook.field_prospectprenom_ac,
                            prebook.field_prospectnom_ac
                          )}
                        </span>
                        {prebook.field_prospectmail_ac && (
                          <span className={styles.contact}>{prebook.field_prospectmail_ac}</span>
                        )}
                        {prebook.field_prospecttelephone_ac && (
                          <span className={styles.contact}>
                            {prebook.field_prospecttelephone_ac}
                          </span>
                        )}
                      </div>
                    }
                  />
                )}
              />
            </div>
          )}

          {isCoAcquereur && (
            <div className={styles.input}>
              <Controller
                name="field-co-acquereur-id"
                control={control}
                defaultValue={undefined}
                render={props => (
                  <Attachment
                    cniPdfImageFilename={`CNI_${prebook.field_referenceprogramme_ac}_${prebook.field_numerolot_ac}_${prebook.field_coprospectnom_ac}-${prebook.field_coprospectprenom_ac}`}
                    disabled={prebook.field_statutnom_ac !== ACTIVITY_STATUS_ACTIVE}
                    inputId="co-acquereur-id"
                    onInputValueChange={value => props?.field.onChange(value)}
                    onInputValueError={setError}
                    readonly={readonly}
                    selectedFile={props?.field.value}
                    settings={inputSettings}
                    title={
                      <div className={styles.titleContent}>
                        <span className={styles.name}>
                          {getProspectFullName(
                            prebook.field_coprospectcivilite_ac,
                            prebook.field_coprospectprenom_ac,
                            prebook.field_coprospectnom_ac
                          )}
                        </span>
                        {prebook.field_coprospectmail_ac && (
                          <span className={styles.contact}>{prebook.field_coprospectmail_ac}</span>
                        )}
                        {prebook.field_coprospecttelephone_ac && (
                          <span className={styles.contact}>
                            {prebook.field_coprospecttelephone_ac}
                          </span>
                        )}
                      </div>
                    }
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      {!readonly && !error && (
        <div className={classnames(styles.footer, styles.footerSign)}>
          <div className={styles.cancelButton}>
            <Button onClick={onClose} fullWidth variant="contained" color="secondary">
              {LABEL_CANCEL_BUTTON}
            </Button>
          </div>
          <div className={styles.createButton}>
            <Button
              loading={loadingSubmit}
              disabled={!acquereurFile || (isCoAcquereur && !coAcquereurFile)}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              labelWhileLoading
            >
              {loadingSubmit ? LABEL_SEND_LOADING : LABEL_SEND_SIGNATURE_REQUEST}
            </Button>
          </div>
        </div>
      )}
      {Boolean(error) && (
        <div className={classnames(styles.footer, { [styles.errorFooter]: Boolean(error) })}>
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => setError(undefined)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{error?.title}</h4>
              <p>{error?.content}</p>
              <p className={styles.errorDetails}>{error?.details}</p>
            </div>
          </div>
        </div>
      )}
      <p className={styles.lastSend}>
        {LABEL_LAST_SEND} :{' '}
        {dateLastSend
          ? formatActivityDate(dateLastSend, 'DD/MM/YYYY à HH:mm')
          : LABEL_NO_PROOF_SENT}
      </p>

      <TagCommanderEvent
        isActive={Boolean(error)}
        navigation_pagename="form_envoicni.erreur"
        navigation_template="envoicni"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </>
  );
}
