import React from 'react';
import type { ComponentProps } from 'react';
import { Document, Link, Text } from '@react-pdf/renderer';

import { DOC_NATURE_PLAQUETTE } from 'settings/documents';
import { LABEL_DATE_PDF } from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { replaceTokens } from 'services/formatter';

import PdfPageLayout from '../Layout/PdfPageLayout';
import PdfSection from '../Layout/PdfSection';
import PdfBottomContent from '../Layout/PdfBottomContent';

import LotPdfBanner from './Components/LotPdfBanner/LotPdfBanner';
import LotPdfTotem from './Components/LotPdfTotem/LotPdfTotem';
import LotPdfPlan from './Components/LotPdfPlan/LotPdfPlan';
import LotPdfInfos from './Components/LotPdfInfos/LotPdfInfos';

import styles from './LotPdfStyles';

interface LotPdfProps {
  lot: LotTypeV2;
  plan?: string;
  program: ProgramTypeV2;
  statuses: ComponentProps<typeof LotPdfTotem>['statuses'];
}

export default function LotPdf({ lot, plan, program, statuses }: LotPdfProps) {
  const booklet = program.documents.find(
    document => document.nature.toLocaleLowerCase() === DOC_NATURE_PLAQUETTE.toLocaleLowerCase()
  );

  return (
    <Document>
      <PdfPageLayout>
        <LotPdfBanner lot={lot} program={program}>
          <LotPdfTotem lot={lot} statuses={statuses} />
          {booklet?.url && (
            <Text style={styles.booklet}>
              Plaquette commerciale -{' '}
              <Link style={styles.bookletLink} src={booklet.url}>
                {booklet.url}
              </Link>
            </Text>
          )}
        </LotPdfBanner>
        <LotPdfInfos lot={lot} program={program} />
        {plan && (
          <PdfSection>
            <LotPdfPlan lot={lot} plan={plan} />
          </PdfSection>
        )}
        <PdfBottomContent
          text={`${replaceTokens(LABEL_DATE_PDF, {
            [TOKEN_DATE]: new Date(Date.now()).toLocaleDateString(),
          })}
          https://www.vinci-immobilier-patrimoine.com/mentions-legales`}
        />
      </PdfPageLayout>
    </Document>
  );
}
