import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { showQuarter } from 'services/date';
import { formatPrice, replaceTokens } from 'services/formatter';
import { taxMapping } from 'services/taxes';
import { PARKING_TYPE } from 'settings/app';
import { KITCHEN_TYPE_INLUDED, KITCHEN_TYPE_OPTION } from 'settings/kitchen';
import {
  LABEL_ACTABILITY_DATE,
  LABEL_CHAUFFAGE_TYPE,
  LABEL_DELIVERY,
  LABEL_KITCHEN_LOT_DETAIL_INCLUDED,
  LABEL_KITCHEN_LOT_DETAIL_OPTION,
  LABEL_KITCHEN_LOT_DETAIL_TITLE,
  LABEL_MANAGER,
  LABEL_NATURE,
  LABEL_RESIDENCE_TYPE,
} from 'settings/labels';
import { PROGRAM_HOUSING_TYPE_MAPPING } from 'settings/programs';
import { TAX_TYPE_DEMEMBREMENT, TAX_TYPE_LMNP } from 'settings/taxes';
import { TOKEN_PRICE } from 'settings/token';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { lotHasKitchen } from 'services/lotsV2';

import PdfRowDetailsList from '../../../Common/PdfRowDetailsList/PdfRowDetailsList';
import PdfSvgPicto from '../../../Common/PdfSvgPicto/PdfSvgPicto';

import styles from './LotPdfInfosStyles';

export default function LotPdfGeneralInfos({ lot, program }: LotPdfGeneralInfosProps) {
  const { fiscalites } = lot;

  const isLMNP =
    fiscalites && fiscalites.length > 0 && taxMapping.get(fiscalites[0]) === TAX_TYPE_LMNP;
  const isDemembrement =
    fiscalites && fiscalites.length > 0 && taxMapping.get(fiscalites[0]) === TAX_TYPE_DEMEMBREMENT;
  const housingType = program?.type && PROGRAM_HOUSING_TYPE_MAPPING.get(program.type);

  return (
    <View>
      <PdfRowDetailsList
        items={{
          [LABEL_DELIVERY]: { label: LABEL_DELIVERY, value: showQuarter(program.dates.livraison) },
          [LABEL_ACTABILITY_DATE]: {
            label: LABEL_ACTABILITY_DATE,
            value: showQuarter(program?.dates.actabilite),
          },
          [LABEL_RESIDENCE_TYPE]: {
            label: LABEL_RESIDENCE_TYPE,
            value: housingType || program?.type,
          },
          [LABEL_CHAUFFAGE_TYPE]: {
            label: LABEL_CHAUFFAGE_TYPE,
            value: lot?.typeChauffage,
          },
          ...(isLMNP || isDemembrement
            ? {
                [LABEL_MANAGER]: {
                  label: LABEL_MANAGER,
                  value:
                    program?.residencesGerees?.nomGestionnaire &&
                    program?.residencesGerees?.nomGestionnaire !== '.'
                      ? program.residencesGerees.nomGestionnaire
                      : undefined,
                },
              }
            : {}),
          ...(lot.natureSimplifiee === PARKING_TYPE
            ? {
                [LABEL_NATURE]: {
                  label: LABEL_NATURE,
                  value: lot.nature,
                },
              }
            : {}),
        }}
      />
      <View>
        {lotHasKitchen(lot) && (
          <View style={styles.kitchen}>
            <View style={styles.kitchenPicto}>
              <PdfSvgPicto picto="kitchen" />
            </View>
            <Text style={styles.kitchenLabel}>{LABEL_KITCHEN_LOT_DETAIL_TITLE}</Text>
            {lot?.packs?.cuisineEquipee?.type === KITCHEN_TYPE_OPTION && (
              <Text style={styles.kitchenPrice}>
                {replaceTokens(LABEL_KITCHEN_LOT_DETAIL_OPTION, {
                  [TOKEN_PRICE]: formatPrice(lot?.packs?.cuisineEquipee?.prix, '€', true),
                })}
              </Text>
            )}
            {lot?.packs?.cuisineEquipee?.type === KITCHEN_TYPE_INLUDED && (
              <Text style={styles.kitchenPrice}>{LABEL_KITCHEN_LOT_DETAIL_INCLUDED}</Text>
            )}
          </View>
        )}
      </View>
    </View>
  );
}

export type LotPdfGeneralInfosProps = {
  lot: LotTypeV2;
  program: ProgramTypeV2;
};
