import React, { useContext } from 'react';
import type { ReactNode } from 'react';
import { Button, Divider, List, ListItem } from '@material-ui/core';

import {
  LABEL_FOLDERS_MY_FOLDERS,
  LABEL_MY_SEARCH_PLURAL,
  LABEL_NEWSLETTER_TOOLTIP,
} from 'settings/labels';
import { NOTIF_TYPE_SAVED_SEARCH } from 'settings/notifications';
import { PANEL_ID_FOLDERS, PANEL_ID_NEWSLETTER, PANEL_ID_SEARCH } from 'settings/panels';

import { TmsContext } from 'modules/App/Contexts/TmsContext';
import { userContext } from 'modules/App/Contexts';

import NotificationDot from 'commonUi/NotificationDot/NotificationDot';
import Link from 'commonUi/Link/Link';
import UnreadNotificationsPastille from 'layout/components/HeaderAuthenticated/Toolbar/UnreadNotificationsPastille';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import stylesHeaderAuthenticated from 'layout/components/HeaderAuthenticated/HeaderAuthenticated.module.scss';
import styles from './MobileMenu.module.scss';

interface MobileMenuProps {
  closePanel: () => void;
  handleToolbarIcon: (toolbarIconId: string) => void;
  pathname: string;
  openPanel: (panel: string) => void;
  toolbarIconButtonMobile: {
    href?: string;
    icon: ReactNode;
    id?: string;
    label: string;
  }[];
  toolbarLinks: {
    href: string;
    label: string;
  }[];
}

export default function MobileMenu({
  closePanel,
  handleToolbarIcon,
  openPanel,
  pathname,
  toolbarIconButtonMobile,
  toolbarLinks,
}: MobileMenuProps) {
  const { setPageVars, setComponentPageVars } = useContext(TmsContext);
  const { unreadNewsletters } = useContext(userContext);

  const handleLinkClick = () => {
    setPageVars({});
    setComponentPageVars({});
    closePanel();
  };

  return (
    <>
      <List classes={{ root: styles.listRoot }}>
        {toolbarLinks.map(toolbarLink => (
          <ListItem
            button
            key={toolbarLink.label}
            classes={{ root: styles.listItemRoot }}
            disableRipple
          >
            <div className={stylesHeaderAuthenticated.linkContainer}>
              <Link
                href={toolbarLink.href}
                label={toolbarLink.label}
                target="_self"
                appbarLink
                isActive={pathname.includes(toolbarLink.href)}
                action={handleLinkClick}
              />
            </div>
          </ListItem>
        ))}
      </List>
      <Divider classes={{ root: styles.dividerRoot }} />
      <List classes={{ root: styles.listRoot }}>
        <ListItem button classes={{ root: styles.listItemRoot }} disableRipple>
          <Button
            classes={{
              root: styles.buttonIconMobile,
              label: styles.buttonIconMobileLabel,
              startIcon: styles.startIcon,
            }}
            variant="text"
            startIcon={<SvgIcon className={styles.iconMySearch} iconId="icon-receipt-bicolor" />}
            disableRipple
            onClick={() => openPanel(PANEL_ID_NEWSLETTER)}
          >
            {LABEL_NEWSLETTER_TOOLTIP}
            <NotificationDot className={styles.notifs} amount={unreadNewsletters} />
          </Button>
        </ListItem>
        <ListItem button classes={{ root: styles.listItemRoot }} disableRipple>
          <Button
            classes={{
              root: styles.buttonIconMobile,
              label: styles.buttonIconMobileLabel,
              startIcon: styles.startIcon,
            }}
            variant="text"
            startIcon={<SvgIcon className={styles.iconMySearch} iconId="icon-my-research" />}
            disableRipple
            onClick={() => openPanel(PANEL_ID_SEARCH)}
          >
            {LABEL_MY_SEARCH_PLURAL}
            <UnreadNotificationsPastille
              className={styles.notifs}
              types={[NOTIF_TYPE_SAVED_SEARCH]}
            />
          </Button>
        </ListItem>
        <ListItem button classes={{ root: styles.listItemRoot }} disableRipple>
          <Button
            classes={{
              root: styles.buttonIconMobile,
              label: styles.buttonIconMobileLabel,
              startIcon: styles.startIcon,
            }}
            variant="text"
            startIcon={<SvgIcon className={styles.iconMyFolder} iconId="icon-my-folders" />}
            disableRipple
            onClick={() => openPanel(PANEL_ID_FOLDERS)}
          >
            {LABEL_FOLDERS_MY_FOLDERS}
          </Button>
        </ListItem>
        {toolbarIconButtonMobile.map(toolbarIconButton => (
          <ListItem
            button
            key={toolbarIconButton.label}
            classes={{ root: styles.listItemRoot }}
            disableRipple
          >
            <Button
              classes={{
                root: styles.buttonIconMobile,
                label: styles.buttonIconMobileLabel,
                startIcon: styles.startIcon,
              }}
              variant="text"
              startIcon={toolbarIconButton.icon}
              disableRipple
              onClick={() => {
                if (toolbarIconButton.id) {
                  handleToolbarIcon(toolbarIconButton.id);
                }
              }}
              href={toolbarIconButton.href}
            >
              {toolbarIconButton.label}
              {toolbarIconButton.label === 'Mes notifications' && (
                <UnreadNotificationsPastille
                  className={styles.notifs}
                  excludeTypes={[NOTIF_TYPE_SAVED_SEARCH]}
                />
              )}
            </Button>
          </ListItem>
        ))}
      </List>
    </>
  );
}
