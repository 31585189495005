import React, { useCallback, useContext, useMemo, useState } from 'react';
import type { ContextType, PropsWithChildren } from 'react';
import type { AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';

import { getTokenFromSession, isTokenExpired } from 'services/authentication';
import { ACTIVITY_STATUS_ACTIVE } from 'settings/activity';
import { NEWSLETTER_STATUS_READ } from 'settings/newsletter';

import useAlerts from 'hooks/useAlerts';
import { useOptions, usePreBookings } from 'hooks/useActivities';

import SettingsContext from 'modules/App/Contexts/SettingsContext';
import { userContext } from 'modules/App/Contexts/userContext';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { useSWROffres } from 'api/offresAPI/useSWROffres';
import { UserTokenType } from 'api/vi3pAPI/apiTypes/UserTokenType';
import { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';
import { Newsletter } from 'api/vi3pAPI/apiTypes/Newsletter';
import { Prescriptor } from 'api/vi3pAPI/apiTypes/Prescriptor';
import { SavedSearchType } from 'api/vi3pAPI/apiTypes/SavedSearch';

const NEWSLETTER_REFRESH_INTERVAL = 60 * 1000; // 1 minute

export default function UserProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const { settings, isValidating: isSettingsValidating } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !isSettingsValidating && !!settings?.cdo?.liste_attribution;
  const [userToken, setUserToken] = useState(getTokenFromSession());
  const userCrm = useMemo<UserTokenType>(() => (userToken ? jwtDecode(userToken) : undefined), [
    userToken,
  ]);
  const savedSearchUrl = `profils/${userCrm?.extension_VI3P_ContactId}/recherches`;

  const { data: exclusionList } = useSWROffres<string[] | undefined>(
    {
      url:
        userCrm?.extension_VI3P_CompteId && isAttributionSwitchedOn
          ? `/listesAttribution/${userCrm.extension_VI3P_CompteId}/exclusion`
          : undefined,
    },
    {
      shouldRetryOnError: false, // No retry on error because a 404 is a valid response
    }
  );
  const processedExclusionList = useMemo(() => {
    if (isAttributionSwitchedOn) {
      return (
        exclusionList?.map(
          str => str.split(';') as ContextType<typeof userContext>['exclusionList'][number]
        ) ?? []
      );
    }
    return [];
  }, [exclusionList, isAttributionSwitchedOn]);

  const { data: prescriptorData } = useSWRVi3p<{ data: Prescriptor }>({
    url:
      !isTokenExpired(userCrm) && userCrm?.extension_VI3P_CompteId
        ? `prescripteurs/${userCrm?.extension_VI3P_CompteId}`
        : undefined,
  });
  const userPrescripteurs = prescriptorData?.data;

  const { data: allOptions, url: optionsUrl } = useOptions('all');
  const { data: allPreBookings, url: preBookingsUrl } = usePreBookings('all');
  const options = allOptions?.filter(
    option => option.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
  );
  const preBookings = allPreBookings?.filter(
    prebooking => prebooking.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
  );

  const activityUrls = useMemo(
    () => ({
      options: optionsUrl,
      preBookings: preBookingsUrl,
      savedSearch: savedSearchUrl,
    }),
    [optionsUrl, preBookingsUrl]
  );

  const {
    alerts,
    createAlert,
    deleteAlert,
    toggleAlert,
    alertsMarketing = {},
    createMarketingAlert,
    deleteMarketingAlerts,
  } = useAlerts(userCrm);
  const { data: savedSearch } = useSWRVi3p<SavedSearchType[]>({
    url: userCrm ? savedSearchUrl : undefined,
  });
  const {
    data: contactPrescriptor,
    isValidating: isContactPrescriptorValidating,
    mutate: mutateContactPrescriptor,
  } = useSWRVi3p<ContactPrescriptorType>({
    url:
      !isTokenExpired(userCrm) && userCrm?.extension_VI3P_ContactId
        ? `contactsPrescripteurs/${userCrm?.extension_VI3P_ContactId}`
        : undefined,
  });

  const newsletterUrl = userCrm?.extension_VI3P_ContactId
    ? `profils/${userCrm.extension_VI3P_ContactId}/newsletters`
    : undefined;
  const { data: newsletters = [], mutate: mutateNewsletters } = useSWRVi3p<Newsletter[]>(
    { url: newsletterUrl },
    {
      refreshInterval: NEWSLETTER_REFRESH_INTERVAL,
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  const unreadNewsletters = useMemo(
    () => newsletters?.filter(({ statut }) => statut !== NEWSLETTER_STATUS_READ).length ?? 0,
    [newsletters]
  );
  const updateNewsletterStatus = useCallback<
    ContextType<typeof userContext>['updateNewsletterStatus']
  >(
    async (id, status) => {
      if (newsletterUrl) {
        try {
          const response = await axiosVI3PInstance.patch<any, AxiosResponse<Newsletter[]>>(
            newsletterUrl,
            { id, statut: status }
          );
          await mutateNewsletters(response.data, { revalidate: false });
        } catch (e) {
          // do nothing
        }
      }
      throw new Error('No Contact Prescriptor CRM ID provided');
    },
    [newsletterUrl, mutateNewsletters]
  );
  const refreshNewsletters = useCallback<ContextType<typeof userContext>['refreshNewsletters']>(
    () => mutateNewsletters(),
    []
  );

  return (
    <userContext.Provider
      value={{
        contactPrescriptor,
        exclusionList: processedExclusionList,
        userCrm,
        userPrescripteurs,
        userToken,
        setUserToken,
        options,
        preBookings,
        activityUrls,
        alerts,
        createAlert,
        deleteAlert,
        toggleAlert,
        alertsMarketing,
        createMarketingAlert,
        deleteMarketingAlerts,
        savedSearch,
        mutateContactPrescriptor,
        isContactPrescriptorLoading: !contactPrescriptor && isContactPrescriptorValidating,
        newsletters,
        unreadNewsletters,
        updateNewsletterStatus,
        refreshNewsletters,
      }}
    >
      {children}
    </userContext.Provider>
  );
}
