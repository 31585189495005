import { useEffect, useState } from 'react';
import useSWR from 'swr';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { Status, StatusLabel } from 'api/viOffresAPI/apiTypes/Statuses';

import { getLotsStatuses } from 'api/viOffresAPI/apiClient';

export default function useGetLotsStatuses(lots: LotJson[] = [], attributionList?: string) {
  const [statuses, setStatuses] = useState<Record<string, Status>>({});

  // Keep only the NIDs of Lots of which we don't already have the updated Status
  const ids = lots.map(({ nid }) => nid).filter(nid => nid && (!statuses || !statuses[nid]));

  const { data: updatedStatuses, mutate: refreshStatuses } = useSWR(
    ids.length ? [`/lotStatut`, ids.join()] : null,
    ([url, ids]) => getLotsStatuses(url, ids, attributionList)
  );

  useEffect(() => {
    if (updatedStatuses && Object.keys(updatedStatuses).length) {
      setStatuses(statuses => ({ ...statuses, ...updatedStatuses }));
    }
  }, [updatedStatuses]);

  return {
    statuses,
    refreshStatuses,
    updateStatus(lotNid: string, newStatus: StatusLabel) {
      setStatuses(prevStatuses => ({
        ...prevStatuses,
        [lotNid]: { id: lotNid, label: newStatus, expirationDate: '' },
      }));
    },
  };
}

// Get statuses without update system
// The state update in useGetLotsStatuses is problematic for PDF generation
export function useGetLotStatusesDirect(lots: LotJson[] = [], attributionList?: string) {
  const ids = lots.map(({ nid }) => nid).filter(x => x);

  const { data: statuses } = useSWR(ids.length ? [`/lotStatut`, ids.join()] : null, ([url, ids]) =>
    getLotsStatuses(url, ids, attributionList)
  );

  return { statuses };
}
