import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { LABEL_DISPLAY_MAP } from 'settings/labels';
import { TMS_ORIGIN_SEARCH } from 'settings/tms';

import type { SelectedLotType } from 'types/lots';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getFilterableValues } from 'services/search';
import { modifyQuery } from 'services/url';

import { useModal } from 'hooks/useModal';
import useSearchSortState from 'hooks/useSearchSortState';
import { useSortedPrograms } from 'hooks/useSortedPrograms';

import useFilterData from 'modules/HomePage/hooks/useFilterData';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import LotDropdownSort from 'commonUi/ListLotV2/LotDropdownSort/LotDropdownSort';

import ListSwitcher from 'modules/HomePage/Components/ListSwitcher/ListSwitcher';
import LotsListDesktop from 'modules/HomePage/Components/LotsList/LotsListDesktop';
import LotsListSelectedActions from 'modules/HomePage/Components/LotsList/LotsListSelectedActions';
import { ProgramsDropdownSort } from 'modules/HomePage/Components/ProgramsDropdownSort/ProgramsDropdownSort';
import ProgramsList from 'modules/HomePage/Components/ProgramsList/ProgramsList';
import FiltersPanel from 'modules/HomePage/Components/Search/FiltersPanel/FiltersPanel';
import SearchBar from 'modules/HomePage/Components/Search/SearchBar/SearchBar';
import SearchMap from 'modules/HomePage/Components/Search/SearchMap';
import SaveSearchButtonWithModal from 'modules/SaveSearch/Components/SaveSearchButton/SaveSearchButtonWithModal';

import styles from './SearchLayout.module.scss';

export default function SearchLayoutDesktop({
  tmsLots = {},
  tmsPrograms = {},
}: SearchLayoutDesktopProps) {
  const history = useHistory();
  const timeout = useRef<Record<string, ReturnType<typeof setTimeout> | undefined>>({});
  const search = useSearch();
  const { type: searchType = 'program' } = search;
  const filterableValues = getFilterableValues(search);
  const {
    filterData,
    lots,
    programs,
    isValidating: isDataValidating,
    isLotsLoading,
  } = useFilterData();
  const modal = useModal();
  const { taxesById } = useContext(TaxonomiesContext);

  const { lots: filteredLots, programs: filteredPrograms, lotNbByPrograms } = useMemo(
    () => filterData(filterableValues),
    [filterData, JSON.stringify(filterableValues)]
  );

  const [filtersDrawerIsOpen, setFiltersDrawerIsOpen] = useState(!!search.searchId);
  const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(
    search.map === 'off' || !!search.searchId
  );
  const {
    programs: { sortBy: programsSortBy, sortOrder: programsSortOrder },
    lots: { sortBy: lotsSortBy, sortOrder: lotsSortOrder },
    reorderPrograms,
    reorderLots,
  } = useSearchSortState(filterableValues.locations);

  const { programs: sortedPrograms, isLoading: isSortLoading } = useSortedPrograms(
    filteredPrograms,
    programsSortBy,
    programsSortOrder
  );

  const switchRef = useRef<HTMLDivElement>(null);

  const [selectedLots, setSelectedLots] = useState<SelectedLotType[]>([]);

  useEffect(() => {
    if (search.searchId) {
      setIsPanelExpanded(true);
      setFiltersDrawerIsOpen(true);
    }
  }, [search.searchId]);

  function handleTogglePanel() {
    if (isPanelExpanded) {
      history.replace(modifyQuery({ map: 'on' }, ['lotNumber']));
      setIsPanelExpanded(false);
    } else {
      history.replace(modifyQuery({ map: 'off' }, ['lotNumber']));
      setIsPanelExpanded(true);
    }
  }

  const listSwitcher = (
    <ListSwitcher
      ref={switchRef}
      className={classnames(styles.listSwitcherContainer, {
        [styles.listSwitcherContainerExp]: isPanelExpanded,
      })}
      type={searchType}
      lotsNumber={filteredLots?.length}
      lotsOnClick={() => setIsPanelExpanded(true)}
      lotsUrlParams={{ map: 'off' }}
      programsNumber={sortedPrograms?.length}
    />
  );

  const saveSearchBtn = (
    <div
      className={classnames(styles.saveSearchBtn, { [styles.saveSearchBtnExp]: isPanelExpanded })}
    >
      <SaveSearchButtonWithModal
        classes={{ root: styles.saveSearch, icon: styles.icon, labelText: styles.text }}
        modal={modal}
        resultsLots={filteredLots}
        resultsPrograms={sortedPrograms}
      />
    </div>
  );

  const sortDropdown =
    searchType === 'lot' ? (
      <LotDropdownSort
        buttonClassName={styles.sortButton}
        className={styles.sortWrapper}
        taxes={search.taxes}
        reorderLots={sortBy =>
          reorderLots(sortBy, () => history.replace(modifyQuery({}, ['programRef'])))
        }
        lotsSortBy={lotsSortBy}
        lotsSortOrder={lotsSortOrder}
      />
    ) : (
      <ProgramsDropdownSort
        buttonClassName={styles.sortButton}
        className={styles.sortWrapper}
        type={searchType}
        reorderPrograms={reorderPrograms}
        sortBy={programsSortBy}
        sortOrder={programsSortOrder}
      />
    );

  return (
    <div className={styles.root}>
      <div className={styles.panelHeader}>
        <div className={styles.panelHeaderTop}>
          <SearchBar
            className={styles.searchBar}
            onFiltersButtonClick={() => setFiltersDrawerIsOpen(true)}
          />

          <FiltersPanel
            isSavedSearchEdition={!!search.searchId}
            modal={modal}
            open={filtersDrawerIsOpen}
            onClose={() => setFiltersDrawerIsOpen(false)}
            resultsLots={filteredLots}
            resultsPrograms={sortedPrograms}
          />
        </div>
        <div className={styles.panelHeaderBottom}>
          {isPanelExpanded ? (
            <>
              {searchType === 'lot' && (
                <LotsListSelectedActions
                  selectedLots={selectedLots}
                  origin={TMS_ORIGIN_SEARCH}
                  {...tmsLots}
                />
              )}
              {searchType !== 'lot' && sortDropdown}
              {listSwitcher}
              {saveSearchBtn}

              {!search.searchId && (
                <button className={styles.showMapBtn} type="button" onClick={handleTogglePanel}>
                  <Icon className={styles.showMapBtnIcon} icon="map-plan" />
                  {LABEL_DISPLAY_MAP}
                </button>
              )}
            </>
          ) : (
            <>
              {searchType === 'lot' && (
                <LotsListSelectedActions
                  selectedLots={selectedLots}
                  origin={TMS_ORIGIN_SEARCH}
                  {...tmsLots}
                />
              )}
              {searchType !== 'lot' && sortDropdown}
              {listSwitcher}
              {saveSearchBtn}
            </>
          )}
        </div>
      </div>
      <div
        className={classnames(styles.panelWrapper, {
          [styles.panelExpanded]: isPanelExpanded,
        })}
      >
        <div className={styles.panel}>
          <div className={styles.panelContent} id="panelContent">
            {searchType === 'lot' ? (
              <ActivityModalsProvider>
                <LotsListDesktop
                  allLots={lots}
                  allPrograms={programs}
                  lots={filteredLots}
                  isLoading={isLotsLoading}
                  isPanelExpanded={isPanelExpanded}
                  handleTogglePanel={handleTogglePanel}
                  programs={sortedPrograms}
                  reorder={heading => {
                    if (heading.sort) {
                      reorderLots(heading.sort, () =>
                        history.replace(modifyQuery({}, ['programRef']))
                      );
                    }
                  }}
                  selectedLots={selectedLots}
                  setSelectedLots={setSelectedLots}
                  sortBy={lotsSortBy}
                  sortOrder={lotsSortOrder}
                  {...tmsLots}
                />
              </ActivityModalsProvider>
            ) : (
              <ProgramsList
                availableLots={lotNbByPrograms}
                isPanelExpanded={isPanelExpanded}
                onProgramMouseEnter={programRef => {
                  timeout.current[programRef] = setTimeout(
                    () => history.replace(modifyQuery({ programRef, origin: 'list' })),
                    600
                  );
                }}
                onProgramMouseLeave={programRef => {
                  const intervalId = timeout.current[programRef];
                  if (intervalId) {
                    clearInterval(intervalId);
                    timeout.current[programRef] = undefined;
                  }
                }}
                programs={sortedPrograms}
                reduceMobilePanel={() => setIsPanelExpanded(false)}
                setIsPanelExpanded={setIsPanelExpanded}
                isLoading={isDataValidating || isSortLoading}
                {...tmsPrograms}
              />
            )}
          </div>
        </div>
        <div className={styles.map}>
          <button className={styles.closeMapBtn} type="button" onClick={handleTogglePanel}>
            <Icon icon="cross" />
          </button>
          <SearchMap programs={sortedPrograms} taxById={taxesById} />
        </div>
      </div>
    </div>
  );
}

export type SearchLayoutDesktopProps = {
  tmsLots?: Record<string, string>;
  tmsPrograms?: Record<string, string>;
};
