import React, { useContext, useState } from 'react';
import { Collapse as MuiCollapse } from '@material-ui/core';

import { LABEL_KNOW_MORE, LABEL_READ_LESS } from 'settings/labels';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import FieldRte from 'commonUi/Fields/FieldRte/FieldRte';

import styles from './ProgramMentions.module.scss';

export interface FootNotesProps {
  items: string[];
}

export default function ProgramMentions({ items }: FootNotesProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const [isOpen, setIsOpen] = useState({});

  if (!items || items.length === 0) {
    return null;
  }

  const baseTextLineHeight = 1.4;
  const nbClosedVisibleLines = isResponsive ? 2 : 1;
  const collapsedHeight = `${nbClosedVisibleLines * baseTextLineHeight}em`;

  const footNotes = (
    <ul className={styles.root}>
      {items.map((text, key) => {
        if (!text) {
          return null;
        }

        return (
          <li className={styles.item} key={text}>
            <MuiCollapse className={styles.text} in={isOpen[key]} collapsedHeight={collapsedHeight}>
              <FieldRte className={styles.rte} html={text} />
            </MuiCollapse>
            <button
              className={styles.button}
              type="button"
              onClick={() =>
                setIsOpen(prevState => ({
                  ...prevState,
                  [key]: !prevState[key],
                }))
              }
            >
              {isOpen[key] ? LABEL_READ_LESS : LABEL_KNOW_MORE}
            </button>
          </li>
        );
      })}
    </ul>
  );

  return <div>{footNotes}</div>;
}
