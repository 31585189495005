import React, { useContext, useMemo, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';

import { regexEmail } from 'services/constraints';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_CARD_SEND,
  LABEL_EMAIL_FORM_ERROR_TITLE,
  LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR,
  LABEL_FIRSTNAME,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_LASTNAME,
  LABEL_PDF_EMAIL_RECEIVER,
  LABEL_PDF_FIRSTNAME_HELPERTEXT_ERROR,
  LABEL_PDF_LASTNAME_HELPERTEXT_ERROR,
  LABEL_SEND_CARD,
} from 'settings/labels';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import {
  ProgramListType,
  ProgramTypeV2,
  isProgramListType,
  isProgramTypeV2,
} from 'api/viOffresAPI/apiTypes/Program';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import { userContext } from 'modules/App/Contexts';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import TextField from 'commonUi/TextField/TextField';

import styles from '../ModalAction.module.scss';

const FIELD_ID_FIRSTNAME = 'field-firstname';
const FIELD_ID_LASTNAME = 'field-lastname';
const FIELD_ID_MAIL = 'field-mail';

export interface FormSendPdfProps {
  handleClose: () => void;
  blobPdf: Blob | null | undefined;
  loading: boolean;
  lot: LotTypeV2 | undefined;
  // FormSendPdfProgram can be called from the Search context or from other places so it needs to be able to handle both types of Programs
  program: ProgramTypeV2 | ProgramListType;
  setLoading: (loading: boolean) => void;
}

export default function FormSendPdf({
  blobPdf,
  handleClose,
  loading,
  lot,
  program,
  setLoading,
}: FormSendPdfProps) {
  const [apiError, setApiError] = useState(false);
  const { userCrm } = useContext(userContext);
  const { enqueueSnackbar } = useSnackbar();

  const { programName, programRef } = useMemo(() => {
    if (!program) {
      return {};
    }
    if (isProgramTypeV2(program)) {
      return {
        programName: program.nomCommercial,
        programRef: program.referenceProgramme,
      };
    }
    if (isProgramListType(program)) {
      return {
        programName: program.name,
        programRef: program.ref,
      };
    }
    const exhaustivenessCheck: never = program;
    return exhaustivenessCheck;
  }, [program]);

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(sendPdfSchema),
    mode: 'onChange',
    defaultValues: {
      [FIELD_ID_MAIL]: null,
      [FIELD_ID_LASTNAME]: null,
      [FIELD_ID_FIRSTNAME]: null,
    },
  });
  const { isDirty, isValid, errors } = formState;

  return (
    <form
      onSubmit={handleSubmit(async values => {
        setLoading(true);

        let url = `creerDocument?programme=${programRef}&contact=${userCrm?.extension_VI3P_CompteId}`;
        let filename = `fiche-programme-${programRef}.pdf`;

        if (lot) {
          url = `creerDocument?lot=${lot.reference}&programme=${programRef}&contact=${userCrm?.extension_VI3P_CompteId}`;
          filename = `fiche-lot-${lot.reference}.pdf`;
        }
        try {
          const createDocument = await axiosVI3PInstance.post(url, blobPdf, {
            headers: {
              'Content-Type': 'application/octet-stream',
              'Content-Disposition': `file; filename="${filename}"`,
            },
          });

          if (createDocument.status === 201) {
            let datas = {
              NOM_PROGRAMME: programName,
              REF_OPERATION: programRef,
              NOM_CGP: userCrm?.family_name,
              PRENOM_CGP: userCrm?.given_name,
              EMAIL_CGP: userCrm?.email,
              NOM_CLIENT: values?.[FIELD_ID_LASTNAME],
              PRENOM_CLIENT: values?.[FIELD_ID_FIRSTNAME],
              EMAIL_CLIENT: values?.[FIELD_ID_MAIL],
            };
            if (lot) {
              datas = {
                ...datas,
                ...{ LIBELLE_LOT: lot.reference, URL_FICHE_LOT: createDocument.data.path },
              };
            } else {
              datas = { ...datas, ...{ URL_FICHE_PROGRAMME: createDocument.data.path } };
            }
            const sendDocument = await axiosVI3PInstance.post('envoiDocument', datas);

            if (sendDocument.status === 200) {
              enqueueSnackbar(LABEL_CONFIRM_CARD_SEND);
              handleClose();
            }
          }
        } catch (newError) {
          if (newError?.response?.status) {
            setApiError(true);
          }
        }
        setLoading(false);
      })}
    >
      <div className={classNames(styles.form, styles.sendPdf)}>
        <div className={styles.input}>
          <Controller
            name={FIELD_ID_MAIL}
            control={control}
            render={props => (
              <TextField
                error={!!errors?.[FIELD_ID_MAIL]}
                helperText={errors?.[FIELD_ID_MAIL] && LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR}
                id={FIELD_ID_MAIL}
                label={LABEL_PDF_EMAIL_RECEIVER}
                name={FIELD_ID_MAIL}
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name={FIELD_ID_LASTNAME}
            control={control}
            render={props => (
              <TextField
                error={!!errors?.[FIELD_ID_LASTNAME]}
                helperText={errors?.[FIELD_ID_LASTNAME] && LABEL_PDF_LASTNAME_HELPERTEXT_ERROR}
                id={FIELD_ID_LASTNAME}
                label={LABEL_LASTNAME}
                name={FIELD_ID_LASTNAME}
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name={FIELD_ID_FIRSTNAME}
            control={control}
            render={props => (
              <TextField
                error={!!errors?.[FIELD_ID_FIRSTNAME]}
                helperText={errors?.[FIELD_ID_FIRSTNAME] && LABEL_PDF_FIRSTNAME_HELPERTEXT_ERROR}
                id={FIELD_ID_FIRSTNAME}
                label={LABEL_FIRSTNAME}
                name={FIELD_ID_FIRSTNAME}
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        {!apiError && (
          <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
        )}
      </div>
      <div className={classNames(styles.modalFooter, { [styles.errorFooter]: apiError })}>
        {apiError && (
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => setApiError(false)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{LABEL_EMAIL_FORM_ERROR_TITLE}</h4>
              <p>{LABEL_FORM_ERROR_CONTENT}</p>
            </div>
          </div>
        )}
        {!apiError && (
          <>
            <div className={styles.cancelButton}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            </div>
            <div className={styles.createButton}>
              <Button
                disabled={!isDirty || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                {LABEL_SEND_CARD}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const sendPdfSchema = yup.object().shape({
  [FIELD_ID_MAIL]: yup.string().required().matches(RegExp(regexEmail)),
  [FIELD_ID_LASTNAME]: yup
    .string()
    .trim()
    .test('lastname-length', LABEL_PDF_LASTNAME_HELPERTEXT_ERROR, str => (str?.length ?? 0) >= 3),
  [FIELD_ID_FIRSTNAME]: yup
    .string()
    .trim()
    .test('firstname-length', LABEL_PDF_FIRSTNAME_HELPERTEXT_ERROR, str => (str?.length ?? 0) >= 3),
});
