import React, { useState } from 'react';

import { useModal } from 'hooks/useModal';
import { replaceTokens } from 'services';
import { downloadDocs } from 'services/download';
import {
  DOC_TYPE_COMMERCE,
  DOC_TYPE_COMPLEMENTAIRE,
  DOC_TYPE_RESERVATION,
} from 'settings/documents';
import {
  LABEL_DOCUMENTS_DOWNLOAD,
  LABEL_DOCUMENTS_SELECT_ALL,
  LABEL_DOCUMENTS_SHARE,
  LABEL_DOCUMENTS_TYPE_TITLE_COMMERCE,
  LABEL_DOCUMENTS_TYPE_TITLE_COMPLEMENTAIRE,
  LABEL_DOCUMENTS_TYPE_TITLE_RESERVATION,
  LABEL_DOCUMENTS_UNSELECT_ALL,
  LABEL_SEND_FOLDER_PROGRAM,
} from 'settings/labels';
import { TOKEN_PROGRAM_NAME } from 'settings/token';

import { DocumentCategory, DocumentTypeV2 } from 'api/viOffresAPI/apiTypes/Documents';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { ModalSendDocuments } from 'modules/ActionsProgramsAndLots/ModalSendDocuments/ModalSendDocuments';

import CircularProgressWithLabel from 'commonUi/CircularProgressWithLabel/CircularProgressWithLabel';
import DocumentLine from './DocumentLine';

import styles from './DocumentsList.module.scss';

const DOC_TYPE_MAPPING = {
  [DOC_TYPE_RESERVATION]: {
    icon: 'icon-reservation-contract',
    title: LABEL_DOCUMENTS_TYPE_TITLE_RESERVATION,
  },
  [DOC_TYPE_COMPLEMENTAIRE]: {
    icon: 'icon-plans',
    title: LABEL_DOCUMENTS_TYPE_TITLE_COMPLEMENTAIRE,
  },
  [DOC_TYPE_COMMERCE]: {
    icon: 'icon-booklet',
    title: LABEL_DOCUMENTS_TYPE_TITLE_COMMERCE,
  },
};

interface DocumentsListProps {
  className?: string;
  columns: DocumentCategory[];
  program: ProgramTypeV2;
}

export default function DocumentsList({ className, columns, program }: DocumentsListProps) {
  const { documents, nomCommercial } = program;
  const [selectedDocuments, setSelectedDocuments] = useState<number[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false);
  const [downloadProgression, setDownloadProgression] = useState<number>(0);

  const { open, openModal, closeModal } = useModal();

  function toggleDocument(docId: number) {
    setSelectedDocuments(prevState => {
      const docIndex = prevState.indexOf(docId);
      if (docIndex !== -1) {
        return [...prevState.slice(0, docIndex), ...prevState.slice(docIndex + 1)];
      }
      return [...prevState, docId];
    });
  }

  function toggleAll() {
    setSelectedDocuments(prev => {
      if (prev.length === documents.length) {
        return [];
      }
      return documents.map(({ id }) => id);
    });
  }

  async function downloadSelected() {
    setDownloadInProgress(true);
    const documentsDatas = selectedDocuments.reduce<DocumentTypeV2[]>((array, id) => {
      const document = documents.find(doc => doc.id === id);
      if (document) {
        return [...array, document];
      }
      return array;
    }, []);
    await downloadDocs(documentsDatas, nomCommercial, setDownloadProgression);
    setDownloadInProgress(false);
  }

  return (
    <div className={className}>
      <div className={styles.heading}>
        <button type="button" className={styles.selectAll} onClick={toggleAll}>
          {selectedDocuments.length === documents.length
            ? LABEL_DOCUMENTS_UNSELECT_ALL
            : LABEL_DOCUMENTS_SELECT_ALL}
        </button>
        <div className={styles.actions}>
          {selectedDocuments.length > 0 && (
            <>
              <Button
                className={styles.action}
                color="primary"
                iconClass={styles.actionIcon}
                iconId={!downloadInProgress && 'icon-download-bicolor'}
                labelWhileLoading={false}
                loading={false}
                onClick={downloadSelected}
                variant="outlined"
              >
                {!downloadInProgress && LABEL_DOCUMENTS_DOWNLOAD}
                {downloadInProgress && (
                  <CircularProgressWithLabel
                    value={downloadProgression}
                    size={24}
                    labelClassName={styles.labelCircularProgress}
                  />
                )}
              </Button>
              <Button
                className={styles.action}
                color="primary"
                iconClass={styles.actionIcon}
                iconId="icon-send"
                onClick={openModal}
                variant="outlined"
              >
                {LABEL_DOCUMENTS_SHARE}
              </Button>
            </>
          )}
        </div>
      </div>

      <div className={styles.list}>
        {columns.map(colTitle => (
          <div key={colTitle} className={styles.col}>
            <div className={styles.colHeader}>
              <span className={styles.colIcon}>
                <SvgIcon iconId={DOC_TYPE_MAPPING[colTitle]?.icon} />
              </span>
              {DOC_TYPE_MAPPING[colTitle]?.title}
            </div>

            <div className={styles.colBody}>
              {documents
                .filter(doc => doc.categorie === colTitle)
                .map(doc => (
                  <DocumentLine
                    key={doc.id}
                    className={styles.document}
                    document={doc}
                    onToggle={toggleDocument}
                    programName={program.nomCommercial}
                    selected={selectedDocuments.includes(doc.id)}
                  />
                ))}
            </div>
          </div>
        ))}
      </div>

      <ModalSendDocuments
        closeCallBack={closeModal}
        documents={documents}
        open={open}
        title={replaceTokens(LABEL_SEND_FOLDER_PROGRAM, {
          [TOKEN_PROGRAM_NAME]: nomCommercial,
        })}
      />
    </div>
  );
}
