import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import useSWR from 'swr';
import type { SWRConfiguration } from 'swr';

import { axiosVI3PInstance } from './axiosInstance';

export type apiData<T> = { data: T };
export type SWRVi3pDataType<T> = AxiosResponse<T> | T | apiData<T>;
export type SWRVi3pErrorType = AxiosError;

export const useSWRVi3p = <T extends unknown>(
  request: Omit<AxiosRequestConfig, 'baseURL' | 'method' | 'data'>,
  config?: Partial<SWRConfiguration<T>>
) => {
  const requestModified: AxiosRequestConfig = {
    ...request,
    method: `GET`,
    params: {
      site: 'vi3p',
    },
  };

  const { data: axiosData, ...responseUseSWR } = useSWR<T, SWRVi3pErrorType>(
    requestModified.url,
    async () => axiosVI3PInstance(requestModified).then((response: apiData<T>) => response.data),
    {
      revalidateOnFocus: false,
      ...config,
    }
  );

  return { ...responseUseSWR, data: axiosData };
};
