import React, { useContext } from 'react';
import { useSnackbar } from 'notistack';

import { LABEL_DOWNLOAD, LABEL_FAIL_DOWNLOAD_FILE } from 'settings/labels';
import { FILE_SIZE_UNITS } from 'settings/app';

import { formatActivityDate } from 'services/date';
import { openBlob } from 'services/files';
import { unitsFormatter } from 'services/formatter';

import { ActivityDocumentType } from 'api/viOffresAPI/apiTypes/Documents';

import { ResponsiveContext } from 'modules/App/Contexts';

import useDownloadActivityFile from 'hooks/useDownloadActivityFile';

import Button from 'commonUi/Button/Button';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import CircularProgressWithLabel from 'commonUi/CircularProgressWithLabel/CircularProgressWithLabel';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ActivityDownloadDocumentItem.module.scss';

export default function ActivityDownloadDocumentItem({
  activityId,
  document,
}: ActivityDownloadDocumentItemProps) {
  const { isDownloading, downloadingProgress, handleFileDownload } = useDownloadActivityFile();
  const { isResponsive } = useContext(ResponsiveContext);
  const { enqueueSnackbar } = useSnackbar();

  function handleDownloadFile(ev: React.MouseEvent) {
    ev.stopPropagation();
    if (!isDownloading) {
      openBlob(
        () =>
          handleFileDownload(`activiteCommerciale/${activityId}/documents/${document.idCrm}`)
            .then(response => new Blob([response.data], { type: response.headers['content-type'] }))
            .catch(() => {
              enqueueSnackbar(LABEL_FAIL_DOWNLOAD_FILE, {
                autoHideDuration: null,
                content: (key, message) => (
                  <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
                ),
              });
              throw new Error();
            }),
        document.nom,
        isResponsive
      );
    }
  }

  return (
    <div className={styles.root}>
      <div className={styles.docInfos}>
        <div className={styles.nature}>{document.nature}</div>
        <div className={styles.infos}>
          {formatActivityDate(document.dateModification, 'DD/MM/YYYY HH:mm')}
          {/* activity document size is stored in Ko so we need to correct it here */}
          {document.poids && ` - ${unitsFormatter(document.poids * 1000, FILE_SIZE_UNITS, 1)}`}
        </div>
      </div>
      <div>
        <Button
          className={styles.button}
          color="primary"
          disabled={isDownloading}
          onClick={handleDownloadFile}
          tooltip={LABEL_DOWNLOAD}
          variant="outlined"
        >
          {isDownloading ? (
            <CircularProgressWithLabel value={downloadingProgress} />
          ) : (
            <SvgIcon className={styles.buttonSvgIcon} iconId="icon-download-bicolor" />
          )}
        </Button>
      </div>
    </div>
  );
}

type ActivityDownloadDocumentItemProps = {
  activityId: string;
  document: ActivityDocumentType;
};
