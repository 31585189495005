import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import { LABEL_HOUSING_ENVIRONMENT, LABEL_PROGRAM_INFO_ADDRESS } from 'settings/labels';

import { ResponsiveContext } from 'modules/App/Contexts';
import { kelQuartierModuleContext } from 'sharedModulesV4/KelQuartier/contexts/kelQuartierModuleContext';
import { KELQUARTIER_PICTOS } from 'sharedModulesV4/KelQuartier/settings/kelquartier';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import GMap from 'modules/HomePage/Components/Search/Map/Map/GMap';
import Marker from 'modules/HomePage/Components/Search/Map/Marker/Marker';
import KelQuartier from 'sharedModulesV4/KelQuartier/components/KelQuartier/KelQuartier';
import ProgramItinerary from 'sharedModulesV4/ProgramItinerary/ProgramItinerary';

import styles from './ProgramLocation.module.scss';

export default function ProgramLocation({ className = undefined, program }: ProgramLocationType) {
  const { isAppleDevice, isResponsive } = useContext(ResponsiveContext);
  const { kelQuartierPOIsData, getPicto } = useContext(kelQuartierModuleContext);
  const [activeInfoBox, setActiveInfoBox] = useState<string | number>();

  if (!program) {
    return null;
  }

  const {
    id,
    localisation: {
      adresse: address,
      codePostal: postalCode,
      latitude: lat,
      longitude: lng,
      ville: city,
    },
    nomCommercial: programName,
  } = program;

  return (
    <div className={classnames(className, styles.root)}>
      <h2 className={styles.title}>{LABEL_HOUSING_ENVIRONMENT}</h2>
      <address className={styles.titleAddress} data-prefix={LABEL_PROGRAM_INFO_ADDRESS}>
        {address}
      </address>

      <div className={styles.map}>
        <div style={{ height: 650, width: '100%' }}>
          <GMap center={{ lat, lng }} zoom={15} withTransitLayer>
            <Marker lat={lat} lng={lng} nid={id} />
            {kelQuartierPOIsData.data &&
              kelQuartierPOIsData.data.map(({ id, lat, lon: lng, nom, id_theme_2: theme }) => (
                <Marker
                  hideLabel
                  lat={lat}
                  lng={lng}
                  nid={id}
                  customIcon={getPicto(KELQUARTIER_PICTOS[theme])}
                  customInfobox={nom}
                  onToggleInfoBox={nid => {
                    if (nid === activeInfoBox || !nid) {
                      setActiveInfoBox(undefined);
                    } else {
                      setActiveInfoBox(nid);
                    }
                  }}
                  isOpen={
                    (typeof activeInfoBox === 'string'
                      ? parseInt(activeInfoBox, 10)
                      : activeInfoBox) === (typeof id === 'string' ? parseInt(id, 10) : id)
                  }
                />
              ))}
          </GMap>
        </div>
        <ProgramItinerary
          classes={{
            address: styles.itineraryAddress,
            destination: styles.destination,
            root: styles.itinerary,
            start: styles.start,
          }}
          isDesktop={!isResponsive}
          isMobileApple={isAppleDevice}
          address={address}
          city={city}
          lng={lng}
          lat={lat}
          postalCode={postalCode}
          title={programName}
        />
        <KelQuartier defaultOpenPanel residenceLat={lat} residenceLng={lng} />
      </div>
    </div>
  );
}

export type ProgramLocationType = {
  className?: string;
  program: ProgramTypeV2;
};
