import React, { useContext } from 'react';
import type { ComponentProps } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import classnames from 'classnames';

import { showQuarter } from 'services/date';
import { formatPrice } from 'services/formatter';
import { htmlEntityDecode } from 'services/stringHtml';
import { LABEL_CONTROLLED_PRICE, LABEL_SHOW_PLAN } from 'settings/labels';
import {
  LOT_CELL_TYPE_ACTION as ACTION,
  LOT_CELL_TYPE_CHECKBOX as CHECKBOX,
  LOT_CELL_TYPE_DATE as DATE,
  LOT_CELL_TYPE_KIND_WITH_PLAN as KIND_WITH_PLAN,
  LOT_CELL_TYPE_LOT as LOT,
  LOT_CELL_TYPE_PERCENTAGE as PERCENTAGE,
  LOT_CELL_TYPE_PLAN as PLAN,
  LOT_CELL_TYPE_PRICE as PRICE,
  LOT_CELL_TYPE_PROGRAM as PROGRAM,
  LOT_CELL_TYPE_PROMO as PROMO,
  LOT_CELL_TYPE_RENT as RENT,
  LOT_CELL_TYPE_STATUS as STATUS,
  LOT_CELL_TYPE_SURFACE as SURFACE,
  LOT_CELL_TYPE_SURFACE_ANNEX as SURFACE_ANNEX,
  LOT_CELL_TYPE_TAX as TAX,
} from 'settings/lots';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { lotIsControlledPrice } from 'services';

import { useSearch } from 'modules/HomePage/hooks/useSearch';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { Checkbox } from 'commonUi/Checkbox/Checkbox';
import LotOpen from 'commonUi/ListLotV2/LotOpen/LotOpen';
import PromoPastille from 'commonUi/PromoPastille/PromoPastille';
import { StatusCell } from './StatusCell';

import styles from './LotCell.module.scss';

function getColValue(lot: LotJson, field: string | string[] | undefined) {
  if (!field) {
    return undefined;
  }
  return Array.isArray(field) ? lot[field[0]] : lot[field];
}
export function getColContent(lot: LotJson, type: string, field: HeadingType['field']) {
  switch (type) {
    case DATE:
      return showQuarter(getColValue(lot, field));

    case LOT:
      return getColValue(lot, field) ?? '-';

    case PERCENTAGE: {
      const value = getColValue(lot, field);
      return value > 0 ? `${value}%` : '-';
    }

    case PRICE: {
      const value = getColValue(lot, field);
      return (
        <>
          <span>{value > 0 ? formatPrice(value) : '-'}</span>
          {lotIsControlledPrice(lot) && value > 0 && <span>{LABEL_CONTROLLED_PRICE}</span>}
        </>
      );
    }

    case RENT: {
      const value = getColValue(lot, field);
      return value > 0 ? formatPrice(value) : '-';
    }

    default: {
      return getColValue(lot, field);
    }
  }
}

interface LotCellProps {
  alerts?: ComponentProps<typeof StatusCell>['alerts'];
  className?: string;
  col: HeadingType | undefined;
  handleDisplayLot?: () => void;
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  isChecked?: boolean;
  isDetailsLoading?: boolean;
  isLotOpen?: boolean;
  lot: LotJson;
  myBookings?: ComponentProps<typeof StatusCell>['myBookings'];
  myOptions?: ComponentProps<typeof StatusCell>['myOptions'];
  onStatusAlertClick: ComponentProps<typeof StatusCell>['onAlertClick'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  statuses?: ComponentProps<typeof StatusCell>['statuses'];
  taxById: TaxesById;
}

export default function LotCell({
  alerts,
  className,
  col,
  handleDisplayLot = () => {},
  handleOnChangeCheckbox,
  isChecked = false,
  isDetailsLoading = true,
  isLotOpen,
  lot,
  myBookings,
  myOptions,
  onStatusAlertClick,
  pageTemplate,
  pageTemplateRef,
  statuses = {},
  taxById,
}: LotCellProps) {
  const hasControlledPrice = lot.others.includes('prix_maitrises');
  const { lotNumber: lotNumberUrl, programRef: programRefUrl } = useSearch();
  const { isResponsive } = useContext(ResponsiveContext);

  const displayDetail = (lotNumberUrl === lot.number && programRefUrl === lot.ref) || isLotOpen;

  if (!col || !taxById) return null;

  // checkbox havent content
  if (col.type === CHECKBOX || col.field === CHECKBOX) {
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className={className} onClick={ev => ev.stopPropagation()}>
        <Checkbox
          checked={isChecked}
          color="primary"
          handleChange={ev => {
            ev.preventDefault();
            ev.stopPropagation();

            if (typeof handleOnChangeCheckbox === 'function') {
              handleOnChangeCheckbox(lot.ref, lot.number);
            }
          }}
        />
      </div>
    );
  }

  if (Array.isArray(col.type)) {
    return (
      <div className={className}>
        {col.type.map((type, index) => (
          <div
            key={type}
            className={classnames({
              [styles.price]: [PRICE, RENT].includes(type),
            })}
          >
            {getColContent(lot, type, Array.isArray(col.field) ? col.field[index] : col.field)}
          </div>
        ))}
      </div>
    );
  }

  switch (col.type) {
    case DATE: {
      if (!col.field) {
        return <div className={className} />;
      }
      return (
        <div className={classnames(className, styles.date)}>
          {getColContent(lot, col.type, col.field)}
        </div>
      );
    }

    case LOT: {
      return (
        <button
          className={classnames(className, styles.lot)}
          onClick={ev => {
            ev.stopPropagation();
            handleDisplayLot();
          }}
          type="button"
        >
          {getColContent(lot, col.type, col.field)}
        </button>
      );
    }

    case PERCENTAGE:
      return (
        <div className={classnames(className, styles.percent)}>
          {getColContent(lot, col.type, col.field)}
        </div>
      );

    case PLAN:
      return (
        <div className={classnames(className, styles.planContainer)}>
          {lot.plan ? (
            <Tooltip
              arrow
              classes={{
                arrow: styles.arrowTooltip,
                tooltip: styles.tooltip,
              }}
              title={LABEL_SHOW_PLAN}
              placement="top"
            >
              <a
                className={styles.btnPlan}
                href={lot.plan}
                target="_blank"
                rel="noreferrer"
                onClick={ev => ev.stopPropagation()}
              >
                <Icon className={styles.btnPlanIcon} icon="plans" />
              </a>
            </Tooltip>
          ) : (
            '-'
          )}
        </div>
      );

    case PRICE: {
      const value = getColValue(lot, col.field);
      return (
        <div
          className={classnames(className, styles.price, {
            [styles.controlledPrice]: hasControlledPrice && value > 0,
          })}
        >
          <span>{value > 0 ? formatPrice(value) : '-'}</span>
          {hasControlledPrice && value > 0 && <span>{LABEL_CONTROLLED_PRICE}</span>}
        </div>
      );
    }

    case RENT: {
      const value = getColValue(lot, col.field);
      return (
        <div className={classnames(className, styles.price)}>
          <span>{value > 0 ? formatPrice(value) : '-'}</span>
        </div>
      );
    }

    case PROGRAM:
      return (
        <Link
          className={classnames(className, styles.programContainer)}
          to={`/programme/page/${lot.ref}/prix`}
          target="_blank"
          onClick={ev => ev.stopPropagation()}
        >
          <span className={styles.programName}>{htmlEntityDecode(lot.program.name)}</span>
          <span className={styles.programLocation}>
            {`${htmlEntityDecode(lot.program.city)} (${htmlEntityDecode(lot.program.postalCode)})`}
          </span>
        </Link>
      );

    case STATUS: {
      return (
        <StatusCell
          className={className}
          myBookings={myBookings}
          myOptions={myOptions}
          alerts={alerts}
          content={getColValue(lot, col.field) ?? '-'}
          lot={lot}
          statuses={statuses}
          onAlertClick={onStatusAlertClick}
          pageTemplate={pageTemplate}
          pageTemplateRef={pageTemplateRef}
        />
      );
    }

    case SURFACE:
    case SURFACE_ANNEX: {
      const value = getColValue(lot, col.field);
      if (value <= 0) return null;
      return <div className={classnames(className, styles.surface)}>{value}</div>;
    }

    case TAX:
      return (
        <div className={classnames(className, styles.tax)}>
          <PastilleFiscality tax={taxById[getColValue(lot, col.field)]} />
        </div>
      );

    case ACTION:
      return (
        <LotOpen loading={isDetailsLoading} isOpen={displayDetail} onClick={handleDisplayLot} />
      );

    case PROMO:
      if (isResponsive) {
        return null;
      }
      // Return a space to avoid '-' displayed for empty cells
      if (!lot.promotions?.length || isLotOpen) {
        return <> </>;
      }
      return <PromoPastille vertical promotions={lot.promotions} />;

    case KIND_WITH_PLAN:
      return (
        <div className={classnames(className, `result-item__${col.id}`)}>
          {getColValue(lot, col.field) ?? '-'}

          {lot.plan && (
            <Tooltip
              arrow
              classes={{
                arrow: styles.arrowTooltip,
                tooltip: styles.tooltip,
              }}
              title={LABEL_SHOW_PLAN}
              placement="top"
            >
              <a
                className={classnames(styles.btnPlan, styles.planTypo)}
                href={lot.plan}
                target="_blank"
                rel="noreferrer"
                onClick={ev => ev.stopPropagation()}
              >
                <Icon className={styles.btnPlanIcon} icon="plans" />
              </a>
            </Tooltip>
          )}
        </div>
      );

    default:
      return (
        <div className={classnames(className, `result-item__${col.id}`)}>
          {getColValue(lot, col.field) ?? '-'}
        </div>
      );
  }
}
