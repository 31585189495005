import React, { useContext } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';

import { NOTIF_TYPE_SAVED_SEARCH } from 'settings/notifications';
import { PANEL_ID_NEWSLETTER, PANEL_ID_SEARCH } from 'settings/panels';

import { userContext } from 'modules/App/Contexts';

import NotificationDot from 'commonUi/NotificationDot/NotificationDot';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import UnreadNotificationsPastille from './UnreadNotificationsPastille';

import styles from '../HeaderAuthenticated.module.scss';

interface MobileToolbarProps {
  handleToolbarIcon?: (icon: string) => void;
  handleOpenDrawer: () => void;
  handleOpenInterlocutorsDrawer: () => void;
  isDrawerOpen: boolean;
  openPanel: (panel: string) => void;
}

export default function MobileToolbar({
  handleOpenDrawer,
  handleOpenInterlocutorsDrawer,
  handleToolbarIcon = () => {},
  isDrawerOpen,
  openPanel,
}: MobileToolbarProps) {
  const { unreadNewsletters } = useContext(userContext);
  return (
    <div className={styles.actions}>
      <IconButton
        classes={{
          label: styles.interlocutor,
          root: classNames(styles.iconButtonRoot, styles.actionBtnWrapper),
        }}
        onClick={handleOpenInterlocutorsDrawer}
      >
        <Icon icon="interlocutor" />
      </IconButton>
      <IconButton
        classes={{
          label: styles.iconButtonLabel,
          root: classNames(
            styles.iconButtonRoot,
            styles.actionBtnWrapper,
            styles.actionBtnNewsletters
          ),
        }}
        onClick={() => openPanel(PANEL_ID_NEWSLETTER)}
      >
        <SvgIcon iconId="icon-receipt-bicolor" />
        <NotificationDot className={styles.actionBtnNotifs} amount={unreadNewsletters} />
      </IconButton>
      <IconButton
        classes={{
          label: styles.iconButtonLabel,
          root: classNames(styles.iconButtonRoot, styles.actionBtnWrapper, styles.actionBtnSearch),
        }}
        onClick={() => openPanel(PANEL_ID_SEARCH)}
      >
        <SvgIcon iconId="icon-my-research" />
        <UnreadNotificationsPastille
          className={styles.actionBtnNotifs}
          types={[NOTIF_TYPE_SAVED_SEARCH]}
        />
      </IconButton>
      <IconButton
        classes={{
          label: styles.iconButtonLabel,
          root: classNames(styles.iconButtonRoot, styles.actionBtnWrapper),
        }}
        disableRipple
        disableFocusRipple
        onClick={() => handleToolbarIcon('myNotifications')}
      >
        <SvgIcon iconId="icon-notifications" />
        <UnreadNotificationsPastille
          className={styles.actionBtnNotifs}
          excludeTypes={[NOTIF_TYPE_SAVED_SEARCH]}
        />
      </IconButton>
      {!isDrawerOpen && (
        <IconButton
          classes={{
            label: styles.iconButtonLabel,
            root: styles.iconButtonRoot,
          }}
          disableRipple
          disableFocusRipple
          onClick={handleOpenDrawer}
        >
          <SvgIcon iconId="icon-burger" />
        </IconButton>
      )}
      {isDrawerOpen && (
        <IconButton
          classes={{
            label: styles.iconButtonLabel,
            root: styles.iconButtonRoot,
          }}
          disableRipple
          disableFocusRipple
          onClick={handleOpenDrawer}
        >
          <SvgIcon iconId="icon-cross" style={{ width: '18px', height: '18px' }} />
        </IconButton>
      )}
    </div>
  );
}
