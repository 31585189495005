import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import classnames from 'classnames';

import { CircularProgress } from '@material-ui/core';
import PastilleFiscality from 'commonUi/Pastille/PastilleFiscality';
import { taxMapping } from 'services/taxes';
import { LABEL_LOT_NUMBER, LABEL_NEW_RESIDENCE } from 'settings/labels';

import {
  lotHasKitchen,
  lotIsAdb,
  lotIsControlledPrice,
  lotIsFNO,
  lotIsFurnishEligible,
  lotIsHonoboost,
} from 'services/lotsV2';
import { programIsNew } from 'services/programs';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import KitchenIcon from 'commonUi/KitchenIcon/KitchenIcon';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { LotPrice } from './LotPrice';

import styles from './LotCard.module.scss';

interface LotCardProps {
  className?: string;
  isLoading?: boolean;
  lotNumber: string;
  program: ProgramTypeV2 | undefined;
  lot: LotTypeV2 | undefined;
}

export function LotCard({ className, lotNumber, program, lot, isLoading = false }: LotCardProps) {
  if (!lot || !program) {
    if (isLoading) {
      return (
        <div className={styles.loading}>
          <CircularProgress classes={{ root: styles.loader }} variant="indeterminate" size={24} />
        </div>
      );
    }
    return <div className={styles.loading} />;
  }

  const currentFiscality = taxMapping?.get(lot.fiscalites[0]);
  const programPageLink = `/programme/page/${program?.referenceProgramme}/prix`;

  return (
    <div className={classnames(styles.root, className)}>
      <div className={styles.top}>
        <ReactRouterLink className={styles.image} to={programPageLink} target="_blank">
          <div className={styles.imgContainer}>
            <img
              className={styles.img}
              src={program.perspectives.panorama}
              alt={program.nomCommercial}
            />
          </div>
          {programIsNew(program) && <div className={styles.new}>{LABEL_NEW_RESIDENCE}</div>}
        </ReactRouterLink>
        <div className={styles.infos}>
          <ReactRouterLink className={styles.program} to={programPageLink} target="_blank">
            <div className={styles.name}>{program.nomCommercial}</div>
            <div className={styles.location}>
              {`${program.localisation.ville} (${program.localisation.codePostal})`}
            </div>
          </ReactRouterLink>
          <div className={styles.pastilles}>
            {currentFiscality && (
              <PastilleFiscality className={styles.pastille} tax={currentFiscality} />
            )}
            {lotHasKitchen(lot) && <KitchenIcon iconClassName={styles.pastille} />}
            {lotIsControlledPrice(lot) && (
              <SvgIcon className={styles.pastille} iconId="icon-prix-maitrises" />
            )}
            {lotIsAdb(lot) && <SvgIcon className={styles.pastille} iconId="icon-adb" />}
            {lotIsFNO(lot) && <SvgIcon className={styles.pastille} iconId="icon-fno" />}
            {lotIsHonoboost(lot) && <SvgIcon className={styles.pastille} iconId="icon-honoboost" />}
            {lotIsFurnishEligible(lot) && (
              <SvgIcon className={styles.pastille} iconId="icon-furnish-eligible" />
            )}
          </div>
        </div>
      </div>
      <div className={styles.details}>
        <div className={styles.detailItem}>
          <Icon className={styles.detailItemIcon} icon="label" />
          <ReactRouterLink
            className={classnames(styles.detailLabel, styles.lotNumber)}
            to={`/programme/page/${program?.referenceProgramme}/prix?lotNumber=${lotNumber}&programRef=${program?.referenceProgramme}`}
            target="_blank"
          >
            {LABEL_LOT_NUMBER}
            {lotNumber}
          </ReactRouterLink>
        </div>
        <div className={styles.detailItem}>
          <Icon className={styles.detailItemIcon} icon="house" />
          <span className={styles.detailLabel}>{lot.typologie}</span>
        </div>
        <div className={styles.detailItem}>
          <LotPrice displayPanel lot={lot} />
        </div>
      </div>
    </div>
  );
}
