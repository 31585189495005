import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { formatPrice } from 'services/formatter';
import { PASTILLE_STATUS_COLOR } from 'services/pastille';
import { taxMapping } from 'services/taxes';
import {
  LABEL_HT,
  LABEL_LOT_NUMBER,
  LABEL_PRICE_IMMO_HT,
  LABEL_TTC,
  LABEL_VAT,
} from 'settings/labels';
import { PDF_PASTILLE_STATUS_ICON, PDF_PASTILLE_STATUS_ICON_WIDTHS } from 'settings/pdf';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { StatusLabel } from 'api/viOffresAPI/apiTypes/Statuses';

import PdfPastille from '../../../Common/PdfPastille/PdfPastille';
import PdfPastilleFiscality from '../../../Common/PdfPastille/PdfPastilleFiscality';
import PdfSvgPicto from '../../../Common/PdfSvgPicto/PdfSvgPicto';

import styles from './LotPdfTotemStyles';

interface LotPdfTotemProps {
  lot: LotTypeV2;
  statuses?: Record<string, { label: StatusLabel }>;
}

export default function LotPdfTotem({ lot, statuses }: LotPdfTotemProps) {
  const { fiscalites, id, prix, reference, typologie } = lot;
  const { prixHTHorsMobilier, TVAalternative, TVANormale } = prix;

  const status = statuses?.[id] && statuses[id].label ? statuses[id].label : lot.statut;
  const fiscality = fiscalites && fiscalites.length > 0 && taxMapping.get(fiscalites[0]);

  return (
    <View style={styles.totem}>
      <View style={styles.totemLeft}>
        <View style={styles.totemLeftLine}>
          <View style={styles.totemLeftLineCol}>
            <View style={styles.picto}>
              <PdfSvgPicto picto="lot" />
            </View>
            <Text>
              {LABEL_LOT_NUMBER} {reference}
            </Text>
          </View>
          <View style={styles.totemLeftLineCol}>
            <View style={styles.picto}>
              <PdfSvgPicto picto="typology" />
            </View>
            <Text>{typologie}</Text>
          </View>
        </View>
        {fiscalites.includes('LMNP') && prixHTHorsMobilier && (
          <View style={styles.totemLeftLine}>
            <View style={styles.totemLeftLineCol}>
              <View style={styles.picto}>
                <PdfSvgPicto picto="priceGrey" />
              </View>
              <View style={styles.totemLeftLineColGroup}>
                <Text>{`${formatPrice(prixHTHorsMobilier, '€', true, true)} ${LABEL_HT}`}</Text>
                {fiscalites.includes('LMNP') && (
                  <Text style={styles.subInfos}>{LABEL_PRICE_IMMO_HT}</Text>
                )}
              </View>
            </View>
          </View>
        )}

        {!fiscalites.includes('LMNP') && TVANormale?.prixTTC && (
          <View style={styles.totemLeftLine}>
            <View style={styles.totemLeftLineCol}>
              <View style={styles.picto}>
                <PdfSvgPicto picto="priceGrey" />
              </View>
              <Text>{`${formatPrice(TVANormale.prixTTC, '€', true, true)} ${LABEL_TTC}`}</Text>
            </View>
            {TVAalternative?.prixTTC && (
              <View style={styles.totemLeftLineCol}>
                <Text>{`${LABEL_VAT} ${Number(TVANormale.tauxTva)}%`}</Text>
              </View>
            )}
          </View>
        )}
        {!fiscalites.includes('LMNP') && TVAalternative?.prixTTC && (
          <View style={styles.totemLeftLine}>
            <View style={styles.totemLeftLineCol}>
              <View style={styles.picto}>
                <PdfSvgPicto picto="priceGrey" />
              </View>
              <Text>{`${formatPrice(TVAalternative.prixTTC, '€', true, true)} ${LABEL_TTC}`}</Text>
            </View>
            <View style={styles.totemLeftLineCol}>
              <Text>{`${LABEL_VAT} ${Number(TVAalternative.tauxTva)}%`}</Text>
            </View>
          </View>
        )}
      </View>
      <View style={styles.totemRight}>
        {fiscality && (
          <View style={styles.pastilleWrapper}>
            <View style={styles.pastille}>
              <PdfPastilleFiscality tax={fiscality} />
            </View>
          </View>
        )}
        <View style={styles.pastilleWrapper}>
          <PdfPastille
            backgroundColor={PASTILLE_STATUS_COLOR[status].bgColor}
            label={status}
            picto={PDF_PASTILLE_STATUS_ICON[status]}
            pictoStyle={[styles.pastillePicto, { width: PDF_PASTILLE_STATUS_ICON_WIDTHS[status] }]}
            textColor={PASTILLE_STATUS_COLOR[status].textColor}
          />
        </View>
      </View>
    </View>
  );
}
