import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { tmsGetLocationLabels } from 'services/tms';
import { PANEL_ID_FOLDERS, PANEL_ID_SEARCH } from 'settings/panels';
import { SEARCH_TMS_INFOS } from 'settings/search';

import { usePanels } from 'hooks/usePanels';

import { apiKelQuartier } from 'api/KelQuartier/ApiKelQuartier';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import KelQuartierModuleProvider from 'sharedModulesV4/KelQuartier/providers/KelQuartierModuleProvider';

import TagCommander from 'modules/App/TagCommander/TagCommander';
import VideoPopin from 'modules/HomePage/Components/VideoPopin/VideoPopin';
import SavedSearchDrawer from 'modules/SaveSearch/Components/SavedSearchDrawer/SavedSearchDrawer';
import FolderLinks from 'modules/Folders/FolderLinks';

import SearchLayoutDesktop from './SearchLayoutDesktop';
import SearchLayoutMobile from './SearchLayoutMobile';
import SearchLayoutTablette from './SearchLayoutTablette';

export function SearchLayout() {
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const { locations, searchId, type } = useSearch();
  const location = useLocation();
  const params = qs.parse(location.search);
  const [isVideoPopinOpen, setIsVideoPopinOpen] = useState(params?.video === 'open');
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  let navigationTemplate = '';
  let navigationPagename = '';

  if (params?.panelMySearch !== 'open') {
    navigationTemplate = 'recherche';
    navigationPagename = type === 'lot' ? 'resultats_lots' : 'resultats_programmes';
  }

  if (searchId) {
    navigationPagename = 'mesrecherches_modifier';
    navigationTemplate = 'mesrecherches';
  }

  let searchLocation: ReturnType<typeof tmsGetLocationLabels> | undefined;

  if (!searchId && locations) {
    searchLocation = tmsGetLocationLabels(locations);
  }

  useEffect(() => {
    if (params?.panelMySearch === 'open') {
      openPanel(PANEL_ID_SEARCH);
    }
    if (params?.panelFolders === 'open') {
      openPanel(PANEL_ID_FOLDERS);
    }
  });

  return (
    <>
      <KelQuartierModuleProvider
        getGoogleReverseGeocoding={apiKelQuartier.getGoogleReverseGeocoding}
        kelquartierGetPois={apiKelQuartier.kelquartierGetPois}
        theme={['vi3p', 'vi3p-search']}
      >
        {isResponsive && (
          <SearchLayoutMobile
            tmsLots={SEARCH_TMS_INFOS.lots}
            tmsPrograms={SEARCH_TMS_INFOS.programmes}
          />
        )}
        {!isResponsive && isTablette && (
          <SearchLayoutTablette
            tmsLots={SEARCH_TMS_INFOS.lots}
            tmsPrograms={SEARCH_TMS_INFOS.programmes}
          />
        )}
        {!isResponsive && !isTablette && (
          <SearchLayoutDesktop
            tmsLots={SEARCH_TMS_INFOS.lots}
            tmsPrograms={SEARCH_TMS_INFOS.programmes}
          />
        )}
      </KelQuartierModuleProvider>

      <VideoPopin
        onClose={() => setIsVideoPopinOpen(false)}
        open={isVideoPopinOpen}
        videoId="a82nTCYnJ-A"
      />

      <SavedSearchDrawer onClose={closePanel} open={idOpenedPanel === PANEL_ID_SEARCH} />
      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      <TagCommander
        navigation_template={navigationTemplate}
        navigation_pagename={navigationPagename}
        search_location={searchLocation}
        useEffectDeps={['navigation_pagename', 'search_location']}
      />
    </>
  );
}
