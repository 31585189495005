import React, { useEffect, useState } from 'react';
import type { RefObject } from 'react';

import classNames from 'classnames';

export interface HeaderGuestProps {
  refDisconnectedHomePage: RefObject<HTMLElement>;
}

export default function HeaderGuest({ refDisconnectedHomePage }: HeaderGuestProps) {
  const [shown, setShown] = useState(false);

  useEffect(() => {
    const disconnectElement = refDisconnectedHomePage.current;
    if (disconnectElement) {
      const handleScroll = () => {
        const loginBottom = disconnectElement.getBoundingClientRect().bottom;
        if (shown && loginBottom > 0) {
          setShown(false);
        } else if (!shown && loginBottom <= 0) {
          setShown(true);
        }
      };
      document.addEventListener('scroll', handleScroll);
      return () => {
        document.removeEventListener('scroll', handleScroll);
      };
    }
    return undefined;
  }, [refDisconnectedHomePage]);

  return (
    <div className="header-wrapper">
      <header className={classNames('header', { 'header--shown': shown })} id="guest-header">
        <a className="header__site-logo" href="/">
          <picture>
            <source srcSet="/images/logo_vip_color.png" media="(max-width: 991px)" />
            <img
              src="/images/logo_vi3p_color.png"
              alt="Logo Vinci Patrimoine"
              width={976}
              height={201}
            />
          </picture>
        </a>

        <img
          src="/images/new_images/logo_vinci.svg"
          alt="Logo vinci"
          className="header__vinci-logo"
          width="120"
          height="44"
        />
      </header>
    </div>
  );
}
