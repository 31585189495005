import React, { useEffect, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';
import ReactPDF from '@react-pdf/renderer';

import { sortLots } from 'services/lots';
import { formatLots } from 'services/lotsV2';
import { LOT_CELL_ID_KIND, LOT_JSON_PROGRAM_REF } from 'settings/lots';
import { SORT_ORDER_ASC } from 'settings/search';
import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import type { LotExport, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType, ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { useGetLotStatusesDirect } from 'hooks/useGetLotsStatuses';

import ProgramPdf from 'commonUi/PDF/ProgramPdf/ProgramPdf';

import PdfButtonUI from './PdfButtonUI';

interface PdfButtonWithLoadingSystemProps {
  allLots: LotExport[] | undefined;
  allPrograms: ProgramListType[] | undefined;
  button: ComponentProps<typeof PdfButtonUI>['button'];
  isButtonActive: boolean;
  loadPdf: (instance: ReactPDF.UsePDFInstance) => void;
  openDropdownTop?: boolean;
  openModal: (modalId: string) => void;
  program: ProgramTypeV2;
  programPdf?: ReactPDF.UsePDFInstance;
  setActiveButton: (activeButton: any) => void;
  taxById: TaxesById;
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
}

export default function PdfButtonWithLoadingSystem({
  allLots,
  allPrograms,
  button,
  isButtonActive,
  loadPdf,
  openDropdownTop = false,
  openModal,
  program,
  programPdf,
  setActiveButton,
  taxById,
  tms,
}: PdfButtonWithLoadingSystemProps) {
  const [loading, setLoading] = useState<boolean>(false);

  const lots = useMemo<LotJson[]>(() => {
    const prog = allPrograms?.find(({ ref }) => ref === program.referenceProgramme);
    if (!program || !prog || !allLots) return [];

    return formatLots(
      allLots.filter(lot => lot[LOT_JSON_PROGRAM_REF] === program.referenceProgramme),
      { [program.id]: prog }
    );
  }, [allLots, allPrograms, program]);
  const sortedLots = useMemo<LotJson[]>(() => sortLots(lots, LOT_CELL_ID_KIND, SORT_ORDER_ASC), [
    lots,
  ]);
  const { statuses } = useGetLotStatusesDirect(loading ? sortedLots : undefined);

  async function generateProgramPdf() {
    if (statuses) {
      const blob = await ReactPDF.pdf(
        <ProgramPdf
          program={program}
          sortedLots={sortedLots}
          statuses={statuses}
          taxById={taxById}
        />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      loadPdf({
        blob,
        url,
        loading: false,
        error: null,
      });
    }
  }

  function onClick() {
    if (!programPdf?.url) {
      setLoading(true);
    }
  }

  useEffect(() => {
    if (loading && sortedLots && statuses && taxById) {
      generateProgramPdf();
    }
  }, [loading, sortedLots, statuses, taxById]);

  useEffect(() => {
    if (programPdf) {
      setLoading(false);
    }
  }, [programPdf]);

  return (
    <PdfButtonUI
      onClick={onClick}
      loading={loading}
      button={button}
      isButtonActive={isButtonActive}
      url={programPdf?.url}
      setActiveButton={setActiveButton}
      openDropdownTop={openDropdownTop}
      openModal={openModal}
      tms={tms}
    />
  );
}
