import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { blocAlerteModalContext } from '../contexts/blocAlerteModalContext';
import { LOCALSTORAGE_KEY_BLOC_ALERTE } from '../settings/localStorage';

function hasUserSeenBlock(userIdCrm, timestamp) {
  if (!userIdCrm || !timestamp) {
    return true;
  }
  const data = JSON.parse(window.localStorage.getItem(LOCALSTORAGE_KEY_BLOC_ALERTE));
  return data[userIdCrm] && data[userIdCrm] >= timestamp;
}

const DELAY_REFRESH_SETTINGS = 60 * 1000 * 2; // 2 minutes

export default function BlocAlerteModalProvider(props) {
  const { children, settings, updateSettings, theme, userIdCrm } = props;
  const [showBlock, setShowBlock] = useState(false);

  const dateModification = settings?.date_modification;

  useEffect(() => {
    const timer = setInterval(() => updateSettings(), DELAY_REFRESH_SETTINGS);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    try {
      setShowBlock(!hasUserSeenBlock(userIdCrm, dateModification));
    } catch (e) {
      setShowBlock(true);
    }
  }, [userIdCrm, dateModification]);

  const setBlockAsSeenByUser = useCallback(
    userIdCrm => {
      if (localStorage) {
        let data;
        try {
          data = JSON.parse(localStorage.getItem(LOCALSTORAGE_KEY_BLOC_ALERTE));
        } catch (e) {
          data = {};
        }

        localStorage.setItem(
          LOCALSTORAGE_KEY_BLOC_ALERTE,
          JSON.stringify({ ...data, [userIdCrm]: dateModification })
        );
      }
    },
    [dateModification]
  );

  return (
    <blocAlerteModalContext.Provider
      value={{
        showBlock,
        setShowBlock,
        settings,
        theme,
        userIdCrm,
        setBlockAsSeenByUser,
      }}
    >
      {children}
    </blocAlerteModalContext.Provider>
  );
}

BlocAlerteModalProvider.propTypes = {
  children: PropTypes.node.isRequired,
  settings: PropTypes.shape({
    titre: PropTypes.string,
    image: PropTypes.any,
    contenu: PropTypes.string,
    bloc_alerte_cta: PropTypes.shape({
      libelle: PropTypes.string,
      url: PropTypes.string,
      target: PropTypes.string,
    }),
    est_affiche: PropTypes.oneOf([0, 1]),
    date_modification: PropTypes.number,
  }),
  updateSettings: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  userIdCrm: PropTypes.string,
};

BlocAlerteModalProvider.defaultProps = {
  userIdCrm: undefined,
  settings: undefined,
};
