import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { ComponentProps } from 'react';
import dayjs from 'dayjs';
import { IconButton } from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroll-component';

import { downloadContract, openBlob } from 'services/files';
import { LABEL_NOTIFICATIONS_TITLE } from 'settings/labels';
import {
  NOTIF_STATUS_UNREAD,
  NOTIF_TYPE_SAVED_SEARCH,
  NOTIF_TYPE_SIGNED_CONTRACT,
  NOTIF_TYPE_SIGNED_CONTRACT_COACQ,
} from 'settings/notifications';

import { getDocumentUrl } from 'services/documents';

import { userContext } from 'modules/App/Contexts/userContext';
import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import { useInfinitePagerWithTyped } from 'hooks/useInfinitePager';

import { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';
import { apiData, useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import ModalLoader from 'commonUi/Modal/ModalLoader';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import { NotificationWithHeader } from './NotificationWithHeader';

import styles from './NotificationsList.module.scss';

const isDigitalSigningEnabled = window.vinci.REACT_APP_ENABLE_DIGITAL_SIGNING === 'true';

export const NOTIFICATIONS_DELAY = 60 * 1000; // 1 minute

interface NotificationsListProps {
  closePanel?: () => void;
}

// Besoin API [ ASSEZ IMPORTANT ] : Paginer notifications.
export default function NotificationsList({ closePanel }: NotificationsListProps) {
  const [showModal, setShowModal] = useState(false);
  const [notificationsPage, setNotificationsPage] = useState(0);

  const { userCrm } = useContext(userContext);
  const { isResponsive } = useContext(ResponsiveContext);

  const { data: unsortedNotifications, mutate } = useSWRVi3p<apiData<NotificationType[]>>(
    { url: `/profils/${userCrm?.extension_VI3P_ContactId}/notifications` },
    { refreshInterval: NOTIFICATIONS_DELAY }
  );

  const notifications = useMemo(
    () =>
      unsortedNotifications?.data
        .filter(n => n.notification_type !== NOTIF_TYPE_SAVED_SEARCH)
        .sort(({ date: dateA }, { date: dateB }) => {
          return dayjs(dateB).diff(dayjs(dateA));
        }) ?? [],
    [unsortedNotifications]
  );

  const unreadNb = useMemo(() => {
    return notifications?.filter(({ status }) => status === NOTIF_STATUS_UNREAD).length;
  }, [notifications]);

  const {
    paginatedItems: paginatedNotifications,
    hasMoreItems: hasMoreNotifications,
  } = useInfinitePagerWithTyped<NotificationType>(notificationsPage, 10, notifications);

  // refresh count number when all notification are read
  useEffect(() => {
    async function optimisticPatchNotification() {
      const notificationsRead = notifications
        ?.filter(({ status }) => status === NOTIF_STATUS_UNREAD)
        .map(
          notification =>
            ({
              ...notification,
              status: 'lu',
            } as NotificationType)
        );
      const notificationUpdated = [
        ...notificationsRead,
        ...notifications?.filter(({ status }) => status !== NOTIF_STATUS_UNREAD),
      ];

      mutate({ data: notificationUpdated }, false);
      await axiosVI3PInstance.patch(
        `profils/${userCrm?.extension_VI3P_ContactId}/notifications`,
        notificationsRead.map(notification => ({
          id: notification.notification_id,
          statut: notification.status,
        }))
      );
      mutate({ data: notificationUpdated });
    }

    return () => {
      if (unreadNb > 0) {
        optimisticPatchNotification();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateIframe = useCallback((documentUrl: string) => {
    setShowModal(true);
    openBlob(
      () =>
        downloadContract(documentUrl)
          .then(response => {
            if (response?.data) {
              return new Blob([response.data], { type: 'application/pdf' });
            }
            throw new Error();
          })
          .finally(() => setShowModal(false)),
      undefined,
      isResponsive
    );
  }, []);

  const handleClick: ComponentProps<typeof NotificationWithHeader>['onClick'] = (
    _,
    url,
    docId,
    notifType
  ) => {
    if (typeof closePanel === 'function') {
      closePanel();
    }
    if (
      docId &&
      notifType !== NOTIF_TYPE_SIGNED_CONTRACT &&
      notifType !== NOTIF_TYPE_SIGNED_CONTRACT_COACQ &&
      !isDigitalSigningEnabled
    ) {
      const docUrl = getDocumentUrl(docId, userCrm?.extension_VI3P_ContactId);
      if (docUrl) {
        setShowModal(true);
        openBlob(
          () =>
            downloadContract(docUrl)
              .then(response => {
                if (response?.data) {
                  return new Blob([response.data], { type: 'application/pdf' });
                }
                throw new Error();
              })
              .finally(() => setShowModal(false)),
          undefined,
          isResponsive
        );
      }
      const documentUrl = getDocumentUrl(docId, userCrm?.extension_VI3P_ContactId);
      if (documentUrl) {
        populateIframe(documentUrl);
      }
      return;
    }
    window.open(url, '_self');
  };

  return (
    <div className={styles.root}>
      <div className={styles.notificationsHeader}>
        <div className={styles.headerTitle}>
          <SvgIcon className={styles.svgIcon} iconId="icon-notifications" />
          {unreadNb > 0 && <span className={styles.unreadNbNotifications}>{unreadNb}</span>}
          <span>{LABEL_NOTIFICATIONS_TITLE}</span>
        </div>
        {!isResponsive && (
          <IconButton classes={{ root: styles.headerCross }} onClick={closePanel}>
            <Icon icon="cross" />
          </IconButton>
        )}
      </div>
      <div className={styles.notificationsListContainer}>
        {notifications && (
          <InfiniteScroll
            className={styles.infiniteScroll}
            dataLength={(paginatedNotifications as NotificationType[])?.length}
            loader={null}
            next={() => setNotificationsPage(prev => prev + 1)}
            hasMore={hasMoreNotifications as boolean}
            hasChildren
            style={{ height: 'auto', overflowY: 'scroll' }}
            height={notifications?.length}
          >
            <ul id="notifications" className={styles.notificationsList}>
              {paginatedNotifications?.map((notif, indexNotif) => (
                <NotificationWithHeader
                  key={notif.uuid}
                  notif={notif}
                  displayHeader={
                    indexNotif === 0 ||
                    (indexNotif > 0 &&
                      dayjs(notif.date).format('YYYY-MM-DD') !==
                        dayjs(paginatedNotifications[indexNotif - 1]?.date).format('YYYY-MM-DD'))
                  }
                  onClick={handleClick}
                />
              ))}
              <ModalLoader closeCallback={() => setShowModal(false)} showModal={showModal} />
            </ul>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
}
