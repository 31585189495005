import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { LABEL_CONTACT, LABEL_MY_INTERLOCUTORS, LABEL_RETURN } from 'settings/labels';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import { AssistanceType } from '../AssistanceType';

import styles from './AssistanceBlock.module.scss';

export default function AssistanceBlockDesktop({ defaultJob, interlocutors }: AssistanceType) {
  const { pathname } = useLocation();
  const [interlocutorIdx, setInterlocutorIdx] = useState<number>();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  return (
    <Accordion
      classes={{
        root: styles.accordionRoot,
      }}
      expanded={open}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        classes={{
          content: styles.accordionSummaryContent,
          expanded: styles.accordionSummaryExpanded,
          root: styles.accordionSummaryRoot,
        }}
        id="panel1a-header"
        onClick={() => setOpen(prevState => !prevState)}
      >
        <Icon className={styles.icon} icon="interlocutor" />
        <span className={styles.label}>{LABEL_MY_INTERLOCUTORS}</span>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classnames(styles.accordionDetailsRoot, {
            [styles.isInfosOpen]: interlocutorIdx !== undefined,
          }),
        }}
      >
        {interlocutorIdx !== undefined && (
          <div className={classnames(styles.contactInfos, styles.contactInfosVisible)}>
            <button
              className={styles.return}
              onClick={() => setInterlocutorIdx(undefined)}
              type="button"
            >
              <Icon className={styles.returnIcon} icon="arrow-left" />
              {LABEL_RETURN}
            </button>
            <div>
              <a
                className={classnames(styles.button, styles.buttonLight)}
                href={`mailto:${interlocutors[interlocutorIdx].field_email}`}
              >
                <Icon className={styles.buttonIcon} icon="mail" /> {LABEL_CONTACT}
              </a>
              <a className={styles.button} href={`tel:${interlocutors[interlocutorIdx].field_tel}`}>
                <Icon className={styles.buttonIcon} icon="call" />{' '}
                {interlocutors[interlocutorIdx].field_tel}
              </a>
            </div>
          </div>
        )}
        <CustomScrollbar>
          {interlocutors.map((interlocutor, idx) => {
            const {
              field_nom: lastname,
              field_prenom: firstname,
              /* job not return by api */
            } = interlocutor;
            return (
              <button
                className={styles.contact}
                key={`${lastname}-${firstname}`}
                onClick={() => setInterlocutorIdx(prevIdx => (prevIdx === idx ? undefined : idx))}
                type="button"
              >
                <div className={styles.contactName}>
                  {lastname} {firstname}
                </div>
                <div className={styles.contactJob}>{defaultJob}</div>
              </button>
            );
          })}
        </CustomScrollbar>
      </AccordionDetails>
    </Accordion>
  );
}
