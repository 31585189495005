import React from 'react';
import type { PropsWithChildren, ReactNode } from 'react';

import styles from './PanelContainer.module.scss';

interface PanelContainerProps {
  headerRight?: ReactNode;
  title?: string;
}

export default function PanelContainer({
  children,
  headerRight,
  title,
}: PropsWithChildren<PanelContainerProps>) {
  return (
    <div className={styles.root}>
      <div className={styles.panel}>
        {title && (
          <div className={styles.header}>
            <h2 className={styles.title}>{title}</h2>
            {headerRight}
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
