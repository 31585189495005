import React, { useEffect, useRef, useState } from 'react';
import ReactPDF from '@react-pdf/renderer';

import { LABEL_DISPLAY_CARD_PROGRAM } from 'settings/labels';
import { TMS_TIMEOUT } from 'settings/tms';

import Button from 'commonUi/Button/Button';

export interface ProgramBtnDownloadPdfProps {
  clickDownloadPDF: boolean;
  generateProgramPdf: () => void;
  programPdf?: ReactPDF.UsePDFInstance;
  setClickDownloadPDF: (click: boolean) => void;
}

export default function ProgramBtnDownloadPdf({
  clickDownloadPDF,
  generateProgramPdf,
  programPdf,
  setClickDownloadPDF,
}: ProgramBtnDownloadPdfProps) {
  const [loading, setLoading] = useState(false);
  const btnRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!programPdf) {
      setLoading(true);
      generateProgramPdf();
    }
  }, []);

  useEffect(() => {
    if (clickDownloadPDF && programPdf?.url) {
      btnRef.current?.click();
      setLoading(false);
      setClickDownloadPDF(false);
    }
  }, [programPdf?.url]);

  useEffect(() => {
    if (programPdf?.url && clickDownloadPDF) {
      setTimeout(() => {
        setClickDownloadPDF(false);
      }, TMS_TIMEOUT);
    }
  }, [clickDownloadPDF]);

  return (
    <Button
      color="primary"
      icon="display"
      variant="contained"
      href={programPdf?.url}
      target="_blank"
      loading={loading}
      ref={btnRef}
      onClick={() => {
        if (!clickDownloadPDF) {
          setClickDownloadPDF(true);
        }
      }}
    >
      {LABEL_DISPLAY_CARD_PROGRAM}
    </Button>
  );
}
