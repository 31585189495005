import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { APP_URL_PENDING } from 'settings/app';

import { isAuthenticated } from 'services';

import ActualityPage from 'modules/Actuality/components/ActualityPage/ActualityPage';
import { SearchLayout } from 'modules/HomePage';

// TODO implement lazy-loading routing.

import { MyFolderPage } from 'modules/MyFolder';
import OtherPage from 'layout/components/OtherPage/OtherPage';
import ActualityDetail from 'modules/Actuality/components/ActualityDetail/ActualityDetail';
import VinciCookies from 'modules/VinciCookies/VinciCookies';
import { Activity } from 'modules/ActivityPage';
import { ComparisonPage } from 'modules/Comparison';
import { Prebooking } from 'modules/ActionsProgramsAndLots/PagePrebooking/Prebooking';
import { PageContractSign } from 'modules/ActionsProgramsAndLots/PageContractSigne/PageContractSign';

import { FormGenerateContract } from 'modules/ActionsProgramsAndLots/PageGenerateContract/FormGenerateContract';

import Logout from '../modules/Authentication/Logout';
import PendingPage from '../modules/PendingPage/PendingPage';
import { Program } from '../modules/ProgramPage';
import Report from '../modules/ReportsPage/ReportsDetail/Report';
import ReportsList from '../modules/ReportsPage/ReportsList/ReportsList';
import MyProfile from '../modules/MyProfile/MyProfile';
import HomeRoute from './Route/HomeRoute';
import GuestRoute from './Route/GuestRoute';
import AuthenticatedRoute from './Route/AuthenticatedRoute';

const HISTORY_KEY = 'vinci_history';

function updateHistory() {
  if (sessionStorage.getItem(HISTORY_KEY) == null) {
    sessionStorage.setItem(
      HISTORY_KEY,
      JSON.stringify({
        previous: '/',
        current: '/',
      })
    );
  }

  const userNav = JSON.parse(sessionStorage.getItem(HISTORY_KEY) as string); // cannot be `null` because if it was we just set it in the previous `if`
  if (userNav.current !== window.location.pathname) {
    userNav.previous = userNav.current;
    userNav.current = window.location.pathname;
  }

  sessionStorage.setItem(HISTORY_KEY, JSON.stringify(userNav));
}

export default function RouterSwitch() {
  const SmartRoute = isAuthenticated() ? AuthenticatedRoute : GuestRoute;

  updateHistory();

  return (
    <Switch>
      <HomeRoute exact path="/" />
      <Route path={APP_URL_PENDING} component={PendingPage} />
      <AuthenticatedRoute exact path="/recherche" component={SearchLayout} noFooter />
      <Route path="/deconnexion" component={Logout} />
      {/* <AuthenticatedRoute path="/plus-de-criteres" component={AdvancedSearchPage} /> */}
      {/* <AuthenticatedRoute exact path="/:page/liste" component={SearchPage} noFooter />
       <AuthenticatedRoute exact path="/lot/page/:id/:activeTab" component={DetailLot} /> */}
      <AuthenticatedRoute
        path="/programme/page/:programRef/chantiers/:reportId"
        component={Report}
      />
      <AuthenticatedRoute path="/programme/page/:programRef/chantiers" component={ReportsList} />
      <AuthenticatedRoute exact path="/programme/page/:programRef" component={Program} />
      <AuthenticatedRoute path="/programme/page/:programRef/:activeTab" component={Program} />
      <AuthenticatedRoute path="/mon-profil" component={MyProfile} />
      <AuthenticatedRoute exact path="/mon-activite-commerciale" component={Activity} />
      <AuthenticatedRoute path="/mon-activite-commerciale/:activeTab" component={Activity} />
      <AuthenticatedRoute exact path="/mes-dossiers/:id" component={MyFolderPage} />
      <AuthenticatedRoute exact path="/actualites" component={ActualityPage} />
      <SmartRoute exact path="/actualites/:id" component={ActualityDetail} />
      <AuthenticatedRoute exact path="/comparaison" component={ComparisonPage} />
      <AuthenticatedRoute
        exact
        path="/pre-reservation/:programRef/:lotNumber"
        component={Prebooking}
        noHeader
      />
      <AuthenticatedRoute
        exact
        path="/generation-contrat/:prebookIdCrm"
        component={FormGenerateContract}
        noHeader
      />
      <AuthenticatedRoute
        exact
        path="/signature-contrat/:idReservation"
        component={PageContractSign}
        noHeader
      />
      {/* <AuthenticatedRoute exact path="/pending-validation" component={PendingValidation} />
       <AuthenticatedRoute exact path="/document/:documentId" component={DocumentPage} /> */}
      <SmartRoute exact path="/vinci-cookies" component={VinciCookies} />
      <SmartRoute path="*" component={OtherPage} />
    </Switch>
  );
}
