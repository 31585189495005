import { useContext, useEffect, useMemo, useState } from 'react';

import { error, tmsObserver } from 'services';
import { TmsContext } from 'modules/App/Contexts';

function updateStateFromCookies(setState) {
  const categories = window?.tC?.privacy?.getOptinCategories?.() ?? [];
  const vendors = window.tC?.privacy?.getOptinVendors?.() ?? [];
  setState({
    categories,
    data: window.tC.privacy.cookieData,
    vendors,
  });
}

export default function useTms({ category, vendor }: { category: string; vendor: string }) {
  const { tmsIsReady } = useContext(TmsContext);
  const [{ categories, data, vendors }, setCookies] = useState({
    categories: [] as string[],
    data: [] as string[],
    vendors: [] as string[],
  });

  useEffect(() => {
    try {
      if (tmsIsReady) {
        updateStateFromCookies(setCookies);
      }
    } catch (err) {
      error('TagCommander - SetCookies failed', err);
    }
  }, [tmsIsReady]);

  useEffect(() => {
    try {
      if (tmsIsReady) {
        return tmsObserver.subscribe(() => updateStateFromCookies(setCookies));
      }
    } catch (err) {
      error('TagCommander - SetCookies failed', err);
    }
    return () => {};
  }, [tmsIsReady]);

  return useMemo(() => {
    if (!tmsIsReady) {
      return false;
    }
    if (data instanceof Array && data[2] === 'ALL') {
      return true;
    }
    return categories?.includes(category) || vendors?.includes(vendor);
  }, [categories, data, category, vendor, tmsIsReady, vendors]);
}
