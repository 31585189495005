import React, { useContext } from 'react';

import { userContext } from 'modules/App/Contexts';
import { replaceTokens } from 'services';
import {
  LABEL_NB_OPTIONS,
  LABEL_NB_OPTION_DURATION,
  LABEL_NB_PRE_BOOKINGS,
  LABEL_NB_PRE_BOOKING_DURATION,
  LABEL_NO_COMMERCIAL_RIGHTS,
  LABEL_OPTIONS,
  LABEL_PRE_BOOKINGS,
  LABEL_TAB_COMMERCIAL_RIGHTS,
  TOKEN_NB,
} from 'settings';

import CardIcon, { ICON_TYPE_SPRITE } from 'commonUi/CardIcon/CardIcon';
import PanelContainer from 'layout/components/PanelContainer/PanelContainer';
import CardsList from '../CardsList/CardsList';

import styles from './AccountRights.module.scss';

export default function AccountRights() {
  const { userCrm } = useContext(userContext);
  const optionDuration = userCrm?.extension_VI3P_DroitsCommerciaux_DureeOption;
  const preBookingDuration = userCrm?.extension_VI3P_DroitsCommerciaux_DureePreReservation;
  const optionNumber = userCrm?.extension_VI3P_DroitsCommerciaux_NbOptions;
  const preBookingNumber = userCrm?.extension_VI3P_DroitsCommerciaux_NbPreReservation;

  let content = <p className={styles.text}>{LABEL_NO_COMMERCIAL_RIGHTS}</p>;

  if (optionDuration || preBookingDuration || optionNumber || preBookingNumber) {
    content = (
      <>
        {(optionDuration || optionNumber) && (
          <CardsList title={LABEL_OPTIONS}>
            {optionNumber && (
              <CardIcon
                icon="icon-option"
                iconType={ICON_TYPE_SPRITE}
                title={replaceTokens(LABEL_NB_OPTIONS, { [TOKEN_NB]: optionNumber })}
              />
            )}
            {optionDuration && (
              <CardIcon
                icon="calendar"
                title={replaceTokens(LABEL_NB_OPTION_DURATION, { [TOKEN_NB]: optionDuration })}
              />
            )}
          </CardsList>
        )}
        {(preBookingNumber || preBookingDuration) && (
          <CardsList title={LABEL_PRE_BOOKINGS}>
            {preBookingNumber && (
              <CardIcon
                icon="icon-pre-book"
                iconType={ICON_TYPE_SPRITE}
                title={replaceTokens(LABEL_NB_PRE_BOOKINGS, { [TOKEN_NB]: preBookingNumber })}
              />
            )}
            {preBookingDuration && (
              <CardIcon
                icon="calendar"
                title={replaceTokens(LABEL_NB_PRE_BOOKING_DURATION, {
                  [TOKEN_NB]: preBookingDuration,
                })}
              />
            )}
          </CardsList>
        )}
      </>
    );
  }

  return (
    <PanelContainer title={LABEL_TAB_COMMERCIAL_RIGHTS}>
      <div className={styles.root}>{content}</div>
    </PanelContainer>
  );
}
