import React, { useContext, useState } from 'react';
import { IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';

import { regexEmail } from 'services/constraints';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_CONFIRM_BOOKLET_SEND,
  LABEL_EMAIL_CONTENT,
  LABEL_EMAIL_FORM_ERROR_TITLE,
  LABEL_EMAIL_RECEIVER,
  LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR,
  LABEL_EMAIL_SUBJECT,
  LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR,
  LABEL_FORM_ERROR_CONTENT,
  LABEL_FORM_FIELDS_REQUIRED,
  LABEL_SEND_BOOKLET,
} from 'settings/labels';
import { MAIL_MESSAGE_DOCUMENTS } from 'settings/mailSharing';
import { TMS_TIMEOUT } from 'settings/tms';

import Button from 'commonUi/Button/Button';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import TextField from 'commonUi/TextField/TextField';
import { userContext } from 'modules/App/Contexts';
import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';

import styles from '../ModalAction.module.scss';

const FIELD_ID_RECEIVER = 'field-receiver';
const FIELD_ID_SUBJECT = 'field-object';
const FIELD_ID_BODY = 'field-content';

interface FormSendBookletProps {
  booklet: { name: string; url: string };
  handleClose: () => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
}

export function FormSendBooklet({
  booklet,
  handleClose,
  loading,
  setLoading,
}: FormSendBookletProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { userCrm } = useContext(userContext);
  const [apiError, setApiError] = useState(false);

  const { control, handleSubmit, formState } = useForm({
    resolver: yupResolver(sendBookletSchema),
    mode: 'onChange',
    defaultValues: {
      [FIELD_ID_RECEIVER]: null,
      [FIELD_ID_SUBJECT]: null,
      [FIELD_ID_BODY]: MAIL_MESSAGE_DOCUMENTS(userCrm),
    },
  });
  const handleSendMail = handleSubmit(async values => {
    setLoading(true);
    try {
      await axiosVI3PInstance.post(`mailer`, {
        to: values?.[FIELD_ID_RECEIVER],
        subject: values?.[FIELD_ID_SUBJECT],
        message: values?.[FIELD_ID_BODY],
        attachments: [booklet],
        type: 'document',
        prescripteurFullName: userCrm ? `${userCrm.given_name} ${userCrm.family_name}` : undefined,
        prescripteurEmail: userCrm?.email,
      });
      setTimeout(() => {
        enqueueSnackbar(LABEL_CONFIRM_BOOKLET_SEND);
        handleClose();
      }, TMS_TIMEOUT);
    } catch (newError) {
      setLoading(false);
      if (newError?.response?.status) {
        setApiError(true);
      }
    }
  });
  const { isDirty, isValid, errors } = formState;

  return (
    <form onSubmit={handleSendMail}>
      <div className={styles.form}>
        <div className={styles.input}>
          <Controller
            name={FIELD_ID_RECEIVER}
            control={control}
            render={props => (
              <TextField
                error={!!errors?.[FIELD_ID_RECEIVER]}
                helperText={errors?.[FIELD_ID_RECEIVER] && LABEL_EMAIL_RECEIVER_HELPERTEXT_ERROR}
                label={LABEL_EMAIL_RECEIVER}
                name={FIELD_ID_RECEIVER}
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-object"
            control={control}
            render={props => (
              <TextField
                error={!!errors?.[FIELD_ID_SUBJECT]}
                helperText={errors?.[FIELD_ID_SUBJECT] && LABEL_EMAIL_SUBJET_HELPERTEXT_ERROR}
                id="field-object"
                label={LABEL_EMAIL_SUBJECT}
                name="field-object"
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        <div className={styles.input}>
          <Controller
            name="field-content"
            control={control}
            render={props => (
              <TextField
                id="field-content"
                label={LABEL_EMAIL_CONTENT}
                multiline
                name="field-content"
                onChange={ev => props?.field.onChange(ev.target.value)}
                value={props?.field.value}
                required
              />
            )}
          />
        </div>
        {!apiError && (
          <span className={styles.additionalInformation}>* {LABEL_FORM_FIELDS_REQUIRED}</span>
        )}
      </div>
      <div className={classNames(styles.modalFooter, { [styles.errorFooter]: apiError })}>
        {apiError && (
          <div className={styles.errorContent}>
            <div className={styles.right}>
              <IconButton
                classes={{ root: styles.iconButton }}
                disableRipple
                onClick={() => setApiError(false)}
              >
                <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
              </IconButton>
            </div>
            <div className={styles.center}>
              <h4>{LABEL_EMAIL_FORM_ERROR_TITLE}</h4>
              <p>{LABEL_FORM_ERROR_CONTENT}</p>
            </div>
          </div>
        )}
        {!apiError && (
          <>
            <div className={styles.cancelButton}>
              <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
                {LABEL_CANCEL_BUTTON}
              </Button>
            </div>
            <div className={styles.createButton}>
              <Button
                disabled={!isDirty || !isValid}
                fullWidth
                variant="contained"
                color="primary"
                type="submit"
                loading={loading}
              >
                {LABEL_SEND_BOOKLET}
              </Button>
            </div>
          </>
        )}
      </div>
    </form>
  );
}

const sendBookletSchema = yup.object().shape({
  [FIELD_ID_RECEIVER]: yup.string().required().matches(RegExp(regexEmail)),
  [FIELD_ID_SUBJECT]: yup.string().trim().required(),
  [FIELD_ID_BODY]: yup.string().trim().required(),
});
