import React, { useContext } from 'react';
import classNames from 'classnames';

import { getNormalizedRoleFrom } from 'services';
import {
  LABEL_CIVILITY,
  LABEL_COMPANY_NAME,
  LABEL_DISCONNECTION,
  LABEL_EMAIL_ADDRESS,
  LABEL_INFORMATIONS_EDITED,
  LABEL_LEGAL_FIRSTNAME,
  LABEL_LEGAL_LASTNAME,
  LABEL_LEGAL_STATUS,
  LABEL_MOBILE_PHONE,
  LABEL_NOT_SPECIFIED,
  LABEL_PHONE,
  LABEL_POSTAL_ADDRESS,
  LABEL_PREFERRED_LOCATION,
  LABEL_PROFILE_MODIFY,
  LABEL_PROFILE_TAB_ACCOUNT_TITLE,
} from 'settings/labels';
import { USER_ROLE_DO, USER_ROLE_OP } from 'settings/user';

import { ResponsiveContext, userContext } from 'modules/App/Contexts';

import Button from 'commonUi/Button/Button';
import PanelContainer from 'layout/components/PanelContainer/PanelContainer';

import AccountDetailField from './AccountDetailField';
import AccountDetailPreferredField from './AccountDetailPreferredField';

import styles from './AccountDetail.module.scss';

export default function AccountDetail(props: AccountDetailType) {
  const { isEdited = false } = props;
  const { userCrm, userPrescripteurs } = useContext(userContext);
  const { isFooterVisibleMobile, isResponsive } = useContext(ResponsiveContext);

  if (!userCrm || !userPrescripteurs) {
    return <PanelContainer title={LABEL_PROFILE_TAB_ACCOUNT_TITLE} />;
  }

  const {
    field_raisonsociale: companyName,
    field_statutjuridique: legalStatus,
  } = userPrescripteurs;
  const {
    city,
    email,
    extension_civilite: civility,
    extension_telephone: phone,
    extension_telephoneMobile: mobilePhone,
    extension_VI3P_Role: dirtyRole,
    family_name: lastName,
    given_name: firstName,
    postalCode,
    streetAddress,
  } = userCrm;
  const role = getNormalizedRoleFrom(dirtyRole);
  const address = [streetAddress, postalCode, city]
    .filter(v => v !== LABEL_NOT_SPECIFIED)
    .join(' ');

  return (
    <PanelContainer
      headerRight={
        role &&
        ![USER_ROLE_OP, USER_ROLE_DO].includes(role) && (
          <Button
            className={styles.modifyLink}
            color="secondary"
            href={window.vinci.REACT_APP_PROFILE_EDIT_URL}
            variant="contained"
          >
            {LABEL_PROFILE_MODIFY}
          </Button>
        )
      }
      title={LABEL_PROFILE_TAB_ACCOUNT_TITLE}
    >
      <div className={styles.root}>
        {isEdited && <p className={styles.text}>{LABEL_INFORMATIONS_EDITED}</p>}
        <div className={styles.grid}>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_CIVILITY} value={civility || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailPreferredField label={LABEL_PREFERRED_LOCATION} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_LEGAL_LASTNAME} value={lastName || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_LEGAL_FIRSTNAME} value={firstName || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_PHONE} value={phone || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_MOBILE_PHONE} value={mobilePhone || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_EMAIL_ADDRESS} value={email || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_POSTAL_ADDRESS} value={address || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_LEGAL_STATUS} value={legalStatus || ' '} />
          </div>
          <div className={styles.gridItem}>
            <AccountDetailField label={LABEL_COMPANY_NAME} value={companyName || ' '} />
          </div>
        </div>
        {isResponsive && (
          <>
            <div className={styles.disconnectWrapper}>
              <Button
                className={styles.disconnectIconBtn}
                color="primary"
                href="/deconnexion"
                icon="power-off"
                variant="contained"
              >
                {LABEL_DISCONNECTION}
              </Button>
            </div>
            {role && ![USER_ROLE_OP, USER_ROLE_DO].includes(role) && (
              <div
                className={classNames(styles.bottom, {
                  [styles.isSticky]: !isFooterVisibleMobile,
                })}
              >
                <Button
                  className={styles.modifyLink}
                  color="secondary"
                  href={window.vinci.REACT_APP_PROFILE_EDIT_URL}
                  variant="contained"
                >
                  {LABEL_PROFILE_MODIFY}
                </Button>
              </div>
            )}
          </>
        )}
      </div>
    </PanelContainer>
  );
}

type AccountDetailType = {
  isEdited?: boolean;
};
