import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { LABEL_DISPLAY_MAP } from 'settings/labels';
import { TMS_ORIGIN_SEARCH } from 'settings/tms';

import type { SelectedLotType } from 'types/lots';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getFilterableValues } from 'services/search';
import { modifyQuery } from 'services/url';

import { useModal } from 'hooks/useModal';
import useSearchSortState from 'hooks/useSearchSortState';
import { useSortedPrograms } from 'hooks/useSortedPrograms';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import LotDropdownSort from 'commonUi/ListLotV2/LotDropdownSort/LotDropdownSort';
import SaveSearchButtonWithModal from 'modules/SaveSearch/Components/SaveSearchButton/SaveSearchButtonWithModal';
import useFilterData from '../../../hooks/useFilterData';

import ProgramsList from '../../ProgramsList/ProgramsList';
import ListSwitcher from '../../ListSwitcher/ListSwitcher';

import Autocomplete from '../../Search/Autocomplete/Autocomplete';
import FiltersPanel from '../../Search/FiltersPanel/FiltersPanel';
import SearchMap from '../../Search/SearchMap';
import { ProgramsDropdownSort } from '../../ProgramsDropdownSort/ProgramsDropdownSort';
import LotsListTablette from '../../LotsList/LotsListTablette';
import LotsListSelectedActions from '../../LotsList/LotsListSelectedActions';

import styles from './SearchLayout.module.scss';

export default function SearchLayoutTablette({
  tmsLots = {},
  tmsPrograms = {},
}: SearchLayoutTabletteProps) {
  const history = useHistory();
  const search = useSearch();
  const { type: searchType = 'program' } = search;
  const filterableValues = getFilterableValues(search);
  const {
    filterData,
    lots,
    programs,
    isValidating: isDataValidating,
    isLotsLoading,
  } = useFilterData();
  const { lots: filteredLots, programs: filteredPrograms, lotNbByPrograms } = useMemo(
    () => filterData(filterableValues),
    [filterData, JSON.stringify(filterableValues)]
  );
  const modal = useModal();
  const { taxesById } = useContext(TaxonomiesContext);

  const [isMoreCriteriasOpen, setIsMoreCriteriasOpen] = useState(false);
  const [isPanelExpanded, setIsPanelExpanded] = useState<boolean>(
    search.map === 'off' || !!search.searchId
  );

  const {
    programs: { sortBy: programsSortBy, sortOrder: programsSortOrder },
    lots: { sortBy: lotsSortBy, sortOrder: lotsSortOrder },
    reorderPrograms,
    reorderLots,
  } = useSearchSortState(filterableValues.locations);

  const { programs: sortedPrograms, isLoading: isSortLoading } = useSortedPrograms(
    filteredPrograms,
    programsSortBy,
    programsSortOrder
  );

  const switchRef = useRef<HTMLDivElement>(null);

  const [selectedLots, setSelectedLots] = useState<SelectedLotType[]>([]);

  useEffect(() => {
    if (search.searchId) {
      setIsPanelExpanded(true);
    }
  }, [search.searchId]);

  function handleTogglePanel() {
    if (isPanelExpanded) {
      history.replace(modifyQuery({ map: 'on' }, ['lotNumber']));
    } else {
      history.replace(modifyQuery({ map: 'off' }, ['lotNumber']));
    }
    setIsPanelExpanded(prevState => !prevState);
  }

  const listSwitcher = (
    <ListSwitcher
      ref={switchRef}
      className={classnames(styles.listSwitcherContainer, {
        [styles.listSwitcherContainerExp]: isPanelExpanded,
      })}
      type={searchType}
      lotsNumber={filteredLots?.length}
      lotsOnClick={() => setIsPanelExpanded(true)}
      lotsUrlParams={{ map: 'off' }}
      programsNumber={sortedPrograms?.length}
    />
  );

  const saveSearchBtn = (
    <div
      className={classnames(styles.saveSearchBtn, { [styles.saveSearchBtnExp]: isPanelExpanded })}
    >
      <SaveSearchButtonWithModal
        hideButtonLabel
        modal={modal}
        resultsLots={filteredLots}
        resultsPrograms={filteredPrograms}
      />
    </div>
  );

  const sortDropdown =
    searchType === 'lot' ? (
      <LotDropdownSort
        buttonClassName={styles.sortButton}
        taxes={search.taxes}
        reorderLots={sortBy =>
          reorderLots(sortBy, () => history.replace(modifyQuery({}, ['programRef'])))
        }
        lotsSortBy={lotsSortBy}
        lotsSortOrder={lotsSortOrder}
      />
    ) : (
      <ProgramsDropdownSort
        buttonClassName={styles.sortButton}
        type={searchType}
        reorderPrograms={reorderPrograms}
        sortBy={programsSortBy}
        sortOrder={programsSortOrder}
      />
    );

  return (
    <div className={styles.root}>
      <div
        className={classnames(styles.panelWrapper, {
          [styles.panelExpanded]: isPanelExpanded,
        })}
      >
        <div className={styles.panel}>
          {isPanelExpanded && (
            <div className={styles.panelHeaderTop}>
              <div className={styles.location}>
                <Autocomplete
                  classes={{
                    root: styles.autocomplete,
                    textFieldRoot: styles.container,
                    inputRoot: styles.input,
                  }}
                />
                <button
                  type="button"
                  className={styles.moreCriteriasBtn}
                  onClick={() => setIsMoreCriteriasOpen(true)}
                >
                  <Icon className={styles.moreCriteriasBtnIcon} icon="criterias" />
                </button>
              </div>
            </div>
          )}
          <FiltersPanel
            isSavedSearchEdition={!!search.searchId}
            modal={modal}
            onClose={() => setIsMoreCriteriasOpen(false)}
            open={isMoreCriteriasOpen}
            resultsLots={filteredLots}
            resultsPrograms={sortedPrograms}
          />
          <div className={styles.panelHeader}>
            <div className={styles.panelHeaderBottom}>
              {isPanelExpanded ? (
                <>
                  {sortDropdown}
                  {listSwitcher}
                  {saveSearchBtn}
                  {searchType === 'lot' && (
                    <LotsListSelectedActions
                      hideButtonsLabel
                      selectedLots={selectedLots}
                      origin={TMS_ORIGIN_SEARCH}
                      {...tmsLots}
                    />
                  )}
                  {!search.searchId && (
                    <button className={styles.showMapBtn} type="button" onClick={handleTogglePanel}>
                      <Icon className={styles.showMapBtnIcon} icon="map-plan" />
                      {LABEL_DISPLAY_MAP}
                    </button>
                  )}
                </>
              ) : (
                <>
                  {sortDropdown}
                  {listSwitcher}
                  {saveSearchBtn}
                  {searchType === 'lot' && (
                    <LotsListSelectedActions
                      hideButtonsLabel
                      selectedLots={selectedLots}
                      origin={TMS_ORIGIN_SEARCH}
                      {...tmsLots}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <div className={styles.panelContent} id="panelContent">
            {search.searchId && <div className={styles.panelContentHideLayer} />}
            {searchType === 'lot' ? (
              <ActivityModalsProvider>
                <LotsListTablette
                  allLots={lots}
                  allPrograms={programs}
                  isLoading={isLotsLoading}
                  lots={filteredLots}
                  lotsSortBy={lotsSortBy}
                  lotsSortOrder={lotsSortOrder}
                  displayAllHeader
                  programs={sortedPrograms}
                  selectedLots={selectedLots}
                  setSelectedLots={setSelectedLots}
                  {...tmsLots}
                />
              </ActivityModalsProvider>
            ) : (
              <ProgramsList
                availableLots={lotNbByPrograms}
                isLoading={isDataValidating || isSortLoading}
                isPanelExpanded={isPanelExpanded}
                programs={sortedPrograms}
                reduceMobilePanel={() => setIsPanelExpanded(false)}
                setIsPanelExpanded={setIsPanelExpanded}
                {...tmsPrograms}
              />
            )}
          </div>
        </div>
        <div className={styles.map}>
          <button className={styles.closeMapBtn} type="button" onClick={handleTogglePanel}>
            <Icon icon="cross" />
          </button>
          {!isPanelExpanded && (
            <div className={classnames(styles.location, styles.onMap)}>
              <Autocomplete
                classes={{
                  root: styles.autocomplete,
                  textFieldRoot: styles.container,
                  inputRoot: styles.input,
                }}
              />
              <button
                type="button"
                className={styles.moreCriteriasBtn}
                onClick={() => setIsMoreCriteriasOpen(true)}
              >
                <Icon className={styles.moreCriteriasBtnIcon} icon="criterias" />
              </button>
            </div>
          )}
          <SearchMap programs={sortedPrograms} taxById={taxesById} />
        </div>
      </div>
    </div>
  );
}

export type SearchLayoutTabletteProps = {
  tmsLots?: Record<string, string>;
  tmsPrograms?: Record<string, string>;
};
