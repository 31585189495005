import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { TMS_ORIGIN_FOLDER, TMS_ORIGIN_PROGRAM, TMS_ORIGIN_SEARCH } from 'settings/tms';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import TagCommander from 'modules/App/TagCommander/TagCommander';
import { ComparisonPageDesktopAndTablet } from './ComparisonPageDesktopAndTablet';
import { ComparisonPageMobile } from './ComparisonPageMobile';

export function ComparisonPage() {
  const { isResponsive } = useContext(ResponsiveContext);
  const location = useLocation();
  const params = qs.parse(location.search, { arrayFormat: 'bracket' });

  let navigationTemplate = 'comparaison';
  switch (params?.origin) {
    case TMS_ORIGIN_FOLDER:
      navigationTemplate = 'comparaison.dossier';
      break;

    case TMS_ORIGIN_PROGRAM:
      navigationTemplate = 'comparaison.programme';
      break;

    case TMS_ORIGIN_SEARCH:
      navigationTemplate = 'comparaison.resultats_lots';
      break;

    default:
      break;
  }

  return (
    <>
      {isResponsive ? <ComparisonPageMobile /> : <ComparisonPageDesktopAndTablet />}
      <TagCommander navigation_template="comparaison" navigation_pagename={navigationTemplate} />
    </>
  );
}
