import React, { useContext } from 'react';

import { userContext } from 'modules/App/Contexts';
import {
  DEFAULT_CONTACT,
  LABEL_PROFILE_NO_CONTACTS,
  LABEL_PROFILE_TAB_INTERLOCUTOR_TITLE,
} from 'settings';

import ContactCard from 'commonUi/ContactCard/ContactCard';
import PanelContainer from 'layout/components/PanelContainer/PanelContainer';

import styles from './AccountContacts.module.scss';

export default function AccountContacts() {
  const { userPrescripteurs } = useContext(userContext);

  if (!userPrescripteurs) {
    return <PanelContainer title={LABEL_PROFILE_TAB_INTERLOCUTOR_TITLE} />;
  }

  if (!userPrescripteurs.interlocuteurs) {
    return (
      <PanelContainer title={LABEL_PROFILE_TAB_INTERLOCUTOR_TITLE}>
        <p className={styles.text}>{LABEL_PROFILE_NO_CONTACTS}</p>
      </PanelContainer>
    );
  }

  return (
    <PanelContainer title={LABEL_PROFILE_TAB_INTERLOCUTOR_TITLE}>
      {[...userPrescripteurs.interlocuteurs, DEFAULT_CONTACT].map(contact => (
        <ContactCard
          key={contact.field_email}
          className={styles.contact}
          email={contact.field_email}
          firstname={contact.field_prenom}
          lastname={contact.field_nom}
          phone={contact.field_tel}
        />
      ))}
    </PanelContainer>
  );
}
