import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import { removeTokenFromStorage } from 'services/authentication';
import { setMainSearchValues } from 'services/storage';
import { MAIN_SEARCH_DEFAULT_PARAMS } from 'settings/search';

import { userContext } from 'modules/App/Contexts/userContext';

export default function Logout() {
  const { setUserToken } = useContext(userContext);

  setUserToken(undefined);
  removeTokenFromStorage();
  localStorage.removeItem('register-step');
  localStorage.removeItem('registered-files');

  setMainSearchValues(MAIN_SEARCH_DEFAULT_PARAMS);

  return <Redirect to="/" />;
}
