import { useCallback, useContext } from 'react';

import {
  SEARCH_CRITERIA_PREVIEW,
  SEARCH_PREVIEW_FILTER_BLACKLIST,
  SEARCH_PREVIEW_OTHERS_BLACKLIST,
  SLIDER_RANGE,
} from 'settings/search';
import { TMS_CATEGORY_PERSONALIZATION, TMS_VENDOR_GOOGLE_MAPS } from 'settings/tms';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { SearchType } from 'modules/HomePage/hooks/useSearch';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import programLotContext from 'modules/App/Contexts/programLotContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { formatLots } from 'services/lotsV2';
import {
  getSearchFilterArray,
  getSearchFilterBounds,
  getSearchFilterDate,
  getSearchFilterInterval,
  getSearchFilterLocations,
  getSearchFilterRangeContains,
  getSearchFilterValue,
} from 'services/search';

import useTms from 'hooks/context/useTms';

function prefilterProgramsWithPromo(programs: ProgramListType[], promoRef?: string) {
  if (promoRef) {
    const programsWithPromo =
      programs?.filter(program =>
        program.promotions?.some(promo => Object.keys(promo).includes(promoRef))
      ) ?? [];

    if (programsWithPromo.length) {
      return programsWithPromo;
    }
  }
  return programs;
}

function filterPrograms(
  programs: ProgramListType[],
  search: Partial<SearchType>,
  filterWithMap = false
) {
  const programIsInBounds = getSearchFilterBounds<ProgramListType>(
    {
      north: Number(search.north),
      east: Number(search.east),
      south: Number(search.south),
      west: Number(search.west),
    },
    x => x
  );
  const filterByLocations = getSearchFilterLocations<ProgramListType>(search, program => ({
    city: program.cleanCity,
    department: program.department,
    region: program.region,
  }));
  const filterByDelivery = getSearchFilterDate<ProgramListType>(
    search.delivery,
    program => program.delivery
  );
  const filterByActability = getSearchFilterDate<ProgramListType>(
    search.actability,
    program => program.actability
  );
  const filterByTaxes = getSearchFilterArray<ProgramListType>(
    search.taxes,
    program => program.taxes,
    'any'
  );

  return programs?.filter(
    program =>
      // Avoid filtering according to the map's bounds unless needed
      (!filterWithMap || programIsInBounds(program)) &&
      filterByDelivery(program) &&
      filterByActability(program) &&
      filterByTaxes(program) &&
      filterByLocations(program)
  );
}

function filterLots(lots: LotJson[] | undefined, search: Partial<SearchType>, taxById: TaxesById) {
  if (!lots?.length) return [];

  const filterBudgetLmnp = getSearchFilterRangeContains<LotJson>(
    { min: Number(search.budgetMin), max: Number(search.budgetMax) },
    lot => lot.customPrice1,
    SLIDER_RANGE.values.budget.max === Number(search.budgetMax)
  );

  const filterBudgetWithTax = getSearchFilterRangeContains<LotJson>(
    { min: Number(search.budgetMin), max: Number(search.budgetMax) },
    lot => lot.priceIncludingTax,
    SLIDER_RANGE.values.budget.max === Number(search.budgetMax)
  );

  const filterBudgetWithoutTax = getSearchFilterRangeContains<LotJson>(
    { min: Number(search.budgetMin), max: Number(search.budgetMax) },
    lot => lot.priceReducedVat,
    SLIDER_RANGE.values.budget.max === Number(search.budgetMax)
  );

  const filterTaxFn = getSearchFilterValue<LotJson>(search.taxes, (lot: LotJson) => lot.tax);
  const filterSurfaceFn = getSearchFilterRangeContains<LotJson>(
    {
      min: search.surfaceMin ? parseInt(search.surfaceMin, 10) : undefined,
      max: search.surfaceMax ? parseInt(search.surfaceMax, 10) : undefined,
    },
    lot => lot.surface,
    search.surfaceMax !== undefined &&
      SLIDER_RANGE.values.surface?.max === parseInt(search.surfaceMax, 10)
  );
  const filterKindsFn = getSearchFilterValue<LotJson>(search.kinds, (lot: LotJson) => lot.kindTid);
  const filterAnnexesFn = getSearchFilterArray<LotJson>(search.annexes, lot => lot.annexes, 'all');
  const filterProfitabilityFn = getSearchFilterInterval<LotJson>(
    {
      min: search.profitabilityMin ? parseInt(search.profitabilityMin, 10) : undefined,
      max: search.profitabilityMax ? parseInt(search.profitabilityMax, 10) : undefined,
    },
    lot => lot.profitability,
    search.profitabilityMax !== undefined &&
      SLIDER_RANGE.values.profitability?.max === parseInt(search.profitabilityMax, 10)
  );

  const filterOthersFn = getSearchFilterArray<LotJson>(search.others, lot => lot.others, 'all');

  return lots?.filter(lot => {
    // Filter by budget :
    // For LMNP, filter on a specific price field
    // For other fiscalities, filter on normal VAT price except if it is 0, then filter on reduced VAT
    // It prevents problems of lots without normal VAT price to be in lots results
    let budgetFilter =
      lot.priceIncludingTax === 0 && lot.priceReducedVat
        ? filterBudgetWithoutTax(lot)
        : filterBudgetWithTax(lot);

    if (taxById && taxById[lot?.tax] === 'LMNP') {
      budgetFilter = filterBudgetLmnp(lot);
    }

    return (
      (search.budgetMin !== undefined && search.budgetMax ? budgetFilter : true) &&
      (search.taxes ? filterTaxFn(lot) : true) &&
      (search.surfaceMin !== undefined && search.surfaceMax ? filterSurfaceFn(lot) : true) &&
      (search.kinds ? filterKindsFn(lot) : true) &&
      (search.annexes ? filterAnnexesFn(lot) : true) &&
      (search.others ? filterOthersFn(lot) : true) &&
      (search.profitabilityMin ? filterProfitabilityFn(lot) : true)
    );
  });
}

/**
 * Only keep programs that have Lots or that are Preview IF the filters don't have blacklisted fields or values
 */
export function filterProgramsWithLots(
  programs: ProgramListType[],
  programRefsFromLots: string[],
  search: Partial<SearchType>
) {
  const searchHasBlacklistedFilter =
    !!search.others?.some(value => SEARCH_PREVIEW_OTHERS_BLACKLIST.includes(value)) ||
    (Object.entries(search) as [keyof typeof search, any][])
      .filter(([, filterValue]) =>
        Array.isArray(filterValue)
          ? filterValue.length
          : ![undefined, null, ''].includes(filterValue)
      )
      .some(([filterName]) => SEARCH_PREVIEW_FILTER_BLACKLIST.includes(filterName));

  return programs.filter(
    program =>
      programRefsFromLots.includes(program.ref) ||
      (!searchHasBlacklistedFilter && program.others.includes(SEARCH_CRITERIA_PREVIEW))
  );
}

export default function useFilterData() {
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  const isMapAllowed = useTms({
    category: TMS_CATEGORY_PERSONALIZATION,
    vendor: TMS_VENDOR_GOOGLE_MAPS,
  });
  const { lots, programs, isLotsValidating, isProgramsValidating } = useContext(programLotContext);

  const isProgramsLoading = !programs && isProgramsValidating;
  const isLotsLoading = !lots && isLotsValidating;

  return {
    filterData: useCallback(
      ({ promoRef, ...search }: Partial<SearchType>) => {
        if (isProgramsLoading || isLotsLoading || isTaxesLoading) {
          return { lots: [], programs: [], lotNbByPrograms: {} };
        }
        const filteredPrograms = filterPrograms(
          prefilterProgramsWithPromo(programs ?? [], promoRef),
          search,
          isMapAllowed
        );
        const programsMapByNid = Object.fromEntries(
          filteredPrograms?.map(program => [program.nid, program])
        );
        const formattedLots = formatLots(lots, programsMapByNid);
        const filteredLots = filterLots(formattedLots, search, taxesById);

        const lotNbByPrograms = filteredLots.reduce<Record<LotJson['ref'], number>>(
          (acc, lot) => ({ ...acc, [lot.ref]: (acc[lot.ref] ?? 0) + 1 }),
          {}
        );

        return {
          programs: filterProgramsWithLots(filteredPrograms, Object.keys(lotNbByPrograms), search),
          lots: filteredLots,
          lotNbByPrograms,
        };
      },
      [programs, lots, taxesById, isMapAllowed, isLotsLoading, isProgramsLoading, isTaxesLoading]
    ),
    lots: lots ?? [],
    programs: programs ?? [],
    isValidating: isProgramsValidating || isLotsValidating || isTaxesLoading,
    isProgramsLoading,
    isLotsLoading,
  };
}
