import React from 'react';
import moment from 'moment';

import { replaceTokens } from 'services/formatter';
import { LABEL_CREATED_WITH_DATE, LABEL_PRINT } from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import iconPrint from 'images/icons/icon_print-white.svg';
import backgroundUrl from 'images/placeholders/1665x937.png';

import { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';
import SubActuality from './SubActuality';
import ActualityDetailLots from './ActualityDetailLots';

import styles from './ActualityDetailItem.module.scss';

function ActualityDetailItem({ actuality }: { actuality: ActualityType }) {
  if (!actuality) {
    return null;
  }

  const { date, introduction: intro, lots, photo, sactu, titre: title } = actuality;

  return (
    <section className={styles.root}>
      <div className={styles.banner}>
        <div className={styles.detailImgWrapper}>
          <img
            className={styles.detailImg}
            src={photo && photo.detail_article ? photo.detail_article : backgroundUrl}
            alt="Vinci"
            title="Vinci"
          />
          <div className={styles.opacity} />
          <h1 className={styles.title}>{title}</h1>
          <button type="button" className={styles.print} onClick={() => window.print()}>
            <img src={iconPrint} alt={LABEL_PRINT} />
          </button>
        </div>
      </div>
      <div className={styles.main}>
        <div className={styles.content}>
          <div className={styles.date}>
            {replaceTokens(LABEL_CREATED_WITH_DATE, {
              [TOKEN_DATE]: moment.unix(date).format('DD/MM/YYYY'),
            })}
          </div>
          <p className={styles.desc}>{intro}</p>
          <hr className={styles.separation} />
          {sactu &&
            Object.entries(sactu).map(([subActuId, subActu]) => (
              <SubActuality key={subActuId} actu={subActu} />
            ))}
          {lots && <ActualityDetailLots lots={lots} />}
        </div>
      </div>
    </section>
  );
}

export default ActualityDetailItem;
