import React, { useContext } from 'react';
import type { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

import SettingsContext from 'modules/App/Contexts/SettingsContext';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

interface KitchenIconProps
  extends Pick<HTMLAttributes<HTMLDivElement>, 'className' | 'style' | 'title'> {
  iconClassName?: string;
  Wrapper?: keyof JSX.IntrinsicElements | null;
}

export default function KitchenIcon({
  className,
  iconClassName,
  Wrapper = 'div',
  ...props
}: KitchenIconProps) {
  const { settings } = useContext(SettingsContext);
  const kitchenUrl = settings?.pages?.cuisine_equipee;

  if (kitchenUrl) {
    return (
      <Link className={className} to={kitchenUrl} target="_blank" {...props}>
        <SvgIcon className={iconClassName} iconId="icon-equipped-kitchen" />
      </Link>
    );
  }

  if (Wrapper) {
    return (
      <Wrapper className={className} {...props}>
        <SvgIcon className={iconClassName} iconId="icon-equipped-kitchen" />
      </Wrapper>
    );
  }

  return <SvgIcon className={iconClassName ?? className} iconId="icon-equipped-kitchen" />;
}
