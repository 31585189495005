import React, { useRef } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { isAuthenticated, isInvite } from 'services/authentication';

import AuthenticatedLayout from 'layout/AuthenticatedLayout';
import DisconnectedHomePage from 'modules/DisconnectedPage/DisconnectedHomePage';
import GuestLayout from 'layout/GuestLayout';
import PendingValidation from 'modules/Authentication/PendingValidation';
import TagCommander from 'modules/App/TagCommander/TagCommander';
import ErrorBoundary from './Tools/ErrorBoundary';

export default function HomeRoute(props: HomeRouteType) {
  const disconnected = useRef<HTMLElement>(null);
  const location = useLocation();
  const params = qs.parse(location.search);

  if (isAuthenticated()) {
    if (isInvite()) {
      return (
        <AuthenticatedLayout hideFooter>
          <ErrorBoundary location={location}>
            <PendingValidation />
          </ErrorBoundary>
        </AuthenticatedLayout>
      );
    }

    return <Redirect to={`/recherche${params?.video === 'open' ? '?video=open' : ''}`} />;
  }

  return (
    <Route {...props}>
      <GuestLayout refDisconnectedHomePage={disconnected}>
        <DisconnectedHomePage ref={disconnected} />
        <TagCommander navigation_pagename="homepage" navigation_template="homepage" />
      </GuestLayout>
    </Route>
  );
}

export type HomeRouteType = Omit<RouteProps, 'path'> & {
  path: RouteProps['path'];
};
