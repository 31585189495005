import React, { useContext, useEffect, useState } from 'react';
import Sticky from 'react-stickynode';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import { Link as AnchorLink } from 'react-scroll';
import classnames from 'classnames';

import { HEADER_DOM_ID, PROGRAM_ANCHORS_DOM_ID, PROGRAM_PROMO_DOM_ID } from 'settings/ui';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import styles from './ProgramMenuAnchors.module.scss';

export interface ProgramMenuAnchorsProps {
  anchors: {
    icon: string;
    iconClassName?: string;
    iconHover: string;
    id: string;
    label: string;
    nb?: number;
    onClick?: () => void;
  }[];
  program: ProgramTypeV2;
  lots: number;
}

export default function ProgramMenuAnchors({ anchors, program, lots }: ProgramMenuAnchorsProps) {
  const [activeTab, setActiveTab] = useState<string>();
  const [scrollOffset, setScrollOffset] = useState<number>(0);
  const [showReturnTopBtn, setShowReturnTopBtn] = useState<boolean>(false);
  const { isResponsive, isTablette } = useContext(ResponsiveContext);

  const { codePostal: postalCode, ville: city } = program?.localisation ?? {};

  function showAnchor(anchorId: string) {
    switch (anchorId) {
      case 'lots':
        return (
          program.promotions.filter(promo => !promo.honoboost).length > 0 ||
          program.paragraphesLibresVi3P.length > 0 ||
          lots > 0
        );

      default:
        return true;
    }
  }

  const handleChange = (event, newTab: string) => {
    setActiveTab(newTab.toLowerCase());
  };

  function handleReturnTop() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  function onScroll() {
    const scroll = document.documentElement.scrollTop || document.body.scrollTop;
    if (scroll > 0 && !showReturnTopBtn) {
      setShowReturnTopBtn(true);
    }
    if (scroll === 0) {
      setShowReturnTopBtn(false);
    }
  }

  useEffect(() => {
    if (!isResponsive) {
      document.addEventListener('scroll', onScroll);
    }
  });

  const headerDOM = document.getElementById(HEADER_DOM_ID);
  const programPromoBanner = document.getElementById(PROGRAM_PROMO_DOM_ID);
  const programMenuAnchors = document.getElementById(PROGRAM_ANCHORS_DOM_ID);

  useEffect(() => {
    if (scrollOffset === 0) {
      setScrollOffset(
        prevState =>
          prevState -
          (programMenuAnchors?.offsetHeight || 0) -
          (programPromoBanner?.offsetHeight || 0)
      );
    }
  });

  return (
    <Sticky
      enabled
      top={(headerDOM?.offsetHeight || 0) + (programPromoBanner?.offsetHeight || 0)}
      innerZ={12}
      className={styles.sticky}
      activeClass={styles.active}
    >
      <div className={styles.menu} id={PROGRAM_ANCHORS_DOM_ID}>
        {!isResponsive && (
          <div className={styles.menuProgramInfos}>
            {city && (
              <span className={styles.menuProgramInfosLocation}>
                {city} {postalCode && <>({postalCode})</>}
              </span>
            )}
            <span>{program.nomCommercial}</span>
          </div>
        )}

        <MuiTabs
          classes={{
            flexContainer: styles.menuAnchorsFlexContainer,
            indicator: styles.menuAnchorsIndicator,
            root: styles.menuAnchors,
            scrollButtons: styles.menuAnchorsScrollButtons,
          }}
          onChange={handleChange}
          scrollButtons="on"
          value={activeTab ?? false}
          variant="scrollable"
        >
          {anchors.map(({ icon, iconClassName, iconHover, id, label, nb, onClick }) =>
            showAnchor(id) ? (
              <AnchorLink
                key={id}
                className={styles.menuAnchorLink}
                smooth
                spy
                to={id}
                offset={scrollOffset}
              >
                <MuiTab
                  classes={{
                    root: styles.menuAnchorsItem,
                  }}
                  disableRipple
                  key={id}
                  icon={
                    <div className={styles.menuAnchorsItemIconWrapper}>
                      <SvgIcon
                        className={classnames(styles.menuAnchorsItemIcon, iconClassName)}
                        iconId={icon}
                      />
                      <SvgIcon
                        className={classnames(styles.menuAnchorsItemIconHover, iconClassName)}
                        iconId={iconHover}
                      />
                    </div>
                  }
                  label={
                    <>
                      {nb && nb > 0 && <span className={styles.menuAnchorsItemNb}>{nb}</span>}
                      <span className={styles.menuAnchorsItemLabel}>{label}</span>
                    </>
                  }
                  value={id}
                  onClick={() => {
                    if (typeof onClick === 'function') {
                      onClick();
                    }
                  }}
                />
              </AnchorLink>
            ) : null
          )}
        </MuiTabs>

        {!isResponsive && !isTablette && showReturnTopBtn && (
          <div className={styles.buttonReturnTopWrapper}>
            <button type="button" className={styles.buttonReturnTop} onClick={handleReturnTop}>
              <Icon className={styles.buttonReturnTopIcon} icon="chevron-down" />
            </button>
          </div>
        )}
      </div>
    </Sticky>
  );
}
