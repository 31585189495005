import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';

import { COOKIE_TEST_MODAL_AUTO_OPEN } from 'settings/cookies';
import {
  LABEL_CANCEL_BUTTON,
  LABEL_DEFAULT_SORT_SAVE_FAIL,
  LABEL_DEFAULT_SORT_SAVE_SUCCESS,
  LABEL_POSITION_MODAL_TITLE,
  LABEL_SAVE,
} from 'settings/labels';
import { MAP_DEFAULT_LAT } from 'settings/search';

import type { ContactPrescriptorType } from 'api/vi3pAPI/apiTypes/ContactPrescriptorType';
import type { Ville } from 'api/viOffresAPI/apiTypes/GeoSearch';

import { userContext } from 'modules/App/Contexts';

import { isTestCookieEnabled } from 'services/cookies';
import { geocode } from 'services/map';
import { getPreferredLocationString, patchContactPrescriptor } from 'services/user';

import AutocompleteCity from 'commonUi/AutocompleteCity/AutocompleteCity';
import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import styles from './ModalPosition.module.scss';

function modalShouldOpen(contactPrescriptor: ContactPrescriptorType | undefined) {
  return (
    !isTestCookieEnabled(COOKIE_TEST_MODAL_AUTO_OPEN) &&
    !!contactPrescriptor &&
    !contactPrescriptor.localisationPreferee
  );
}

interface ModalPositionProps {
  noToast?: boolean;
  onConfirmed?: () => void;
  openSignal?: any;
}

export default function ModalPosition({
  noToast = false,
  onConfirmed = () => {},
  openSignal,
}: ModalPositionProps) {
  const { enqueueSnackbar } = useSnackbar();
  const { contactPrescriptor, userCrm, mutateContactPrescriptor } = useContext(userContext);
  const [isOpen, setIsOpen] = useState(() => modalShouldOpen(contactPrescriptor));
  const [inputLocation, setInputLocation] = useState<Ville>();
  const [loading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>();

  const defaultValue = useMemo(() => {
    if (contactPrescriptor?.localisationPreferee) {
      const [postalCode, city] = contactPrescriptor.localisationPreferee.split(':');
      return {
        libelle_postal_code: postalCode,
        libelle_ville: decodeURIComponent(city),
      } as Ville;
    }
    if (contactPrescriptor?.ville && contactPrescriptor?.codePostal) {
      return {
        libelle_postal_code: contactPrescriptor.codePostal,
        libelle_ville: decodeURIComponent(contactPrescriptor.ville),
      } as Ville;
    }
    return undefined;
  }, [contactPrescriptor]);

  useEffect(() => {
    if (openSignal) {
      setIsOpen(true);
    }
  }, [openSignal]);

  useEffect(() => {
    // Open the modal only if it was previously closed & it should now be open
    setIsOpen(prevState => (prevState ? true : modalShouldOpen(contactPrescriptor)));
  }, [contactPrescriptor]);

  if (!contactPrescriptor) {
    return null;
  }

  function handleCancel() {
    setInputLocation(undefined);
    setIsOpen(false);
  }
  const handleConfirm = async () => {
    setIsLoading(true);
    // If a location has been chosen via the autocomplete, use it, otherwise use the default values
    // coming from the contactPrescriptor and request the latitude & longitude from Google
    const location =
      inputLocation ??
      (await geocode([contactPrescriptor.codePostal, contactPrescriptor.ville].join(' '))
        .then(result => ({
          postal_code: contactPrescriptor.codePostal,
          libelle_ville: contactPrescriptor.ville,
          latitude: result.geometry.location.lat(),
          longitude: result.geometry.location.lng(),
        }))
        .catch(() => ({
          postal_code: contactPrescriptor.codePostal,
          libelle_ville: contactPrescriptor.ville,
          latitude: MAP_DEFAULT_LAT,
          longitude: MAP_DEFAULT_LAT,
        })));
    if (location && userCrm?.extension_VI3P_ContactId) {
      // Optimistic update
      let oldPreferredLocation: string | undefined;
      const newPreferredLocation = getPreferredLocationString(
        location.postal_code,
        location.libelle_ville,
        location.latitude,
        location.longitude
      );
      mutateContactPrescriptor(contactPrescriptor => {
        oldPreferredLocation = contactPrescriptor?.localisationPreferee;
        return contactPrescriptor
          ? { ...contactPrescriptor, localisationPreferee: newPreferredLocation }
          : undefined;
      }, false);
      patchContactPrescriptor(userCrm.extension_VI3P_ContactId, {
        localisationPreferee: newPreferredLocation,
      })
        .then(contactPrescripteur => {
          // Actual update
          mutateContactPrescriptor(contactPrescripteur, false);
          onConfirmed();
          setIsOpen(false);
          setError(undefined);
          if (!noToast) {
            enqueueSnackbar(LABEL_DEFAULT_SORT_SAVE_SUCCESS);
          }
        })
        .catch(() => {
          // Undo optimistic update
          mutateContactPrescriptor(
            {
              ...contactPrescriptor,
              localisationPreferee: oldPreferredLocation,
            },
            false
          );
          setError(LABEL_DEFAULT_SORT_SAVE_FAIL);
        })
        .finally(() =>
          // Delay return to non-loading state until after the popin has entirely closed
          setTimeout(() => setIsLoading(false), 2000)
        );
    }
  };

  return (
    <ModalConfirm
      classes={{
        root: styles.root,
        inner: styles.inner,
        content: styles.content,
        title: styles.title,
        footer: styles.footer,
        buttonConfirm: styles.confirm,
      }}
      colorCancel="secondary"
      labelCancel={LABEL_CANCEL_BUTTON}
      labelConfirm={LABEL_SAVE}
      loading={loading}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
      open={isOpen && !!contactPrescriptor}
      title={
        <>
          <SvgIcon className={styles.icon} iconId="icon-position" />
          {LABEL_POSITION_MODAL_TITLE}
        </>
      }
    >
      <p>
        Définissez le tri qui sera appliqué pour vos résultats de recherche en saisissant votre code
        postal préféré.
      </p>

      <div className={styles.locationContainer}>
        <label htmlFor="popin-position-input" className={styles.label}>
          Visualiser en premier les programmes situés à :
        </label>

        <AutocompleteCity
          id="popin-position-input"
          classes={{ root: styles.location, input: styles.input, inputOpen: styles.open }}
          onChange={(_, location) => setInputLocation(location)}
          value={inputLocation}
          defaultValue={defaultValue}
        />

        {error && <div className={styles.error}>{error}</div>}
      </div>
    </ModalConfirm>
  );
}
