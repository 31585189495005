import React, { useContext, useState } from 'react';
import type { ComponentProps } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import {
  LABEL_ADD_TO_A_FOLDER,
  LABEL_BOOKLET,
  LABEL_CARD_LOT,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_OPTIONATE,
  LABEL_PRE_BOOK,
} from 'settings/labels';
import { LOT_STATUS_FREE, LOT_STATUS_OPTIONED } from 'settings/lots';
import { MODAL_ID_SEND_BOOKLET, MODAL_ID_SEND_CARD_LOT } from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { LotJson, LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';
import type { StatusLabel } from 'api/viOffresAPI/apiTypes/Statuses';

import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import { getLotActionButtons } from 'services/lotsV2';

import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';

import Button from 'commonUi/Button/Button';

import { ModalSendPdf } from 'modules/ActionsProgramsAndLots/ModalSendPdf/ModalSendPdf';
import BookletDropdown from 'commonUi/Dropdowns/BookletDropdown';
import PdfProgramDropdown from 'commonUi/Dropdowns/PdfProgramDropdown';
import PdfLotButtonWithLoadingSystem from 'commonUi/Dropdowns/PdfLotButtonWithLoadingSystem';

import styles from './LotButtons.module.scss';

const lotDropdown = {
  label: LABEL_CARD_LOT,
  icon: 'lot-card' as const,
  id: 'lot-card',
  modalId: MODAL_ID_SEND_CARD_LOT,
};

interface LotButtonsProps {
  isOdd?: boolean;
  lot: Pick<LotJson, 'nid' | 'number' | 'ref' | 'status'> & {
    program: Pick<LotJson['program'], 'name'>;
  };
  lotFromApi: LotTypeV2;
  myOptions?: Activity[];
  myBookings?: Activity[];
  openDropdownTop?: boolean;
  pageTemplate?: string;
  pageTemplateRef?: string;
  program: ProgramTypeV2;
  statuses: ComponentProps<typeof PdfLotButtonWithLoadingSystem>['statuses'];
  updateStatus?: (lotNid: string, status: StatusLabel) => void;
  programPdf?: ComponentProps<typeof PdfProgramDropdown>['programPdf'];
  generateProgramPdf?: () => void;
  openPanel: (panel: string) => void;
  taxById: TaxesById;
}

export function LotButtons({
  allLots,
  allPrograms,
  isOdd = false,
  lot,
  lotFromApi,
  myOptions,
  myBookings,
  openDropdownTop = false,
  pageTemplate,
  pageTemplateRef,
  program,
  statuses,
  updateStatus,
  programPdf,
  generateProgramPdf,
  openPanel,
  taxById,
}: LotButtonsProps & Pick<ComponentProps<typeof PdfProgramDropdown>, 'allLots' | 'allPrograms'>) {
  const { openCancelOption, openCancelPreBooking, openCreateOption } = useContext(
    ActivityModalsContext
  );
  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(program?.referenceProgramme, true);

  const option = myOptions?.find(
    option =>
      option.field_numerolot_ac === lot.number && option.field_referenceprogramme_ac === lot.ref
  );
  const preBooking = myBookings?.find(
    booking =>
      booking.field_numerolot_ac === lot.number && booking.field_referenceprogramme_ac === lot.ref
  );

  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType, setFolderTms } = useContext(
    foldersContext
  );
  const { setPageVars, setComponentPageVars } = useContext(TmsContext);

  function handleAddFolderClick() {
    if (folderSaveType !== 'lot') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('lot');
    setFolderLots([{ lotNumber: lot.number, programRef: lot.ref }]);
    if (pageTemplate && pageTemplateRef) {
      setFolderTms({
        confirmation: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}.${lot.number}_ajoutdossier.confirmation`,
        },
        default: {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}.${lot.number}_ajoutdossier`,
        },
      });
    }
  }

  const {
    showCancelOption,
    showCancelPreBook,
    showMakeOption,
    showMakePrebook,
  } = getLotActionButtons(
    lot ? statuses?.[lot.nid]?.label ?? lot.status : undefined,
    Boolean(option) || Boolean(preBooking)
  );

  const [lotPdf, setLotPdf] = useState<
    ComponentProps<typeof PdfLotButtonWithLoadingSystem>['lotPdf']
  >();
  const [activeButton, setActiveButton] = useState<string>();

  const lotDropdownTms =
    pageTemplate && pageTemplateRef
      ? {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_fichelot`,
        }
      : undefined;

  const programDropdownTms =
    pageTemplate && pageTemplateRef
      ? {
          navigation_template: pageTemplate,
          navigation_pagename: `${pageTemplateRef}_ficheprogramme`,
        }
      : undefined;

  return (
    <div className={classnames(styles.buttons, { [styles.odd]: isOdd })}>
      <div className={styles.buttonsGroup}>
        {showMakeOption && (
          <div className={styles.buttonWrapper}>
            <Button
              data-test-id="lot-buttons--optionate"
              className={styles.button}
              color="primary"
              iconId="icon-option"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() =>
                openCreateOption(
                  {
                    lotNumber: lot.number,
                    programRef: lot.ref,
                    programName: lot.program.name,
                  },
                  () => {
                    if (typeof updateStatus === 'function') {
                      updateStatus(lot.nid, LOT_STATUS_OPTIONED);
                    }
                  }
                )
              }
            >
              {LABEL_OPTIONATE}
            </Button>
          </div>
        )}
        {showCancelOption && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-cancel"
              data-test-id="lot-buttons--cancel-optionate"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() => {
                if (option?.field_dateexpiration_ac) {
                  openCancelOption(
                    {
                      activityId: option.field_idcrm_ac,
                      expirationDate: new Date(option.field_dateexpiration_ac),
                      lotNumber: option.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            >
              {LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION}
            </Button>
          </div>
        )}
        {showMakePrebook && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              data-test-id="lot-buttons--pre-book"
              labelClassName={styles.buttonLabel}
              variant="outlined"
              component={RouterLink}
              to={`/pre-reservation/${lot.ref}/${lot.number}?backUrl=${encodeURIComponent(
                window.location.href
              )}`}
              onClick={() => {
                setPageVars({});
                setComponentPageVars({});
              }}
            >
              {LABEL_PRE_BOOK}
            </Button>
          </div>
        )}
        {showCancelPreBook && (
          <div className={styles.buttonWrapper}>
            <Button
              className={styles.button}
              color="primary"
              iconId="icon-pre-book"
              iconClass={styles.buttonSvgIcon}
              labelClassName={styles.buttonLabel}
              variant="outlined"
              onClick={() => {
                if (preBooking?.field_dateexpiration_ac) {
                  openCancelPreBooking(
                    {
                      activityId: preBooking.field_idcrm_ac,
                      expirationDate: new Date(preBooking.field_dateexpiration_ac),
                      lotNumber: preBooking.field_numerolot_ac,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_FREE);
                      }
                    }
                  );
                }
              }}
            >
              {LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK}
            </Button>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.button}
            color="primary"
            iconId="icon-add-to-folder"
            iconClass={styles.buttonSvgIcon}
            labelClassName={styles.buttonLabel}
            onClick={handleAddFolderClick}
            variant="outlined"
          >
            {LABEL_ADD_TO_A_FOLDER}
          </Button>
        </div>
      </div>

      <div className={styles.buttonsGroup}>
        <div className={styles.buttonWrapper}>
          <BookletDropdown
            bookletUrl={booklet?.url}
            buttonIcon="booklet"
            buttonId="booklet"
            lot={lotFromApi}
            modalId={MODAL_ID_SEND_BOOKLET}
            openModal={openModal}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
          >
            {LABEL_BOOKLET}
          </BookletDropdown>
        </div>
        <div className={styles.buttonWrapper}>
          <PdfProgramDropdown
            allLots={allLots}
            allPrograms={allPrograms}
            generateProgramPdf={generateProgramPdf}
            program={program}
            programPdf={programPdf}
            taxById={taxById}
            tms={programDropdownTms}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <PdfLotButtonWithLoadingSystem
            loadPdf={setLotPdf}
            lot={lotFromApi}
            button={lotDropdown}
            isButtonActive={activeButton === lotDropdown.id}
            lotPdf={lotPdf}
            setActiveButton={setActiveButton}
            openDropdownTop={openDropdownTop}
            openModal={openModal}
            program={program}
            statuses={statuses}
            tms={lotDropdownTms}
          />
        </div>
      </div>

      {open === MODAL_ID_SEND_CARD_LOT && (
        <ModalSendPdf
          closeCallBack={closeModal}
          blobPdf={lotPdf?.blob}
          lot={lotFromApi}
          open
          pdfUrl={lotPdf?.url}
          program={program}
        />
      )}
      {open === MODAL_ID_SEND_BOOKLET && (
        <ModalSendBooklet
          booklet={booklet}
          closeCallBack={closeModal}
          open={open === MODAL_ID_SEND_BOOKLET}
          program={program}
        />
      )}
    </div>
  );
}
