import React, { useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, IconButton } from '@material-ui/core';

import { LABEL_ACCESS_OFFER, LABEL_MY_SEARCH_EMPTY, LABEL_MY_SEARCH_PLURAL } from 'settings/labels';
import { NOTIF_STATUS_UNREAD, NOTIF_TYPE_SAVED_SEARCH } from 'settings/notifications';
import { SEARCH_PANEL_TMS_INFOS } from 'settings/search';
import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import { SavedSearchType } from 'api/vi3pAPI/apiTypes/SavedSearch';

import { modifyQuery } from 'services/url';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';
import { userContext } from 'modules/App/Contexts/userContext';

import Button from 'commonUi/Button/Button';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';
import Drawer, { DRAWER_DIRECTION_RIGHT } from 'commonUi/Drawer/Drawer';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import { axiosVI3PInstance } from 'api/vi3pAPI/axiosInstance';
import { apiData, useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { NotificationType } from 'api/vi3pAPI/apiTypes/NotificationType';

import { NOTIFICATIONS_DELAY } from 'modules/Notifications/NotificationsList';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import SavedSearchItem from './SavedSearchItem';

import styles from './SavedSearchDrawer.module.scss';

interface SavedSearchDrawerProps {
  onClose: () => void;
  open: boolean;
}

export default function SavedSearchDrawer({ onClose, open }: SavedSearchDrawerProps) {
  const history = useHistory();
  const { isResponsive } = useContext(ResponsiveContext);
  const { savedSearch, userCrm } = useContext(userContext);
  const { setEventPageVars } = useContext(TmsContext);
  const [tms, setTms] = useState<Partial<typeof TMS_DEFAULT_VALUES>>(SEARCH_PANEL_TMS_INFOS);

  const { data: allNotifications, mutate } = useSWRVi3p<apiData<NotificationType[]>>(
    { url: userCrm ? `/profils/${userCrm.extension_VI3P_ContactId}/notifications` : undefined },
    { refreshInterval: NOTIFICATIONS_DELAY }
  );

  const notifications = useMemo(() => {
    return (
      allNotifications?.data.filter(
        ({ notification_type, status }) =>
          status === NOTIF_STATUS_UNREAD && notification_type === NOTIF_TYPE_SAVED_SEARCH
      ) ?? []
    );
  }, [allNotifications]);

  async function updateNotifications(searchId: string) {
    const currentSearchNotifications = notifications
      .filter(n => n.target_id === searchId)
      .map(
        notification =>
          ({
            ...notification,
            status: 'lu',
          } as NotificationType)
      );
    if (currentSearchNotifications?.length > 0) {
      const notificationsUpdated = [...notifications, ...currentSearchNotifications];

      mutate({ data: notificationsUpdated }, false);
      await axiosVI3PInstance.patch(
        `profils/${userCrm?.extension_VI3P_ContactId}/notifications`,
        currentSearchNotifications.map(notification => ({
          id: notification.notification_id,
          statut: notification.status,
        }))
      );
      mutate({ data: notificationsUpdated });
    }
  }

  function handleClose() {
    setEventPageVars({});
    onClose?.();
    history.replace(modifyQuery({}, ['panelMySearch']));
  }

  const searchItemsList = savedSearch?.map(search => (
    <SavedSearchItem
      key={search.id}
      className={styles.searchItem}
      handleClose={handleClose}
      notifications={notifications}
      search={search as SavedSearchType}
      setTms={setTms}
      tms={tms}
      updateNotifications={updateNotifications}
    />
  ));

  return (
    <Drawer open={open} openDirection={DRAWER_DIRECTION_RIGHT} onClose={handleClose}>
      {open && (
        <div className={styles.panelContent}>
          <div className={styles.header}>
            <div className={styles.title}>
              <SvgIcon className={styles.svgIcon} iconId="icon-my-research" />
              <span>{LABEL_MY_SEARCH_PLURAL}</span>
            </div>
            <IconButton classes={{ root: styles.close }} onClick={handleClose}>
              <Icon icon="cross" />
            </IconButton>
          </div>
          {!savedSearch && (
            <div className={styles.loading}>
              <CircularProgress variant="indeterminate" size={42} />
            </div>
          )}
          {savedSearch?.length === 0 && (
            <div className={styles.noResults}>
              <p>{LABEL_MY_SEARCH_EMPTY}</p>
              <Button
                className={styles.noResultsLink}
                labelClassName={styles.noResultsLinkLabel}
                href="/"
                variant="text"
              >
                {LABEL_ACCESS_OFFER}
              </Button>
            </div>
          )}

          {!!savedSearch && savedSearch.length > 0 && (
            <div className={styles.content}>
              {isResponsive ? (
                searchItemsList
              ) : (
                <CustomScrollbar className={styles.scroll}>
                  <div className={styles.scrollContent}>{searchItemsList}</div>
                </CustomScrollbar>
              )}
            </div>
          )}

          <TagCommanderEvent isActive={open} {...tms} useEffectDeps={Object.keys(tms)} />
        </div>
      )}
    </Drawer>
  );
}
