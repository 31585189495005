import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import { ResponsiveContext } from 'modules/App/Contexts';

import { Icons } from 'sharedModulesV4/common/components/Atoms/Icon';

import { Color } from 'types/ui';

import AccordionMediaItem from './AccordionMediaItem';
import AccordionMediaVisual from './AccordionMediaVisual';

import styles from './AccordionMedia.module.scss';

interface AccordionMediaProps {
  buttonsIcon?: Icons;
  classes?: {
    root?: string;
    itemsRoot?: string;
    itemsButton?: string;
  };
  colors?: {
    text: Color;
    separator: Color;
  };
  items: ComponentProps<typeof AccordionMediaItem>['item'][];
  openFirst?: boolean;
}

export default function AccordionMedia({
  buttonsIcon,
  classes = {},
  colors,
  items,
  openFirst = false,
}: AccordionMediaProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const containerRef = useRef<HTMLDivElement>(null);
  const [openItem, setOpenItem] = useState(openFirst ? items?.[0]?.uuid : undefined);

  const openMedia = useMemo(() => {
    return items.find(({ uuid }) => uuid === openItem)?.medias ?? undefined;
  }, [items, openItem]);
  const handleOpenItem = useCallback(
    newOpenItem => {
      setOpenItem(newOpenItem);
    },
    [isResponsive]
  );

  return (
    <div className={classnames(classes?.root, styles.root)}>
      <div className={styles.items} ref={containerRef}>
        {items.map(item => (
          <AccordionMediaItem
            key={item.uuid}
            buttonIcon={buttonsIcon}
            classes={{
              root: classes?.itemsRoot,
              button: classes?.itemsButton,
            }}
            colors={colors}
            item={item}
            open={openItem === item.uuid}
            onOpen={handleOpenItem}
            showMedias={isResponsive}
          />
        ))}
      </div>

      {!isResponsive && <AccordionMediaVisual className={styles.medias} medias={openMedia} />}
    </div>
  );
}
