import { useContext } from 'react';
import useSWR from 'swr';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';

import { userContext } from 'modules/App/Contexts';

import { axiosViOffreInstance } from 'api/viOffresAPI/axiosInstance';

import useAttributionProgram from './useAttributionProgram';

export default function useLoadProgramAndLot(
  programRef: string | undefined,
  lotNumber: string | undefined
) {
  const { exclusionList } = useContext(userContext);
  const { program, isValidating: isProgramValidating, error: errorProgram } = useAttributionProgram(
    programRef
  );
  const isProgramLoading = !!programRef && !program && isProgramValidating;
  const { data: lot, isValidating: isLotValidating, error: errorLot } = useSWR(
    lotNumber &&
      programRef &&
      exclusionList.every(
        ([excludedRef, excludedNumber]) =>
          excludedRef !== programRef || excludedNumber !== lotNumber
      )
      ? `/programmes/${programRef}/lots/${lotNumber}?site=vi3p`
      : null,
    url => axiosViOffreInstance.get<LotTypeV2>(url).then(response => response.data),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    }
  );
  const isLotLoading = !!lotNumber && !lot && isLotValidating;

  return {
    isError: !!errorLot || !!errorProgram,
    isLoading: isLotLoading || isProgramLoading,
    isLotLoading,
    isProgramLoading,
    lot,
    program,
  };
}
