import React, { useContext } from 'react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { IconButton, Menu as MuiMenu } from '@material-ui/core';
import {
  LABEL_ACTUALITY,
  LABEL_FOLDERS_MY_FOLDERS,
  LABEL_MY_ACTIVITY,
  LABEL_MY_SEARCH_PLURAL,
  LABEL_NEWSLETTER_TOOLTIP,
  LABEL_SEARCH,
} from 'settings/labels';
import { NOTIF_TYPE_SAVED_SEARCH } from 'settings/notifications';

import { PANEL_ID_FOLDERS, PANEL_ID_NEWSLETTER, PANEL_ID_SEARCH } from 'settings/panels';
import { foldersContext } from 'modules/App/Contexts/foldersContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import { userContext } from 'modules/App/Contexts/userContext';
import NotificationDot from 'commonUi/NotificationDot/NotificationDot';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';

import Tooltip from 'commonUi/Tooltip/Tooltip';
import MyAccountMenu from './MyAccountMenu';

import UnreadNotificationsPastille from './UnreadNotificationsPastille';
import styles from '../HeaderAuthenticated.module.scss';

interface DesktopToolbarProps {
  handleToolbarIcon: (iconId: string) => void;
  panelOpen: string;
  pathname: string;
  toolbarLinks: { href: string; label: string }[];
  toolbarIconsDesktop: {
    icon: ReactNode;
    id: string;
    label: string;
  }[];
  openPanel: (panelId: string) => void;
  openedPanel?: string;
}

export default function DesktopToolbar({
  handleToolbarIcon,
  panelOpen,
  pathname,
  toolbarLinks,
  toolbarIconsDesktop,
  openPanel,
  openedPanel,
}: DesktopToolbarProps) {
  const history = useHistory();
  const { unreadNewsletters } = useContext(userContext);
  const { emptyDatas } = useContext(foldersContext);
  const { setPageVars, setComponentPageVars } = useContext(TmsContext);

  function handleOpenFoldersPanel() {
    openPanel(PANEL_ID_FOLDERS);
    emptyDatas();
  }

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();
  const open = Boolean(anchorEl);

  const getIcon = (toolbarLinkLabel: string) => {
    switch (toolbarLinkLabel) {
      case LABEL_SEARCH:
        return 'icon-search-v2';

      case LABEL_MY_ACTIVITY:
        return 'icon-activity';

      case LABEL_ACTUALITY:
        return 'icon-actuality';

      default:
        return '';
    }
  };

  return (
    <div className={styles.actions}>
      {toolbarLinks.map(toolbarLink => (
        <div className={styles.actionBtnWrapper} key={toolbarLink.label}>
          <Tooltip arrow title={toolbarLink.label}>
            <IconButton
              classes={{
                label: styles.iconButtonLabel,
                root: styles.iconButtonRoot,
              }}
              className={classNames({ [styles.panelOpen]: pathname.includes(toolbarLink.href) })}
              disableRipple
              disableFocusRipple
              onClick={() => {
                setPageVars({});
                setComponentPageVars({});
                history.push(toolbarLink.href);
              }}
            >
              <SvgIcon iconId={getIcon(toolbarLink.label)} />
            </IconButton>
          </Tooltip>
        </div>
      ))}
      <div className={classNames(styles.actionBtnWrapper, styles.actionBtnSearch)}>
        <Tooltip arrow title={LABEL_NEWSLETTER_TOOLTIP}>
          <IconButton
            classes={{
              label: styles.iconButtonLabel,
              root: styles.iconButtonRoot,
            }}
            className={classNames({ [styles.panelOpen]: openedPanel === PANEL_ID_NEWSLETTER })}
            disableRipple
            disableFocusRipple
            onClick={() => openPanel(PANEL_ID_NEWSLETTER)}
          >
            <SvgIcon iconId="icon-receipt-bicolor" />
            <NotificationDot className={styles.actionBtnNotifs} amount={unreadNewsletters} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title={LABEL_MY_SEARCH_PLURAL}>
          <IconButton
            classes={{
              label: styles.iconButtonLabel,
              root: styles.iconButtonRoot,
            }}
            className={classNames({
              [styles.panelOpen]: openedPanel === PANEL_ID_SEARCH,
            })}
            disableRipple
            disableFocusRipple
            onClick={() => openPanel(PANEL_ID_SEARCH)}
          >
            <SvgIcon iconId="icon-my-research" />
            <UnreadNotificationsPastille
              className={styles.actionBtnNotifs}
              types={[NOTIF_TYPE_SAVED_SEARCH]}
            />
          </IconButton>
        </Tooltip>
      </div>
      <div className={styles.actionBtnWrapper}>
        <Tooltip arrow title={LABEL_FOLDERS_MY_FOLDERS}>
          <IconButton
            classes={{
              label: styles.iconButtonLabel,
              root: styles.iconButtonRoot,
            }}
            className={classNames({
              [styles.panelOpen]: openedPanel === PANEL_ID_FOLDERS,
            })}
            disableRipple
            disableFocusRipple
            onClick={handleOpenFoldersPanel}
          >
            <SvgIcon iconId="icon-my-folders" />
          </IconButton>
        </Tooltip>
      </div>
      {toolbarIconsDesktop.map(toolbarIcon => (
        <div key={`toolbarIcon-${toolbarIcon.label}`} className={styles.actionBtnWrapper}>
          {toolbarIcon.id === 'myAccount' && (
            <>
              <div>
                <Tooltip arrow title={toolbarIcon.label}>
                  <IconButton
                    classes={{
                      label: styles.iconButtonLabel,
                      root: styles.iconButtonRoot,
                    }}
                    className={classNames({
                      [styles.panelOpen]: panelOpen === toolbarIcon.id,
                    })}
                    disableRipple
                    disableFocusRipple
                    id="account-menu-button"
                    aria-controls="account-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={ev => {
                      handleToolbarIcon(toolbarIcon.id);
                      setAnchorEl(ev.currentTarget);
                    }}
                  >
                    {toolbarIcon.icon}
                  </IconButton>
                </Tooltip>
                <MuiMenu
                  id="account-menu"
                  anchorEl={anchorEl}
                  classes={{ paper: styles.menuPaper, list: styles.menuList }}
                  getContentAnchorEl={null}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={open}
                  onClose={() => setAnchorEl(undefined)}
                  MenuListProps={{
                    'aria-labelledby': 'account-menu-button',
                  }}
                >
                  <MyAccountMenu />
                </MuiMenu>
              </div>
            </>
          )}
          {toolbarIcon.id !== 'myAccount' && (
            <Tooltip arrow title={toolbarIcon.label}>
              <IconButton
                classes={{
                  label: styles.iconButtonLabel,
                  root: styles.iconButtonRoot,
                }}
                className={classNames({
                  [styles.panelOpen]: panelOpen === toolbarIcon.id,
                })}
                disableRipple
                disableFocusRipple
                onClick={() => handleToolbarIcon(toolbarIcon.id)}
              >
                {toolbarIcon.icon}
                {toolbarIcon.label === 'Mes notifications' && (
                  <UnreadNotificationsPastille
                    className={styles.actionBtnNotifs}
                    excludeTypes={[NOTIF_TYPE_SAVED_SEARCH]}
                  />
                )}
              </IconButton>
            </Tooltip>
          )}
        </div>
      ))}
    </div>
  );
}
