import React, { useContext } from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';
import { Slide as MuiSlide } from '@material-ui/core';

import Toast from 'commonUi/Toast/Toast';

import { ResponsiveContext } from '../Contexts';

import styles from './SnackbarProvider.module.scss';

export default function SnackbarProvider({ children }: SnackbarProviderType) {
  const { isResponsive } = useContext(ResponsiveContext);

  return (
    <NotistackProvider
      hideIconVariant
      autoHideDuration={3000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      dense={isResponsive}
      TransitionComponent={MuiSlide}
      content={(key, message) => <Toast id={key} message={message} />}
      classes={{ containerRoot: styles.root }}
    >
      {children}
    </NotistackProvider>
  );
}

export type SnackbarProviderType = {
  children?: JSX.Element;
};
