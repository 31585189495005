import React, { useState } from 'react';
import type { ComponentProps } from 'react';
import ReactPDF from '@react-pdf/renderer';

import { LABEL_CARD_PROGRAM } from 'settings/labels';
import { MODAL_ID_SEND_CARD_PROGRAM } from 'settings/modal';
import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { useModalMultiples } from 'hooks/useModal';

import PdfButtonUI from 'commonUi/Dropdowns/PdfButtonUI';
import ProgramPdfDropdownWithLoading from 'commonUi/Dropdowns/ProgramPdfDropdownWithLoading';
import { ModalSendPdf } from 'modules/ActionsProgramsAndLots/ModalSendPdf/ModalSendPdf';

interface PdfProgramDropdownProps {
  allLots?: ComponentProps<typeof ProgramPdfDropdownWithLoading>['allLots'];
  allPrograms?: ComponentProps<typeof ProgramPdfDropdownWithLoading>['allPrograms'];
  generateProgramPdf?: ComponentProps<typeof ProgramPdfDropdownWithLoading>['generateProgramPdf'];
  program: ProgramTypeV2;
  programPdf?: ComponentProps<typeof ProgramPdfDropdownWithLoading>['programPdf'];
  taxById: TaxesById;
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
  waitForPdfDatas?: boolean;
}

export default function PdfProgramDropdown({
  allLots,
  allPrograms,
  generateProgramPdf,
  program,
  programPdf,
  taxById,
  tms,
  waitForPdfDatas = false,
}: PdfProgramDropdownProps) {
  const [programPdfLoadedOnClick, loadProgramPdfOnClick] = useState<ReactPDF.UsePDFInstance>();
  const [activeButton, setActiveButton] = useState<string>();
  const { open, openModal, closeModal } = useModalMultiples();

  const programDropdown = {
    label: LABEL_CARD_PROGRAM,
    icon: 'program-card' as const,
    id: 'program-card',
    modalId: MODAL_ID_SEND_CARD_PROGRAM,
  };

  return (
    <>
      {programPdf ? (
        <PdfButtonUI
          button={programDropdown}
          isButtonActive={activeButton === programDropdown.id}
          url={programPdf.url}
          setActiveButton={setActiveButton}
          openModal={openModal}
          tms={tms}
        />
      ) : (
        <ProgramPdfDropdownWithLoading
          allLots={allLots}
          allPrograms={allPrograms}
          button={programDropdown}
          isButtonActive={activeButton === programDropdown.id}
          generateProgramPdf={generateProgramPdf}
          loadPdf={loadProgramPdfOnClick}
          openModal={openModal}
          program={program}
          programPdf={programPdfLoadedOnClick}
          setActiveButton={setActiveButton}
          taxById={taxById}
          tms={tms}
          waitForPdfDatas={waitForPdfDatas}
        />
      )}

      {open === MODAL_ID_SEND_CARD_PROGRAM && (
        <ModalSendPdf
          closeCallBack={closeModal}
          blobPdf={programPdf?.blob || programPdfLoadedOnClick?.blob}
          open
          pdfUrl={programPdf?.url || programPdfLoadedOnClick?.url}
          program={program}
        />
      )}
    </>
  );
}
