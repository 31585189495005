import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import memoize from 'memoizee';
import classnames from 'classnames';

import { LOT_JSON_NUMBER, LOT_JSON_PRICE_INCLUDING_TAX, LOT_JSON_PROGRAM_REF } from 'settings/lots';

import programLotContext from 'modules/App/Contexts/programLotContext';

import { formatPrice } from 'services/formatter';

import { getLot, getProgramByRef } from 'api/viOffresAPI/apiClient';

import styles from './ActualityDetailLots.module.scss';

interface Lot {
  number: string;
  programRef: string;
  priceIncludingTax: number | string | null;
  program: {
    city: string;
    name: string;
    postalCode: string;
  };
}

const memoGetProgram = memoize(getProgramByRef);
const memoGetLot = memoize(getLot);

interface ActualityDetailLotsProps {
  className?: string;
  lots: string;
}

export default function ActualityDetailLots({
  className,
  lots: propsLots,
}: ActualityDetailLotsProps) {
  const { lots, programs } = useContext(programLotContext);
  const [stateLots, setStateLots] = useState<Lot[]>([]);

  useEffect(() => {
    if (!propsLots || !lots || !programs) {
      return;
    }
    const lotIds = propsLots
      .replace(/[[\]\s]/g, '')
      .split(';')
      .map(str => (str.split(',') as unknown) as [string, string]);

    Promise.allSettled(
      lotIds.map<Promise<Lot>>(async ([programRef, lotNumber]) => {
        const lot = lots.find(
          lot =>
            lot[LOT_JSON_PROGRAM_REF].toLowerCase() === programRef.toLowerCase() &&
            lot[LOT_JSON_NUMBER].toLowerCase() === lotNumber.toLowerCase()
        );

        if (lot) {
          const program = programs.find(
            program => program.ref.toLowerCase() === programRef.toLowerCase()
          );
          if (program) {
            return {
              number: lot[LOT_JSON_NUMBER],
              programRef: lot[LOT_JSON_PROGRAM_REF],
              priceIncludingTax: lot[LOT_JSON_PRICE_INCLUDING_TAX],
              program: {
                city: program.city,
                name: program.name,
                postalCode: program.postalCode,
              },
            };
          }
        }

        const [programData, lotData] = await Promise.all([
          memoGetProgram(programRef),
          memoGetLot(programRef, lotNumber),
        ]);
        return {
          number: lotData.reference,
          programRef: lotData.referenceProgramme,
          priceIncludingTax: lotData.prix.TVANormale.prixTTC,
          program: {
            city: programData.localisation.ville,
            name: programData.nomCommercial,
            postalCode: programData.localisation.codePostal.toString(),
          },
        };
      })
    ).then(lots => {
      setStateLots(
        lots
          .filter(lot => lot.status === 'fulfilled')
          .map((lot: PromiseFulfilledResult<Lot>) => lot.value)
      );
    });
  }, [propsLots, lots, programs]);

  if (!stateLots.length) {
    return null;
  }

  return (
    <table className={classnames(className, styles.root)}>
      <thead>
        <tr>
          <th>Dpt - ville</th>
          <th>N° Lot</th>
          <th>Prix immo. TTC</th>
        </tr>
      </thead>
      <tbody>
        {stateLots.map(lot => (
          <tr key={`${lot.programRef}-${lot.number}`}>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {lot.program.name} - {lot.program.postalCode} {lot.program.city}
              </NavLink>
            </td>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {lot.number}
              </NavLink>
            </td>
            <td>
              <NavLink to={`/programme/${lot.programRef}/lot/${lot.number}/description`}>
                {formatPrice(lot.priceIncludingTax ?? undefined)}
              </NavLink>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
