import React from 'react';
import type { ComponentProps, PropsWithChildren } from 'react';

import Footer from './components/Footer';
import HeadElement from './components/HeadElement';
import HeaderGuest from './components/HeaderGuest';

export interface GuestLayoutProps {
  refDisconnectedHomePage: ComponentProps<typeof HeaderGuest>['refDisconnectedHomePage'];
}

export default function GuestLayout({
  children,
  refDisconnectedHomePage,
}: PropsWithChildren<GuestLayoutProps>) {
  return (
    <>
      <HeadElement />
      <HeaderGuest refDisconnectedHomePage={refDisconnectedHomePage} />
      <div className="page-layout">
        <main role="main">{children}</main>
      </div>
      <Footer />
    </>
  );
}
