import React, { useContext } from 'react';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import programLotContext from 'modules/App/Contexts/programLotContext';
import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import { ProgramCard } from 'commonUi/ProgramCard/ProgramCard';
import { CustomScrollbar } from 'commonUi/CustomScrollbar/CustomScrollbar';

import styles from './ListAddItems.module.scss';

export function ProgramsList() {
  const { folderPrograms } = useContext(foldersContext);
  const { isResponsive } = useContext(ResponsiveContext);
  const { programs } = useContext(programLotContext);

  if (folderPrograms.length === 0) {
    return null;
  }

  const programsCardsHtml = folderPrograms.map(item => (
    <div className={styles.card} key={item.programRef}>
      <ProgramCard program={programs?.find(p => p.ref === item.programRef)} />
    </div>
  ));

  return (
    <div>
      {isResponsive ? programsCardsHtml : <CustomScrollbar>{programsCardsHtml}</CustomScrollbar>}
    </div>
  );
}
