import { useContext, useEffect, useMemo, useState } from 'react';

import {
  MAP_DEFAULT_LAT,
  MAP_DEFAULT_LNG,
  SORT_ORDER_ASC,
  SORT_PROGRAM_BY_DELIVERY,
  SORT_PROGRAM_BY_POSITION,
  SORT_PROGRAM_BY_SEARCH,
  SORT_PROGRAM_BY_TAX,
} from 'settings/search';

import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import { userContext } from 'modules/App/Contexts';

import { error } from 'services/log';
import { geocode, isGeocoderReady } from 'services/map';
import { programIsNew, programIsPreview } from 'services/programs';

import useDistancesFromPoint from 'hooks/useDistancesFromPoint';
import { useSearch } from 'modules/HomePage/hooks/useSearch';

function sortPrograms(
  programs: ProgramListType[],
  sortBy: string,
  sortOrder: string,
  taxesById?: TaxesById,
  distancesFromMapCenter?: Map<string, number>,
  distancesFromPosition?: Map<string, number>
) {
  /**
   * Pull `Preview` & `New` programs to the top of the list, in that order. Then, they're sorted normally among themselves
   */
  function separatePreviewAndNew(generalSort: (a: ProgramListType, b: ProgramListType) => number) {
    return Array.from(programs).sort((a, b) => {
      if (programIsPreview(a) && programIsPreview(b)) {
        return generalSort(a, b);
      }
      if (programIsPreview(a)) {
        return -1;
      }
      if (programIsPreview(b)) {
        return 1;
      }
      if (programIsNew(a) && programIsNew(b)) {
        return generalSort(a, b);
      }
      if (programIsNew(a)) {
        return -1;
      }
      if (programIsNew(b)) {
        return 1;
      }
      return generalSort(a, b);
    });
  }

  if (!programs?.length) {
    return [];
  }

  const sign = sortOrder === SORT_ORDER_ASC ? 1 : -1;
  switch (sortBy) {
    case SORT_PROGRAM_BY_DELIVERY:
      return separatePreviewAndNew((a, b) => {
        if (!a.delivery && !b.delivery) return 0;
        // If only A or only B has no delivery date, send it to the end of the array
        if (!a.delivery) return 1;
        if (!b.delivery) return -1;
        return sign * (a.delivery.getTime() - b.delivery.getTime());
      });

    case SORT_PROGRAM_BY_TAX:
      if (!taxesById) {
        break;
      }
      return separatePreviewAndNew((a, b) => {
        const taxIds = Object.keys(taxesById);
        if (!taxIds.includes(a.taxes[0]) && !taxIds.includes(b.taxes[0])) return 0;
        // If only A or only B has no valid tax type, send it to the end of the array
        if (!taxIds.includes(a.taxes[0])) return 1;
        if (!taxIds.includes(a.taxes[0])) return -1;
        return sign * taxesById[a.taxes[0]].localeCompare(taxesById[b.taxes[0]]);
      });

    case SORT_PROGRAM_BY_POSITION: {
      const distances = distancesFromPosition ?? distancesFromMapCenter;
      return separatePreviewAndNew((a, b) => {
        const distanceA = distances?.get(a.ref);
        const distanceB = distances?.get(b.ref);
        if (distanceA !== undefined && distanceB !== undefined) {
          return distanceA - distanceB;
        }
        if (distanceA === undefined) {
          return 1;
        }
        return -1;
      });
    }

    case SORT_PROGRAM_BY_SEARCH: {
      return separatePreviewAndNew((a, b) => {
        const distanceA = distancesFromMapCenter?.get(a.ref);
        const distanceB = distancesFromMapCenter?.get(b.ref);
        if (distanceA !== undefined && distanceB !== undefined) {
          return distanceA - distanceB;
        }
        if (distanceA === undefined) {
          return 1;
        }
        return -1;
      });
    }

    default:
      break;
  }

  return separatePreviewAndNew((a, b) => {
    if (!a.city && !b.city) return 0;
    // If only A or only B has no city, send it to the end of the array
    if (!a.city) return 1;
    if (!b.city) return -1;
    return sign * a.city.toLocaleLowerCase().localeCompare(b.city.toLocaleLowerCase());
  });
}
function isSortLoading(
  sortBy: string,
  isTaxesLoading: boolean,
  isContactPrescriptorLoading: boolean
) {
  switch (sortBy) {
    case SORT_PROGRAM_BY_TAX:
      return isTaxesLoading;

    case SORT_PROGRAM_BY_POSITION:
      return isContactPrescriptorLoading;

    default:
      return false;
  }
}

export function useSortedPrograms(programs: ProgramListType[], sortBy: string, sortOrder: string) {
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  const { contactPrescriptor, isContactPrescriptorLoading } = useContext(userContext);
  const [preferredLocationCoords, setPreferredLocationCoords] = useState<
    { address: string } & Parameters<typeof useDistancesFromPoint>[1]
  >();
  const { lat: centerLat, lng: centerLng } = useSearch();
  const parsedLat = centerLat !== undefined ? parseFloat(centerLat) : undefined;
  const parsedLng = centerLng !== undefined ? parseFloat(centerLng) : undefined;

  const programLocations = useMemo(
    () => programs.map(program => ({ id: program.ref, lat: program.lat, lng: program.lng })),
    [programs]
  );
  const distancesFromMapCenter = useDistancesFromPoint(programLocations, {
    lat: parsedLat ?? MAP_DEFAULT_LAT,
    lng: parsedLng ?? MAP_DEFAULT_LNG,
  });
  const distancesFromPreferredLocation = useDistancesFromPoint(
    programLocations,
    preferredLocationCoords
  );

  useEffect(() => {
    async function getCoords(addressQuery: string) {
      // if (!preferredLocationCoords || addressQuery !== preferredLocationCoords.address) {
      try {
        const result = await geocode(addressQuery);
        return {
          address: addressQuery,
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        };
      } catch (e) {
        error('geoloc', `Unable to geoloc query "${addressQuery}"`);
        return {
          address: addressQuery,
          lat: MAP_DEFAULT_LAT,
          lng: MAP_DEFAULT_LNG,
        };
      }
    }

    // If we don't already have the preferred location's coordinates, retrieve them from the contact
    // prescriptor if it has them. If not, fetch them from the postal code and/or the city name
    if (contactPrescriptor?.localisationPreferee) {
      const [postalCode, city, latStr, lngStr] = contactPrescriptor.localisationPreferee.split(':');
      const lat = parseFloat(latStr);
      const lng = parseFloat(lngStr);
      if (
        !Number.isNaN(lat) &&
        !Number.isNaN(lng) &&
        (preferredLocationCoords?.lat !== lat || preferredLocationCoords?.lng !== lng)
      ) {
        setPreferredLocationCoords({
          address: [postalCode, city].filter(Boolean).join(' '),
          lat,
          lng,
        });
      } else {
        const addressQuery = [postalCode, city].filter(Boolean).join(' ');
        if (
          isGeocoderReady() &&
          addressQuery &&
          (!preferredLocationCoords || addressQuery !== preferredLocationCoords.address)
        ) {
          getCoords(addressQuery).then(setPreferredLocationCoords);
        }
      }
    } else if (contactPrescriptor?.codePostal || contactPrescriptor?.ville) {
      const addressQuery = [contactPrescriptor?.codePostal, contactPrescriptor?.ville]
        .filter(Boolean)
        .join(' ');
      if (
        isGeocoderReady() &&
        addressQuery &&
        (!preferredLocationCoords || addressQuery !== preferredLocationCoords.address)
      ) {
        getCoords(addressQuery).then(setPreferredLocationCoords);
      }
    }
  }, [contactPrescriptor, isGeocoderReady()]);

  const isLoading = isSortLoading(sortBy, isTaxesLoading, isContactPrescriptorLoading);
  return {
    programs: useMemo(
      () =>
        !isLoading
          ? sortPrograms(
              programs,
              sortBy,
              sortOrder,
              taxesById,
              distancesFromMapCenter,
              distancesFromPreferredLocation
            )
          : [],
      [
        programs,
        sortOrder,
        sortBy,
        taxesById,
        distancesFromMapCenter,
        distancesFromPreferredLocation,
        isLoading,
      ]
    ),
    isLoading,
  };
}
