import React from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';

import { formatActivityDate } from 'services/date';
import { replaceTokens } from 'services/formatter';
import { PASTILLE_STATUS_COLOR } from 'services/pastille';
import {
  ACTIVITY_STATUS_ACTIVE,
  ACTIVITY_STATUS_CANCELED,
  ACTIVITY_STATUS_LOST,
  ACTIVITY_STATUS_WON,
  ACTIVITY_TAB_ID_BOOKINGS,
  ACTIVITY_TAB_ID_PREBOOKINGS,
  ACTIVITY_TAB_ID_SALES,
  ACTIVITY_TYPE_BOOKING,
  ACTIVITY_TYPE_OPTION,
  ACTIVITY_TYPE_PRE_BOOKING,
  ACTIVITY_TYPE_SALE,
} from 'settings/activity';
import {
  LABEL_DATE,
  LABEL_DATE_EXPIRATION,
  LABEL_SEE_BOOKING,
  LABEL_SEE_PRE_BOOKING,
  LABEL_SEE_SALE,
} from 'settings/labels';
import { TOKEN_DATE } from 'settings/token';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';

import { modifyQuery } from 'services/url';

import ActivityStep from 'commonUi/ActivityStep/ActivityStep';
import { Pastille } from 'commonUi/Pastille/Pastille';

import styles from './ActivityStatus.module.scss';

interface ActivityStatusType {
  activity: Activity;
  changeTab?: (newTab: string) => void;
  className?: string;
  displayColumn?: boolean;
  hover?: boolean;
  showSteps?: boolean;
  showStepsForWon?: boolean;
  showSubtitle?: boolean;
}

export default function ActivityStatus({
  activity,
  changeTab,
  className = undefined,
  displayColumn = false,
  hover = false,
  showSteps = false,
  showStepsForWon = false,
  showSubtitle = true,
}: ActivityStatusType) {
  const history = useHistory();
  let subtitle = '';
  if (activity.field_dateexpiration_ac) {
    const date = formatActivityDate(activity.field_dateexpiration_ac, 'DD/MM/YYYY - HH:mm');
    let text = '';
    if (activity.field_statutnom_ac === ACTIVITY_STATUS_CANCELED) {
      text = LABEL_DATE;
    } else if (
      activity.field_statutnom_ac === ACTIVITY_STATUS_LOST ||
      activity.field_statutnom_ac === ACTIVITY_STATUS_ACTIVE
    ) {
      text = LABEL_DATE_EXPIRATION;
    }
    subtitle = date && replaceTokens(text, { [TOKEN_DATE]: date });
  }

  if (activity.field_statutnom_ac === ACTIVITY_STATUS_WON) {
    const handleClick = (ev: React.MouseEvent) => {
      if (typeof changeTab === 'function') {
        history.replace(modifyQuery({}, ['activityId']));
        ev.stopPropagation();
        if (activity.field_type_ac === ACTIVITY_TYPE_OPTION) {
          changeTab(ACTIVITY_TAB_ID_PREBOOKINGS);
        } else if (activity.field_type_ac === ACTIVITY_TYPE_PRE_BOOKING) {
          changeTab(ACTIVITY_TAB_ID_BOOKINGS);
        } else if (activity.field_type_ac === ACTIVITY_TYPE_BOOKING) {
          changeTab(ACTIVITY_TAB_ID_SALES);
        }
      }
    };
    let tooltip: string | undefined;
    if (activity.field_type_ac === ACTIVITY_TYPE_OPTION) {
      tooltip = LABEL_SEE_PRE_BOOKING;
    } else if (activity.field_type_ac === ACTIVITY_TYPE_PRE_BOOKING) {
      tooltip = LABEL_SEE_BOOKING;
    } else if (activity.field_type_ac === ACTIVITY_TYPE_BOOKING) {
      tooltip = LABEL_SEE_SALE;
    }
    return (
      <div className={classnames(className, styles.root)}>
        <div className={classnames(styles.tags, { [styles.displayColumn]: displayColumn })}>
          <Pastille
            className={styles.tag}
            label={activity.field_statutnom_ac}
            tooltip={activity.field_type_ac !== ACTIVITY_TYPE_SALE ? tooltip : undefined}
            onClick={activity.field_type_ac !== ACTIVITY_TYPE_SALE ? handleClick : undefined}
            {...PASTILLE_STATUS_COLOR[activity.field_statutnom_ac]}
          />

          {showStepsForWon && (
            <ActivityStep className={styles.tag} activity={activity} isHover={hover} />
          )}
        </div>
        {showSubtitle && subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    );
  }

  return (
    <div className={classnames(className, styles.root, { [styles.displayColumn]: displayColumn })}>
      <div className={classnames(styles.tags, { [styles.displayColumn]: displayColumn })}>
        <Pastille
          className={styles.tag}
          label={activity.field_statutnom_ac}
          {...PASTILLE_STATUS_COLOR[activity.field_statutnom_ac]}
        />

        {showSteps && <ActivityStep className={styles.tag} activity={activity} isHover={hover} />}
      </div>
      {showSubtitle && subtitle && <span className={styles.subtitle}>{subtitle}</span>}
    </div>
  );
}
