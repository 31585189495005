import { FLOORS } from 'settings/app';
import {
  LOT_CELL_ID_SURFACE_ANNEX as ID_ANNEX,
  LOT_CELL_ID_CUSTOM_PRICE_1 as ID_CUSTOM_PRICE_1,
  LOT_CELL_ID_CUSTOM_PRICE_2 as ID_CUSTOM_PRICE_2,
  LOT_CELL_ID_CUSTOM_RENT as ID_CUSTOM_RENT,
  LOT_CELL_ID_DELIVERY as ID_DELIVERY,
  LOT_CELL_ID_EXPOSURE as ID_EXPOSURE,
  LOT_CELL_ID_FLOOR as ID_FLOOR,
  LOT_CELL_ID_KIND as ID_KIND,
  LOT_CELL_ID_NUMBER as ID_NUMBER,
  LOT_CELL_ID_PARKING as ID_PARKING,
  LOT_CELL_ID_PLAN as ID_PLAN,
  LOT_CELL_ID_PRICE_REDUCED_VAT as ID_PRICE_REDUCED,
  LOT_CELL_ID_PRICE_WITH_TAX as ID_PRICE_WITH_TAX,
  LOT_CELL_ID_PROFITABILITY as ID_PROFITABILITY,
  LOT_CELL_ID_PROFITABILITY_CUSTOM as ID_PROFITABILITY_CUSTOM,
  LOT_CELL_ID_STATUS as ID_STATUS,
  LOT_CELL_ID_SURFACE as ID_SURFACE,
  LOT_CELL_ID_TAX as ID_TAX,
} from 'settings/lots';
import { KINDS_ORDER, SORT_ORDER_ASC } from 'settings/search';

// Démembrement : 9 + customPrice1
// DeficitFoncier : 9 + customRent
// Pinel : 9 + price1 + customPrice2 + customRent + customProfitability + delivery
// ResidenceHorsPinel : 9 + price1 + customPrice2 + customRent
// LMNP : 9 + type + customPrice1 + customPrice2 + customRent + customProfitability + delivery
// default : 9 + price1 + price2 + rent + profitability + delivery

export function sortLots(rows, sortBy, sortOrder, taxById = undefined) {
  if (sortBy === ID_KIND) {
    const sortedDatas = rows.sort((a, b) => {
      return KINDS_ORDER.indexOf(a.kind) - KINDS_ORDER.indexOf(b.kind);
    });

    return sortOrder === SORT_ORDER_ASC ? sortedDatas : sortedDatas.reverse();
  }

  if (sortBy === ID_TAX && taxById) {
    return rows.sort((a, b) => {
      const sign = sortOrder === SORT_ORDER_ASC ? 1 : -1;

      if (a.tax === null) {
        return sign * -1;
      }

      if (b.tax === null) {
        return sign * 1;
      }

      return sign * taxById[a.tax].localeCompare(taxById[b.tax]);
    });
  }

  return [...rows].sort((...rowsToCompare) => {
    const order = Number(sortOrder === SORT_ORDER_ASC) * 2 - 1;
    const [value1, value2] = rowsToCompare.map(row => {
      switch (sortBy) {
        case ID_ANNEX:
          return row.surfaceAnnex;

        case ID_CUSTOM_PRICE_1:
          return row.customPrice1;

        case ID_PRICE_WITH_TAX:
          return row.priceIncludingTax;

        case ID_CUSTOM_PRICE_2:
          return row.customPrice2;

        case ID_CUSTOM_RENT:
          return row.customRent;

        case ID_PRICE_REDUCED:
          return row.priceReducedVat;

        case ID_DELIVERY:
          return row.delivery;

        case ID_EXPOSURE:
          return row.exposure;

        case ID_FLOOR:
          return Object.values(FLOORS).indexOf(row.floor);

        case ID_NUMBER:
          return row.number;

        case ID_PARKING:
          return row.hasParking;

        case ID_PLAN:
          return row.plan ? '1' : '2';

        case ID_PROFITABILITY:
          return row.profitability;

        case ID_PROFITABILITY_CUSTOM:
          return row.customProfitability;

        case ID_STATUS:
          return row.status;

        case ID_SURFACE:
          return row.surface;

        default:
          return 0;
      }
    });

    if (value1 === value2) {
      return 0;
    }
    return order * (Number(value1 > value2) * 2 - 1);
  });
}
