/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';

import type { HeadingType, HeadingsWidthType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';

import { getColumnStyles } from 'services/ui';

import { ResponsiveContext } from 'modules/App/Contexts';

import styles from './LotRow.module.scss';

interface LotRowProps {
  className?: string;
  style?: any;
  isCurrentRowOpen?;
  onClickRow?;
  data: LotJson;
  headings?: HeadingType[];
  index: number;
  renderShowMore?: (index: number) => ReactNode;
  renderCell: (col: HeadingType, handleDisplayLot: () => void, isLotOpen) => ReactNode;
  onClick?: any;
  onMouseEnter?: () => void;
  onMouseOut?: () => void;
  cellsWidth?: HeadingsWidthType;
}

export default function LotRow({
  style = {},
  className = undefined,
  headings = [],
  index,
  isCurrentRowOpen,
  onClickRow,
  renderCell,
  renderShowMore,
  onClick,
  onMouseEnter = undefined,
  onMouseOut = undefined,
  data,
  cellsWidth = undefined,
}: LotRowProps) {
  const { number, ref } = data;
  const { isResponsive, isTablette } = useContext(ResponsiveContext);

  const handleClickRow = () => {
    if (onClick) onClick();
    else if (!isResponsive) onClickRow();
  };

  const handleClickCell = () => {
    if (!isResponsive) onClickRow();
  };

  return (
    <div style={style}>
      <div
        data-test-id={`lots-list-item--${number}`}
        className={classnames(
          className,
          styles.resultItem,
          index % 2 === 0 ? styles.even : styles.odd,
          {
            [styles.resultItemTablet]: isTablette,
            [styles.isLotOpen]: isCurrentRowOpen,
          }
        )}
        role="button"
        tabIndex={0}
        onClick={handleClickRow}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseOut}
        onBlur={() => {}}
        id={`lot-${ref}-${number}`}
      >
        {headings?.map(col => (
          <div
            data-test-id="results-list--lots-row"
            key={col.id}
            className={classnames(styles.cell, {
              [styles.alignCenter]: col.align === 'center',
              [styles.alignLeft]: col.align === 'left',
              [styles.alignRight]: col.align === 'right',
              [styles.cellPromo]: col.id === 'promo',
            })}
            style={getColumnStyles(col, headings, cellsWidth)}
          >
            {renderCell(col, handleClickCell, isCurrentRowOpen)}
          </div>
        ))}
      </div>
      {isCurrentRowOpen && renderShowMore?.(index)}
    </div>
  );
}
