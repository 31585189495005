import React, { useEffect } from 'react';

import TagCommander from 'modules/App/TagCommander/TagCommander';
import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';
import { ActualityType } from 'api/vi3pAPI/apiTypes/ActualityType';
import ActualityDetailItem from './ActualityDetailItem';

function ActualityDetail({
  match,
}: {
  match: { path: string; url: string; isExact: boolean; params: { id: number } };
}) {
  const { id: actualityId } = match.params;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data: actuality } = useSWRVi3p<ActualityType>({
    url: actualityId ? `actualites/${actualityId}` : undefined,
  });

  if (!actuality) {
    return null;
  }

  return (
    <>
      <ActualityDetailItem actuality={actuality} />
      <TagCommander
        navigation_pagename={`actualite.${actuality.titre}`}
        navigation_template="actualites"
      />
    </>
  );
}

export default ActualityDetail;
