import React, { useContext, useState } from 'react';
import type { ComponentProps } from 'react';
import classnames from 'classnames';

import { replaceTokens } from 'services/formatter';
import { sortLotsRepartition } from 'services/programs';
import { getFirstPromotion } from 'services/promotions';
import {
  LABEL_AVAILABLE_LOTS_ALERT_PLURAL,
  LABEL_AVAILABLE_LOTS_ALERT_SINGLE,
  LABEL_LOTS_NO_RESULT,
  LABEL_NB_AVAILABLE_LOT,
  LABEL_NB_AVAILABLE_LOTS,
} from 'settings/labels';
import { PROGRAM_KINDS_ORDER } from 'settings/programs';
import { TMS_ORIGIN_PROGRAM } from 'settings/tms';
import { TOKEN_NB } from 'settings/token';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';
import type { SelectedLotType } from 'types/lots';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import ActivityModalsProvider from 'modules/App/Providers/ActivityModalsProvider';
import HonoBoostPromotionBanner from 'commonUi/HonoBoostPromotionBanner/HonoBoostPromotionBanner';
import ToggleButton from 'commonUi/ToggleButton/ToggleButton';
import LotsListSelectedActions from 'modules/HomePage/Components/LotsList/LotsListSelectedActions';
import ProgramLotsMobile from './ProgramLotsMobile';
import ProgramLotsDesktopAndTablet from './ProgramLotsDesktopAndTablet';

import styles from './ProgramLots.module.scss';

interface ProgramLotsProps {
  className?: string;
  generateProgramPdf: () => void;
  isValidatingLots: boolean;
  lots: LotJson[];
  program: ProgramTypeV2;
  programPdf?: ComponentProps<typeof ProgramLotsMobile>['programPdf'] &
    ComponentProps<typeof ProgramLotsDesktopAndTablet>['programPdf'];
  statuses: Record<string, Status>;
  updateStatus: (lotNid: string, status: any) => void;
}

export default function ProgramLots({
  className = undefined,
  generateProgramPdf,
  isValidatingLots: isValidating,
  lots,
  program,
  programPdf,
  statuses,
  updateStatus,
}: ProgramLotsProps) {
  const { isTablette, isResponsive } = useContext(ResponsiveContext);

  const [selectedLots, setSelectedLots] = useState<SelectedLotType[]>([]);

  const typology =
    typeof program?.repartitionsLots === 'string'
      ? (JSON.parse(program.repartitionsLots) as Record<string, number>)
      : undefined;
  const pageTemplate = `programme.${program.referenceProgramme}`;
  const pageTemplateRef = 'programme';

  const [selectedTypologies, setSelectedTypologies] = useState<string[]>([]);
  const filteredLots =
    selectedTypologies?.length > 0
      ? lots.filter(
          l =>
            selectedTypologies.includes(l.kind) ||
            (selectedTypologies.includes('Maison') &&
              ['M1', 'M2', 'M3', 'M4', 'M5'].includes(l.kind)) ||
            (selectedTypologies.includes('T5 et plus') && ['T5'].includes(l.kind))
        )
      : lots;
  const sortedTypologies = sortLotsRepartition(typology, PROGRAM_KINDS_ORDER);

  if (!filteredLots?.length && isValidating) return null;

  const promotionsHB = getFirstPromotion(program.promotions, true);

  const listHeader = (
    <div className={styles.header} id="program-lots-header">
      <div className={styles.headerInfos}>
        {!isResponsive && (
          <LotsListSelectedActions
            selectedLots={selectedLots}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            origin={TMS_ORIGIN_PROGRAM}
          />
        )}
        <div className={styles.title}>
          {replaceTokens(lots.length === 1 ? LABEL_NB_AVAILABLE_LOT : LABEL_NB_AVAILABLE_LOTS, {
            [TOKEN_NB]: lots.length,
          })}
        </div>
        {lots.length > 0 && typology && (
          <div className={styles.typology}>
            {sortedTypologies.map(typology => (
              <div key={typology.typology} className={styles.typologyItem}>
                <ToggleButton
                  nb={typology.nb}
                  selectedTypologies={selectedTypologies}
                  setSelectedTypologies={setSelectedTypologies}
                  typology={typology.typology}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {!isResponsive && !isTablette && promotionsHB && (
        <HonoBoostPromotionBanner
          classes={{ root: styles.promotionHBRoot }}
          promotion={promotionsHB}
        />
      )}
    </div>
  );

  return (
    <div className={classnames(className, styles.root)}>
      {lots.length > 0 && lots.length < 4 && (
        <div className={classnames(styles.warning, styles.alert)}>
          {replaceTokens(
            lots.length === 1
              ? LABEL_AVAILABLE_LOTS_ALERT_SINGLE
              : LABEL_AVAILABLE_LOTS_ALERT_PLURAL,
            { [TOKEN_NB]: lots.length }
          )}
        </div>
      )}

      {(isResponsive || isTablette) && promotionsHB && (
        <HonoBoostPromotionBanner
          classes={{ root: styles.promotionHBRoot }}
          promotion={promotionsHB}
        />
      )}

      {filteredLots.length === 0 && (
        <div className={styles.noResult}>
          <div>{listHeader}</div>
          <div>{LABEL_LOTS_NO_RESULT}</div>
        </div>
      )}
      <ActivityModalsProvider>
        {filteredLots.length > 0 && isResponsive && (
          <ProgramLotsMobile
            className={styles.lots}
            headerContent={listHeader}
            lots={filteredLots}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            program={program}
            programPdf={programPdf}
            generateProgramPdf={generateProgramPdf}
            statuses={statuses}
            updateStatus={updateStatus}
          />
        )}
        {filteredLots.length > 0 && !isResponsive && (
          <ProgramLotsDesktopAndTablet
            headerContent={listHeader}
            lots={filteredLots}
            pageTemplate={pageTemplate}
            pageTemplateRef={pageTemplateRef}
            program={program}
            programPdf={programPdf}
            generateProgramPdf={generateProgramPdf}
            statuses={statuses}
            updateStatus={updateStatus}
            selectedLots={selectedLots}
            setSelectedLots={setSelectedLots}
          />
        )}
      </ActivityModalsProvider>
    </div>
  );
}
