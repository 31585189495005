import React, { useContext, useState } from 'react';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';
import classNames from 'classnames';

import { replaceTokens } from 'services/formatter';
import {
  LABEL_DOWNLOAD_CARD,
  LABEL_PDF_SEND_LOT_CARD,
  LABEL_PDF_SEND_PROGRAM_CARD,
} from 'settings/labels';
import { TOKEN_NAME } from 'settings/token';

import { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import { TmsContext } from 'modules/App/Contexts/TmsContext';

import Button from 'commonUi/Button/Button';
import { LotCard } from 'commonUi/LotCard/LotCard';
import { ProgramCard } from 'commonUi/ProgramCard/ProgramCard';
import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import FormSendPdf from './FormSendPdf';

import styles from '../ModalAction.module.scss';

export interface ModalSendPdfProps {
  blobPdf: Blob | null | undefined;
  closeCallBack: () => void;
  lot?: LotTypeV2;
  open: boolean;
  pdfUrl: string | null | undefined;
  program: ProgramTypeV2 | undefined;
}

export function ModalSendPdf({
  blobPdf,
  closeCallBack,
  lot = undefined,
  open,
  pdfUrl,
  program,
}: ModalSendPdfProps) {
  const { isResponsive } = useContext(ResponsiveContext);
  const { setEventPageVars } = useContext(TmsContext);
  const [loading, setLoading] = useState<boolean>(false);

  function handleClose() {
    setEventPageVars({});
    if (typeof closeCallBack === 'function') {
      closeCallBack();
    }
  }

  if (!open || !program) {
    return null;
  }

  // Truncate long program names only for mobile
  const programName =
    isResponsive && program?.nomCommercial?.length > 11
      ? `${program.nomCommercial.substring(0, 11)}...`
      : program.nomCommercial;

  let modalTitle = replaceTokens(LABEL_PDF_SEND_PROGRAM_CARD, { [TOKEN_NAME]: programName });

  if (lot) {
    modalTitle = replaceTokens(LABEL_PDF_SEND_LOT_CARD, { [TOKEN_NAME]: lot.reference });
  }

  let navigationTemplate = lot ? 'form_fichelot' : 'form_ficheprogramme';
  if (loading) {
    navigationTemplate = `${navigationTemplate}.confirmation`;
  }

  return (
    <div>
      <MuiDialog
        open={open}
        onClose={handleClose}
        classes={{
          paper: classNames(styles.modalSkin, styles.modalSkinPdf),
          root: styles.modalRootForm,
          scrollPaper: styles.scrollPaper,
        }}
      >
        <div className={styles.modalHeader}>
          <div className={styles.title}>
            <Icon className={styles.icon} icon="send" />
            <span>{modalTitle}</span>
          </div>
          <div className={styles.rightContent}>
            {!isResponsive && (
              <div className={styles.actionButton}>
                <Button
                  className={styles.link}
                  variant="contained"
                  color="primary"
                  disabled={!pdfUrl}
                  href={pdfUrl}
                  icon="download"
                  iconClass={styles.icon}
                  target="_blank"
                >
                  {LABEL_DOWNLOAD_CARD}
                </Button>
              </div>
            )}
            <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
              <Icon className={styles.svgIcon} icon="cross" />
            </IconButton>
          </div>
        </div>

        <div className={styles.modalContent}>
          {lot ? (
            <div className={styles.lotCardWrapper}>
              <LotCard
                className={styles.lotCard}
                lotNumber={lot.reference}
                program={program}
                lot={lot}
              />
            </div>
          ) : (
            <div className={styles.programWardWrapper}>
              <ProgramCard program={program} />
            </div>
          )}

          {isResponsive && (
            <div className={styles.actionButton}>
              <Button
                className={styles.link}
                variant="contained"
                color="primary"
                disabled={!pdfUrl}
                href={pdfUrl}
                icon="download"
                iconClass={styles.icon}
                target="_blank"
              >
                {LABEL_DOWNLOAD_CARD}
              </Button>
            </div>
          )}
          <FormSendPdf
            handleClose={handleClose}
            program={program}
            blobPdf={blobPdf}
            lot={lot}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </MuiDialog>

      <TagCommanderEvent
        isActive={open}
        navigation_pagename={navigationTemplate}
        navigation_template={lot ? 'fichelot' : 'ficheprogramme'}
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}
