import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Tooltip } from '@material-ui/core';

import { formatActivityDate } from 'services/date';
import { isLotOptionnedByMe, isLotPreBookedByMe } from 'services/lotsV2';
import { PASTILLE_STATUS_COLOR, PASTILLE_STATUS_ICON } from 'services/pastille';
import { LABEL_CREATE_ALERT, LABEL_DATE_END_ON, LABEL_DELETE_ALERT } from 'settings/labels';
import { LOT_STATUS_OPTIONED, LOT_STATUS_PRE_BOOKED } from 'settings/lots';
import { TMS_TIMEOUT } from 'settings/tms';

import type { Activity } from 'api/vi3pAPI/apiTypes/ActivityType';
import type { AlertType } from 'api/vi3pAPI/apiTypes/AccountTypes';
import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { Status } from 'api/viOffresAPI/apiTypes/Statuses';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import { Pastille } from 'commonUi/Pastille/Pastille';

import styles from './LotCell.module.scss';

interface StatusCellProps {
  alerts?: AlertType[];
  className?: string;
  content: string;
  lot: LotJson;
  myBookings?: Activity[];
  myOptions?: Activity[];
  onAlertClick: (lotNumber: string, programRef: string) => any;
  pageTemplate?: string;
  pageTemplateRef?: string;
  statuses?: Record<string, Status>;
}

export function StatusCell({
  alerts,
  className,
  content,
  lot,
  myBookings,
  myOptions,
  onAlertClick,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  statuses,
}: StatusCellProps) {
  const { nid } = lot;

  const status = statuses?.[nid] && statuses[nid].label ? statuses[nid].label : content;
  const expirationDate = statuses?.[nid] ? statuses[nid].expirationDate : undefined;
  // eslint-disable-next-line no-param-reassign
  const isOptionnedByMe =
    status === LOT_STATUS_OPTIONED ? isLotOptionnedByMe(myOptions, lot.ref, lot.number) : false;
  const isPreBookedByMe =
    status === LOT_STATUS_PRE_BOOKED ? isLotPreBookedByMe(myBookings, lot.ref, lot.number) : false;

  const isAlertActive = useMemo(
    () => alerts?.some(alert => alert.lotNumber === lot?.number && alert.programRef === lot.ref),
    [alerts]
  );
  const [isAlertBtnClicked, setIsAlertBtnClicked] = useState<boolean>(false);

  useEffect(() => {
    if (isAlertBtnClicked) {
      setTimeout(() => setIsAlertBtnClicked(false), TMS_TIMEOUT);
    }
  }, [isAlertBtnClicked]);

  function handleClick(ev: React.MouseEvent) {
    ev.stopPropagation();
    setIsAlertBtnClicked(true);
    onAlertClick(lot?.number, lot?.program?.ref);
  }

  return (
    <div data-test-id={`status-cell--${status}`} className={classNames(className, styles.status)}>
      <div className={styles.statusTop}>
        {status && PASTILLE_STATUS_COLOR[status] && (
          <Pastille
            chipClassName={styles.pastilleChipRoot}
            label={status}
            labelClassName={styles.pastille}
            icon={PASTILLE_STATUS_ICON[status]}
            iconClassName={styles.pastilleIcon}
            bgColor={PASTILLE_STATUS_COLOR[status].bgColor}
            textColor={PASTILLE_STATUS_COLOR[status].textColor}
          />
        )}

        {((status === LOT_STATUS_OPTIONED && myOptions && !isOptionnedByMe) ||
          (status === LOT_STATUS_PRE_BOOKED && myBookings && !isPreBookedByMe)) && (
          <Tooltip
            arrow
            classes={{
              arrow: styles.arrowTooltip,
              tooltip: styles.tooltip,
            }}
            title={isAlertActive ? LABEL_DELETE_ALERT : LABEL_CREATE_ALERT}
            placement="top"
          >
            <Button className={styles.alertBtn} onClick={handleClick}>
              {isAlertActive && <SvgIcon className={styles.alertIcon} iconId="icon-active-alert" />}
              {!isAlertActive && (
                <>
                  <Icon
                    className={classNames(styles.alertIcon, styles.addAlert)}
                    icon="notifications"
                  />
                  <SvgIcon
                    className={classNames(styles.alertIcon, styles.addAlertHover)}
                    iconId="icon-inactive-alert-onhover"
                  />
                </>
              )}
            </Button>
          </Tooltip>
        )}
      </div>
      <div className={styles.statutEndDateContainer}>
        {(status === LOT_STATUS_OPTIONED || status === LOT_STATUS_PRE_BOOKED) && expirationDate && (
          <span className={styles.statutEndDate}>
            {LABEL_DATE_END_ON} {formatActivityDate(expirationDate, 'DD/MM/YY HH:mm')}{' '}
          </span>
        )}
      </div>
      {pageTemplateRef && pageTemplate && (
        <TagCommanderEvent
          isActive={isAlertBtnClicked}
          navigation_pagename={`${pageTemplateRef}.${lot.number}_alerte`}
          navigation_template={pageTemplate}
          useEffectDeps={['navigation_template', 'navigation_pagename']}
        />
      )}
    </div>
  );
}
