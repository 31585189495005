import React, { useContext } from 'react';
import type { ComponentProps } from 'react';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { ResponsiveContext } from 'modules/App/Contexts';

import ProgramBannerLabel from './ProgramBannerLabel';
import ProgramBannerPictos from './ProgramBannerPictos';
import ProgramBooster from './ProgramBooster';

import styles from './ProgramBanner.module.scss';

interface ProgramBannerProps {
  allLots: ComponentProps<typeof ProgramBooster>['allLots'];
  allPrograms: ComponentProps<typeof ProgramBooster>['allPrograms'];
  generateProgramPdf: () => void;
  program: ProgramTypeV2 | undefined;
  programPdf?: ComponentProps<typeof ProgramBooster>['programPdf'];
  taxById: TaxesById;
  waitForPdfDatas?: boolean;
}

export default function ProgramBanner({
  allLots,
  allPrograms,
  generateProgramPdf,
  program,
  programPdf,
  taxById,
  waitForPdfDatas = false,
}: ProgramBannerProps) {
  const { isMobile } = useContext(ResponsiveContext);

  if (!program) {
    return null;
  }

  const Booster = (
    <ProgramBooster
      allLots={allLots}
      allPrograms={allPrograms}
      className={styles.booster}
      program={program}
      programPdf={programPdf}
      generateProgramPdf={generateProgramPdf}
      taxById={taxById}
      waitForPdfDatas={waitForPdfDatas}
    />
  );

  return (
    <div className={styles.root}>
      <div className={styles.backgroundContainer}>
        <div className={styles.bottom}>
          <div className={styles.content}>
            <ProgramBannerLabel className={styles.label} program={program} />

            <h1 className={styles.programTitle}>{program.nomCommercial}</h1>

            {program.localisation.ville && (
              <div className={styles.location}>
                {program.localisation.ville}{' '}
                {program.localisation.codePostal && <>({program.localisation.codePostal})</>}
              </div>
            )}
            <ProgramBannerPictos program={program} />
          </div>

          {!isMobile && Booster}
        </div>

        <img
          className={styles.backgroundImg}
          src={program.perspectives?.panorama}
          alt={program.nomCommercial}
        />
      </div>

      {isMobile && Booster}
    </div>
  );
}
