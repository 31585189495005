import React, { useContext, useState } from 'react';
import type { ComponentProps } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import classnames from 'classnames';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSnackbar } from 'notistack';

import { ACTIVITY_STATUS_LOST, DOCUMENT_NATURE_FIELD_DOSSIER_RESERVATION } from 'settings/activity';
import {
  LABEL_CONTRACT_GENERATE,
  LABEL_DOWNLOAD_CONTRACT,
  LABEL_FAIL_DOWNLOAD_CONTRACT,
  LABEL_FAIL_DOWNLOAD_NO_CONTRACT,
  LABEL_QUIT,
  LABEL_SEND_SIGNATURE_ERROR,
  LABEL_SEND_SIGNATURE_ERROR_DEFAULT,
  LABEL_SEND_SIGNATURE_ERROR_MESSAGE,
  LABEL_SEND_SIGNATURE_NOT_OWNER,
  LABEL_SEND_SIGNATURE_REQUEST,
  LABEL_SEND_SIGNATURE_SUCCESS_SUBTITLE_LONG,
  LABEL_SEND_SIGNATURE_SUCCESS_TITLE_LONG,
} from 'settings/labels';

import { axiosViCrmInstance } from 'api/viCrmApi/axiosInstance';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';
import { userContext } from 'modules/App/Contexts/userContext';

import type { ActivityDocumentType } from 'api/viOffresAPI/apiTypes/Documents';

import { getCommercialApiBaseUrl } from 'services/activities';
import { formatActivityDate } from 'services/date';
import { openBlob } from 'services/files';

import useDownloadActivityFile from 'hooks/useDownloadActivityFile';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import { LotCard } from 'commonUi/LotCard/LotCard';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';
import CircularProgressWithLabel from 'commonUi/CircularProgressWithLabel/CircularProgressWithLabel';
import TagCommander from 'modules/App/TagCommander/TagCommander';

import { ConfirmationSign } from './ConfirmationSign';
import { FormAttachments } from './FormAttachments';

import styles from '../PageAction.module.scss';

const getLatestContract = (documents: ActivityDocumentType[] = []) => {
  if (!documents.length) {
    return undefined;
  }
  return [
    ...documents.filter(({ nature }) => nature === DOCUMENT_NATURE_FIELD_DOSSIER_RESERVATION),
  ]?.sort(
    ({ dateModification: date1 }, { dateModification: date2 }) =>
      new Date(date2).getTime() - new Date(date1).getTime()
  )?.[0];
};

export function PageContractSign({
  history,
  match,
}: RouteComponentProps<{ idReservation?: string }>) {
  const prebookIdCrm = match.params?.idReservation?.toLowerCase();
  const { enqueueSnackbar } = useSnackbar();
  const { isResponsive } = useContext(ResponsiveContext);
  const { settings } = useContext(settingsContext);
  const { preBookings: myPrebooks } = useContext(userContext);
  const prebookData = myPrebooks?.find(
    prebook => prebook?.field_idcrm_ac.toLowerCase() === prebookIdCrm
  );

  const [error, setError] = useState<ComponentProps<typeof FormAttachments>['error']>();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<{
    title: string;
    content: string;
  }>();

  const { lot, program, isLoading } = useLoadProgramAndLot(
    prebookData?.field_referenceprogramme_ac,
    prebookData?.field_numerolot_ac
  );

  const { isDownloading, downloadingProgress, handleFileDownload } = useDownloadActivityFile();

  function onFailDownload(message: string) {
    enqueueSnackbar(message, {
      autoHideDuration: null,
      content: (key, message) => <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />,
    });
  }

  function handleDownloadContract(ev: React.MouseEvent) {
    ev.stopPropagation();
    if (contract) {
      if (!isDownloading) {
        openBlob(
          () =>
            handleFileDownload(`activiteCommerciale/${prebookIdCrm}/documents/${contract.idCrm}`)
              ?.then(
                response => new Blob([response.data], { type: response.headers['content-type'] })
              )
              .catch(() => {
                onFailDownload(LABEL_FAIL_DOWNLOAD_CONTRACT);
                throw new Error();
              }),
          contract.nom,
          isResponsive
        );
      }
    } else {
      onFailDownload(LABEL_FAIL_DOWNLOAD_NO_CONTRACT);
    }
  }

  function handleClose() {
    history.goBack();
  }

  const methods = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    shouldUnregister: false,
  });

  if (!prebookData && isLoading) {
    return (
      <div className={styles.root}>
        <div className={classnames(styles.header)}>
          <button className={styles.returnBtn} type="button" onClick={handleClose}>
            <Icon className={styles.returnBtnIcon} icon="arrow-left" />
            <span>{LABEL_QUIT}</span>
          </button>
          <div className={styles.name}>
            {!isResponsive && <Icon className={styles.returnBtnIcon} icon="send-documents" />}
            {LABEL_SEND_SIGNATURE_REQUEST}
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.formContainer}>
            <div className={styles.form}>
              <div className={styles.noOwnership}>{LABEL_SEND_SIGNATURE_NOT_OWNER}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!prebookIdCrm || !prebookData) {
    return null;
  }
  const contractDate = prebookData.field_dategenerationcontrat_ac
    ? new Date(prebookData.field_dategenerationcontrat_ac)
    : undefined;
  // Get the most recent contract
  const contract = getLatestContract(prebookData.field_documents_ac);
  const handleSubmit = methods.handleSubmit(async () => {
    const {
      'field-acquereur-id': acquereurFile,
      'field-co-acquereur-id': coAcquereurFile,
    } = methods.getValues();

    try {
      setLoadingSubmit(true);
      const apiBaseUrl = getCommercialApiBaseUrl(!!settings?.cdo?.activite_commerciale_api);
      const uploadPreBookingDocument = (
        preBookingId: string,
        file: File,
        fileNature: string,
        synchronous?: boolean
      ) =>
        new Promise(resolve => {
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = e => resolve(e.target?.result);
        }).then(encodedFile =>
          axiosViCrmInstance.post(
            `${apiBaseUrl}/pre-reservations/${preBookingId}/documents`,
            encodedFile,
            {
              headers: {
                'Content-Type': file.type,
                'Content-Length': '',
              },
              params: { nature: fileNature, filename: file.name, synchronous },
            }
          )
        );
      if (acquereurFile) {
        await uploadPreBookingDocument(prebookIdCrm, acquereurFile, 'CNI', true);
      }
      if (coAcquereurFile) {
        await uploadPreBookingDocument(prebookIdCrm, coAcquereurFile, 'CNI', true);
      }
      const signedContract = await axiosViCrmInstance.post(
        `${apiBaseUrl}/pre-reservations/${prebookIdCrm}/signerContrat`,
        {
          application: 'VI3P',
          correlationId: uuidv4(),
        }
      );
      if (signedContract.status === 202) {
        setSuccessMessage({
          title: LABEL_SEND_SIGNATURE_SUCCESS_TITLE_LONG,
          content: LABEL_SEND_SIGNATURE_SUCCESS_SUBTITLE_LONG,
        });
      }
    } catch (newError) {
      let title = LABEL_SEND_SIGNATURE_ERROR_DEFAULT;
      let content = LABEL_SEND_SIGNATURE_ERROR_MESSAGE;
      let details = '';
      if (newError?.response?.data?.message) {
        title = LABEL_SEND_SIGNATURE_ERROR;
        content = newError?.response?.data?.message;
        details = newError?.response?.data?.detail?.document?.join(', ');
      }
      setError({ title, content, details });
    } finally {
      setLoadingSubmit(false);
    }
  });

  return (
    <div className={styles.root}>
      <div className={classnames(styles.header)}>
        <button className={styles.returnBtn} type="button" onClick={handleClose}>
          <Icon className={styles.returnBtnIcon} icon="arrow-left" />
          <span>{LABEL_QUIT}</span>
        </button>
        <div className={styles.name}>
          {!isResponsive && <Icon className={styles.returnBtnIcon} icon="send-documents" />}
          {LABEL_SEND_SIGNATURE_REQUEST}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          {contract && (
            <div className={styles.contract}>
              <span className={styles.contractLabel}>
                <strong>{LABEL_CONTRACT_GENERATE}</strong>
              </span>
              <span className={styles.contractDate}>
                {formatActivityDate(contractDate, 'Le DD/MM/YYYY à HH:mm')}
              </span>

              {isDownloading ? (
                <div>
                  <CircularProgressWithLabel value={downloadingProgress} />
                </div>
              ) : (
                <button
                  type="button"
                  className={styles.contractDownload}
                  onClick={handleDownloadContract}
                >
                  {LABEL_DOWNLOAD_CONTRACT}
                </button>
              )}
            </div>
          )}

          <div className={styles.lotCard}>
            <LotCard lotNumber={prebookData.field_numerolot_ac} lot={lot} program={program} />
          </div>
        </div>
        <div className={styles.formContainer}>
          {!successMessage && (
            <FormProvider {...methods}>
              <form className={styles.contentBody} onSubmit={handleSubmit}>
                <FormAttachments
                  onClose={handleClose}
                  prebook={prebookData}
                  loadingSubmit={loadingSubmit}
                  readonly={prebookData.field_statutnom_ac === ACTIVITY_STATUS_LOST}
                  setError={setError}
                  error={error}
                />
              </form>
            </FormProvider>
          )}
          {successMessage && <ConfirmationSign />}
        </div>
      </div>

      <TagCommander
        navigation_pagename={successMessage ? 'form_envoicni.confirmation' : 'form_envoicni'}
        navigation_template="envoicni"
        useEffectDeps={['navigation_template', 'navigation_pagename']}
      />
    </div>
  );
}

const formSchema = yup.object().shape({
  'field-acquereur-id': yup.string().trim().required(),
  'field-co-acquereur-id': yup.string().trim(),
});
