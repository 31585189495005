import { createContext } from 'react';

import type { LotExport, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

interface ProgramLotsExportsContext {
  lots: LotExport[];
  programs: ProgramListType[];
  getLot(lotNumber: string, programRef: string): LotJson | undefined;
  getProgram(programRef: string): ProgramListType | undefined;
  isLotsValidating: boolean;
  isProgramsValidating: boolean;
}

export default createContext<ProgramLotsExportsContext>({
  lots: [],
  programs: [],
  getLot: () => undefined,
  getProgram: () => undefined,
  isLotsValidating: false,
  isProgramsValidating: false,
});
