import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import ReactPDF from '@react-pdf/renderer';

import {
  LABEL_DISPLAY_LOTS,
  LABEL_DISPLAY_MAP,
  LABEL_SAVE_TO_FOLDER,
  LABEL_SEND_BOOKLET,
  LABEL_SEND_PROGRAM_CARD,
} from 'settings/labels';
import { LOT_CELL_ID_KIND, LOT_JSON_PROGRAM_REF } from 'settings/lots';
import { MODAL_ID_SEND_CARD_PROGRAM } from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';
import { SORT_ORDER_ASC } from 'settings/search';

import type { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { foldersContext } from 'modules/App/Contexts/foldersContext';
import programLotContext from 'modules/App/Contexts/programLotContext';
import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { sortLots } from 'services/lots';
import { formatLots } from 'services/lotsV2';
import { modifyQuery } from 'services/url';

import useAttributionProgram from 'hooks/useAttributionProgram';
import { useGetLotStatusesDirect } from 'hooks/useGetLotsStatuses';
import { useModalMultiples } from 'hooks/useModal';
import { usePanels } from 'hooks/usePanels';

import Button from 'commonUi/Button/Button';
import ButtonModalPdf from 'commonUi/ButtonModalPdf/ButtonModalPdf';
import ProgramPdf from 'commonUi/PDF/ProgramPdf/ProgramPdf';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';
import { ModalSendPdf } from 'modules/ActionsProgramsAndLots/ModalSendPdf/ModalSendPdf';
import FolderLinks from 'modules/Folders/FolderLinks';

import styles from './ProgramResultActions.module.scss';

interface ProgramCardActionsProps {
  className?: string;
  hideMapButton?: boolean;
  program: ProgramListType;
  openModalSendBooklet?: (programRef: string) => void;
  reduceMobilePanel?: () => void;
  setIsPanelExpanded?: (isPanelExpanded: boolean) => void;
  showLotsListButton?: boolean;
  showSendProgramPdfButton?: boolean;
  tmsInfos?: Record<'confirmation' | 'default', Record<string, string>>;
}

export default function ProgramResultActions({
  className,
  hideMapButton = false,
  openModalSendBooklet = () => {},
  program,
  reduceMobilePanel,
  setIsPanelExpanded,
  showLotsListButton = true,
  showSendProgramPdfButton = true,
  tmsInfos,
}: ProgramCardActionsProps) {
  const history = useHistory();
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const { taxesById } = useContext(TaxonomiesContext);
  const { open, openModal, closeModal } = useModalMultiples();
  const { lots: allLots, programs: allPrograms } = useContext(programLotContext);
  const [clickSendProgramPDF, setClickSendProgramPDF] = useState<boolean>(false);
  const [programPdf, setProgramPdf] = useState<ReactPDF.UsePDFInstance>();

  const lots = useMemo<LotJson[]>(() => {
    const prog = allPrograms?.find(({ ref }) => ref === program.ref);
    if (!program || !prog || !allLots) return [];

    return formatLots(
      allLots.filter(lot => lot[LOT_JSON_PROGRAM_REF] === program.ref),
      { [program.nid]: prog }
    );
  }, [allLots, allPrograms, program]);
  const sortedLots = useMemo<LotJson[]>(() => sortLots(lots, LOT_CELL_ID_KIND, SORT_ORDER_ASC), [
    lots,
  ]);
  const { statuses } = useGetLotStatusesDirect(clickSendProgramPDF ? sortedLots : undefined);

  const { program: programFromApi } = useAttributionProgram(program.ref, clickSendProgramPDF);

  const generateProgramPdf = useCallback(async () => {
    if (!programFromApi) return;
    const blob = await ReactPDF.pdf(
      <ProgramPdf
        program={programFromApi}
        sortedLots={sortedLots}
        statuses={statuses}
        taxById={taxesById}
      />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    setProgramPdf({ blob, url, loading: false, error: null });
  }, [programFromApi, sortedLots, statuses, taxesById]);

  const {
    emptyDatas,
    folderSaveType,
    setFolderPrograms,
    setFolderSaveType,
    setFolderTms,
  } = useContext(foldersContext);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  function handleDisplayOnMapClick() {
    if (typeof reduceMobilePanel === 'function') {
      reduceMobilePanel();
    }
    history.replace(
      modifyQuery({
        programRef: program.ref,
        origin: 'list',
        lat: program.lat,
        lng: program.lng,
        zoom: 14,
      })
    );
  }

  function handleDisplayLotsClick() {
    history.replace(
      modifyQuery({
        origin: 'map',
        programRef: program.ref,
        type: 'lot',
        from: 'display_lots_btn',
      })
    );
    if (typeof setIsPanelExpanded === 'function') {
      setIsPanelExpanded(true);
    }
  }

  function handleClickAddFolder() {
    if (folderSaveType !== 'program') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('program');
    setFolderPrograms([{ programName: program.name, programRef: program.ref }]);
    if (tmsInfos) {
      setFolderTms(tmsInfos);
    }
  }

  return (
    <div className={classnames(className, styles.root)}>
      {showSendProgramPdfButton && (
        <div className={styles.item}>
          {programFromApi && clickSendProgramPDF ? (
            <ButtonModalPdf
              clickOpenSendPDF={clickSendProgramPDF}
              enablePdfGeneration={
                !!taxesById && !!sortedLots && (sortedLots.length === 0 || !!statuses)
              }
              generatePdf={generateProgramPdf}
              pdf={programPdf}
              handleClick={() => openModal(MODAL_ID_SEND_CARD_PROGRAM)}
              className={styles.button}
              variant="outlined"
              iconClass={styles.svg}
              iconId="icon-program-card"
              tooltip={LABEL_SEND_PROGRAM_CARD}
              color="primary"
            />
          ) : (
            <Button
              className={styles.button}
              variant="outlined"
              iconClass={styles.svg}
              iconId="icon-program-card"
              tooltip={LABEL_SEND_PROGRAM_CARD}
              color="primary"
              loading={clickSendProgramPDF}
              onClick={() => setClickSendProgramPDF(true)}
            />
          )}
        </div>
      )}
      <div className={styles.item}>
        <Button
          className={styles.button}
          color="primary"
          iconId="icon-booklet"
          iconClass={styles.svg}
          onClick={() => openModalSendBooklet(program.ref)}
          variant="outlined"
          tooltip={LABEL_SEND_BOOKLET}
        />
      </div>
      <div className={styles.item}>
        <Button
          className={styles.button}
          color="primary"
          iconId="icon-add-to-folder"
          iconClass={styles.svg}
          onClick={handleClickAddFolder}
          variant="outlined"
          tooltip={LABEL_SAVE_TO_FOLDER}
        />
      </div>
      {!isResponsive && !isTablette && showLotsListButton && (
        <div className={styles.item}>
          <Button
            className={styles.button}
            color="primary"
            iconId="icon-display-bicolor"
            iconClass={styles.svg}
            onClick={handleDisplayLotsClick}
            variant="outlined"
            tooltip={LABEL_DISPLAY_LOTS}
          />
        </div>
      )}
      {(isTablette || isResponsive) && !hideMapButton && (
        <div className={styles.item}>
          <Button
            className={styles.button}
            color="primary"
            icon="map-plan"
            iconClass={styles.icon}
            onClick={handleDisplayOnMapClick}
            tooltip={LABEL_DISPLAY_MAP}
            variant="outlined"
          />
        </div>
      )}

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

      {open === MODAL_ID_SEND_CARD_PROGRAM && (
        <ModalSendPdf
          closeCallBack={() => {
            setClickSendProgramPDF(false);
            closeModal();
          }}
          blobPdf={programPdf?.blob}
          open
          pdfUrl={programPdf?.url}
          program={programFromApi}
        />
      )}

      <TagCommanderEvent
        isActive={clickSendProgramPDF}
        navigation_template="recherche"
        navigation_pagename="resultats_programmes_ficheprogramme"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </div>
  );
}
