import { Promotion } from 'types/promotions';

import { SettingsType } from 'api/vi3pAPI/apiTypes/SettingsType';

export function hasHonoBoostPromotion(promotions: Promotion[]) {
  return promotions.length > 0 && promotions.some(promo => promo.honoboost);
}

export function getFirstPromotion(promotions: Promotion[] | undefined, isHonoboost: boolean) {
  return (
    promotions && promotions.length > 0 && promotions.find(promo => promo.honoboost === isHonoboost)
  );
}

export function getPromotionStylesFromSettings(settings: SettingsType) {
  return {
    backgroundColor: settings?.promotions?.promotions_background_color,
    color: settings?.promotions?.promotions_text_color,
  };
}
