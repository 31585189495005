import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import Helmet from 'react-helmet';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';

import SilentRefresh from 'modules/App/SilentRefresh/SilentRefresh';
import AssistanceBlock from 'modules/Assistance/components/AssistanceBlock/AssistanceBlock';
import FoldersProvider from 'modules/App/Providers/FoldersProvider';
import Footer from './components/Footer';
import HeadElement from './components/HeadElement';
import HeaderAuthenticated from './components/HeaderAuthenticated/HeaderAuthenticated';

function AuthenticatedLayout({
  children,
  hideFooter = false,
  hideHeader = false,
}: {
  children: ReactNode;
  hideFooter?: boolean;
  hideHeader?: boolean;
}) {
  const { isResponsive } = useContext(ResponsiveContext);
  return (
    <>
      <SilentRefresh enableTimer />
      <div className="page-layout">
        <FoldersProvider>
          <HeadElement />
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
          {!hideHeader && <HeaderAuthenticated />}
          <main role="main" className={classnames({ main__authenticated: !hideHeader })}>
            {children}
          </main>
          {!isResponsive && <AssistanceBlock />}
        </FoldersProvider>
      </div>
      {!hideFooter && <Footer authenticatedFooter />}
    </>
  );
}

export default AuthenticatedLayout;
