import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import { LABEL_NEW_RESIDENCE } from 'settings/labels';

import type { LotTypeV2 } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';

import {
  lotIsControlledPrice,
  lotIsFNO,
  lotIsFurnishEligible,
  lotIsHonoboost,
} from 'services/lotsV2';
import { programIsNew, programIsVi4You } from 'services/programs';

import imgControlledPrices from 'images/Logo-prix-maitrises.png';
import imgVi4you from 'images/Logo_4you_Plan_de_travail_1.png';

import PdfPastilleNotaryFees from '../../../Common/PdfPastille/PdfPastilleNotaryFees';
import PdfPastilleHonoBoost from '../../../Common/PdfPastille/PdfPastilleHonoBoost';
import PdfPastilleFurnished from '../../../Common/PdfPastille/PdfPastilleFurnished';

import styles from './LotPdfImageStyles';

interface LotPdfImageProps {
  lot: LotTypeV2;
  program: ProgramTypeV2;
}

export default function LotPdfImage({ lot, program }: LotPdfImageProps) {
  return (
    <View style={styles.image}>
      <View style={styles.imageWrapper}>
        {/* GET parameter required, see	https://bugs.chromium.org/p/chromium/issues/detail?id=855713 for info about Chrome caching issue */}
        {program?.perspectives?.panorama && (
          <Image src={`${program.perspectives.panorama}?pdf`} style={styles.bannerImage} />
        )}
      </View>
      {programIsNew(program) && <Text style={styles.new}>{LABEL_NEW_RESIDENCE}</Text>}
      <View style={styles.pictosList}>
        {lotIsHonoboost(lot) && (
          <View style={styles.pictosListItem}>
            <PdfPastilleHonoBoost />
          </View>
        )}
        {lotIsFNO(lot) && (
          <View style={styles.pictosListItem}>
            <PdfPastilleNotaryFees />
          </View>
        )}
        {programIsVi4You(program) && (
          <View style={styles.pictosListItem}>
            <Image src={imgVi4you} style={styles.imgVi4you} />
          </View>
        )}
        {lotIsControlledPrice(lot) && (
          <View style={styles.pictosListItem}>
            <Image src={imgControlledPrices} style={styles.imgControlledPrices} />
          </View>
        )}
        {lotIsFurnishEligible(lot) && (
          <View style={styles.pictosListItem}>
            <PdfPastilleFurnished />
          </View>
        )}
      </View>
    </View>
  );
}
