import React, { useContext, useState } from 'react';
import type { CSSProperties, ComponentProps } from 'react';
import classnames from 'classnames';

import { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';

import { getPromotionStylesFromSettings } from 'services/promotions';

import { ResponsiveContext } from 'modules/App/Contexts/ResponsiveContext';
import settingsContext from 'modules/App/Contexts/SettingsContext';

import ProgramCardThumbnail from './ProgramCardThumbnail';
import ProgramCardInfo from './ProgramCardInfo';
import ProgramResultActions from './ProgramResultActions';

import styles from './ProgramResult.module.scss';

interface ProgramResultProps {
  availableLots: ComponentProps<typeof ProgramCardInfo>['availableLots'];
  className?: string;
  isSelected?: boolean;
  onMouseEnter?: (programRef: string) => void;
  onMouseLeave?: (programRef: string) => void;
  program: ProgramListType;
  tmsInfos?: {
    actions?: ComponentProps<typeof ProgramResultActions>['tmsInfos'];
    launchAlert?: ComponentProps<typeof ProgramCardInfo>['tmsInfos'];
  };
}

export default function ProgramResult({
  availableLots,
  className,
  isSelected = false,
  onMouseEnter,
  onMouseLeave,
  program,
  tmsInfos,
  ...actions
}: ProgramResultProps &
  Pick<
    ComponentProps<typeof ProgramResultActions>,
    | 'hideMapButton'
    | 'openModalSendBooklet'
    | 'reduceMobilePanel'
    | 'setIsPanelExpanded'
    | 'showLotsListButton'
    | 'showSendProgramPdfButton'
  >) {
  const { settings } = useContext(settingsContext);
  const { backgroundColor: promoColor } = getPromotionStylesFromSettings(settings);
  const { isResponsive, isTablette } = useContext(ResponsiveContext);
  const [isHover, setIsHover] = useState(false);

  if (!program) {
    return null;
  }

  const hasPromo = !!program.promotions?.[0] && !!Object.values(program.promotions[0])[0];

  return (
    <div
      data-test-id={`program-card--${program.ref}`}
      onMouseEnter={e => {
        if (!isResponsive && !isTablette) {
          e.preventDefault();
          setIsHover(true);
        }
        onMouseEnter?.(program.ref);
      }}
      onMouseLeave={e => {
        if (!isResponsive && !isTablette) {
          e.preventDefault();
          setIsHover(false);
        }
        onMouseLeave?.(program.ref);
      }}
      className={classnames(className, styles.root, {
        [styles.active]: isSelected,
        [styles.promo]: hasPromo,
      })}
      style={{ '--promo-bg-color': promoColor } as CSSProperties}
    >
      <div className={styles.content}>
        <ProgramCardThumbnail className={styles.thumbnail} isHover={isHover} program={program} />
        <ProgramCardInfo
          className={styles.infos}
          availableLots={availableLots}
          isHover={isHover}
          program={program}
          tmsInfos={tmsInfos?.launchAlert}
        />
      </div>
      <ProgramResultActions
        className={styles.buttons}
        program={program}
        tmsInfos={tmsInfos?.actions}
        {...actions}
      />
    </div>
  );
}
