import React from 'react';

import { Cta } from 'types/cta';

import Button from 'commonUi/Button/Button';

export default function FieldCta({ cta = undefined, ...buttonProps }: FieldCtaProps) {
  let docReference;
  let href;
  let target;
  let title;

  if (cta) {
    docReference = cta.document_reference;
    href = cta.uri;
    target = cta.target;
    title = cta.title;
  }

  if (!href && !title) {
    return null;
  }

  return (
    <Button
      variant="contained"
      color="primary"
      docReference={docReference}
      href={href}
      target={target}
      {...buttonProps}
    >
      {title}
    </Button>
  );
}

export type FieldCtaProps = {
  cta?: Cta;
  [x: string]: any;
};
