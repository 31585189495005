import React from 'react';
import type { ComponentProps } from 'react';
import { Text, View } from '@react-pdf/renderer';

import { showQuarter } from 'services/date';
import { formatPrice } from 'services/formatter';
import { LABEL_CONTROLLED_PRICE } from 'settings/labels';
import {
  LOT_CELL_TYPE_DATE as DATE,
  LOT_CELL_TYPE_LOT as LOT,
  LOT_CELL_TYPE_PRICE as PRICE,
  LOT_CELL_TYPE_STATUS as STATUS,
  LOT_CELL_TYPE_SURFACE as SURFACE,
  LOT_CELL_TYPE_SURFACE_ANNEX as SURFACE_ANNEX,
  LOT_CELL_TYPE_TAX as TAX,
} from 'settings/lots';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import { lotIsControlledPrice } from 'services/lotsV2';

import PdfPastilleFiscality from '../PdfPastille/PdfPastilleFiscality';
import PdfLotCellStatus from '../PdfLotCellStatus/PdfLotCellStatus';

import styles from './PdfLotCellStyles';

function getColValue(lot: LotJson, field: string | string[] | undefined) {
  if (!field) {
    return undefined;
  }
  return Array.isArray(field) ? lot[field[0]] : lot[field];
}

interface PdfLotCellProps {
  col: HeadingType;
  lot: LotJson;
  statuses?: ComponentProps<typeof PdfLotCellStatus>['statuses'];
  taxById: TaxesById;
}

export default function PdfLotCell({ col, lot, statuses, taxById }: PdfLotCellProps) {
  if (!col || !taxById || !statuses) return null;

  switch (col.type) {
    case DATE: {
      return col.field ? <Text>{showQuarter(getColValue(lot, col.field))}</Text> : null;
    }

    case LOT:
      return <Text style={styles.bold}>{getColValue(lot, col.field) ?? '-'}</Text>;

    case PRICE: {
      const value = getColValue(lot, col.field);
      return (
        <View>
          <Text style={styles.bold}>{value > 0 ? formatPrice(value) : '-'}</Text>
          {lotIsControlledPrice(lot) && value > 0 && <Text>{LABEL_CONTROLLED_PRICE}</Text>}
        </View>
      );
    }

    case STATUS:
      return (
        <PdfLotCellStatus
          content={getColValue(lot, col.field) ?? '-'}
          lot={lot}
          statuses={statuses}
        />
      );

    case SURFACE: {
      const value = getColValue(lot, col.field);
      if (value <= 0) return null;
      return <Text style={styles.bold}>{value ?? '-'}</Text>;
    }

    case SURFACE_ANNEX: {
      const value = getColValue(lot, col.field);
      if (value <= 0) return null;
      return <Text>{value}</Text>;
    }

    case TAX: {
      const tax = taxById[getColValue(lot, col.field)];
      if (!tax) return null;
      return (
        <View style={styles.tax}>
          <PdfPastilleFiscality tax={tax} />
        </View>
      );
    }

    default:
      return <Text>{getColValue(lot, col.field) ?? '-'}</Text>;
  }
}
