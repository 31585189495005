import React, { useContext, useEffect, useState } from 'react';
import type { MouseEvent } from 'react';
import { IconButton, Dialog as MuiDialog } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

import { replaceBold, replaceTokens } from 'services/formatter';
import { htmlEntityDecode } from 'services/stringHtml';
import {
  LABEL_CONFIRM_CANCEL_OPTION,
  LABEL_CONFIRM_CANCEL_OPTION_FAIL,
  LABEL_MODAL_CONTENT_CANCEL_OPTION,
  LABEL_MODAL_CONTENT_CANCEL_OPTION_EXP,
  LABEL_MODAL_FAIL_CANCEL_BOLD,
  LABEL_MODAL_FAIL_CANCEL_CONTACT,
  LABEL_MODAL_FAIL_CANCEL_OPTION_TITLE,
  LABEL_MODAL_TITLE_CANCEL_OPTION,
} from 'settings/labels';
import { MODAL_ID_CANCEL_OPTION } from 'settings/modal';
import { TOKEN_DATE, TOKEN_NB } from 'settings/token';

import { useCancelOption } from 'hooks/useActivities';

import { userContext } from 'modules/App/Contexts';

import ModalConfirm from 'commonUi/ModalConfirm/ModalConfirm';
import SvgIcon from 'commonUi/SvgIcon/SvgIcon';
import Toast, { TOAST_VARIANT_ERROR } from 'commonUi/Toast/Toast';

import { ModalType } from '../Modal';

import styles from './ModalCancelOption.module.scss';

export interface ModalCancelOptionType {
  activityId: string | undefined;
  expirationDate?: Date;
  lotNumber: string | undefined;
  onClose: () => void;
  onSuccess?: (type: ModalType, activityId?: string) => void;
  open: boolean;
  optimisticSuccess?: () => void;
  undoOptimisticSuccess?: () => void;
}

export default function ModalCancelOption({
  activityId,
  expirationDate,
  lotNumber,
  onSuccess,
  open,
  onClose,
  optimisticSuccess,
  undoOptimisticSuccess,
}: ModalCancelOptionType) {
  const { enqueueSnackbar } = useSnackbar();
  const cancelOption = useCancelOption();
  const [isLoading, setIsLoading] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const { userCrm } = useContext(userContext);

  const date = userCrm?.extension_VI3P_DroitsCommerciaux_DureeOption
    ? dayjs(expirationDate).add(userCrm.extension_VI3P_DroitsCommerciaux_DureeOption, 'd')
    : dayjs(expirationDate);

  function handleClose(ev: MouseEvent) {
    ev.stopPropagation();
    if (onClose) {
      onClose();
    }
    setHasFailed(false);
  }

  useEffect(() => {
    // Reset the failed state when opening the modal
    if (open) {
      setHasFailed(false);
      setIsLoading(false);
    }
  }, [open]);

  return (
    <>
      <ModalConfirm
        data-test-id="modal-cancel-option"
        loading={isLoading}
        onCancel={handleClose}
        onConfirm={ev => {
          ev.stopPropagation();
          if (!activityId) return;
          setIsLoading(true);
          if (optimisticSuccess) {
            optimisticSuccess();
          }
          cancelOption(activityId)
            .then(() => {
              if (onSuccess) onSuccess(MODAL_ID_CANCEL_OPTION);
              if (typeof onClose === 'function') {
                onClose();
              }
              enqueueSnackbar(LABEL_CONFIRM_CANCEL_OPTION);
            })
            .catch(() => {
              if (undoOptimisticSuccess) {
                undoOptimisticSuccess();
              }
              enqueueSnackbar(LABEL_CONFIRM_CANCEL_OPTION_FAIL, {
                content: (key, message) => (
                  <Toast id={key} message={message} variant={TOAST_VARIANT_ERROR} />
                ),
              });
              setHasFailed(true);
            })
            .finally(() => setIsLoading(false));
        }}
        onClick={ev => ev.stopPropagation()} // This is necessary to avoid propagating the click on the backdrop
        open={open && !hasFailed}
        title={LABEL_MODAL_TITLE_CANCEL_OPTION}
      >
        <p className={styles.textMain}>
          {replaceTokens(LABEL_MODAL_CONTENT_CANCEL_OPTION, {
            [TOKEN_NB]: lotNumber as NonNullable<typeof lotNumber>,
          })}
        </p>
        {date.isValid() && (
          <p
            className={styles.textSecondary}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: htmlEntityDecode(
                replaceBold(
                  replaceTokens(LABEL_MODAL_CONTENT_CANCEL_OPTION_EXP, {
                    [TOKEN_DATE]: date.format('DD/MM/YYYY'),
                  })
                )
              ),
            }}
          />
        )}
      </ModalConfirm>

      <MuiDialog
        open={open && hasFailed}
        onClose={handleClose}
        onClick={ev => ev.stopPropagation()} // This is necessary to avoid propagating the click on the backdrop
        classes={{
          paper: styles.failSkin,
        }}
      >
        <div className={styles.inner}>
          <div className={styles.failHeader}>
            <IconButton classes={{ root: styles.iconButton }} disableRipple onClick={handleClose}>
              <SvgIcon className={styles.svgIcon} iconId="icon-cross" />
            </IconButton>
          </div>
          <div className={styles.failContent}>
            <h2 className={styles.title}>{LABEL_MODAL_FAIL_CANCEL_OPTION_TITLE}</h2>

            <p>
              <strong>{LABEL_MODAL_FAIL_CANCEL_BOLD}</strong>
            </p>
            <p>{LABEL_MODAL_FAIL_CANCEL_CONTACT}</p>
          </div>
        </div>
      </MuiDialog>
    </>
  );
}
