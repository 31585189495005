import React from 'react';
import type { ReactNode } from 'react';
import classname from 'classnames';

import { SORT_ORDER_ASC, SORT_ORDER_DESC } from 'settings/search';

import styles from './SortItem.module.scss';

interface SortItemProps {
  className?: string;
  onClick: (type: string) => void;
  sortBy: string;
  sortOrder: string;
  title: ReactNode;
  type: string;
  withSortIndicator?: boolean;
}

export default function SortItem({
  className,
  onClick = () => {},
  sortBy,
  sortOrder,
  title,
  type,
  withSortIndicator = true,
}: SortItemProps) {
  const sortAsc = sortBy === type && sortOrder === SORT_ORDER_ASC;
  const sortDesc = sortBy === type && sortOrder === SORT_ORDER_DESC;

  return (
    <button
      type="button"
      className={classname(className, styles.root, {
        [styles.sortAsc]: sortAsc,
        [styles.sortDesc]: sortDesc,
      })}
      onClick={() => onClick(type)}
    >
      {title}
      {withSortIndicator && <div className={styles.sortIndicator} />}
    </button>
  );
}
