import React from 'react';
import Button from '@material-ui/core/Button';

import { COOKIE_TEST_MODAL_AUTO_OPEN } from 'settings/cookies';

import { isTestCookieEnabled } from 'services/cookies';

import BlocAlerteModal from 'sharedModulesV4/BlocAlerteModal/BlocAlerteModal';

import ScrollToTop from 'commonUi/ScrollToTop/ScrollToTop';

import RouterSwitch from 'router/RouterSwitch';

import BlocAlerteModalProviderContainer from 'modules/App/Providers/BlocAlerteModalProviderContainer';
import ModalUiProvider from 'modules/App/Providers/ModalUiProvider';
import ProgramLotProvider from 'modules/App/Providers/ProgramLotProvider';
import ResponsiveProvider from 'modules/App/Providers/ResponsiveProvider';
import SettingsProvider from 'modules/App/Providers/SettingsProvider';
import SnackbarProvider from 'modules/App/Providers/SnackbarProvider';
import TaxonomiesProvider from 'modules/App/Providers/TaxonomiesProvider';
import TmsProvider from 'modules/App/Providers/TmsProvider';
import UserProvider from 'modules/App/Providers/UserProvider';

export default function AppLayout() {
  const preventModalAutoOpen = isTestCookieEnabled(COOKIE_TEST_MODAL_AUTO_OPEN);

  return (
    <ResponsiveProvider>
      <SnackbarProvider>
        <ModalUiProvider>
          <TaxonomiesProvider>
            <SettingsProvider>
              <UserProvider>
                <ProgramLotProvider>
                  <TmsProvider>
                    <BlocAlerteModalProviderContainer theme="vi3p">
                      <ScrollToTop>
                        <RouterSwitch />
                        <BlocAlerteModal customCTA={Button} disable={preventModalAutoOpen} />
                      </ScrollToTop>
                    </BlocAlerteModalProviderContainer>
                  </TmsProvider>
                </ProgramLotProvider>
              </UserProvider>
            </SettingsProvider>
          </TaxonomiesProvider>
        </ModalUiProvider>
      </SnackbarProvider>
    </ResponsiveProvider>
  );
}
