import type { ComponentProps } from 'react';
import React, { useContext, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import classnames from 'classnames';

import { TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS } from 'settings/taxes';

import { TMS_TIMEOUT } from 'settings/tms';
import { LABEL_SEARCH_NO_RESULTS, LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS } from 'settings/labels';

import { LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import { SelectedLotType } from 'types/lots';

import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';

import { getHeadingsWidth, getLotHeadings } from 'services/lotsV2';
import { modifyQuery } from 'services/url';

import SpinLoader from 'commonUi/SpinLoader/SpinLoader';
import TagCommanderEvent from 'modules/App/TagCommander/TagCommanderEvent';

import { useSearch } from '../../hooks/useSearch';

import LotTableDesktop from './LotTableDesktop';

import styles from './LotsList.module.scss';

interface LotsListDesktopProps
  extends Pick<
    ComponentProps<typeof LotTableDesktop>,
    'allLots' | 'allPrograms' | 'reorder' | 'sortBy' | 'sortOrder'
  > {
  isLoading?: boolean;
  isPanelExpanded: boolean;
  handleTogglePanel: () => void;
  lots: LotJson[];
  programs?: ProgramListType[];
  pageTemplate?: string;
  pageTemplateRef?: string;
  selectedLots: SelectedLotType[];
  setSelectedLots: (selectedLots: SelectedLotType[]) => void;
}

export default function LotsListDesktop({
  allLots,
  allPrograms,
  handleTogglePanel,
  lots,
  isLoading = false,
  isPanelExpanded,
  programs = [],
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  reorder,
  selectedLots,
  setSelectedLots,
  sortBy,
  sortOrder,
}: LotsListDesktopProps) {
  const history = useHistory();
  const { taxesById, isTaxesLoading } = useContext(TaxonomiesContext);
  let programsRef: { programRef: string }[] = [];
  if (programs?.length > 0) {
    programsRef = programs.map(program => ({ programRef: program.ref }));
  } else if (lots?.length > 0) {
    programsRef = lots?.map(lot => ({ programRef: lot.ref }));
  }

  const { locations, taxes, nearProgram } = useSearch();

  const taxeFormatted = taxes && taxes?.length === 1 ? taxesById?.[taxes?.[0]] : undefined;
  const headings = useMemo(() => {
    const isPinel =
      taxesById &&
      taxes.every(taxId =>
        [TAX_TYPE_PINEL, TAX_TYPE_PINEL_DEROGE, TAX_TYPE_PINEL_PLUS].includes(taxesById[taxId])
      );
    return getLotHeadings(
      !isPanelExpanded,
      isPinel && taxes.length > 1 ? TAX_TYPE_PINEL : taxeFormatted,
      true,
      false,
      'desktop'
    );
  }, [taxes, taxesById, isPanelExpanded]);

  const handleOnChangeCheckbox = (programRef: string, lotNumber: string) => {
    if (
      selectedLots.find(
        selectedLot => selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber
      )
    ) {
      setSelectedLots(
        selectedLots.filter(
          selectedLot =>
            !(selectedLot.programRef === programRef && selectedLot.lotNumber === lotNumber)
        )
      );
      return;
    }
    setSelectedLots([...selectedLots, { programRef, lotNumber }]);
  };

  const displayAnnex = !!nearProgram && locations.length === 1;

  const headingsWidth = getHeadingsWidth(!isPanelExpanded, taxeFormatted);

  const location = useLocation();
  const params = qs.parse(location.search);

  useEffect(() => {
    if (params?.from === 'display_lots_btn') {
      setTimeout(() => {
        history.replace(modifyQuery({}, ['from']));
      }, TMS_TIMEOUT);
    }
  });

  if (isLoading || isTaxesLoading) {
    return (
      <div className={styles.loading}>
        <SpinLoader width={100} height={100} />
      </div>
    );
  }

  return (
    <div className={classnames(styles.lotsList, { [styles.isExpanded]: isPanelExpanded })}>
      {(!programsRef?.length || displayAnnex) && (
        <div className={styles.noResults}>
          <span>{LABEL_SEARCH_NO_RESULTS}</span>
          {displayAnnex && !!programsRef?.length && (
            <span>{LABEL_SEARCH_NO_RESULTS_NEAR_PROGRAMS}</span>
          )}
        </div>
      )}
      <LotTableDesktop
        allLots={allLots}
        allPrograms={allPrograms}
        lots={lots}
        programsRef={programsRef}
        headings={headings}
        headingsWidth={headingsWidth}
        handleOnChangeCheckbox={handleOnChangeCheckbox}
        taxById={taxesById}
        isPanelExpanded={isPanelExpanded}
        handleTogglePanel={handleTogglePanel}
        reorder={reorder}
        selectedLots={selectedLots}
        sortBy={sortBy}
        sortOrder={sortOrder}
        pageTemplate={pageTemplate}
        pageTemplateRef={pageTemplateRef}
      />

      <TagCommanderEvent
        isActive={!!params?.from}
        navigation_pagename="resultats_programmes_afficher_lots"
        navigation_template="recherche"
        useEffectDeps={['navigation_pagename', 'navigation_template']}
      />
    </div>
  );
}
