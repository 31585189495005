import React, { useEffect, useRef } from 'react';
import type { PropsWithChildren } from 'react';

import { SettingsType } from 'api/vi3pAPI/apiTypes/SettingsType';

import SettingsContext, { settingsContextDefault } from 'modules/App/Contexts/SettingsContext';

import { useSWRVi3p } from 'api/vi3pAPI/useSWRVi3p';

export default function SettingsProvider({ children }: PropsWithChildren<Record<never, never>>) {
  const laggySettingsRef = useRef<SettingsType>(settingsContextDefault);
  const { data: settingsSwr, isValidating, mutate } = useSWRVi3p<SettingsType>({
    url: '/settings',
  });

  useEffect(() => {
    if (settingsSwr !== undefined) {
      laggySettingsRef.current = settingsSwr;
    }
  }, [settingsSwr]);

  const settings = settingsSwr === undefined ? laggySettingsRef.current : settingsSwr;
  const isLagging = settingsSwr === undefined && laggySettingsRef.current !== undefined;

  return (
    <SettingsContext.Provider value={{ settings, isLagging, isValidating, mutate }}>
      {settings ? children : null}
    </SettingsContext.Provider>
  );
}
