import React, { useEffect, useState } from 'react';
import type { ComponentProps } from 'react';
import ReactPDF from '@react-pdf/renderer';

import Button from 'commonUi/Button/Button';

export interface ButtonModalPdfProps
  extends Omit<ComponentProps<typeof Button>, 'loading' | 'onClick'> {
  clickOpenSendPDF: boolean;
  enablePdfGeneration?: boolean;
  generatePdf: () => void;
  handleClick: () => void;
  pdf: ReactPDF.UsePDFInstance | undefined;
}

export default function ButtonModalPdf({
  clickOpenSendPDF,
  enablePdfGeneration = true,
  generatePdf,
  handleClick,
  pdf,
  ...ButtonProps
}: ButtonModalPdfProps) {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!pdf) {
      setLoading(true);
      if (enablePdfGeneration) {
        generatePdf();
      }
    }
  }, [enablePdfGeneration]);

  useEffect(() => {
    if (clickOpenSendPDF && pdf?.url) {
      handleClick();
      setLoading(false);
    }
  }, [pdf?.url]);

  return <Button loading={loading} onClick={handleClick} {...ButtonProps} />;
}
