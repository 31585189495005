import React, { useContext } from 'react';
import type { ComponentProps } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { Button as ButtonMui } from '@material-ui/core';

import {
  LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_OPTION,
  LABEL_LOT_ACTION_TOOLTIP_PREBOOK,
  LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET,
} from 'settings/labels';
import {
  LOT_CELL_ID_FLOOR,
  LOT_CELL_ID_KIND,
  LOT_CELL_ID_NUMBER,
  LOT_CELL_ID_PARKING,
  LOT_CELL_ID_PLAN,
  LOT_CELL_ID_PROGRAM,
  LOT_CELL_ID_STATUS,
  LOT_CELL_ID_SURFACE,
  LOT_CELL_ID_SURFACE_ANNEX,
  LOT_CELL_ID_TAX,
  LOT_CELL_TYPE_ACTION,
  LOT_CELL_TYPE_CHECKBOX,
  LOT_CELL_TYPE_PROMO,
  LOT_STATUS_FREE,
  LOT_STATUS_OPTIONED,
  LOT_TABLETTE_HEADINGS,
} from 'settings/lots';
import { MODAL_ID_SEND_BOOKLET } from 'settings/modal';
import { PANEL_ID_FOLDERS } from 'settings/panels';

import type { HeadingType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';
import type { ProgramListType } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import ActivityModalsContext from 'modules/App/Contexts/activityModalsContext';
import { foldersContext } from 'modules/App/Contexts/foldersContext';

import { getLotActionButtons } from 'services/lotsV2';
import { modifyQuery } from 'services/url';

import { useBooklet } from 'hooks/useBooklet';
import { useModalMultiples } from 'hooks/useModal';
import { usePanels } from 'hooks/usePanels';

import Icon from 'sharedModulesV4/common/components/Atoms/Icon';

import Button from 'commonUi/Button/Button';
import PromoPastille from 'commonUi/PromoPastille/PromoPastille';
import ModalSendBooklet from 'modules/ActionsProgramsAndLots/ModalSendBooklet/ModalSendBooklet';
import FolderLinks from 'modules/Folders/FolderLinks';

import LotCell from '../LotCell/LotCell';

import styles from './LotRowTablette.module.scss';

interface LotRowTabletteProps {
  alerts?: ComponentProps<typeof LotCell>['alerts'];
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  hideMapButton?: boolean;
  isChecked?: boolean;
  isDrawerOpen: boolean;
  lot: LotJson;
  myOptions?: ComponentProps<typeof LotCell>['myOptions'];
  myBookings?: ComponentProps<typeof LotCell>['myBookings'];
  onStatusAlertClick: ComponentProps<typeof LotCell>['onStatusAlertClick'];
  pageTemplate?: string;
  pageTemplateRef?: string;
  programs?: ProgramListType[];
  reduceMobilePanel?: () => void;
  renderShowMore: () => JSX.Element | null;
  setCurrentRowOpen: (nid: string) => void;
  statuses: ComponentProps<typeof LotCell>['statuses'];
  taxById: TaxesById;
  updateStatus: (lotNid: string, newStatus: string) => void;
}

export default function LotRowTablette({
  handleOnChangeCheckbox = undefined,
  headings = [],
  hideMapButton = false,
  lot,
  renderShowMore,
  statuses,
  updateStatus,
  taxById = {},
  reduceMobilePanel = () => {},
  myOptions,
  myBookings,
  programs,
  onStatusAlertClick,
  alerts,
  isChecked = false,
  pageTemplate = undefined,
  pageTemplateRef = undefined,
  isDrawerOpen,
  setCurrentRowOpen,
}: LotRowTabletteProps) {
  const history = useHistory();
  const { tax } = lot;
  const { open, openModal, closeModal } = useModalMultiples();
  const { booklet } = useBooklet(lot.ref, open === MODAL_ID_SEND_BOOKLET);
  const program = programs?.find(program => program.ref === lot.ref);

  const option = myOptions?.find(
    option =>
      option.field_numerolot_ac === lot.number && option.field_referenceprogramme_ac === lot.ref
  );
  const preBooking = myBookings?.find(
    booking =>
      booking.field_numerolot_ac === lot.number && booking.field_referenceprogramme_ac === lot.ref
  );

  const bodyHeadings = headings.filter(({ id }) => !LOT_TABLETTE_HEADINGS.includes(id));
  const taxHead = headings.find(({ id }) => id === LOT_CELL_ID_TAX);
  const statusHead = headings.find(({ id }) => id === LOT_CELL_ID_STATUS);
  const programHead = headings.find(({ id }) => id === LOT_CELL_ID_PROGRAM);
  const checkboxHead = headings.find(({ id }) => id === LOT_CELL_TYPE_CHECKBOX);
  const numberHead = headings.find(({ id }) => id === LOT_CELL_ID_NUMBER);
  const taxName = taxById[tax];

  const { openCancelOption, openCancelPreBooking, openCreateOption } = useContext(
    ActivityModalsContext
  );
  const { emptyDatas, folderSaveType, setFolderLots, setFolderSaveType } = useContext(
    foldersContext
  );
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  const sortBy = [
    LOT_CELL_ID_PLAN,
    LOT_CELL_ID_KIND,
    LOT_CELL_ID_FLOOR,
    LOT_CELL_ID_PARKING,
    LOT_CELL_ID_SURFACE,
    LOT_CELL_ID_SURFACE_ANNEX,
  ];
  const bodyHeadingsSorted = sortBy
    .map(id => bodyHeadings.find(heading => heading.id === id))
    .concat(bodyHeadings.filter(heading => !sortBy.includes(heading.id)));

  function displayMapInfo() {
    if (typeof reduceMobilePanel === 'function') {
      reduceMobilePanel();
    }
    history.replace(
      modifyQuery({
        programRef: lot.program.ref,
        origin: 'list',
        lat: lot.program.lat,
        lng: lot.program.lng,
        zoom: 14,
      })
    );
  }

  function handleAddFolderClick() {
    if (folderSaveType !== 'lot') {
      emptyDatas();
    }
    openPanel(PANEL_ID_FOLDERS);
    setFolderSaveType('lot');
    setFolderLots([{ lotNumber: lot.number, programRef: lot.ref }]);
  }

  const {
    showCancelOption,
    showCancelPreBook,
    showMakeOption,
    showMakePrebook,
  } = getLotActionButtons(
    statuses?.[lot.nid]?.label ?? lot.status,
    Boolean(option) || Boolean(preBooking)
  );

  const hasPromo = !!lot.promotions && lot.promotions.length > 0;

  return (
    <>
      {isDrawerOpen && renderShowMore()}
      <div className={styles.resultItemWrapper}>
        <div className={classnames(styles.resultItemHeader, { [styles.withPromo]: hasPromo })}>
          {hasPromo && (
            <PromoPastille
              vertical
              classes={{ root: styles.promo, text: styles.text }}
              promotions={lot.promotions}
            />
          )}
          <div className={styles.leftContent}>
            {checkboxHead && (
              <LotCell
                className={styles.headerCell}
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={checkboxHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
                handleOnChangeCheckbox={handleOnChangeCheckbox}
                isChecked={isChecked}
              />
            )}
            {numberHead && (
              <LotCell
                className={styles.headerCell}
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={numberHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
              />
            )}
            {taxName && taxHead && (
              <LotCell
                className={styles.headerCell}
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={taxHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
              />
            )}
            {statusHead && (
              <LotCell
                className={styles.headerCell}
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={statusHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
                pageTemplate={pageTemplate}
                pageTemplateRef={pageTemplateRef}
              />
            )}

            {programHead && (
              <LotCell
                className={styles.headerCell}
                onStatusAlertClick={onStatusAlertClick}
                myBookings={myBookings}
                myOptions={myOptions}
                alerts={alerts}
                col={programHead}
                lot={lot}
                statuses={statuses}
                taxById={taxById}
              />
            )}
          </div>

          <div className={styles.rightContent}>
            {!hideMapButton && (
              <button className={styles.showMapBtn} type="button" onClick={displayMapInfo}>
                <Icon className={styles.showMapBtnIcon} icon="map-plan" />
              </button>
            )}
            <div className={styles.btnDetailsContainer}>
              <ButtonMui
                onClick={() => setCurrentRowOpen(lot.nid)}
                classes={{ root: styles.rootBtnDetails }}
                disableRipple
                fullWidth
              >
                <Icon icon="chevron-down" />
              </ButtonMui>
            </div>
          </div>
        </div>

        <dl className={styles.resultItemInfo}>
          {bodyHeadingsSorted.map((col: HeadingType) => {
            if (col.type === LOT_CELL_TYPE_ACTION) {
              return null;
            }

            if (col.type === LOT_CELL_TYPE_PROMO) {
              return lot.promotions?.length ? (
                <div key={col.id}>
                  <span className={styles.dot} />

                  <dt>
                    {col.title}
                    {col.sub && <br />}
                    {col.sub && <span className={styles.additionnalInfo}> {col.sub}</span>}
                  </dt>
                  <dd>
                    <PromoPastille promotions={lot.promotions} />
                  </dd>
                </div>
              ) : null;
            }

            return (
              <div key={col.id}>
                <span className={styles.dot} />

                <dt>
                  {col.title}
                  {col.sub && <br />}
                  {col.sub && <span className={styles.additionnalInfo}> {col.sub}</span>}
                </dt>
                <dd>
                  <LotCell
                    onStatusAlertClick={onStatusAlertClick}
                    myBookings={myBookings}
                    myOptions={myOptions}
                    alerts={alerts}
                    col={col}
                    lot={lot}
                    statuses={statuses}
                    taxById={taxById}
                  />
                </dd>
              </div>
            );
          })}
        </dl>

        <div className={styles.actionsBtn}>
          {showMakeOption && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-option"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_OPTION}
                onClick={() =>
                  openCreateOption(
                    {
                      lotNumber: lot.number,
                      programRef: lot.ref,
                      programName: lot.program.name,
                    },
                    () => {
                      if (typeof updateStatus === 'function') {
                        updateStatus(lot.nid, LOT_STATUS_OPTIONED);
                      }
                    }
                  )
                }
              />
            </span>
          )}
          {showCancelOption && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-cancel"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_OPTION}
                onClick={() => {
                  if (option?.field_idcrm_ac && option.field_dateexpiration_ac) {
                    openCancelOption(
                      {
                        activityId: option.field_idcrm_ac,
                        expirationDate: new Date(option.field_dateexpiration_ac),
                        lotNumber: option.field_numerolot_ac,
                      },
                      () => {
                        if (typeof updateStatus === 'function') {
                          updateStatus(lot.nid, LOT_STATUS_FREE);
                        }
                      }
                    );
                  }
                }}
              />
            </span>
          )}
          {showMakePrebook && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-pre-book"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_PREBOOK}
                component={RouterLink}
                to={`/pre-reservation/${lot.ref}/${lot.number}?backUrl=${encodeURIComponent(
                  window.location.href
                )}`}
              />
            </span>
          )}
          {showCancelPreBook && (
            <span className={styles.actionBtnContainer}>
              <Button
                className={styles.actionBtn}
                color="primary"
                iconId="icon-pre-book"
                iconClass={styles.buttonSvgIcon}
                labelClassName={styles.buttonLabel}
                variant="outlined"
                tooltip={LABEL_LOT_ACTION_TOOLTIP_CANCEL_PREBOOK}
                onClick={() => {
                  if (preBooking?.field_idcrm_ac && preBooking.field_dateexpiration_ac) {
                    openCancelPreBooking(
                      {
                        activityId: preBooking.field_idcrm_ac,
                        expirationDate: new Date(preBooking.field_dateexpiration_ac),
                        lotNumber: preBooking.field_numerolot_ac,
                      },
                      () => {
                        if (typeof updateStatus === 'function') {
                          updateStatus(lot.nid, LOT_STATUS_FREE);
                        }
                      }
                    );
                  }
                }}
              />
            </span>
          )}
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-add-to-folder"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_ADD_FOLDER}
              color="primary"
              fullWidth
              onClick={handleAddFolderClick}
            />
          </span>
          <span className={styles.actionBtnContainer}>
            <Button
              className={styles.actionBtn}
              variant="outlined"
              iconClass={styles.svgIcon}
              iconId="icon-booklet"
              tooltip={LABEL_LOT_ACTION_TOOLTIP_SEND_BOOKLET}
              color="primary"
              fullWidth
              onClick={() => openModal(MODAL_ID_SEND_BOOKLET)}
            />
          </span>
        </div>

        <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />

        {open === MODAL_ID_SEND_BOOKLET && (
          <ModalSendBooklet booklet={booklet} closeCallBack={closeModal} open program={program} />
        )}
      </div>
    </>
  );
}
