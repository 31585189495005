/* eslint-disable react/prop-types */
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isAuthenticated, isInvite } from 'services';
import { getLoginUrlWithState } from 'services/url';

import AuthenticatedLayout from 'layout/AuthenticatedLayout';
import PendingValidation from 'modules/Authentication/PendingValidation';

import ErrorBoundary from './Tools/ErrorBoundary';

function AuthenticatedRoute(props) {
  const {
    component: Component,
    location, // location is automatically provided by the router switch
    noFooter,
    noHeader = false,
    path,
    ...rest
  } = props;

  // Go to Login page if we are no longer connected.
  if (!isAuthenticated()) {
    window.location.href = getLoginUrlWithState();
    return null;
  }

  // Don't display "pending validation" for technical uri"
  if (!['/deconnexion', '/refresh-aadb2c'].includes(path) && isInvite()) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated() ? (
            <AuthenticatedLayout hideFooter={noFooter} hideHeader={noHeader}>
              <ErrorBoundary location={location}>
                <PendingValidation {...props} />
              </ErrorBoundary>
            </AuthenticatedLayout>
          ) : (
            <Redirect to="/deconnexion" />
          )
        }
      />
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <AuthenticatedLayout hideFooter={noFooter} hideHeader={noHeader}>
          <ErrorBoundary location={location}>
            <Component {...props} />
          </ErrorBoundary>
        </AuthenticatedLayout>
      )}
    />
  );
}

export default AuthenticatedRoute;
