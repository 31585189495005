import React, { useEffect, useState } from 'react';
import type { ComponentProps } from 'react';
import ReactPDF from '@react-pdf/renderer';

import type { TMS_DEFAULT_VALUES } from 'settings/tms';

import type { ProgramTypeV2 } from 'api/viOffresAPI/apiTypes/Program';
import type { TaxesById } from 'api/viOffresAPI/apiTypes/Taxonomies';

import PdfButtonUI from './PdfButtonUI';
import PdfButtonWithLoadingSystem from './PdfButtonWithLoadingSystem';

interface ProgramPdfDropdownWithLoadingProps {
  allLots?: ComponentProps<typeof PdfButtonWithLoadingSystem>['allLots'];
  allPrograms?: ComponentProps<typeof PdfButtonWithLoadingSystem>['allPrograms'];
  button: ComponentProps<typeof PdfButtonUI>['button'];
  generateProgramPdf?: () => void;
  isButtonActive: boolean;
  loadPdf: (instance: ReactPDF.UsePDFInstance) => void;
  openDropdownTop?: boolean;
  openModal: (modalId: string) => void;
  program: ProgramTypeV2;
  programPdf?: ComponentProps<typeof PdfButtonWithLoadingSystem>['programPdf'];
  setActiveButton: (activeButton: any) => void;
  taxById: TaxesById;
  tms?: Partial<typeof TMS_DEFAULT_VALUES>;
  waitForPdfDatas?: boolean;
}

export default function ProgramPdfDropdownWithLoading({
  allLots,
  allPrograms,
  button,
  generateProgramPdf,
  isButtonActive,
  loadPdf,
  openDropdownTop = false,
  openModal,
  program,
  programPdf,
  setActiveButton,
  taxById,
  tms,
  waitForPdfDatas = false,
}: ProgramPdfDropdownWithLoadingProps) {
  const [loading, setLoading] = useState<boolean>(false);

  function handleClick() {
    setLoading(true);
  }

  useEffect(() => {
    if (loading && !waitForPdfDatas && generateProgramPdf) {
      generateProgramPdf();
    }
  }, [loading]);

  return generateProgramPdf ? (
    <PdfButtonUI
      onClick={handleClick}
      button={button}
      isButtonActive={isButtonActive}
      loading={loading}
      setActiveButton={setActiveButton}
      tms={tms}
    />
  ) : (
    <PdfButtonWithLoadingSystem
      allLots={allLots}
      allPrograms={allPrograms}
      loadPdf={loadPdf}
      button={button}
      isButtonActive={isButtonActive}
      setActiveButton={setActiveButton}
      openDropdownTop={openDropdownTop}
      openModal={openModal}
      program={program}
      programPdf={programPdf}
      taxById={taxById}
      tms={tms}
    />
  );
}
