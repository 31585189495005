import React, { useContext, useEffect, useRef, useState } from 'react';
import type { ComponentProps } from 'react';

import useGetLotsStatuses from 'hooks/useGetLotsStatuses';
import useLoadProgramAndLot from 'hooks/useLoadProgramAndLot';
import { usePanels } from 'hooks/usePanels';

import { userContext } from 'modules/App/Contexts';
import TaxonomiesContext from 'modules/App/Contexts/TaxonomiesContext';
import SettingsContext from 'modules/App/Contexts/SettingsContext';

import { useSearch } from 'modules/HomePage/hooks/useSearch';

import { getColumnWidth } from 'services/ui';
import { LABEL_LOTS_ENABLE_ERROR_SUBTITLE, LABEL_LOTS_ENABLE_ERROR_TITLE } from 'settings/labels';
import { HeadingType, HeadingsWidthType, LotJson } from 'api/viOffresAPI/apiTypes/LotType';

import TableV2 from 'commonUi/Table/TableV2';
import LotRow from 'commonUi/ListLotV2/LotRow/LotRow';
import LotTableErrorModal from 'commonUi/ListLotV2/LotTable/LotTableErrorModal';
import { LotDetailsCpn } from 'modules/DetailsLotsAndActivity/LotDetailsCpn';
import LotCell from 'commonUi/ListLotV2/LotCell/LotCell';
import TableHeader from 'commonUi/Table/TableHeader/TableHeader';
import LotScrollingRow from 'commonUi/ListLotV2/LotRow/LotScrollingRow';
import FolderLinks from 'modules/Folders/FolderLinks';

interface LotTableDesktopProps {
  allLots: ComponentProps<typeof LotDetailsCpn>['allLots'];
  allPrograms: ComponentProps<typeof LotDetailsCpn>['allPrograms'];
  data?: LotJson[];
  handleOnChangeCheckbox?: (programRef: string, lotNumber: string) => void;
  headings?: HeadingType[];
  headingsWidth?: HeadingsWidthType;
  reorder?(sortBy: HeadingType): void;
  selectedLots?: { lotNumber: string; programRef: string }[];
  sortBy: string;
  sortOrder: string;
}

export default function LotTableDesktop({
  allLots,
  allPrograms,
  data = [],
  handleOnChangeCheckbox = undefined,
  headings = [],
  headingsWidth = undefined,
  reorder = () => {},
  selectedLots = [],
  sortBy,
  sortOrder,
}: LotTableDesktopProps) {
  const { settings, isValidating: isSettingsValidating } = useContext(SettingsContext);
  const isAttributionSwitchedOn = !isSettingsValidating && !!settings?.cdo?.liste_attribution;
  const { options: myOptions, preBookings: myBookings, alerts, toggleAlert, userCrm } = useContext(
    userContext
  );
  const { lotNumber, programRef } = useSearch();

  const { taxes, taxesById } = useContext(TaxonomiesContext);
  const [currentRowOpen, setCurrentRowOpen] = useState<string>();
  const [loadLotError, setLoadLotError] = useState<boolean>(
    typeof lotNumber !== 'undefined' &&
      typeof programRef !== 'undefined' &&
      data.length > 0 &&
      !data.some(lot => lot.number === lotNumber && lot.ref === programRef)
  );
  const [openError, setOpenError] = useState<boolean>(false);

  // load current lot
  const { number: currentLotNumber, ref: currentProgramRef } =
    data?.find(lot => currentRowOpen === lot.nid) ?? {};
  const {
    lot: lotDetail,
    program: programDetail,
    isLoading: isDetailsLoading,
    isError,
  } = useLoadProgramAndLot(currentProgramRef, currentLotNumber);

  const rowsListRef = useRef(null);
  const { open: idOpenedPanel, openPanel, closePanel } = usePanels();

  useEffect(() => {
    if (lotNumber) {
      const activeIndex = data?.findIndex(l => l.number === lotNumber);
      const currentOpen = activeIndex !== -1 ? data[activeIndex].nid : undefined;
      setCurrentRowOpen(currentOpen);
    }
  }, [lotNumber]);

  useEffect(() => {
    if (!isDetailsLoading && isError) {
      setOpenError(true);
    }
  }, [isDetailsLoading, isError]);

  // put current lot on top of the list
  let sortedData = data;
  if (data && lotNumber && programRef) {
    const lotIndex = data.findIndex(lot => lot.number === lotNumber && lot.ref === programRef);
    if (lotIndex !== -1) {
      sortedData = [data[lotIndex], ...data.slice(0, lotIndex), ...data.slice(lotIndex + 1)];
    }
  }
  const { statuses, updateStatus } = useGetLotsStatuses(
    sortedData,
    // If attribution switch is ON, the lots have already been filtered for it in `useLot`
    isAttributionSwitchedOn ? undefined : userCrm?.extension_VI3P_ListeAttribution
  );

  if (!sortedData?.length) return null;

  return (
    <>
      <TableV2
        disableHeight
        error={
          <LotTableErrorModal
            open={loadLotError || openError}
            onClose={() => {
              setOpenError(false);
              setLoadLotError(false);
            }}
            title={LABEL_LOTS_ENABLE_ERROR_TITLE}
            subtitle={LABEL_LOTS_ENABLE_ERROR_SUBTITLE}
          />
        }
        headings={headings}
        id="programs-lots-list"
        rowCount={sortedData.length}
        rowsListRef={rowsListRef}
        renderHeader={col => (
          <TableHeader
            key={col.id}
            col={col}
            reorder={reorder}
            sortBy={sortBy}
            sortOrder={sortOrder}
            style={{
              width: headingsWidth ? getColumnWidth(col, headings, headingsWidth) : undefined,
            }}
          />
        )}
        renderRow={({ index, isScrolling, isVisible, key, style }) => {
          if (!isVisible && isScrolling) {
            return (
              <LotScrollingRow
                key={key}
                cellsWidth={headingsWidth}
                headings={headings}
                index={index}
                style={style}
              />
            );
          }

          return (
            <LotRow
              key={key}
              cellsWidth={headingsWidth}
              isCurrentRowOpen={currentLotNumber === sortedData[index]?.number && !isDetailsLoading}
              onClickRow={() => {
                setCurrentRowOpen(
                  currentRowOpen === sortedData[index]?.nid ? undefined : sortedData[index].nid
                );
              }}
              data={sortedData[index]}
              headings={headings}
              index={index}
              style={style}
              renderCell={(col, handleDisplayLot, isLotOpen) => (
                <LotCell
                  handleDisplayLot={() => {
                    handleDisplayLot();
                  }}
                  isLotOpen={isLotOpen}
                  isDetailsLoading={isDetailsLoading && currentRowOpen === sortedData[index]?.nid}
                  onStatusAlertClick={toggleAlert}
                  myBookings={myBookings}
                  myOptions={myOptions}
                  alerts={alerts}
                  handleOnChangeCheckbox={handleOnChangeCheckbox}
                  col={col}
                  lot={sortedData[index]}
                  statuses={statuses}
                  taxById={taxesById}
                  isChecked={selectedLots.some(
                    l =>
                      l.lotNumber === data[index].number &&
                      l.programRef === sortedData[index].program.ref
                  )}
                />
              )}
              renderShowMore={index => {
                if (!lotDetail || !programDetail) {
                  return null;
                }
                return (
                  <LotDetailsCpn
                    allLots={allLots}
                    allPrograms={allPrograms}
                    lotApiOffre={lotDetail}
                    programDatas={programDetail}
                    myOptions={myOptions}
                    myBookings={myBookings}
                    fiscalities={taxes}
                    index={index}
                    statuses={statuses}
                    updateStatus={updateStatus}
                    openDropdownTop={index === sortedData.length - 1}
                    openPanel={openPanel}
                    taxById={taxesById}
                  />
                );
              }}
            />
          );
        }}
      />

      <FolderLinks closePanel={closePanel} idPanelOpen={idOpenedPanel} />
    </>
  );
}
